import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DSystemsServicios from '../../../Services/DatamovilSystems/ServiciosDatamovil';
import Llamada from '../../../Services/Cartera/Llamadas';

const useCostosAdicionales = (handleConcluido) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { state } = useLocation();

    const initial = {

    };

    const [initialValue, setInitialValue] = useState(initial);
    const [loading, setLoading] = useState(false);

    const location = useLocation();
    let url = location.pathname.split("/");

    const opcionesBool = [{ value: true, label: 'Si' }, { value: false, label: 'No' }];
    const tipoUbicaciones = [
        { value: 1, label: 'Interna' },
        { value: 2, label: 'Externa' },
    ];

    useEffect(() => {
        getDataCostoAdicional();
    }, []);

    const getDataCostoAdicional = async () => {
        setLoading(true);
        await DSystemsServicios.getServicioCostosAdicionales(id)
            .then(res => {
                let resData = res.data
                setInitialValue({ ...initialValue, ...resData })
                handleConcluido({ concluido: resData.concluido })
            })
            .catch(error => {
                //Error al obtener datos de revision
            })
            .finally(() => {
                setLoading(false);
            })
    };

    const submitForm = async (values) => {
        setLoading(true);
        values.procesoId = state.tipoProceso
        values.paso = url[5]
        values.porcentaje = 100
        values.status = 2
        DSystemsServicios.updateServicioCostosAdicionales(values)
            .then(res => {
                handleConcluido({ concluido: values.concluido })
                setInitialValue(values)
            })
            .catch(error => {
                //Error al obtener datos de revision
            })
            .finally(() => {
                setLoading(false);
                getDataCostoAdicional();
            })
    };


    return {
        initialValue,
        loading,
        setLoading,
        submitForm
    };
};

export default useCostosAdicionales;
