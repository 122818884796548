import React, { useRef, useState, useEffect } from 'react';
import Modal from '../../Components/Modal/Modal';
import { Formik } from 'formik';
import { TextField } from '../TextField';
import Parametros from '../../Services/Parametros/Parametros';
import * as Yup from 'yup';

const EditarCobranzaParametroModal = ({ isOpen, setIsOpen, data, handleGetAllParametrosCartera, toast }) => {

    const refFormik = useRef();
    const [parametroValor, setParametroValor] = useState(data.valorpublico);
    // const [parametroExtraValor, setParametroExtraValor] = useState(data.extravalue);

    const inicial = {
        parametroValor: data.valorpublico, // Valor inicial 
        tipodato: data.tipodato
        // parametroExtraValor: data.extravalue,//Valor inicial
    }

    const [initialValue, setInitialValue] = useState(inicial)

    const handleCancelar = () => {
        setIsOpen(false);
    };

    const handleContinuar = () => {
        // Verifica que data no sea undefined
        if (!data) {
            console.error("No se han proporcionado datos para el modal.");
            return;
        }

        Actualizar();
        setIsOpen(false);
    };

    //Update register
    const Actualizar = async () => {

        const userId = sessionStorage.getItem('userId').trim()

        let updateData =
        {
            "id": data.id,
            AnyObject: {
                "id": data.id,
                "descripcion": data.descripcion,
                "valor": data.valor,
                "useridalta": data.useridalta,
                "datealta": data.datealta,
                "useridupdate": userId,
                "dateupdate": dateFormat(new Date()),
                "status": data.status,
                "categoria": data.categoria,
                "extravalue": data.extravalue,
                "categoriapublica": data.categoriapublica,
                "valorpublico": parametroValor,
                "tipodato": data.tipodato
            }
        }


        await Parametros.CreateParametro(updateData)
            .then(res => {
                //Insertar log al terminar
                InsertarLog();
                handleGetAllParametrosCartera()
                setIsOpen(false);
                // window.location.reload();
            }).catch(e => {
            })


    }


    // Insertar Log

    const InsertarLog = async () => {

        const userId = sessionStorage.getItem('userId').trim()

        //Log parametro valor
        if (parametroValor != data.valorpublico) {

            let newLogData =
            {
                AnyObject: {
                    "parametro_cartera_id": data.id,
                    "valor_anterior": data.valorpublico,
                    "valor_nuevo": parametroValor,
                    "useridupdate": userId,
                    "dateupdate": dateFormat(new Date())
                }
            }


            await Parametros.CreateCobranzaParametrosLog(newLogData)
                .then(res => {

                }).catch(e => {
                })
        }
        //log extra value
        // if (parametroExtraValor != data.extravalue) {

        //     let newLogData =
        //     {
        //         AnyObject: {
        //             "parametro_cartera_id": data.id,
        //             "valor_anterior": data.extravalue,
        //             "valor_nuevo": parametroExtraValor,
        //             "useridupdate": userId,
        //             "dateupdate": dateFormat(new Date())
        //         }
        //     }


        //     await Parametros.CreateCobranzaParametrosLog(newLogData)
        //         .then(res => {

        //         }).catch(e => {
        //         })
        // }


    }

    // Utilerias
    const dateFormat = (date) => {
        let dateMDY = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}`;
        return dateMDY;
    }

    const validationSchema = Yup.object({
        parametroValor: Yup.mixed()
        .when("tipodato", {
          is: "numero", // Cuando x es igual a "number"
          then: Yup.number()
            .typeError("Debe ser un número")
            .required("ParametroValor es obligatorio")
            .moreThan(0, "Debe ser un número mayor a 0"),
          otherwise: Yup.string()
            .required("ParametroValor es obligatorio")
            .typeError("Debe ser texto"),
        }),
    })

    return (
        <>
            <Formik
                enableReinitialize={true}
                innerRef={refFormik}
                initialValues={initialValue}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    handleContinuar(values); // Envia los datos al servicio al confirmar
                }}
            >
                {({ setFieldValue, handleSubmit, isValid }) => (
                    <Modal isOpen={isOpen} color='#fff' width={500}>
                        <div>
                            <div className='justify-content-start p-2 align-items-center'>
                                <div className="row">
                                    <div className="col col-12">
                                        <div>
                                            <h4><strong>Editar {data.descripcion}</strong></h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col col-12 mt-4 pb-4">
                                        <div>
                                            A continuación se puede modificar el parámetro seleccionado.
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <TextField id="parametroValor" label="Valor" name="parametroValor" type="text" holder="Escribe el Valor"
                                        className="form-control col-12" onChange={(event) => {
                                            setFieldValue("parametroValor", event.target.value);
                                            setParametroValor(event.target.value);
                                        }} />
                                </div>
                                {/* <div className="row pt-3">
                                    <TextField id="parametroExtraValor" label="Valor Extra" name="parametroExtraValor" type="text" holder="Valor extra"
                                        className="form-control col-12" onChange={(event) => {
                                            setFieldValue("parametroExtraValor", event.target.value);
                                            setParametroExtraValor(event.target.value);
                                        }} />
                                </div> */}
                                <div className="row">
                                    <div className="col col-12 mt-4 pb-4">
                                        <div>
                                            <label>Los cambios realizados en el parametro afectarán en el flujo de los procesos correspondientes.</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-4">
                                    <div className="col-lg-6 col-6" style={{ display: 'flex', flexFlow: 'column' }}>
                                        <button
                                            id="BtnCloseModal"
                                            className='btn btn-outline btn-block'
                                            onClick={handleCancelar}
                                        >
                                            Cancelar
                                        </button>
                                    </div>
                                    <div className="col-lg-6 col-6" style={{ display: 'flex', flexFlow: 'column' }}>
                                        <button
                                            id="BtnConfirmModal"
                                            className='btn btn-blue btn-block'
                                            onClick={handleSubmit}
                                            disabled={!(isValid)}
                                        >
                                            Continuar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                )}
            </Formik>

        </>
    )
}

export default EditarCobranzaParametroModal