import { Formik, Form, Field, ErrorMessage } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup';
import Modal from '../../../../Components/Modal/Modal';
import Spinner from '../../../../Components/Loadig';
import { TextAreaField } from '../../../../Components/TextAreaField';
import { message, getDateNow,FormatFecha,formatearMontoMx} from '../../../../Utils/functions';
import UnidadesRecuperadasService from '../../../../Services/Cartera/UnidadesRecuperadas';
import CobranzaService from '../../../../Services/Cobranza/Cobranza';
import { SelectField } from '../../../../Components/SelectField';


const AmpliacionPromesasdePagoModal = ({ isOpen, setIsOpen, item, toast, handleGetAll, state, promesa, GetData, infoContactoData }) => {
    // const refbtn = useRef();
    
                        
    const today = new Date()
    let vigenciaDias = 0
    const fechaVencimientoDay = new Date(promesa.fecha_vencimiento)
    const diferenciaDias = fechaVencimientoDay - today
    vigenciaDias = diferenciaDias / (1000 * 60 * 60 * 24)
    const vigencia=vigenciaDias >= 0 ? Math.ceil(vigenciaDias) : 0;
    let initialDate = getDateNow(promesa.fecha_vencimiento);
    const finalDate = new Date(initialDate);
    finalDate.setDate(finalDate.getDate() + vigencia);
    const formattedDate = finalDate.toLocaleDateString("sv-SE");
    
    const getDiasPromesa = (fecha, promesa) => {
        let dias = 0
        if (promesa !== null && promesa?.fecha_inicio !== null) {
           let fechaInicio = new Date(promesa?.fecha_inicio)
           dias = Math.ceil((new Date(fecha) - fechaInicio) / (1000 * 60 * 60 * 24));
        }
        else {
           dias = Math.ceil((new Date(fecha) - new Date()) / (1000 * 60 * 60 * 24));
        }
 
        return isNaN(dias) ? 0 : dias
     }
    const calcularTotal = () => {
        let frecuencia = 7;
        if (state?.frecuencia_pago?.toLowerCase() === "quincenal") {
            frecuencia = 15;
        } else if (state?.frecuencia_pago?.toLowerCase() === "mensual") {
            frecuencia = 30;
        }
        const dias_promesa = getDiasPromesa(promesa.fecha_vencimiento,promesa);
        let result = Math.floor(dias_promesa / frecuencia);
        let montoadeudo= state.monto_adeudo
        montoadeudo = parseFloat(montoadeudo.replace(/[$,]/g, ''));
        let pago_periodo = (state?.pago_periodo !== undefined && state?.pago_periodo !== null) ? state?.pago_periodo : 0;
        //let monto_adeudo = (montoadeudo !== undefined && montoadeudo !== null) ? montoadeudo : 0;
    
        if (result <= 0) {
            result = 1;
        }
        
        return Number((result * pago_periodo + montoadeudo));
    };
    let initial = {
        // sol_id: state.sol_id,
        // contrato: item.contrato_id,
        // folio_promesa: item.folio_promesa,
        // folio_promesa_dataware: item.folio_promesa_dataware,
        // vin: state.vin,
        // folio_promesa_acendes: item.folio_promesa_acendes,
        motivo_Cancelacion: '',
        comentario: '',
        // monto_promesa: item.monto_promesa,
        // garantia: false,
        // fecha_compromiso: item.fecha_compromiso,
        fecha_vencimiento: initialDate,
        monto_vencido: formatearMontoMx(parseFloat(promesa.monto_vencido.replace(/,/g, ''))),
        pago_periodo: formatearMontoMx(promesa.pago_periodo),
        total:formatearMontoMx(calcularTotal())
    }
    const [initialValue, setInitialValue] = useState(initial);
    const [loading, setLoading] = useState(false);
    const [motivosSelect, setMotivosSelect] = useState([{}])
    const userId = sessionStorage.getItem('userId')
    const usuario = sessionStorage.getItem('usuario')
    useEffect(() => {
        handleGetMotivos();
    }, [])
    
    const handleGetMotivos = async () => {
        await UnidadesRecuperadasService.getAllMotivoAmpliacion().then(res => {
            let motivos = []
            res.data.responseData?.map(item => {
                motivos.push({
                    value: item.id,
                    label: item.nombre
                });
            });
            setMotivosSelect(motivos);
        })
    }


    const validationSchema = Yup.object({
        comentario: Yup.string().test(
            'min-length-if-not-empty',
            'El comentario debe tener al menos 20 caracteres',
            function (value) {
                // Si el valor no está vacío, debe tener al menos 20 caracteres
                return !value || (value && value.length >= 20);
            }),
        motivo_Cancelacion: Yup.string().required('Motivo es requerido')
    })

    const handleDelete = async (data) => {
        setLoading(true);
        const monto_prometidoformat = data.total.replace('$', '')
        //let idRegistroPrincipal = crypto.randomUUID();
        data = {
            "verb": "string",
            "id": "string",
            "field": "string",
            "value": "string",
            "flag": false,
            "anyObject": {
                "id": promesa.id,
                "ruta_head_id": promesa.ruta_head_id,
                "ruta_item_id": promesa.ruta_item_id,
                "evento_head_id": promesa.evento_head_id,
                "evento_item_id": promesa.evento_item_id,
                "tipo_promesa": promesa.tipo_promesa,
                "proceso": promesa.proceso,
                "cobrador_apoyo": promesa.cobrador_apoyo,
                "cobradores": promesa.cobradores,
                "grua": promesa.grua,
                "motivo_promesa": data.motivo_Cancelacion,
                "fecha_compromiso": promesa.fecha_compromiso,
                "fecha_vencimiento": data.fecha_vencimiento,

                "monto_prometido": promesa.monto_prometido,
                "monto_vencido": promesa.monto_vencido,
                "comentarios": data.comentario,


                "registro_concluido": promesa.registro_concluido,
                "cont_ampliacion":promesa.cont_ampliacion+1
            }
        }

        const promesaEliminarData = {
            sol_id: state.sol_id,
            contrato: state.contrato_id,
            folio_promesa: promesa.folio,
            vin: state.vin,
            // folio_promesa_acendes: item.folio_promesa_acendes,
            motivo_Cancelacion: data.anyObject.motivo_promesa,
            comentario: data.anyObject.comentarios,
            monto_promesa: promesa.monto_prometido,
            garantia: false,
            fecha_compromiso: promesa.fecha_compromiso
        }


        const PromesaAscendesData = {
            sol_id: state.sol_id,
            contrato: state.contrato_id,
            vin: state.vin,
            persona: state.tipo_persona,
            motivo: data.anyObject.motivo_promesa,
            comentario: data.anyObject.comentarios,
            monto_promesa: promesa.monto_prometido,
            garantia: false,
            fecha_compromiso: promesa.fecha_compromiso
        }

        const correo_ampliacion={
            usuario:usuario,
            contrato:state.contrato_id,
            dias: vigencia,
            monto_prometido:monto_prometidoformat,
            motivo: data.anyObject.motivo_promesa,
            comentarios:data.anyObject.comentarios
        }

        const notificacion = {
            anyObject: {
                usuarioid: userId, //El usuario que inicio sesión
                modulo: "pago_unidad", //pantalla origen
                tipocorreo: "Ampliación de Garantía de Pago",
                data: JSON.stringify(correo_ampliacion), //Objeto JSON que se va a usar en la notificacion 
                enviado: 0 //Cuando se crea va en 0
            }
        }

        try {
            await CobranzaService.createUpsertCobranzaPromesaPago(data)
            //Ascendes
            await CobranzaService.eliminaPromesaPagoAscendes(promesaEliminarData)
            await CobranzaService.creaAgendaPromesaPago(PromesaAscendesData)
            //Notification
            await UnidadesRecuperadasService.createNotificacionesCobranza(notificacion)



            setLoading(false)
            setIsOpen(false)
            toast.success(message("¡Operación exitosa!"));

        } catch (e) {
            setLoading(false)
            toast.error(message("¡Error!", e.message));
        }
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validationSchema}
            onSubmit={(values) => {
                handleDelete(values);
            }}
        >
            {({ isValid, values, setFieldValue }) => (
                <>
                    <Modal isOpen={isOpen} color='#fff'>
                        <div className='d-flex flex-column  mx-4 '>
                            <span className='fs-5 mb-2 mt-2'><strong>Ampliación de garantía</strong></span>
                        </div>
                        <section className='my-2 mx-4' style={{ width: '28rem' }}>
                            <Form>
                                <div className="col-lg-12 col-12">
                                    <div className="col-lg-12 col-12 py-2"> <span><strong>Fecha de promesa</strong></span></div>
                                    <div className="col-lg-12 col-12 d-flex">
                                        <div className="col-lg-6 col-6 " style={{ display: 'flex', flexFlow: 'column' }}>
                                            <label className='text'>Fecha de vencimiento:</label>
                                            <Field type="date" name="fecha_vencimiento" placeholder="Escribir" min={initialDate}
                                               max={formattedDate} className='programacionJobsCarteraModalSelect' />
                                            <ErrorMessage name="fecha_vencimiento" component="div" className="error" />
                                        </div>
                                        <div className="col-lg-6 col-6 px-2" style={{ display: 'flex', flexFlow: 'column' }}>
                                            <label className='text'>Días de promesa:</label>
                                            <Field type="text" name="dias" placeholder="Escribir" disabled={true} value={vigencia}
                                                className='programacionJobsCarteraModalSelect' />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-12">
                                    <div className="col-lg-12 col-12 py-2"> <span><strong>Monto por cubrir</strong></span></div>
                                    <div className="col-lg-12 col-12 d-flex align-items-center">
                                        <div className="col-lg-5 col-5  d-flex flex-column">
                                            <label className="text">Monto vencido:</label>
                                            <Field
                                                type="text"
                                                name="monto_vencido"
                                                placeholder="Escribir"
                                                disabled={true}
                                                className="programacionJobsCarteraModalSelect"
                                                style={{ width: '100%' }}
                                            />
                                            <ErrorMessage name="monto_vencido" component="div" className="error" />
                                        </div>

                                        <div className="col-lg-1 col-1  d-flex justify-content-center align-items-center" >
                                            <i className="icon-light fa fa-plus pt-3" style={{ color: '#1351A5', fontSize: '20px' }}></i>
                                        </div>

                                        <div className="col-lg-6 col-6 px-2 d-flex flex-column">
                                            <label className="text">Pago de periodo:</label>
                                            <Field
                                                type="text"
                                                name="pago_periodo"
                                                placeholder="1"
                                                disabled={true}
                                                className="programacionJobsCarteraModalSelect"
                                                style={{ width: '100%' }}
                                            />
                                        </div>
                                    </div>



                                </div>
                                <div className="col-lg-12 col-12 mt-3">
                                         <div className="col-lg-6 col-6 " style={{ display: 'flex', flexFlow: 'column' }}>
                                            <label className='text'>Total:</label>
                                            <Field 
                                            type="text"
                                            name="total" 
                                            placeholder="Escribir"
                                            disabled={true}
                                            className='programacionJobsCarteraModalSelect' />
                                            <ErrorMessage name="total" component="div" className="error" />
                                        </div>
                                </div>
                                <div className="col-lg-12 col-12 mt-3">
                                    <div className="col-lg-12 col-12 py-2"> <span><strong>Detalle de ampliación</strong></span></div>
                                        <div className="col-lg-12 col-12 d-flex mt-2">
                                            <div className="col-lg-6 col-6">
                                            <SelectField
                                                id="motivo_Cancelacion"
                                                label="Motivo:"
                                                name="motivo_Cancelacion"
                                                disabled={false}
                                                items={motivosSelect}
                                                onChange={(event) => {
                                                    setFieldValue("motivo_Cancelacion", event.label)
                                                }} />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-12" style={{ display: 'flex', flexFlow: 'column' }}>
                                                <TextAreaField label="Comentarios" className="form-control col-12" rows="3" name="comentario" type="text" holder="Escribe" />
                                        </div>
                                </div>
                                
                               

                                <section className='my-3 d-flex justify-content-end'>
                                    <button id="AT_btnCancelar" className='btn btn-outline' type='reset' onClick={() => { setIsOpen(false) }}>Regresar</button>
                                    <button id="AT_btnGuardar" disabled={!isValid} className='btn mx-2' type="submit">Confirmar</button>
                                </section>
                            </Form>
                        </section>
                    </Modal>
                    {
                        loading &&
                        <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                    }
                </>
            )
            }
        </Formik>
    )
}

export default AmpliacionPromesasdePagoModal