import { Form, Formik } from "formik";
import DSAsignacionesHook from "../../../Hooks/DatamovilSystems/Asignaciones/DSAsignacionesHook";
import Modal from "../../../Components/Modal/Modal";
import Spinner from "../../../Components/Loadig";
import BarraEstadistica from "./BarraEstadistica";
import { TextField } from "../../../Components/TextField";
import Select from 'react-select';
import AsignarServiciosModal from "../../../Components/Modal/AsignarServiciosModal";
import DataTableCollapsable from "./DataTableCollapsable";
import RowPages from "../../../Components/RowPages";
import MultiSelect from "../../../Components/DropDown";
import toast, { Toaster } from 'react-hot-toast'
import AsignacionesDatamovilSystems from "./Asignaciones";

import { ValidatePermission, ValidatePermissionById } from "../../../Auth/ValidatePermission";
import { Section, SubModule } from "../../../Auth/Authorization";

const SeguimientoAsignaciones = () => {
    const {
        refFormik,
        selectRef,
        selectServicioRef,
        selectUbicacionRef,
        initialValue,
        data,
        loading,
        setBuscar,
        buscar,
        columns,
        setModalMessage,
        modalMessage,
        setTipoServicio,
        setTipoUbicacion,
        setTecnicoId,
        tipoServicios,
        tipoUbicaciones,
        handleAsignar,
        tecnicos,
        limpiarFiltros,
        handlePageClick,
        page,
        totalPages,
        setRows,
        state,
        setState
    } = DSAsignacionesHook(toast);

    return (
        <Formik
            innerRef={refFormik}
            enableReinitialize={true}
            initialValues={initialValue}
            onReset={limpiarFiltros}
        // validationSchema={validate}
        >
            {({ isValid, values, setFieldValue, resetForm, errors }) => (
                <>

                    {/* Agrega los tabs */}
                    <AsignacionesDatamovilSystems />

                    <ValidatePermission isActive={ValidatePermissionById(4, Section.AsignacionesServiciosSection, SubModule.AsignacionServicios).Ver}>
                        <Toaster
                            position="top-right"
                            toastOptions={{
                                success: {
                                    style: {
                                        background: '#47a066',
                                        color: '#FFFF',
                                        borderLeft: '10px solid #2f7246'
                                    },
                                },
                                error: {
                                    style: {
                                        background: '#d53f3f',
                                        color: '#FFFF',
                                        borderLeft: '10px solid #ac241a'
                                    },
                                },
                            }}
                        />
                        <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                        <Modal isOpen={modalMessage.isOpen} color='#fff' width={600}>
                            {
                                modalMessage.type === 1 &&
                                <AsignarServiciosModal
                                    modalMessage={modalMessage}
                                    setModalMessage={setModalMessage}
                                />
                            }
                        </Modal>
                        <section className="mt-2 mx-2">
                            <div className='pb-4'>
                                <section className='mx-4 my-4'>
                                    <div className="col-12 mt-2">
                                        <div className="row justify-content-between">
                                            <div className="col-auto">
                                                <strong className='mt-2'>Asignación de servicios</strong>
                                            </div>
                                            <ValidatePermission isActive={ValidatePermissionById(4, Section.AsignacionesServiciosSection, SubModule.AsignacionServicios).Agregar}>
                                                <div className="col-auto">
                                                    <button className="btn" onClick={() => handleAsignar()}>Asignar servicios</button>
                                                </div>
                                            </ValidatePermission>
                                        </div>
                                    </div>
                                    <BarraEstadistica />
                                    <Form>
                                        <div className="row mt-4">
                                            <div className="col-12 col-md-2 mr-1 inner-addon right-addon">
                                                <i className="glyphicon fas fa-search"></i>
                                                <TextField id="buscar" label="" name="buscar" type="text" holder="Escribe" onChange={(event) => {
                                                    setFieldValue("buscar", event.target.value);
                                                    setBuscar(event.target.value)
                                                }} />
                                            </div>
                                            <div className="col-12 col-md-2">
                                                {/* <Select ref={selectRef} name="tecnicoId" options={tecnicos} className='col-12' onChange={(e) => {
                                                    const selectedValue = e ? e.value : '';
                                                    setFieldValue("tecnicoId", selectedValue);
                                                    setTecnicoId(selectedValue);
                                                }} placeholder="Técnico" />                                             */}
                                                <MultiSelect
                                                    ref={selectRef}
                                                    id="tecnicoId"
                                                    holder={"Técnico"}
                                                    className={'col-12 mx-1'}
                                                    name="tecnicoId"
                                                    value={state}
                                                    options={tecnicos}
                                                    handleChange={(values) => {
                                                        setState(values);
                                                        let newDataId = [];
                                                        values?.map(item => {
                                                            newDataId.push(item.value);
                                                        })
                                                        setFieldValue("tecnicoId", newDataId.join());
                                                        setTecnicoId(newDataId.join());
                                                    }}
                                                />
                                            </div>
                                            <div className="col-12 col-md-2">
                                                <Select ref={selectServicioRef} options={tipoServicios} className='col-12' onChange={(e) => {
                                                    const selectedValue = e ? e.value : '';
                                                    setFieldValue("tipoServicioId", selectedValue);
                                                    setTipoServicio(selectedValue);
                                                }} placeholder="Tipo de servicio" />
                                            </div>
                                            <div className="col-12 col-md-2">
                                                <Select ref={selectUbicacionRef} options={tipoUbicaciones} className='col-12' onChange={(e) => {
                                                    const selectedValue = e ? e.value : '';
                                                    setFieldValue("tipoUbicacion", selectedValue);
                                                    setTipoUbicacion(selectedValue);
                                                }} placeholder="Tipo de ubicación" />
                                            </div>
                                            <div className="col-12 col-md-2">
                                                <button type="reset" className="btn">
                                                    Limpiar filtro
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                    <RowPages
                                        setpageRows={setRows}
                                    />
                                    <div className="row">
                                        <div className='col-12'>
                                            <DataTableCollapsable
                                                data={data}
                                                pageCount={totalPages}
                                                handlePageClick={handlePageClick}
                                                currentPage={page - 1}
                                            />
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </section>
                    </ValidatePermission>
                </>
            )
            }
        </Formik>
    );
}

export default SeguimientoAsignaciones;