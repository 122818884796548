import { Formik, Form, Field } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Spinner from '../../../../Components/Loadig';
import Modal from '../../../../Components/Modal/Modal';
import { TextField } from '../../../../Components/TextField';
import DSystemsServicios from '../../../../Services/DatamovilSystems/ServiciosDatamovil';
import Catalogo from '../../../../Services/Catalogos/Catalogo';
import { SelectField } from '../../../../Components/SelectField';
import { Normalize } from '../../../../Utils/functions';

const ModalCostos = ({ isOpen, setIsOpen, item, handleGetAll, toast, valuesInit }) => {
    const refbtn = useRef();
    const { id } = useParams();

    let initial = {
        concepto: item?.concepto ?? "",
        subtotal: item?.subtotal ?? "",
        iva: item?.iva ?? "",
        total: item?.total ?? "",
        concluido: item?.concluido ?? false,
        fechaInicio: item?.fechaInicio ?? "",
        fechaFin: item?.fechaFin ?? "",
        tipoCostoId: item?.tipoCostoId ?? "",
        servicioCostosAdicionalesCostoId: item?.servicioCostosAdicionalesCostoId ?? ""
    };
    const [initialValue, setInitialValue] = useState(initial);
    const [dataTipoCosto, setDataTipoCosto] = useState([]);
    const [loading, setLoading] = useState([]);
    useEffect(() => {
        getCategoriaCosto();
    }, [])

    const getCategoriaCosto = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.CategoriaCosto')
        .then(resp => {
            const categoriaId = resp.data.filter(item => Normalize(item.CategoriaCosto) === 'serviciosdatamovil')[0].CategoriaCostoId;
            if(categoriaId !== undefined){
                getTipoCostos(categoriaId);
            }
        })
        .catch(err => {
            console.error(err);
        });
    };

    const getTipoCostos = async (categoriaId) => {
        await Catalogo.getDropdownsByTable('Catalogos.TipoCosto')
        .then(resp => {
            const tipoCostos = resp.data.filter(item => item.CategoriaCostoId === categoriaId);
            let items = [];
            tipoCostos.forEach(item => {
                items.push({
                    value: item.TipoCostoId,
                    label: item.Nombre
                });
            });

            setDataTipoCosto(items);
        })
        .catch(err => {
            console.error(err);
        });
    }


    // const getTipoCosto = async () => {
    //     let items = [];
    //     await Catalogo.getDropdownsByTable('Catalogos.TipoCosto')
    //         .then(resp => {
    //             resp.data.forEach(item => {
    //                 items.push({
    //                     value: item.TipoCostoId,
    //                     label: item.Nombre
    //                 });
    //             });
    //             setDataTipoCosto(items);
    //         })
    //         .catch(err => {
    //             console.error(err);
    //         });
    // };

    const validate = Yup.object({
        concepto: Yup.string()
            .required("El campo es requerido.")
            .trim("El texto no debe empezar ni terminar con espacios")
            .strict(true)
            .matches(
                "^[a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+(?: [a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+)*$",
                "La información ingresada es incorrecta."
            )
            .matches(
                "^[a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+(?: [a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+)*$",
                "solo debe existir un espacio entre cada palabra."
            )
            .min(3, 'minimo 3 caracteres'),
        subtotal: Yup.number()
            .required("El campo es requerido."),
        // iva: Yup.number()
        //     .required("El campo es requerido."),
        iva: Yup.number().test({
            name: 'is-Iva',
            skipAbsent: true,
            test(value, ctx) {
                if (value < 0)
                    return ctx.createError({ message: 'ingrese un valor positivo' })
                if (value >= 100)
                    return ctx.createError({ message: 'ingrese un valor entre 0 y 99.99' })
                return true
            }
        }),
        total: Yup.number()
            .required("El campo es requerido."),
        tipoCostoId: Yup.string()
            .required("El campo es requerido.")
    });

    const handleAddEdit = async (item) => {
        refbtn.current.disabled = true;
        setLoading(true)
        item.servicioId = id
        
        if (item.servicioCostosAdicionalesCostoId === null || item.servicioCostosAdicionalesCostoId === "") {
            DSystemsServicios.agregarServicioCostosAdicionalesCosto(item)
                .then(res => {
                    handleGetAll()
                })
                .catch(error => {

                })
                .finally(() => {
                    setLoading(false)
                    setIsOpen(false);
                    refbtn.current.disabled = false;
                })
        } else {
            DSystemsServicios.updateServicioCostosAdicionalesCosto(item)
                .then(res => {
                    handleGetAll()
                })
                .catch(error => {

                })
                .finally(() => {
                    setLoading(false)
                    setIsOpen(false);
                    refbtn.current.disabled = false;
                })
        }
    }
    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <Formik
                enableReinitialize={true}
                initialValues={initialValue}
                validationSchema={validate}
                onSubmit={(values) => {
                    handleAddEdit(values);
                }}
            >
                {({ isValid, setFieldValue, values }) => (
                    <Modal isOpen={isOpen} color='#fff'>
                        <section className='d-flex justify-content-between mx-4'>
                            <section className='py-2 d-flex flex-column'>
                                <div className='d-flex justify-content-between'>
                                    <span className=''><strong>Registrar costo</strong></span>
                                </div>
                                <span className='mt-2 fs-6'>A continuación completa todos los campos para el registro del costo.</span>
                            </section>
                        </section>
                        <section className='my-4 mx-4' style={{ width: '30rem' }}>
                            <Form>
                                <div className="mb-3 row">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <TextField label="Concepto" name="concepto" type="text" holder="Escribe" />
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <TextField
                                            label="Subtotal"
                                            name="subtotal"
                                            type="text"
                                            holder="Escribe"
                                            icon={"bi bi-currency-dollar"}
                                            isMoney={true}
                                            onChange={(event) => {
                                                setFieldValue("subtotal", event.target.value)
                                                let total = (parseFloat(event.target.value) * parseInt(values.iva) / 100) + parseFloat(event.target.value);
                                                setFieldValue("total", total);
                                            }} />
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <TextField label="IVA (%)" name="iva" type="number" holder="Escribe" onChange={(event) => {
                                            setFieldValue("iva", event.target.value);
                                            let total = (parseFloat(values.subtotal) * parseInt(event.target.value) / 100) + parseFloat(values.subtotal);
                                            setFieldValue("total", total);
                                        }} />
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <TextField
                                            label="Total"
                                            disabled
                                            name="total"
                                            type="text"
                                            holder="Escribe"
                                            icon={"bi bi-currency-dollar"}
                                            isMoney={true}
                                        />
                                    </div>
                                </div>

                                <div className='mb-3 row'>
                                    <div className='col-lg-6 col-md-6 col-sm-12'>
                                        <SelectField id="tipoCostoId"
                                            label="Tipo de costo"
                                            disabled={false}
                                            name="tipoCostoId"
                                            items={dataTipoCosto}
                                            onChange={(event) => {
                                                setFieldValue("tipoCostoId", event.value);
                                            }} />
                                    </div>
                                </div>
                                <section className='my-3'>
                                    <button id="AT_btnCancelar" className='btn btn-outline' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                                    <button id="AT_btnGuardar" className='btn mx-2' ref={refbtn} type="submit" disabled={!(isValid)}>{initialValue.servicioCostosAdicionalesCostoId == "" ? "Guardar" : "Editar"}</button>
                                </section>
                            </Form>
                        </section>
                    </Modal>
                )
                }
            </Formik >
        </>
    )
}

export default ModalCostos