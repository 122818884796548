import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DSystemsServicios from '../../../Services/DatamovilSystems/ServiciosDatamovil';
import Catalogo from '../../../Services/Catalogos/Catalogo';
import { getDateFormat, isEmpty, IsNullOrEmpty, message } from '../../../Utils/functions';

const useOrdenServicio = ({ handleConcluido, toast }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { state } = useLocation();

    const initial = {
        servicioOrdenId: "",
        servicioId: "",
        departamentoId: null,
        departameto: "",
        motivoSercicioId: null,
        fechaSolicitud: "",
        horaSolicitud: null,
        comentarios: null,
        concluido: false,
        fechaConcluido: null,
        usuarioAlta: "",
        usuarioActualiza: null,
        fechaAlta: "",
        fechaActualiza: null,
        fecha: "",
        personaSolicitaServicio: ""
    };

    const [initialValue, setInitialValue] = useState(initial);
    const [loading, setLoading] = useState(false);
    const [departamentos, setDepartamentos] = useState([]);
    const [motivoServicio, setMotivoServicio] = useState([]);

    useEffect(() => {
        getDepartamentos();
        getMotivoServicio();
        getDataCostoAdicional();
    }, []);



    const DepartamentosList = [
        { value: 1, label: "Seguro" },
        { value: 2, label: "Gestoría" },
        { value: 3, label: "Cobranza" },
        { value: 4, label: "Proceso de Cierre" },
        { value: 5, label: "Garantía" }
    ];

    const getDepartamentos = async () => {
        setDepartamentos(DepartamentosList);
        // let items = [];
        // await Catalogo.getDropdownsByTable('Catalogos.Procesos')
        //     .then(resp => {
        //         resp.data.forEach(item => {
        //             items.push({
        //                 value: item.ProcesoId,
        //                 label: item.Proceso
        //             });
        //         });
        //         setDepartamentos(items);
        //     })
        //     .catch(err => {
        //         console.error(err);
        //     });
    };

    const getMotivoServicio = async () => {
        let items = [];
        await Catalogo.getDropdownsByTable('Catalogos.Motivo_Servicio')
            .then(resp => {
                resp.data.forEach(item => {
                    items.push({
                        value: item.MotivoServicioId,
                        label: item.MotivoServicio
                    });
                });
                setMotivoServicio(items);
            })
            .catch(err => {
                console.error(err);
            });
    };



    const getDataCostoAdicional = async () => {
        setLoading(true);
        await DSystemsServicios.getServicioOrden(id)
            .then(res => {
                let resData = res.data

                if (resData.fechaSolicitud) {
                    resData.fechaSolicitud = resData.fechaAlta.split(" ")[0]
                }
                if (resData.horaSolicitud) {
                    resData.horaSolicitud = resData.horaSolicitud
                }

                resData.concluido = resData.concluido ? true : false

                setInitialValue({ ...initialValue, ...resData })
                handleConcluido({ concluido: resData.concluido })
            })
            .catch(error => {
                //Error al obtener datos de revision
            })
            .finally(() => {
                setLoading(false);
            })
    };

    const submitForm = async (values) => {
        setLoading(true);
        values.procesoId = state.tipoProceso
        values.paso = 1
        values.porcentaje = 100
        values.status = 2

        DSystemsServicios.updateServicioOrden(values)
            .then(res => {
                if (res.data.ban === 1) {
                    toast.success(message(res.data.message));
                } else {
                    toast.error(message(res.data.message));
                }
            })
            .catch(error => {
                toast.error(message("Error al tratar de guardar"));
            })
            .finally(() => {
                getDataCostoAdicional()
                setLoading(false);
            })
    };


    return {
        initialValue,
        loading,
        setLoading,
        submitForm,
        departamentos,
        motivoServicio,
        state
    };
};

export default useOrdenServicio;
