import { Form, Formik, Field } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from "react-router-dom";
import Modal from '../../../../Components/Modal/Modal';
import Spinner from '../../../../Components/Loadig';
import CostosAdicionalesTable from './CostosAdicionalesTable';
import RegistrosPagosTable from './RegistrosPagosTable';
import useCostosAdicionales from '../../../../Hooks/DatamovilSystems/RevisionPasos/useCostosAdicionales';
import { FormatNumberMoney2 } from '../../../../Utils/functions';

const CardCostosAdicionales = ({ handleConcluido }) => {
    const { refFormik } = useRef();
    const { id } = useParams();

    const [collapsible, setCollapsible] = useState(false);
    const [tempConcluido, setTempConcluido] = useState(false);

    //Totales
    const [totalAdeudos, SetTotalAdeudos] = useState(0);
    const [totalPagos, SettotalPagos] = useState(0);
    const {
        initialValue,
        loading,
        setLoading,
        submitForm
    } = useCostosAdicionales(handleConcluido)

    const handleConcluidoChange = (event, setFieldValue) => {
        const isChecked = event.target.checked;
        setTempConcluido(isChecked);
        setFieldValue("concluido", isChecked);
    };

    const submitButton = () => {
        let dataSubmit = {
            servicioId: id,
            concluido: tempConcluido
        }
        submitForm(dataSubmit)
        //console.log(dataSubmit)
    }

    return (
        <>
            <Modal isOpen={loading} color='transparent'>
                <Spinner message={""} /></Modal>
            <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                <div className='row mx-md-4 my-4'>
                    <div className="col-12 px-md-3">
                        <div className="p-4 white-wrapper mb-2">
                            <div
                                className="row mt-2"
                                data-bs-toggle="collapse"
                                href="#collapseCostosAdicionales"
                                role="button"
                                aria-expanded="false"
                                aria-controls="collapseCostosAdicionales"
                                onClick={() => setCollapsible(!collapsible)}
                            >
                                <div className="col-11">
                                    <h6>
                                        <span className={`semaforo semaforo-${initialValue?.concluido ? "green" : "default"}`}>
                                        </span> <strong>Costos adiciones</strong></h6>
                                </div>
                                <div className="col-11">
                                    <small>{initialValue.concluido ? " Concluido" : " En Proceso"}</small>
                                </div>

                                <div className="col-1 text-end">
                                    <i className={!collapsible ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                </div>
                            </div>
                            <div className="collapse" id="collapseCostosAdicionales">
                                <div className="divider"></div>
                                <CostosAdicionalesTable totalCosto={SetTotalAdeudos} submitButton={submitButton} concluido={initialValue.concluido} />
                                <RegistrosPagosTable totalPago={SettotalPagos} concluido={initialValue.concluido} />
                                <div className="row mt-2">
                                    <div className="col-4">
                                        <div className="row">
                                            <strong>Balance</strong>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-12">
                                                <table id='tbl_balance' className='col-12'>
                                                    <thead>
                                                        <tr>
                                                            <th>Concepto</th>
                                                            <th>Monto</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Adeudos</td>
                                                            <td>${FormatNumberMoney2(totalAdeudos)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Pagos cliente</td>
                                                            <td>${FormatNumberMoney2(totalPagos)}</td>
                                                        </tr>
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td>Pendiente</td>
                                                            <td>${FormatNumberMoney2(totalAdeudos - totalPagos)}</td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Formik
                                    innerRef={refFormik}
                                    enableReinitialize={true}
                                    initialValues={initialValue}
                                //validationSchema={validationSchema}
                                //onSubmit={(values) => submitForm(values)}
                                >
                                    {({ setFieldValue }) => (
                                        <>
                                            <div className="row mb-3">
                                                <div className="col-md-6 d-flex align-items-center">
                                                    <Field type="checkbox" name="concluido"
                                                        disabled={initialValue.concluido}
                                                        className="form-check-input me-2"
                                                        onClick={(event) => {
                                                            setFieldValue("concluido", event.target.value);
                                                            handleConcluidoChange(event, setFieldValue)
                                                        }} />
                                                    <label className="form-check-label">Proceso concluido</label>
                                                </div>
                                            </div>

                                            <div className="text-end">
                                                <small className="text-muted ms-3">Inicio de proceso: {initialValue.fechaAlta} | Fin de proceso: {initialValue.fechaConcluido}</small>
                                            </div>
                                        </>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CardCostosAdicionales;
