import { Form, Formik, Field } from 'formik';
import React, { useEffect, useState, useRef } from 'react';
import * as Yup from 'yup';
import { TextField } from '../../../../Components/TextField';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setFields } from '../../../../Redux/Slice/Calendario/calendarDynamicalSlice';
import Modal from '../../../../Components/Modal/Modal'
import Spinner from "../../../../Components/Loadig";
import useAgendarCita from '../../../../Hooks/DatamovilSystems/RevisionPasos/useAgendarCita';
import { TextAreaField } from '../../../../Components/TextAreaField';

const CardGeneral = ({ handleConcluido }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { state } = useLocation();
    const { id } = useParams();
    const refFormik = useRef();

    const [isOpen, setIsOpen] = useState(false);
    const [collapsible, setCollapsible] = useState(false);
    const paso = 1;

    const validate = Yup.object({

    });

    const {
        initialValue,
        loading,
        setLoading
    } = useAgendarCita({ handleConcluido, paso });

    return (
        <>
            <Modal isOpen={loading} color='transparent'>
                <Spinner message={""} /></Modal>
            <div className='row mx-md-4 my-4'>
                <div className="col-12 px-md-3">
                    <div className="p-4 white-wrapper mb-2">
                        <div
                            className="row mt-2"
                            data-bs-toggle="collapse"
                            href="#collapseInfo"
                            role="button"
                            aria-expanded="false"
                            aria-controls="collapseInfo"
                            onClick={() => setCollapsible(!collapsible)}
                        >
                            <div className="col-11">
                                <h6><strong>General</strong></h6>
                            </div>
                            <div className="col-1 text-end">
                                <i className={!collapsible ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                            </div>
                        </div>
                        <div className="collapse" id="collapseInfo">
                            <div className="divider"></div>
                            <span>
                                <b>
                                    Información de orden de servicio
                                </b>
                            </span>
                            <Formik
                                innerRef={refFormik}
                                enableReinitialize={true}
                                initialValues={initialValue}
                                validationSchema={validate}
                            // onSubmit={(values) => submitForm(values)}
                            >
                                {({ setFieldValue }) => (
                                    <Form>
                                        <div className='row mt-3'>
                                            <div className="col-lg-3 col-md-6">
                                                <TextField label="Tipo de orden" disabled={true} name="tipoOrden" type="text" holder="" />
                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <TextField label="Tipo de Servicio" disabled={true} name="tipoServicio" type="text" holder="" />
                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <TextField label="Proceso" disabled={true} name="proceso" type="text" holder="" />
                                            </div>

                                        </div>
                                        <div className='row mt-3'>
                                            <div className="col-lg-3 col-md-6">
                                                <TextField
                                                    label="Persona que solicita el servicio"
                                                    disabled={true}
                                                    id="usuarioSolicita"
                                                    name="usuarioSolicita"
                                                    type="text" holder=""
                                                    onChange={(event) => {
                                                        setFieldValue("usuarioSolicita", event.target.value);
                                                    }}
                                                />
                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <TextField label="Supervisor aprobó" disabled={true} name="usuarioAprobo" type="text" holder="" />
                                            </div>
                                        </div>
                                        <div className='row mt-3'>
                                            <div className="col-6 mt-3">
                                                {/* <TextField label="Comentarios" disabled={true} name="comentarios" type="text" holder="" /> */}
                                                <TextAreaField id="comentarios"
                                                    label="Comentarios"
                                                    disabled={true}
                                                    className="form-control col-8"
                                                    rows="3"
                                                    name="comentarios"
                                                    type="text"
                                                    holder="Escribe"
                                                />
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CardGeneral;
