import { Form, Formik, Field } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import Modal from '../../../../Components/Modal/Modal';
import Spinner from '../../../../Components/Loadig';
import { SelectField } from '../../../../Components/SelectField';
import { TextAreaField } from '../../../../Components/TextAreaField';
import useInicioServicio from '../../../../Hooks/DatamovilSystems/RevisionPasos/useInicioServicioRespaldo';
import toast, { Toaster } from 'react-hot-toast'

const CardInicioServicio = ({ handleConcluido }) => {
    const refFormik = useRef();
    const [collapsible, setCollapsible] = useState(false);

    const navigate = useNavigate();
    const { state } = useLocation();


    const validationSchema = Yup.object().shape({

        vehiculoEnUbicacion: Yup.string()
            .when('concluirRegistroInicioServicio', {
                is: true,
                then: Yup.string()
                    .nullable()
                    .required('Selecciona una opción')
            }),
        // accederUbicacion: Yup.string()
        //     .nullable()
        //     .when(["vehiculoEnUbicacion", "concluirRegistroInicioServicio"], {
        //         is: (vehiculoEnUbicacion, concluirRegistroInicioServicio) => vehiculoEnUbicacion && concluirRegistroInicioServicio,
        //         then: Yup.string()
        //             .nullable()
        //             .required("Selecciona una opción"),
        //     }),
        // accederVehiculo: Yup.string()
        //     .nullable()
        //     .when(["vehiculoEnUbicacion", "concluirRegistroInicioServicio"], {
        //         is: (vehiculoEnUbicacion, concluirRegistroInicioServicio) => vehiculoEnUbicacion && concluirRegistroInicioServicio,
        //         then: Yup.string()
        //             .nullable()
        //             .required("Selecciona una opción"),
        //     }),
        comentariosInicioServicio: Yup.string()
            .nullable()
            .when('concluirRegistroInicioServicio', {
                is: true,
                then: Yup.string()
                    .nullable()
                    .required('Escribe un comentario')
            }),
    });

    const paso = 2
    const {
        initialValue,
        loading,
        setLoading,
        submitFormUbicacionVehiculo,
        opcionesBool,
        tipoUbicaciones,
        motivosNoIngreso
    } = useInicioServicio({ handleConcluido, paso, toast });

    // const { tipoServicio } = useParams();

    const handleUploadFile = async () => {
        navigate(`/datamovil/datadocs/fRevInmov/eIniServ/${state.contrato}/${state.servicioId}`, {
            state: {
                ...state,
                registroId: state.servicioId,
                vin: state.vin,
                nombreMarca: state.marca,
                nombreModelo: state.modelo,
                nombreVersion: state.version,
                nombreYear: state.anio
            }
        });
    }

    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <div className='row mx-md-4 my-4'>
                <div className="col-12 px-md-3">
                    <div className="p-4 white-wrapper mb-2">
                        <div
                            className="row mt-2"
                            data-bs-toggle="collapse"
                            href="#collapseUbicacion"
                            role="button"
                            aria-expanded="false"
                            aria-controls="collapseUbicacion"
                            onClick={() => setCollapsible(!collapsible)}
                        >
                            <div className="col-11">
                                <h6>
                                    <span className={`semaforo semaforo-${initialValue?.concluirRegistroInicioServicio ? "green" : "default"}`}></span>
                                    <strong>
                                        Ubicación de Vehículo
                                    </strong>
                                </h6>
                            </div>
                            <div className="col-11">
                                <small>{initialValue.concluirRegistroInicioServicio ? " Concluido" : " En Proceso"}</small>
                            </div>
                            <div className="col-1 text-end">
                                <i className={!collapsible ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                            </div>
                        </div>
                        <div className="collapse" id="collapseUbicacion">
                            <div className="divider"></div>
                            <Formik
                                innerRef={refFormik}
                                enableReinitialize={true}
                                initialValues={initialValue}
                                validationSchema={validationSchema}
                                onSubmit={(values) => submitFormUbicacionVehiculo(values)}
                            >
                                {({ isValid, values, setFieldValue, errors }) => (
                                    <Form id="agendarCitaForm">
                                        <div className="row mt-2 mb-2">
                                            <div className='col-4'>
                                                <h6><strong>Detalle de ubicación</strong></h6>
                                            </div>
                                            <div className="col-8">
                                                <div className="row justify-content-end">
                                                    <div className="col-auto">
                                                        <button
                                                            id="btnSubirDocumento"
                                                            className="btn btn-outline col-12 mw-100 d-flex "
                                                            type="button"
                                                            onClick={handleUploadFile}
                                                            style={{
                                                                padding: "8px",
                                                                lineHeight: "20px",
                                                                border: "none",
                                                                backgroundColor: "#f0f0f0",
                                                            }}>
                                                            <i className="ri-file-3-fill fs-4" style={{ color: "#004C97", marginRight: "8px", }} ></i>
                                                            Subir documento
                                                        </button>
                                                    </div>
                                                    <div className="col-auto">
                                                        {
                                                            initialValue.concluirRegistroInicioServicio ?
                                                                <fieldset className='btn btn-light secondary d-flex align-items-center mx-2'>
                                                                    <i className="icon-dark bx bx-hide mx-1" ></i>
                                                                    Modo lectura
                                                                </fieldset>
                                                                : <button
                                                                    className='btn'
                                                                    disabled={initialValue.concluirRegistroInicioServicio}
                                                                    type='submit'>
                                                                    Guardar
                                                                </button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col col-md-3">
                                                <SelectField
                                                    id="vehiculoEnUbicacion"
                                                    label="¿Vehículo en ubicación designada?"
                                                    name="vehiculoEnUbicacion"
                                                    items={opcionesBool}
                                                    disabled={initialValue.concluirRegistroInicioServicio}
                                                    onChange={(event) => setFieldValue("vehiculoEnUbicacion", event.value)}
                                                />
                                            </div>

                                            {
                                                (values.vehiculoEnUbicacion && values.vehiculoEnUbicacion !== null) &&
                                                <div className="col-md-3">
                                                    <SelectField
                                                        id="accederUbicacion"
                                                        label="¿Puedes acceder a la ubicación?"
                                                        name="accederUbicacion"
                                                        items={opcionesBool}
                                                        disabled={initialValue.concluirRegistroInicioServicio}
                                                        onChange={(event) => setFieldValue("accederUbicacion", event.value)}
                                                    />
                                                </div>
                                            }

                                            {
                                                (values.vehiculoEnUbicacion && values.vehiculoEnUbicacion !== null) &&
                                                <div className="col-md-3">
                                                    <SelectField
                                                        id="accederVehiculo"
                                                        label="¿Puedes acceder al vehiculo?"
                                                        name="accederVehiculo"
                                                        items={opcionesBool}
                                                        disabled={initialValue.concluirRegistroInicioServicio}
                                                        onChange={(event) => setFieldValue("accederVehiculo", event.value)}
                                                    />
                                                </div>
                                            }

                                            {
                                                (values.vehiculoEnUbicacion !== null && !values.accederUbicacion || !values.accederVehiculo) &&
                                                <div className="col-md-3">
                                                    <SelectField
                                                        id="motivoNoIngreso"
                                                        label="Motivo de no ingreso"
                                                        name="motivoNoIngreso"
                                                        items={motivosNoIngreso}
                                                        disabled={initialValue.concluirRegistroInicioServicio}
                                                        onChange={(event) => setFieldValue("motivoNoIngreso", event.value)}
                                                    />
                                                </div>
                                            }

                                            {
                                                (
                                                    (!values.accederVehiculo && values.accederVehiculo !== null && values.vehiculoEnUbicacion && values.vehiculoEnUbicacion !== null)
                                                    || (!values.vehiculoEnUbicacion && values.vehiculoEnUbicacion !== null)
                                                ) &&
                                                <div className="col-md-3">
                                                    <SelectField
                                                        id="reagendarCita"
                                                        label="¿Reagendar cita?"
                                                        name="reagendarCita"
                                                        items={opcionesBool}
                                                        disabled={initialValue.concluirRegistroInicioServicio}
                                                        onChange={(event) => setFieldValue("reagendarCita", event.value)}
                                                    />
                                                </div>

                                            }
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-md-6">
                                                <TextAreaField id="comentariosInicioServicio"
                                                    label="Comentarios"
                                                    disabled={initialValue.concluirRegistroInicioServicio}
                                                    className="form-control col-8"
                                                    rows="3"
                                                    name="comentariosInicioServicio"
                                                    type="text"
                                                    holder="Escribe"
                                                    onChange={(event) => setFieldValue("comentariosInicioServicio", event.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-md-3 d-flex align-items-center">
                                                <Field
                                                    type="checkbox"
                                                    name="concluirRegistroInicioServicio"
                                                    className="form-check-input me-2"
                                                    disabled={initialValue.concluirRegistroInicioServicio}
                                                    onClick={(event) => {
                                                        setFieldValue("concluirRegistroInicioServicio", event.target.value);
                                                    }} />
                                                <label className="form-check-label">Concluir registro</label>
                                            </div>


                                        </div>

                                        <div className="text-end">
                                            <small className="text-muted">
                                                Inicio de proceso: {initialValue?.fechaInicioServicio} | Fin de proceso: {initialValue?.fechaFinalServicio}
                                            </small>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CardInicioServicio;
