import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router';
import SeguimientoVisitaService from '../../Services/Cartera/SeguimientoVisitaService';
import SeguroPolizaService from '../../Services/Seguros/SeguroPolizaService';
import { message } from '../../Utils/functions';
import { FormatFecha, formatearMontoMx } from '../../Utils/functions'
import InstalacionDatamovilService from '../../Services/Inventario/InventarioDatamovilService';
import CobranzaService from '../../Services/Cobranza/Cobranza';
import { useSelector } from 'react-redux';

const useCarteraGeneral = () => {
    const navigate = useNavigate();
    const columns = [
        { field: 'contrato_id', headerName: 'Contrato', width: 150 },
        { field: 'cobrador', headerName: 'Cobrador', width: 200 },
        { field: 'zona', headerName: 'Zona', width: 150 },
        { field: 'periodo', headerName: 'Periodo', width: 150 },
        { field: 'dias_vencido', headerName: 'Días vencidos', width: 150 },
        { field: 'saldo_credito', headerName: 'Monto insoluto', width: 150 },
        { field: 'monto_Adeudo', headerName: 'Monto adeudo', width: 150 },
        { field: 'fecha_ultimo_pago', headerName: 'Fecha últ. pago', width: 150 },
        { field: 'fecha_ultima_clave', headerName: 'Fecha ultima clave', width: 150 },
        { field: 'dias_sin_consultar_clave', headerName: 'Días sin consultar', width: 150 },
        { field: 'visitas', headerName: 'Visitas', width: 150 },
        { field: 'fecha_ultimo_calculo', headerName: 'Últ. Actualización', width: 150 },
    ];

    const [textoBusqueda, setTextoBusqueda] = useState("")
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(1);
    const [loading, setLoading] = useState(false);
    const [zonas, setzonas] = useState([]);
    const [listaCobradores, setListaCobradores] = useState([]);
    const [zonaSeleccionada, setZonaSeleccionada] = useState("")
    const [cobradorSeleccionado, setCobradorSeleccionado] = useState("")
    //Filtro
    const [data, setData] = useState([]);
    const [pageRows, setpageRows] = useState(10);
    const [currentData, setCurrentData] = useState(data);
    const userId = sessionStorage.getItem('userId').trim();
    const userName = sessionStorage.getItem('usuario')
    const [percentage, setPercentage] = useState(0)
    const { role } = useSelector((state) => state.loginSlice);

    const [active7, setActive7] = useState(false)
    const [active15, setActive15] = useState(false)
    let allContratos = []
    const [firstProgressVigente, setFirstProgressVigente] = useState({
        porcentajeActivos: 0,
        sumaTotalDinero: 0,
        idsDiferentes: 0,
        tooltip: 0,
        totalMontoPromesas: 0,
        totalContratoPromesas: 0
    })
    const [firstProgressCumplidas, setFirstProgressCumplidas] = useState({
        porcentajeActivos: 0,
        sumaTotalDinero: 0,
        idsDiferentes: 0,
        tooltip: 0,
        totalMontoPromesas: 0,
        totalContratoPromesas: 0
    })
    const [secondProgressVigentes, setSecondProgressVigentes] = useState({
        porcentajeActivos: 0,
        sumaTotalDinero: 0,
        idsDiferentes: 0,
        tooltip: 0
    })
    const [secondProgressCumplidas, setSecondProgressCumplidas] = useState({
        porcentajeActivos: 0,
        sumaTotalDinero: 0,
        idsDiferentes: 0,
        tooltip: 0
    })

    const [totalContratos, setTotalContratos] = useState(0)
    const [totalMontoVencido, setTotalMontoVencido] = useState(0)
    const [totalMontoCobrado, setTotalMontoCobrado] = useState(0)
    const [contratosUsuario, setContratosUsuario] = useState([{}]);
    const [totalContratosUsuario, setTotalContratosUsuario] = useState('0')

    let totalContratosAtendidos = 0;
    let totalContratosCartera = 0;
    const [allPromesas, setAllPromesas] = useState()
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });

    const [isOpen, setIsOpen] = useState(false)
    const [isVisible, SetIsVisible] = useState(true)
    const dropdownRef = useRef(null)

    const toggleDropdown = () => {
        setIsOpen(!isOpen)
    }

    const toggleVisible = (visible) => {
        SetIsVisible(visible)
        setIsOpen(false)
    }

    // Volver estas funciones una sola, que active y que guarde los datos secundarios en variable seundaria a enviar.
    const toggleActive7 = () => {
        setActive15(false)
        const vigente = analizarDatos(allPromesas, 7, "vigente")
        const cumplidas = analizarDatos(allPromesas, 7, "cumplida")

        setSecondProgressVigentes(vigente)
        setSecondProgressCumplidas(cumplidas)
        setActive7(!active7)
    }

    const toggleActive15 = () => {
        setActive7(false)
        const vigente = analizarDatos(allPromesas, 15, "vigente")
        const cumplidas = analizarDatos(allPromesas, 15, "cumplida")
        setSecondProgressVigentes(vigente)
        setSecondProgressCumplidas(cumplidas)
        setActive15(!active15)
    }

    function analizarDatos(datos, dias, estado) {
        const hoy = new Date();
        hoy.setHours(0, 0, 0, 0)

        //Sacar el limite del periodo de fechas
        const fechaLimitePeriodo = new Date(hoy);
        const fechaTotal = fechaLimitePeriodo.setDate(hoy.getDate() - 15)
        const fechaTotalFormat = new Date(fechaTotal)

        //filtrado por periodo -15 dias
        let datosFiltrados = datos.filter(item => {
            // let fechaCreacion = new Date(item.fecha_creacion)
            let fechaCreacion = new Date(item?.item?.fechaCreacion)

            if (item?.item?.status === "vigente") {
                // const fechaPromesa = new Date(item.fecha_compromiso);
                const fechaPromesa = new Date(item?.item?.fechaPromesa);
                const today = new Date()
                if (fechaPromesa < today) return false
            }

            return (item?.item?.status === estado) && fechaCreacion >= fechaTotalFormat
        })

        const total = datosFiltrados.length;

        //Sacar el numero de contratos en los ultimos x dias
        const fechaLimite = hoy.setDate(hoy.getDate() - dias)
        const fechaLimiteFormat = new Date(fechaLimite)

        //Filtrado por x dias
        let datosFiltradosFecha = datos.filter(item => {
            let fechaCreacion = new Date(item?.item?.fechaCreacion)

            if (item?.item?.status === "vigente") {
                const fechaPromesa = new Date(item?.item?.fechaPromesa);
                const today = new Date()
                if (fechaPromesa < today) return false
            }

            return fechaCreacion >= fechaLimiteFormat
        })

        // Suma total de dinero
        const activos = datosFiltradosFecha.filter(obj => obj?.item?.status === estado);

        let sumaTotalDinero = activos.length > 0 ? estado === "vigente" ?//Probar el estado
            activos.reduce((total, obj) => total + obj?.item?.monto_promesa, 0) : activos.reduce((total, obj) => total + obj?.item?.pagado, 0) : 0
        // Si es Vigente devolver monto promesa, si es cumplida devolver monto recuperado.

        const sumaTotal = datosFiltrados.length > 0 ? estado === "vigente" ?//Probar el estado
            datosFiltrados.reduce((total, obj) => total + obj?.item?.monto_promesa, 0) : datosFiltrados.reduce((total, obj) => total + obj?.item?.pagado, 0) : 0

        // Número de IDs diferentes
        let idsDiferentes = activos.length

        const contratosDiferentes = datosFiltrados.length > 0 ? new Set(datosFiltrados.map(obj => obj?.item?.contrato_id)).size : 0

        // Número de estado activo y cálculo del porcentaje
        let porcentajeActivos = total > 0 ? (activos.length / total) * 100 : 0;

        //10 para acomodar el tooltip
        const tooltipPercent = Number(porcentajeActivos.toFixed(2)) + 10

        //Si dias es 7 o 15 hacer la resta de sumaTotal de activos, de ids diferentes, y procentajeActivos
        if (dias > 0) {
            if (estado === "vigente") {
                sumaTotalDinero = sumaTotalDinero - firstProgressVigente.sumaTotalDinero
                idsDiferentes = idsDiferentes - firstProgressVigente.idsDiferentes
                porcentajeActivos = porcentajeActivos - firstProgressVigente.porcentajeActivos
            }
            if (estado === "cumplida") {
                sumaTotalDinero = sumaTotalDinero - firstProgressCumplidas.sumaTotalDinero
                idsDiferentes = idsDiferentes - firstProgressCumplidas.idsDiferentes
                porcentajeActivos = porcentajeActivos - firstProgressCumplidas.porcentajeActivos
            }
        }

        return {
            sumaTotalDinero,
            idsDiferentes,
            porcentajeActivos: porcentajeActivos.toFixed(2),
            tooltip: tooltipPercent,
            totalMontoPromesas: sumaTotal,
            totalContratoPromesas: contratosDiferentes
        };
    }

    // useEffect(() => {
    //     handleGetPromesasdePago();
    //     habdleGetAll();
    // }, [])

    useEffect(() => {
        setLoading(true);
        handleCargaInicial()
    }, [])

    const handleCargaInicial = async () => {
        const [] = await Promise.all([handleGetAll(), GetUserStats(), handleGetSeguimientoContrato()]).finally(() => {
            setLoading(false);
        });
    }

    useEffect(() => {
        let montovencido = 0;
        let montocobrado = 0;
        // Filtra los datos según los filtros seleccionados
        // debugger
        const filteredData = data.filter(item => {
            if (role === "DMCobranza Nivel 2") {
                const nameMatches = textoBusqueda === '' || (item.contrato_id && item.contrato_id.toLowerCase().includes(textoBusqueda.toLowerCase()));
                return nameMatches &&
                    (zonaSeleccionada === "" || zonaSeleccionada === "Zonas" || item.zona === zonaSeleccionada);
            }

            if (role === "DMSupervisordeCobranza" || role === "Administrador") {
                const nameMatches = textoBusqueda === '' || (item.contrato_id && item.contrato_id.toLowerCase().includes(textoBusqueda.toLowerCase()));
                return nameMatches &&
                    (zonaSeleccionada === "" || zonaSeleccionada === "Zonas" || item.zona === zonaSeleccionada) &&
                    (cobradorSeleccionado === "" || cobradorSeleccionado === "Cobrador" || item.cobrador === cobradorSeleccionado);
            }
        });

        let filteredContratosUsuario = []
        if (contratosUsuario !== undefined && contratosUsuario !== null && contratosUsuario.length !== 0) {
            filteredContratosUsuario = contratosUsuario?.filter(item => {

                const nameMatches = textoBusqueda === '' || (item?.contrato_id && item?.contrato_id.toLowerCase().includes(textoBusqueda.toLowerCase()));

                return nameMatches
                // && (estatusSeleccionado === "" || estatusSeleccionado === "Estatus" || item?.item?.items[0]?.estatus === estatusSeleccionado)
                // && (tipoSeleccionado === "" || tipoSeleccionado === "Tipo de visita" || item?.tipo_visita === tipoSeleccionado)
            })
        }

        const indexOfLastItem = currentPage * pageRows
        const indexOfFirstItem = indexOfLastItem - pageRows
        const newData = filteredData.slice(indexOfFirstItem, indexOfLastItem)
        setCurrentData(newData)
        const newTotalPages = Math.ceil(filteredData.length / pageRows)
        setPageCount(newTotalPages)

        filteredData.map(m => {
            // montocobrado = montocobrado + m?.pago_periodo ?? 0;
            montovencido += (m?.monto_adeudo != null ? (typeof m?.monto_adeudo === "string" ? parseFloat(m?.monto_adeudo.replace(/[$,]/g, '')) : m?.monto_adeudo) : 0);
        })

        let contadorPromesasCumplidas = 0;
        filteredContratosUsuario.filter(x => x?.item?.status == "cumplida").map(m => {
            // if(m?.item.items.contrato.estatus === "Activo") {
            //   contratosActivos++
            // }
            montocobrado = montocobrado + (m?.item?.pagado ?? 0);
            contadorPromesasCumplidas++;
            // montovencido = montovencido + m.monto_adeudo
        })

        setTotalMontoCobrado(Number(montocobrado.toFixed(2)))
        setTotalMontoVencido(Number(montovencido.toFixed(2)))
        filteredData.forEach((item) => {
            item.monto_adeudo = typeof item.monto_adeudo === "string" ? item.monto_adeudo : formatearMontoMx(item.monto_adeudo);
        });

        setTotalContratosUsuario(contadorPromesasCumplidas)
        setTotalContratos(filteredData.length)
    }, [data, textoBusqueda, pageRows, zonaSeleccionada, cobradorSeleccionado, currentPage, contratosUsuario])


    const GetUserStats = async () => {
        let dataFormateada = []
        let idUsuario = (role === "DMSupervisordeCobranza" || role === "Administrador") ? (role === "Administrador" ? "Admin" : "Supervisor") : userId;

        let data = {
            "verb": "string",
            "id": idUsuario,
            "field": "string",
            "value": "string",
            "level": 0,
            "flag": true,
            "anyObject": {},
            "kvp": {}
        }

        await CobranzaService.GetUserStats(data)
            .then(res => {
                res?.data?.responseData?.map(item => {
                    dataFormateada.push({
                        item
                    });
                });

                const vigente = analizarDatos(dataFormateada, 0, "vigente")
                const cumplidas = analizarDatos(dataFormateada, 0, "cumplida")

                // const suma = dataFormateada.reduce((total, obj) => {
                //   return {
                //     montoVencido: montoVencido + obj.monto_vencido,
                //     montoCobrado: montoCobrado + obj.pagado
                //   };
                // }, { montoVencido: 0, montoCobrado: 0 }); // Inicializamos el acumulador

                setAllPromesas(dataFormateada)
                // setTotalMontoCobrado(suma.montoCobrado)
                // setTotalMontoVencido(suma.montoVencido)
                setFirstProgressVigente(vigente)
                setFirstProgressCumplidas(cumplidas)

            }).catch(e => {
                // setLoading(false);
                if (e.response !== undefined)
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            })

        let length = dataFormateada.length
        // let percentage = contratosActivos > 0 ? dataFormateada.length / contratosActivos : 0

        setTotalContratosUsuario(length)
        // setPercentage(percentage)
        // setDataTipoVisita(newDataTipo)
        setContratosUsuario(dataFormateada)
        // setLoading(false);
    }

    const closeDropdown = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setIsOpen(false)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', closeDropdown)
        return () => document.removeEventListener('mousedown', closeDropdown)
    }, [])

    const handleGetAll = async () => {
        // setLoading(true);
        let datosFormateados = []
        let newDataZonas = [{ value: 0, label: "Zonas" }];
        let newDataCobradores = [{ value: 0, label: "Cobrador" }];
        let counterZonas = 1;
        let counterCobradores = 1;
        let filtrarZonas = new Set()
        let filtrarCobradores = new Set()
        let userContracts = []
        await SeguimientoVisitaService.getAllEjecutivosWithFullDetail()
            .then(res => {
                if (res.data.callSucceded) {
                    if (role === "DMCobranza Nivel 2") {
                        userContracts = res.data?.responseData?.find(user => user.userid.toLowerCase() === userId.toLowerCase());
                        // const userContracts = res.data.responseData.find(user => user.userid.toLowerCase() === 'feb42264-1529-49ad-8473-29e5e61d9402'); //Solo para pruebas  
                        // Validar y filtrar si el listado de contratos no es null o vacío
                        if (Array.isArray(userContracts?.contratos) && userContracts?.contratos?.length > 0) {
                            userContracts.contratos = userContracts.contratos.filter(item => item.cartera_vencida === 1 && item.nivel_cobranza === "2" && item.politica_aval === 0);
                        }
                        if (userContracts !== undefined && userContracts !== null) {
                            if (userContracts?.contratos) {
                                allContratos = userContracts.contratos.map(obj => obj.contrato_id)
                                datosFormateados = userContracts?.contratos?.map((item) => {
                                    const fechaPago = (item.fecha_ultimo_pago === "1990-01-01T00:00:00" || item.fecha_ultimo_pago === "1900-01-01T00:00:00") ? "-" : FormatFecha(item.fecha_ultimo_pago)
                                    const fechaClave = (item.fecha_consulta_clave === "1990-01-01T00:00:00" || item.fecha_consulta_clave === "1900-01-01T00:00:00") ? "-" : FormatFecha(item.fecha_consulta_clave)
                                    const fechaUltActualizacion = FormatFecha(item.fecha_ultimo_calculo)
                                    const saldoCredito = formatearMontoMx(item.saldo_credito);
                                    const montoFormateado = formatearMontoMx(item.monto_adeudo);
                                    const montoAdeudo = item.monto_adeudo;
                                    const cobrador = userContracts?.user_detail?.names;
                                    const posesion = item?.lat === "1" ? true : false;
                                    const eliminable = item?.lat === "1" ? false : true;
                                    // console.log(item?.contrato_id, item)
                                    return {
                                        ...item,
                                        fecha_ultimo_pago: fechaPago,
                                        fecha_ultima_clave: fechaClave,
                                        fecha_ultimo_calculo: fechaUltActualizacion === "01-01-1990" ? "-" : fechaUltActualizacion,
                                        monto_adeudo: montoAdeudo,
                                        monto_Adeudo: montoFormateado,
                                        saldo_credito: saldoCredito,
                                        cobrador: cobrador,
                                        posesion: posesion,
                                        eliminable: eliminable
                                    }
                                });
                                // let contratosFiltrador = userContracts?.contratos?.filter(item => item.estatus === 'Activo')
                                let length = userContracts?.contratos?.length
                                totalContratosCartera = length
                                let percentage = totalContratosCartera > 0 ? ((totalContratosAtendidos / totalContratosCartera) * 100) : 0
                                percentage = percentage >= 1 || percentage <= 100 ? (percentage / 100) : 0;
                                setPercentage(percentage)
                                setTotalContratos(length)
                                datosFormateados.map(z => {
                                    filtrarZonas.add(z?.zona)
                                })
                            }
                        }

                    }
                    if (role === "DMSupervisordeCobranza" || role === "Administrador") {
                        let contratosFiltrador = [];
                        let length = 0;
                        res.data?.responseData.map(user => {
                            let usercontracts = user
                            if (Array.isArray(usercontracts?.contratos) && usercontracts?.contratos?.length > 0) {
                                usercontracts.contratos = usercontracts.contratos.filter(item => item.cartera_vencida === 1 && item.nivel_cobranza === "2" && item.politica_aval === 0);
                            }

                            if (usercontracts?.contratos !== undefined && usercontracts?.contratos !== null) {
                                allContratos = allContratos.concat(usercontracts?.contratos?.map(obj => obj.contrato_id))
                                datosFormateados = datosFormateados.concat(usercontracts?.contratos?.map((item) => {
                                    const fechaPago = (item.fecha_ultimo_pago === "1990-01-01T00:00:00" || item.fecha_ultimo_pago === "1900-01-01T00:00:00") ? "-" : FormatFecha(item.fecha_ultimo_pago)
                                    const fechaClave = (item.fecha_consulta_clave === "1990-01-01T00:00:00" || item.fecha_consulta_clave === "1900-01-01T00:00:00") ? "-" : FormatFecha(item.fecha_consulta_clave)
                                    const fechaUltActualizacion = FormatFecha(item.fecha_ultimo_calculo)
                                    const saldoCredito = formatearMontoMx(item.saldo_credito);
                                    const montoFormateado = formatearMontoMx(item.monto_adeudo);
                                    const montoAdeudo = item.monto_adeudo;
                                    const cobrador = usercontracts?.user_detail?.names;
                                    const posesion = item?.lat === "1" ? true : false;
                                    const eliminable = item?.lat === "1" ? false : true;
                                    // console.log(item?.contrato_id, item)
                                    return {
                                        ...item,
                                        fecha_ultimo_pago: fechaPago,
                                        fecha_ultima_clave: fechaClave,
                                        fecha_ultimo_calculo: fechaUltActualizacion === "01-01-1990" ? "-" : fechaUltActualizacion,
                                        monto_adeudo: montoAdeudo,
                                        monto_Adeudo: montoFormateado,
                                        saldo_credito: saldoCredito,
                                        cobrador: cobrador,
                                        posesion: posesion,
                                        eliminable: eliminable
                                    }
                                }));
                                contratosFiltrador = contratosFiltrador.concat(user?.contratos?.filter(item => item.estatus === 'Activo'))
                                length = length + usercontracts?.contratos?.length
                            }
                        })
                        totalContratosCartera = length
                        let percentage = totalContratosCartera > 0 ? ((totalContratosAtendidos / totalContratosCartera) * 100) : 0
                        percentage = percentage >= 1 || percentage <= 100 ? (percentage / 100) : 0;
                        setTotalContratos(length)
                        setPercentage(percentage)
                        datosFormateados.map(z => {
                            filtrarZonas.add(z?.zona)
                            filtrarCobradores.add(z?.cobrador)
                        })
                    }
                }
                for (const value of filtrarZonas) {
                    newDataZonas.push({ value: counterZonas, label: value })
                    counterZonas++
                }
                for (const value of filtrarCobradores) {
                    newDataCobradores.push({ value: counterCobradores, label: value })
                    counterCobradores++
                }
            }).catch(e => {
                // setLoading(false);
                if (e.response !== undefined)
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            });
        setzonas(newDataZonas);
        setListaCobradores(newDataCobradores)
        let arregloOrdenado = datosFormateados.sort(function (a, b) {
            if (a.fecha_ultimo_pago > b.fecha_ultimo_pago) {
                return 1;
            }
            if (a.fecha_ultimo_pago < b.fecha_ultimo_pago) {
                return -1;
            }
            return 0;
        });
        setData(arregloOrdenado.reverse())
        // setData(datosFormateados);
        // setLoading(false);
    }

    const handleDownloadFile = async (cu) => {
        setLoading(true);

        let datosAExportar = []
        let nombreArchivo = `CarteraGeneral`

        data.map(item => {
            datosAExportar.push({
                // "id_usuario": item?.names?.userId ?? "",
                "Contrato": item?.contrato_id === null ? "-" : item.contrato_id,
                "Cobrador": item?.names?.names ?? "",
                "Zona": item?.zona === null ? "-" : item?.zona,
                "Periodo": item?.periodo === null ? "-" : item.periodo,
                "Días vencidos": item?.dias_vencido === null ? "-" : item.dias_vencido,
                "Monto insoluto": item?.saldo_credito === null ? "-" : item.saldo_credito,
                "Monto adeudo": item?.monto_adeudo === null ? "-" : item.monto_adeudo,
                "Fecha últ. pago": item?.fecha_ultimo_pago === null ? "-" : item.fecha_ultimo_pago,
                "Fecha ultima clave": item?.fecha_ultima_clave === null ? "-" : item.fecha_ultima_clave,
                "Días sin consultar clave": item?.dias_sin_consultar_clave === null ? "-" : item.dias_sin_consultar_clave,
                "Visitas": item?.visitas === null ? "-" : item.visitas,
                "Últ. Actualización": item?.fecha_ultimo_calculo === null ? "-" : item.fecha_ultimo_calculo,
            })
        })

        let reporte = {
            "verb": "string",
            "id": nombreArchivo,
            "field": "string",
            "value": "string",
            "level": 0,
            "flag": true,
            "anyObject": datosAExportar,
            "kvp": {}
        }

        setLoading(true);
        await CobranzaService.ExportReport(reporte)
            .then(res => {
                var FileSaver = require('file-saver');
                FileSaver.saveAs(res.data, `${nombreArchivo}_${new Date(Date.now()).toLocaleString().split(',')[0]}.xlsx`);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })

    }

    const handlePageClick = (event) => {
        setCurrentPage(event.selected + 1);
        // handleGetAll(event.selected + 1);
    };

    const handleDetails = (value) => {
        // Movimiento a Detalles del Job con el invocador
        navigate(`/cobranza/seguimiento-telefonico/detalles`, {
            state: {
                ...value, // Incluye las propiedades del objeto `value`
                urlRegreso: '/cobranza/seguimiento-visita/cartera-general'  // Agrega el parámetro del invocador
            }
        });
    };

    const handleGetSeguimientoContrato = async () => {
        // setLoading(true);
        let dataFormateada = []
        let contratosActivos = 0;
        let contratosAtendidos = 0;

        let data= {
            "verb": "string",
            "id": "string",
            "field": "string",
            "value": "string",
            "level": 1,
            "flag": true,
            "anyObject": {},
            "kvp": {}
          };

        await CobranzaService.GetAllRutasCobranza(data)
            .then(res => {
                res?.data?.responseData?.map(item => {
                    let lista = []
                    if (role === "DMCobranza Nivel 2") {
                        if (item?.userid.toLowerCase() === userId.toLowerCase()) {
                            lista = item?.items
                        }
                    }
                    if (role === "DMSupervisordeCobranza" || role === "Administrador") {
                        lista = item?.items
                    }

                    for (const i of lista) {
                        if (i?.contrato !== null &&
                            i?.contrato.cartera_vencida === 1 &&
                            i?.contrato.nivel_cobranza === "2" &&
                            // i?.estatus === "Pendiente" &&
                            i?.cierre_visita === false) {

                            // if (i?.contrato.estatus === "Activo") {
                            contratosActivos++
                        }
                    }

                    for (const i of lista) {
                        if (i?.contrato !== null &&
                            i?.contrato.cartera_vencida === 1 &&
                            i?.contrato.nivel_cobranza === "2" &&
                            i?.cierre_visita === true) {
                            contratosAtendidos++;
                        }
                    }
                })
            }).catch(e => {
                // setLoading(false);
                if (e.response !== undefined)
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            })

        //   let length = contratosActivos
        totalContratosAtendidos = contratosAtendidos

        let percentage = contratosActivos > 0 ? ((contratosAtendidos / contratosActivos) * 100).toFixed(2) : 0
        percentage = percentage >= 1 || percentage <= 100 ? (percentage / 100) : 0;
        totalContratosAtendidos = contratosAtendidos
        setPercentage(percentage)
        // setDataTipoVisita(newDataTipo)

        let arregloOrdenado = dataFormateada.sort(function (a, b) {
            if (a.fecha > b.fecha) {
                return 1;
            }
            if (a.fecha < b.fecha) {
                return -1;
            }
            return 0;
        });
        setData(arregloOrdenado.reverse())
        // setLoading(false);
    }

    return {
        setTextoBusqueda,
        columns,
        currentData,
        handleDetails,
        handlePageClick,
        currentPage,
        pageCount,
        loading,
        zonas,
        listaCobradores,
        setpageRows,
        setZonaSeleccionada,
        setCobradorSeleccionado,
        handleDownloadFile,
        dropdownRef,
        toggleDropdown,
        toggleVisible,
        firstProgressVigente,
        firstProgressCumplidas,
        secondProgressVigentes,
        secondProgressCumplidas,
        totalMontoCobrado,
        totalMontoVencido,
        active7,
        active15,
        totalContratos,
        totalContratosUsuario,
        percentage,
        isVisible,
        toggleActive7,
        toggleActive15,
        isOpen,
        role
    }
}

export default useCarteraGeneral