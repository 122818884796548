import axios from '../../Utils/ApiService';

const DSAutorizaciones = {
    getSeguimientoServicios: ({ buscar, page, rows }) => {
        return axios.get(`/functions/seguimientoAutorizaciones?Buscar=${buscar}&Page=${page}&Rows=${rows}`);
    },
    getSegumientoCitas: ({ buscar, page, rows,tipoUbicacion }) => {
        return axios.get(`/functions/seguimientoCitasServicios?Buscar=${buscar}&Page=${page}&Rows=${rows}&TipoUbicacion=${tipoUbicacion}`);
    },
}

export default DSAutorizaciones;