import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DSystemsServicios from '../../../Services/DatamovilSystems/ServiciosDatamovil';
import Llamada from '../../../Services/Cartera/Llamadas';
import Catalogo from "../../../Services/Catalogos/Catalogo";
import { useSelector } from 'react-redux';
import { selectScheduleByTipoCalendario } from '../../../Redux/Slice/Calendario/calendarDynamicalSlice';
import { getDateFormat, isEmpty, IsNullOrEmpty,message } from '../../../Utils/functions';

const useInicioServicio = ({ handleConcluido, paso,toast }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { state } = useLocation();

    const CitaServicioDatamovil = useSelector((state) => selectScheduleByTipoCalendario(state, 19));
    const location = useLocation();
    let url = location.pathname.split("/");

    //console.log("calendario", CitaServicioDatamovil);

    const initial = {
        servicioId: "",
        generalId: "",
        tipoServicioId: 0,
        procesoId: 0,
        tipoSolicitudId: "",
        comentarios: "",
        paso: url[5],
        servicioAutorizado: true,
        servicioMotivoRechazo: null,
        comentariosRechazo: null,
        asignado: false,
        fechaCita: "",
        horaCita: "",
        fechayHora: "",
        tipoUbicacion: 1,
        ubicacion: null,
        bloqueoId: null,
        citaAutorizada: null,
        arriboCita: null,
        usuarioAutorizo: null,
        vehiculoEnUbicacion: true,
        usuarioAlta: "",
        usuarioActualiza: null,
        fechaAlta: "",
        fechaUpdate: null,
        concluido: null,
        fechaConcluido: null,
        estadoId: null,
        municipioId: null,
        coloniaId: null,
        calle: null,
        numeroExt: null,
        fechaAutorizacion: null,
        tipoOrden: "",
        tipoServicio: "",
        proceso: "",
        usuarioSolicita: "",
        usuarioAprobo: null,
        usuarioActualizaStr: null,
        concluirRegistroInicioServicio: false,
        fechaInicioUbicacion: "", // Fecha txt
        horaInicioUbicacion: "", // hora visible
        reagendarCita: false,
        motivoNoIngreso: null
    };

    const [initialValue, setInitialValue] = useState(initial);
    const [loading, setLoading] = useState(false);
    const [estados, setEstados] = useState([]);
    const [municipios, setMunicipios] = useState([]);
    const [colonias, setColonias] = useState([]);
    const opcionesBool = [{ value: true, label: 'Si' }, { value: false, label: 'No' }];
    const [ubicaciones, setUbicaciones] = useState([]);
    const [motivosNoIngreso, setMotivosNoIngreso] = useState([]);

    const tipoUbicaciones = [
        { value: 1, label: 'Interna' },
        { value: 2, label: 'Externa' },
    ];

    useEffect(() => {
        getEstados();
        getDataCita();
        getUbicaciones();
        getMotivosNoIngreso();
    }, []);


    const getFormatFromCalendar = (dia, hora) => {
        return `${dia} ${hora}`;
    }

    const mapResponseData = (data, valueKey, labelKey) =>
        data.map(item => ({ value: item[valueKey], label: item[labelKey] }));

    const getUbicaciones = async () => {
        await Catalogo.getDropdownsByTable('Sistema.Ubicacion')
            .then(resp => {
                const items = resp.data.map(item => {
                    return {
                        value: item.UbicacionId,
                        label: item.Nombre,
                        responsable: item.Responsable
                    }
                })
                setUbicaciones(items);
            })
    }

    const getDataCita = async () => {
        try {
            setLoading(true);
            const res = await DSystemsServicios.getServicio(id);
            const data = res.data;
            console.log("data cita", data)
            if (data.fechaCita) data.fechaCita = data.fechaCita.split("T")[0];
            if (data.estadoId) await getMunicipios(data.estadoId);
            if (data.municipioId) await getColonias(data.municipioId);

            data.vehiculoEnUbicacion = data.vehiculoEnUbicacion ?? null;

            data.tipoUbicacion = data?.tipoUbicacion ? data?.tipoUbicacion : CitaServicioDatamovil ? 1 : "";
            data.calendarioId = data?.calendarioId ?? CitaServicioDatamovil?.calendarioId ?? "";
            data.ubicacion = data.ubicacion ?? CitaServicioDatamovil.sucursalName;
            data.fechaCita = data.fechaCita ?? CitaServicioDatamovil.day;
            data.horaCita = data.horaCita ?? CitaServicioDatamovil.hour;
            data.sucursalId = data.sucursalId ?? CitaServicioDatamovil?.sucursalId ?? "";

            data.fechayHora = !IsNullOrEmpty(data?.fechayHora) ? data?.fechayHora : !isEmpty(CitaServicioDatamovil) ? getFormatFromCalendar(CitaServicioDatamovil.day, CitaServicioDatamovil.hour) : "";

            //setInitialValue({ ...initialValue, ...data });
            data.nombreUbicacion = data.nombreUbicacion ?? CitaServicioDatamovil.sucursalName;
            if (!IsNullOrEmpty(data.fechaHoraCita) || !IsNullOrEmpty(CitaServicioDatamovil.day)) {
                data.fechaHoraCita = !IsNullOrEmpty(data?.fechaHoraCita) ? data?.fechaHoraCita : !isEmpty(CitaServicioDatamovil) ? getFormatFromCalendar(CitaServicioDatamovil.day, CitaServicioDatamovil.hour) : "";
            }

            data.fechaInicioUbicacion = data?.fechaInicioServicio?.split(" ")[0]
            data.horaInicioUbicacion = data?.fechaInicioServicio?.split(" ")[1] + ' ' + data?.fechaInicioServicio?.split(" ")[2]

            data.reagendarCita = data.reagendarCita ?? null
            data.paso = url[5]

            if (data?.vehiculoEnUbicacion === false) {
                handleConcluido({ concluido: (data?.codEstatusUbicacion === 2) ? data?.concluirRegistroInicioServicio : false, concluidoGeneral: data?.concluidoGeneral ?? false });
            } else {
                handleConcluido({ concluido: data?.concluirRegistroInicioServicio ?? false, concluidoGeneral: data?.concluidoGeneral ?? false });
            }

            setInitialValue({ ...initialValue, ...data });

        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const submitFormAgendarCita = async (values) => {


    };

    const submitFormUbicacionVehiculo = async (values) => {

        if (values.concluirRegistroInicioServicio) {
            if (!values.subidoUbicacion) {
                toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "Falta subir evidencia.")}</div>);
                setTimeout(() => {
                    toast.remove();
                }, 3000);
                return;
            }
        }

        setLoading(true);
        DSystemsServicios.updateServicioInicio(values)
            .then(res => {
                if (
                    (values.concluirRegistroInicioServicio && values.reagendarCita) ||
                    (values.concluirRegistroInicioServicio && values.vehiculoEnUbicacion === false)
                ) {
                    navigate(`/datamovil/${state.tipoProceso}/${state.tipoServicioid}/${id}/${values.paso - 1}`, {
                        state: { ...state }
                    });
                } else {
                    getDataCita()
                }
            })
            .catch(error => {

            })
            .finally(() => {
                setLoading(false);
            })
    };

    const getEstados = async () => {
        try {
            const res = await Llamada.GetEstadoMx();
            setEstados(mapResponseData(res.data.data, 'edoMexicoId', 'estado'));
        } catch (error) {
            console.error(error);
        }
    };

    const getMunicipios = async (estadoId) => {
        if (!estadoId) return;
        try {
            const res = await Llamada.GetMunicipio(estadoId);
            setMunicipios(mapResponseData(res.data.data, 'municipioId', 'municipio'));
        } catch (error) {
            console.error(error);
        }
    };

    const getColonias = async (municipioId) => {
        if (!municipioId) return;
        try {
            const res = await Llamada.GetColonia(municipioId);
            setColonias(mapResponseData(res.data.data, 'coloniaId', 'colonia'));
        } catch (error) {
            console.error(error);
        }
    };

    const getMotivosNoIngreso = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.MotivoNoIngreso')
            .then(resp => {
                let results = resp.data.map(item => {
                    return {
                        value: item.MotivoNoIngreso,
                        label: item.Descripcion
                    }
                });
                setMotivosNoIngreso(results);
            })
            .catch(err => {
                console.error(err);
            });
    }

    return {
        initialValue,
        loading,
        setLoading,
        submitFormAgendarCita,
        submitFormUbicacionVehiculo,
        getEstados,
        getMunicipios,
        getColonias,
        estados,
        municipios,
        colonias,
        opcionesBool,
        tipoUbicaciones,
        ubicaciones,
        motivosNoIngreso
    };
};

export default useInicioServicio;
