import React, { useRef, useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { toast, Toaster } from 'react-hot-toast'
import Spinner from "../../../../Components/Loadig"
import Modal from "../../../../Components/Modal/Modal"
import { TextField } from '../../../../Components/TextField';
import { Formik, Form, Field } from 'formik'
import { TextAreaField } from '../../../../Components/TextAreaField';
import { SelectField } from '../../../../Components/SelectField';
import RowPages from '../../../../Components/RowPages';
import DataTable from '../../../../Components/datatable/DataTable'
import { FormatNumberMoney2, message, formatearMontoMx, getFormatDate, FormatFecha, NumFolio } from '../../../../Utils/functions'
import CobranzaService from '../../../../Services/Cobranza/Cobranza'
import ModalCostosResguardoUnidad from './ModalCostosResguardoUnidad'
import ModalConfirmarEliminar from './ModalConfirmarEliminar'
import { v4 as uuidv4 } from 'uuid';
import { BrowserView } from 'react-device-detect';
import { useNavigate } from "react-router-dom";
import { setItemGeneral, setContrato } from '../../../../Redux/Slice/Cobranza/SeguimientoVisita/SeguimientoVisitaDetalleSlice';
import VisorFile from '../../../../Components/VisorFile';
import AuthLogin from '../../../../Utils/AuthLogin';
import dataDocsServices from '../../../../Services/DataDocs/dataDocsServices';
import axios from 'axios';

const auth = new AuthLogin();
const ResguardoUnidadCards = React.forwardRef(({ ...props }, ref) => {
   const { itemGeneral, contratoActual } = useSelector((state) => state.SeguimientoVisitaDetalleSlice);
   const navigate = useNavigate();
   const [collpsableGrua, setCollpsableGrua] = useState(false);
   const refFormik = useRef();
   const [registradoPreviamente, setRegistradoPreviamente] = useState(false);
   const [show, setShow] = useState(props.show);
   const [update, setUpdate] = useState(false)
   const [fechaInicio, setFechaInicio] = useState("")
   const [fechaFin, setFechaFin] = useState('')
   const userId = sessionStorage.getItem('userId').trim()
   const [valoresActuales, setValoresActuales] = useState({})
   const { role } = useSelector((state) => state.loginSlice);
   const [isOpenDocs, setIsOpenDocs] = useState(false)
   const [fileName, setFileName] = useState("");
   const [SelectedDocs, setSelectedDocs] = useState([]);
   let url = auth.UrlDataDocs();
   const [origenIdR, setOrigenIdR] = useState("")
   const [loadingSaving, setLoadingSaving] = useState(false)

   useEffect(() => {
      setShow(props.show);
      if (itemGeneral.resguardoUnidad !== null) {
         setUpdate(!update)
         setRegistradoPreviamente(Boolean(itemGeneral?.resguardoUnidad?.registro_concluido));
         const fechahora = itemGeneral?.resguardoUnidad?.fecha_hora_resguardo.split('T') ?? ["", ""];
         setOrigenIdR(itemGeneral.resguardoUnidad?.id)
         setDatosPromesaPago({
            comentarios: itemGeneral.resguardoUnidad?.comentarios ?? '',
            forma_arribo: itemGeneral.resguardoUnidad?.forma_arribo ?? '',
            id_f_arribo: "",
            folio: itemGeneral?.resguardoUnidad?.folio ?? NumFolio(),
            id: itemGeneral?.resguardoUnidad?.id ?? '',
            motivo_resguardo: itemGeneral.resguardoUnidad?.motivo_resguardo ?? '',
            id_m_resguardo: "",
            quien_entrega: itemGeneral.resguardoUnidad?.quien_entrega ?? '',
            costosAdicionalesTbl: handleCargarTabla(itemGeneral.resguardoUnidad?.resguardoCostos, itemGeneral?.resguardoUnidad?.registro_concluido) ?? [],
            ruta_item_id: itemGeneral.resguardoUnidad?.ruta_item_id ?? '',
            ubicacion_entrega: itemGeneral.resguardoUnidad?.ubicacion_entrega ?? '',
            id_u_entrega: "",
            usuario_recibe: itemGeneral.resguardoUnidad?.usuario_recibe ?? '',
            fechaResguardo: fechahora[0],
            horaResguardo: fechahora[1],
            registro_concluido: itemGeneral.resguardoUnidad?.registro_concluido ?? false,
            fecha_inicio: itemGeneral.resguardoUnidad?.fecha_inicio ?? "",
            fecha_fin: itemGeneral.resguardoUnidad?.fecha_fin ?? ""
         })
         setTotalPagos(handleTotal(itemGeneral.resguardoUnidad?.resguardoCostos))
         setFechaFin(itemGeneral.resguardoUnidad?.fecha_fin ?? "")
         setFechaInicio(itemGeneral.resguardoUnidad?.fecha_inicio ?? "")
         Boolean(itemGeneral?.resguardoUnidad?.registro_concluido) ? props.handleSetCerrarVisita(true) : props.handleSetCerrarVisita(false);
      }
   }, [props.show, itemGeneral]);

   const handleCargarTabla = (arreglo, concludio) => {
      let lista = []
      let counter = 1;
      arreglo?.map(item => {
         lista.push({
            id: item?.id,
            count: counter,
            docBin: item?.docBin,
            fechaHora: formatearFecha(new Date(item?.fecha)),
            fecha: item?.fecha,
            ejecutivo: sessionStorage.getItem('nombreUsuario').trim(),
            concepto: item?.concepto,
            subtotal: item?.subtotal,
            subtotal_formateado: formatearMontoMx(item?.subtotal),
            iva: item?.iva,
            total: item?.total,
            total_formateado: formatearMontoMx(item?.total),
            acciones: { subido: false },
            // resguardoCostoDocs: item?.resguardoCostoDocs,
            subido: item?.subido === true || item?.docBin !== null ? true : false
         });
         counter++
      })
      return lista
   }

   let initialPromesaPago = {
      motivo_resguardo: "",
      id_m_resguardo: "",
      forma_arribo: "",
      id_f_arribo: "",
      costosAdicionalesTbl: [],
      fechaResguardo: "",
      horaResguardo: "",
      quien_entrega: "",
      usuario_recibe: "",
      ubicacion_entrega: "",
      id_u_entrega: "",
      comentarios: "",
      registro_concluido: false,
      fecha_inicio: "",
      fecha_fin: "",
      folio: NumFolio()
   };
   const [datosPromesaPago, setDatosPromesaPago] = useState(initialPromesaPago);
   const [totalPagos, setTotalPagos] = useState(0);
   const [dataMotivoResguardo, setDataMotivoResguardo] = useState([]);
   const [dataFormaArribo, setDataFormaArribo] = useState([]);
   const [dataUbicacion, setDataUbicacion] = useState([]);
   const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });
   const [isOpenModal, setIsOpenModal] = useState(false);
   const [selectedItem, setSelectedItem] = useState();
   const [pageRows, setpageRows] = useState(10);
   const [itemSelected, setItemSelected] = useState();
   const [openModalDelete, setOpenModalDelete] = useState(false);
   const [pageCount, setPageCount] = useState(1);
   const [currenPage, setCurrenPage] = useState(1);
   const [loading, setLoading] = useState(false);
   const dispatch = useDispatch();

   const columnsPagos = [
      { field: 'count', headerName: '#' },
      { field: 'fechaHora', headerName: 'Fecha y hora' },
      { field: 'ejecutivo', headerName: 'Ejecutivo' },
      { field: 'concepto', headerName: 'Concepto' },
      { field: 'subtotal_formateado', headerName: 'Subtotal' },
      { field: 'iva', headerName: 'IVA' },
      { field: 'total_formateado', headerName: 'Total' },
      { field: 'acciones', headerName: 'Acciones', width: 200 },
   ];

   useEffect(() => {
      handleGetMotivoResguardo();
      handleGetAllFormaArribo();
      handleGetAllUbicaciones();
   }, [update]);

   const handleEdit = (item) => {
      setSelectedItem({ ...item });
      setIsOpenModal(true)
   };

   const addUpdateCostosAdicionalesTbl = (item) => {
      var counter = datosPromesaPago.costosAdicionalesTbl.length + 1;
      if (item?.idCostoAdicional === "") {
         //insert
         // const maxId = datosPromesaPago.costosAdicionalesTbl.length > 0 ? datosPromesaPago.costosAdicionalesTbl.reduce((max, item) => item.id > max ? item.id : max, datosPromesaPago.costosAdicionalesTbl[0].id) : 0;
         // const nuevoId = maxId + 1; 
         const now = new Date();
         datosPromesaPago.costosAdicionalesTbl.push({
            id: uuidv4(),
            count: counter,
            fechaHora: formatearFecha(now),
            fecha: now,
            ejecutivo: sessionStorage.getItem('nombreUsuario').trim(),
            concepto: item?.concepto,
            subtotal: item?.subtotal,
            subtotal_formateado: formatearMontoMx(item?.subtotal),
            iva: item?.iva,
            total: item?.total,
            total_formateado: formatearMontoMx(item?.total),
            acciones: { subido: false },
            subido: false
         });
      }
      else {
         //update
         let index = datosPromesaPago.costosAdicionalesTbl.findIndex(x => x.id === item?.idCostoAdicional);
         if (index !== -1) {
            datosPromesaPago.costosAdicionalesTbl[index].concepto = item?.concepto;
            datosPromesaPago.costosAdicionalesTbl[index].subtotal = item?.subtotal;
            datosPromesaPago.costosAdicionalesTbl[index].subtotal_formateado = formatearMontoMx(item?.subtotal);
            datosPromesaPago.costosAdicionalesTbl[index].iva = item?.iva;
            datosPromesaPago.costosAdicionalesTbl[index].total = item?.total;
            datosPromesaPago.costosAdicionalesTbl[index].total_formateado = formatearMontoMx(item?.total);
         }
      }
      setTotalPagos(handleTotal(datosPromesaPago.costosAdicionalesTbl));
      setDatosPromesaPago(datosPromesaPago);
      valoresActuales.costosAdicionalesTbl = datosPromesaPago.costosAdicionalesTbl
      valoresActuales.total = handleTotal(datosPromesaPago.costosAdicionalesTbl)
      handleGuardar(valoresActuales, false, true, false, "")
   };


   const handleGetAllUbicaciones = async () => {
      setLoading(true);
      let data = {
         "verb": "string",
         "id": "string",
         "field": "string",
         "value": "string",
         "level": 0,
         "flag": true,
         "anyObject": {},
         "kvp": {}
      }

      await CobranzaService.getAllUbicacion(data)
         .then(res => {
            let listaUbicaciones = [];
            res.data?.responseData?.forEach(item => {
               if (item?.status) {
                  if (datosPromesaPago.ubicacion_entrega !== null && datosPromesaPago.ubicacion_entrega !== '' && item.nombre === datosPromesaPago.ubicacion_entrega) {
                     datosPromesaPago.id_u_entrega = item.ubicacionId
                  }
                  listaUbicaciones.push({
                     value: item.ubicacionId,
                     label: item.nombre,
                     responsable: item?.responsable ?? ""
                  });
               }
            });
            setDataUbicacion(listaUbicaciones);
         })
         .catch(err => {
            setLoading(false);
            console.error(err);
         })
      setLoading(false);
   }

   const handleTotal = (array) => {
      let total = 0.0
      if (array !== null && array !== undefined && array.length > 0) {
         total = array?.reduce((previous, current) => {
            return previous + current?.total; // sumar el valor de una propiedad
         }, 0);
      }
      return total;
   }

   const deleteCostosAdicionalesTbl = async (item) => {
      setLoading(true);
      const now = new Date();
      let data ={
         "verb": "string",
         "id": "string",
         "field": "string",
         "value": "string",
         "level": 0,
         "flag": true,
         "anyObject": {
            "id": item.id,
            "resguardo_id": itemGeneral?.resguardoUnidad?.id,
            "concepto": item.concepto,
            "subtotal": item.subtotal,
            "iva": item.iva,
            "total": item.total,
            "fecha": formatDate(now)
         },
         "kvp": {}
      }

      await CobranzaService.DeleteCobranzaResguardoCosto(data)
         .then(res => {
            console.log(res)
            if(res?.data?.callSucceded){
               let counter = 0;
               datosPromesaPago.costosAdicionalesTbl = datosPromesaPago.costosAdicionalesTbl.filter(x =>{
                  if(x.id !== item?.id){
                     counter++
                     x.count = counter
                     return {
                        ...x
                     }
                  }
               });
               setDatosPromesaPago(datosPromesaPago);
               handleActualizarItem(false, false, "")
            }
         })
         .catch(err => {
            setLoading(false);
            console.error(err);
         })
      setLoading(false);
   };

   const handleUploadFile = async (origenid, id) => {
      let origenId = origenid ?? itemGeneral?.resguardoUnidad?.id

      navigate(`/cobranza/seguimiento-visita/datadocs/fResguardo/eResguardo/${contratoActual?.contrato_id}/${origenId}`, {
         state: { ...contratoActual, folio: itemGeneral?.resguardoUnidad?.folio,  registroId: id, vin: contratoActual?.vin, nombreMarca: contratoActual?.vehiculo?.nombreMarca, nombreModelo: contratoActual?.vehiculo?.nombreModelo, nombreVersion: contratoActual?.vehiculo?.nombreVersion, nombreYear: contratoActual?.vehiculo?.nombreYear }
      });
   }

   const handleGetTokenDatadocs = async () => {
      await dataDocsServices.UpdateTokenDataDc()
         .then(res => {
            auth.handleAuthSesionDataDocs(res.data.data);
         }).catch(e => { })
   }

   const handleViewdocs = async (item) => {
      console.log(item)
      setLoading(true);
      setFileName(item?.docBin?.nombreDocumento);
      await handleGetTokenDatadocs();
      await axios.post(`${url}Documento/GetFileById/${item?.docBin?.idFolder}/${item?.docBin?.documentoId}/false`, {}, {
         headers: {
            'Authorization': 'Bearer ' + auth.tokenDataDocs(),
         },
         responseType: "blob"
         })
         .then(res => {
            var file = new File([res.data], res.data.name, { type: res.data.type });
            setSelectedDocs([file])
            setLoading(false);
            setIsOpenDocs(true)
         }).catch(e => {
            setLoading(false);
            if (e.response !== undefined)
               toast.error(message("Error.!", e.response.data.errors?.error));
            else
               toast.error(message("Error.!", e.message));
         })
   }


   const handlePageClick = (event) => {
      setCurrenPage(event.selected + 1);
      // getAll(event.selected + 1);
   };

   const handleGetMotivoResguardo = async () => {
      setLoading(true);
      const parametrosPeticion = {
         "verb": "string",
         "id": "string",
         "field": "string",
         "value": "string",
         "flag": true,
         "anyObject": {}
      };
      await CobranzaService.getAllMotivoResguardo(parametrosPeticion)
         .then(res => {
            let listadoCatalogo = [];
            res.data.responseData?.map(x => {
               if (datosPromesaPago.motivo_resguardo !== null && datosPromesaPago.motivo_resguardo !== '' && x.valor === datosPromesaPago.motivo_resguardo) {
                  datosPromesaPago.id_m_resguardo = x.id
               }
               listadoCatalogo.push({
                  value: x.id,
                  label: x.valor
               })
            });

            setDataMotivoResguardo(listadoCatalogo);
            setLoading(false);
         }).catch(e => {
            setLoading(false);
            if (e.data !== undefined)
               setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.responseData.message })
            else
               setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
         })
   }

   const handleGetAllFormaArribo = async () => {
      setLoading(true);
      const parametrosPeticion = {
         "verb": "string",
         "id": "string",
         "field": "string",
         "value": "string",
         "flag": true,
         "anyObject": {}
      };
      await CobranzaService.getAllFormaArribo(parametrosPeticion)
         .then(res => {
            let listadoCatalogo = [];
            res.data.responseData?.map(x => {
               if (x?.status) {
                  if (datosPromesaPago.forma_arribo !== null && datosPromesaPago.forma_arribo !== '' && x.resguardoFormaArribo === datosPromesaPago.forma_arribo) {
                     datosPromesaPago.id_f_arribo = x.resguardoFormaArriboId
                  }
                  listadoCatalogo.push({
                     value: x.resguardoFormaArriboId,
                     label: x.resguardoFormaArribo
                  })
               }
            });

            setDataFormaArribo(listadoCatalogo);
            setLoading(false);
         }).catch(e => {
            setLoading(false);
            if (e.data !== undefined)
               setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.responseData.message })
            else
               setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
         })
   }

   const handleGuardar = async (values, subirdocumento, registroCosto, documentocosto, idcosto) => {
      setLoading(true);

      let idRegistroPrincipal = uuidv4();
      const fecha_fin = values.registro_concluido === true ? getSqlServerDateTime() : new Date(1990, 0, 1).toISOString().replace('T', ' ').slice(0, 23);
      const fecha_ini = values.fecha_inicio !== "" ? values.fecha_inicio : getSqlServerDateTime();
      setFechaFin(fecha_fin)
      setFechaInicio(fecha_ini)

      const now = new Date();

      let fechaR = values.fechaResguardo === "" ? `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')}` : values.fechaResguardo;
      let horaR = values.horaResguardo === "" ? `${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}:${String(now.getSeconds()).padStart(2, '0')}` : values.horaResguardo;

      let data = {
         "verb": "string",
         "id": "string",
         "field": "string",
         "value": "string",
         "flag": true,
         "level": -1,
         "anyObject": {
            "id": itemGeneral?.resguardoUnidad?.id ?? idRegistroPrincipal,
            "ruta_head_id": itemGeneral?.ruta_head_id,
            "ruta_item_id": itemGeneral?.id ?? "",
            "evento_head_id": itemGeneral?.evento_head_id,
            "evento_item_id": itemGeneral?.evento_item_id,
            "motivo_resguardo": values.motivo_resguardo,
            "forma_arribo": values.forma_arribo,
            "total_costo": registroCosto ? Number((values.total).toFixed(2)) : Number(totalPagos.toFixed(2)),
            "fecha_hora_resguardo": fechaR + " " + horaR,
            "quien_entrega": values.quien_entrega,
            "usuario_recibe": values.usuario_recibe,
            "otro": "",
            "ubicacion_entrega": values.ubicacion_entrega,
            "comentarios": values.comentarios,
            "registro_concluido": values.registro_concluido === true ? 1 : 0,
            "resguardoCostos": handleCostosAdicionales(values.costosAdicionalesTbl, idRegistroPrincipal),
            "fecha_inicio": fecha_ini,
            "fecha_fin": fecha_fin,
            "folio": values.folio,
         }
      };

      console.log(data)

      await CobranzaService.createUpsertCobranzaResguardoUnidad(data)
         .then(res => {
            console.log(res)
            setLoading(false)
            setRegistradoPreviamente(values.registro_concluido)
            toast.success(message("¡Operación exitosa!", res.data.mensaje));
            UpsertCobranzaRutaItem()
            handleActualizarItem(subirdocumento, documentocosto, idcosto)
            Boolean(values.registro_concluido) ? props.handleSetCerrarVisita(true) : props.handleSetCerrarVisita(false);
            // props.handleSetCerrarVisita(true);
         }).catch(e => {
            console.log(e)
            setLoading(false)
            if (e.response !== undefined)
               toast.error(message("¡Error!", e.response.data.message));
            else
               toast.error(message("¡Error!", e.message));
         })
   }

   const handleCostosAdicionales = (costos, idRegistroPrincipal) => {
      let lista = []
      costos.map(item => (
         lista.push({
            id: item.id,
            resguardo_id: itemGeneral?.resguardoUnidad?.id ?? idRegistroPrincipal,
            concepto: item.concepto,
            subtotal: parseFloat(item.subtotal),
            iva: parseFloat(item.iva),
            total: item.total,
            fecha: formatDate(item.fecha)
         })
      ))
      return lista
   }

   function formatDate(fecha) {
      let date = new Date(fecha)
      let format = "";

      if (date !== undefined && date !== "" && date !== null) {
         format = date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2) + " " + ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2) + ":" + ("0" + date.getSeconds()).slice(-2);
         return format;
      }
   }


   function formatearFecha(fechaISO) {
      if (!fechaISO) {
         return 'Fecha no válida'; // Mensaje para fechas nulas o indefinidas
      }

      const fecha = new Date(fechaISO);

      if (isNaN(fecha.getTime())) {
         return 'Fecha no válida'; // Mensaje si la fecha no puede ser convertida
      }

      const dia = fecha.getDate().toString().padStart(2, '0');
      const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
      const anio = fecha.getFullYear();
      const horas = (fecha.getHours() % 12 || 12).toString().padStart(2, '0');
      const minutos = fecha.getMinutes().toString().padStart(2, '0');
      const segundos = fecha.getSeconds().toString().padStart(2, '0');
      const ampm = fecha.getHours() >= 12 ? 'PM' : 'AM';

      return `${dia}-${mes}-${anio} ${horas}:${minutos}:${segundos} ${ampm}`;
   }

   function getSqlServerDateTime() {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const day = String(now.getDate()).padStart(2, '0');
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      const seconds = String(now.getSeconds()).padStart(2, '0');
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
   }

   const handleActualizarItem = async (subirdocumento, documentocosto, idcosto) => {
      setLoadingSaving(true);

      await CobranzaService.GetAllRutasCobranza()
         .then(res => {
            res?.data?.responseData?.map(item => {
               if (item?.userid.toLowerCase() === userId.toLowerCase() || role === "DMSupervisordeCobranza" || role === "Administrador") {
                  for (const i of item?.items) {
                     if (i?.contrato !== null && i?.contrato.contrato_id === contratoActual?.contrato_id && i?.id === itemGeneral?.id) {
                        setOrigenIdR(i?.resguardoUnidad?.id)
                        dispatch(setItemGeneral(i));
                        dispatch(setContrato(i?.contrato));
                        if (subirdocumento) {
                           if (documentocosto) {
                              handleUploadFile(origenIdR, idcosto)
                           }
                           else {
                              console.log("data actualizada",i)
                              handleUploadFile(i?.resguardoUnidad?.id, i?.resguardoUnidad?.id)
                           }
                        }
                     }
                  }
               }
            })
            setLoadingSaving(false)
         }).catch(e => {
            setLoadingSaving(false);
            if (e.response !== undefined)
               setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
            else
               setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
         })

   }

   const handleDocumentoInspeccion = async () =>{
      setLoading(true)
      let nombreArchivo = "InspeccionRapidaCobranza"
      let currentDay = new Date()
      let data = {
         "verb": "string",
         "id": "InspeccionRapidaCobranza",
         "field": "string",
         "value": "string",
         "level": -1,
         "flag": true,
         "anyObject": {
            "contrato_id":contratoActual?.contrato_id, 
            "vin": contratoActual?.vin, 
            "datamovil":"", 
            "cliente":`${contratoActual?.nombre} ${contratoActual?.apellido_paterno} ${contratoActual?.apellido_materno}`, 
            "marca": contratoActual?.vehiculo?.nombreMarca ?? "", 
            "modelo": contratoActual?.vehiculo?.nombreModelo ?? "", 
            "anio": contratoActual?.vehiculo?.nombreYear ?? "", 
            "version": contratoActual?.vehiculo?.nombreVersion ?? "",
            "color": contratoActual?.vehiculo?.colorVehiculo ?? "",
            "fecha": FormatFecha(currentDay), 
            "folio": NumFolio(), 
            "ubicacion": `${contratoActual?.calle} ${contratoActual?.no_ext}, ${contratoActual?.estado} - ${contratoActual?.municipio} - ${contratoActual?.colonia}`
         },
         "kvp":{}
      }

      console.log(data)

      await CobranzaService.GetAnyPdf(data)
         .then(res=>{
            var FileSaver = require('file-saver');
            FileSaver.saveAs(res.data, `${nombreArchivo}_${new Date(Date.now()).toLocaleString().split(',')[0]}.PDF`);
         })
         .catch(e => {
            setLoading(false);
            if (e.response !== undefined)
               setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
            else
               setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
         })
         setLoading(false)
   }

   const UpsertCobranzaRutaItem = async () => {
      setLoading(true);
      let data = {
         "verb": "string",
         "id": "string",
         "field": "string",
         "value": "string",
         "level": 0,
         "flag": false,
         "anyObject": {
            "id": itemGeneral?.id,
            "ruta_head_id": itemGeneral?.ruta_head_id,
            "evento_item_id": itemGeneral?.evento_item_id,
            "evento_head_id": itemGeneral?.evento_head_id,
            "fecha": itemGeneral?.fecha,
            "tipo_visita": itemGeneral?.tipo_visita,
            "estatus": "Proceso",
            "resultado": itemGeneral?.resultado,
            "userid": contratoActual?.userid,
            // "fecha_inicio": itemGeneral?.fecha_inicio != null ? itemGeneral?.fecha_inicio : getSqlServerDateTime(),
            // "fecha_fin": itemGeneral?.fecha_fin != null ? itemGeneral?.fecha_fin : getSqlServerDateTime(),
            "cierre_visita": itemGeneral?.cierre_visita,
            "dias_vencido": contratoActual?.dias_vencido,
            "monto_vencido": contratoActual?.monto_adeudo,
            "es_aval": itemGeneral?.es_aval
         }
      }

      await CobranzaService.UpsertCobranzaRutaItem(data)
      .then(res => {
         setLoading(false)
      }).catch(e => {
         setLoading(false)
         props.handleNextSteps(false);
         if (e.response !== undefined)
            toast.error(message("¡Error!", e.response.data.message));
         else
            toast.error(message("¡Error!", e.message));
      })
   }

   return (
      <>
         <Toaster
            position="top-right"
            toastOptions={{
               success: {
                  style: {
                     background: '#47a066',
                     color: '#FFFF',
                     borderLeft: '10px solid #2f7246'
                  },
               },
               error: {
                  style: {
                     background: '#d53f3f',
                     color: '#FFFF',
                     borderLeft: '10px solid #ac241a'
                  },
               },
            }}
         />
         {
            loading &&
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
         }
         {
            loadingSaving &&
            <Modal isOpen={loadingSaving} color='transparent'><Spinner message={""} /></Modal>
         }
         {show && (
            <div className="row wrapper-vehiculo">
               <div className="col col-12">
                  <div className="row mx-md-4 my-4">
                     <div className="col-12 px-md-3">
                        <div className="p-4 white-wrapper mb-2">
                           <div
                              className="row mt-2 px-4"
                              data-bs-toggle="collapse"
                              href="#collapseResguardoUnidad"
                              role="button"
                              aria-expanded="false"
                              aria-controls="collapseResguardoUnidad"
                              onClick={() => setCollpsableGrua(!collpsableGrua)}
                           >
                              <div className="col-11">
                                 <h6>
                                    <span className={`semaforo semaforo-`} ></span>{" "}
                                    <strong>Resguardo de unidad</strong>
                                 </h6>
                              </div>
                              <div className="col-1 col-md-1 collapsable-icon" style={{ textAlign: "right" }} >
                                 <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                              </div>
                           </div>
                           <div className="collapse" id="collapseResguardoUnidad">
                              <div className="separator"></div>
                              <Formik
                                 innerRef={refFormik}
                                 enableReinitialize={true}
                                 initialValues={datosPromesaPago}
                                 onSubmit={(values) => {
                                    handleGuardar(values, false, false, false, "");
                                 }}
                              >
                                 {({ isValid, values, setFieldValue }) => (
                                    <Form>
                                       <div className="row mt-2 px-4">
                                          <div className="row justify-content-between">
                                             <div className="col-sm-12 col-xl-6">
                                                <p className="text-base"><strong>Generar documento</strong>
                                                </p>
                                                <div className="row">
                                                   <div className="col-6">
                                                      Documento de inspección de unidad
                                                   </div>
                                                   <div className="col-6">
                                                      <button className='btn btn-gray' type="button" onClick={handleDocumentoInspeccion}>
                                                         <i className="icon-blue bx bx-download"></i>
                                                      </button>
                                                   </div>
                                                </div>
                                             </div>
                                             <div className="row mt-2 justify-content-end">
                                                <div className="row col-lg-3 col-sm-12 justify-content-end">
                                                <div className="col-sm-6 col-lg-6">
                                                   <BrowserView>
                                                      {/* <div className="col-auto"> */}
                                                      <fieldset
                                                         onClick={() => {
                                                            if (registradoPreviamente) {
                                                            handleActualizarItem(true)
                                                            }
                                                            else {
                                                            handleGuardar(values, true)
                                                            }
                                                         }}
                                                         className='btn btn-gray'>
                                                         <i className="icon-light fa fa-file "></i> { registradoPreviamente ? "Ver documentos" : "Subir documento" } 
                                                      </fieldset>
                                                      {/* </div> */}
                                                      </BrowserView>
                                                   </div>
                                                   {
                                                      !registradoPreviamente 
                                                      ?  <div className="col-sm-6 col-lg-6">
                                                            <button
                                                               className="btn col-12"
                                                               type="submit"
                                                               // disabled={!(isValid) || registradoPreviamente}
                                                               style={{
                                                                  padding: "10px",
                                                                  lineHeight: "15px",
                                                               }}>
                                                               Guardar
                                                            </button>
                                                         </div>
                                                      :  <div className="col-sm-6 col-lg-6">
                                                         {
                                                            registradoPreviamente && (
                                                               <fieldset className='btn btn-light secondary d-flex align-items-center mx-2'>
                                                               <i className="icon-dark bx bx-hide mx-1" ></i>Modo lectura</fieldset>
                                                            )
                                                         }
                                                         </div>
                                                   }
                                                </div>
                                             </div>
                                          </div>
                                          <div className="row mt-2 mb-2 justify-content-between">
                                             <div className="col-3">
                                                <p><span style={{ fontSize: "14px", fontWeight: "bold" }}><strong>Detalle del resguardo</strong></span></p>
                                             </div>
                                          </div>
                                          <div className='my-2 mt-4'>
                                             <div className="mb-3 row">
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                   <SelectField id="id_m_resguardo" label="Motivo resguardo" name="id_m_resguardo" items={dataMotivoResguardo}
                                                      disabled={registradoPreviamente}
                                                      onChange={(event) => {
                                                         setFieldValue("id_m_resguardo", event.value)
                                                         if (event.value === "") {
                                                            setFieldValue("motivo_resguardo", "");
                                                            // setMotivoResguardo(0);
                                                         }
                                                         else {
                                                            // setMotivoResguardo(event.value);
                                                            setFieldValue("motivo_resguardo", event.label);
                                                         }
                                                      }} />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                   <SelectField id="id_f_arribo" label="Forma de arribo" name="id_f_arribo" items={dataFormaArribo}
                                                      disabled={registradoPreviamente}
                                                      onChange={(event) => {
                                                         setFieldValue("id_f_arribo", event.value)
                                                         if (event.value === "") {
                                                            setFieldValue("forma_arribo", "");
                                                            // setFormaArribo(0);
                                                         }
                                                         else {
                                                            // setFormaArribo(event.value);
                                                            setFieldValue("forma_arribo", event.label);
                                                         }
                                                      }} />
                                                </div>
                                             </div>
                                          </div>
                                          <div className="row mt-2 mb-2">
                                             <div className="col-auto">
                                                <strong>Costos adicionales</strong>
                                             </div>
                                             <div className="col-auto">
                                                <button
                                                   className="btn"
                                                   type="button"
                                                   onClick={() => {
                                                      setIsOpenModal(true);
                                                      setValoresActuales(values)
                                                      setSelectedItem({ valuacionRegistroGastosId: "" })
                                                   }}
                                                   disabled={registradoPreviamente}
                                                   style={{
                                                      padding: "10px",
                                                      lineHeight: "25px",
                                                   }} >
                                                   Registrar costo
                                                </button>
                                             </div>
                                          </div>

                                          <RowPages setpageRows={setpageRows} />
                                          <div className="row mt-2 px-4 mb-2">
                                             {
                                                !registradoPreviamente
                                                   ? <div className="col-12">
                                                      <DataTable
                                                         id="tablaPagos"
                                                         name="pagos"
                                                         column={columnsPagos}
                                                         data={datosPromesaPago.costosAdicionalesTbl}
                                                         // editable={!initialValue.concluido}
                                                         editable={true}
                                                         handleEdit={handleEdit}
                                                         deleteable={true}
                                                         handleDelete={(item) => { setItemSelected(item); setOpenModalDelete(true); }}
                                                         // handleDocuments={handleViewdocs}
                                                         handleAction={(costo, idx) => { 
                                                            if(costo.subido){
                                                               handleViewdocs(costo)
                                                            }
                                                            else{
                                                               handleGuardar(values, true, false, true, costo.id) 
                                                            }
                                                         }}
                                                         paginate={true}
                                                         pageCount={pageCount}
                                                         handlePageClick={handlePageClick}
                                                         currentPage={currenPage - 1}
                                                      />
                                                   </div>
                                                   : <div className="col-12">
                                                      <DataTable
                                                         id="tablaPagos"
                                                         name="pagos"
                                                         column={columnsPagos}
                                                         data={datosPromesaPago.costosAdicionalesTbl}
                                                         paginate={true}
                                                         pageCount={pageCount}
                                                         handleAction={(costo, idx) => { 
                                                            if(costo.subido){
                                                               handleViewdocs(costo)
                                                            }
                                                            else{
                                                               handleUploadFile(origenIdR, costo.id) 
                                                            }
                                                         }}
                                                         handlePageClick={handlePageClick}
                                                         currentPage={currenPage - 1}
                                                      />
                                                   </div>
                                             }

                                          </div>
                                          <div className="row mt-2 px-4 mb-2 justify-content-end">
                                             <div className="col-auto">
                                                <small><strong>Total: ${FormatNumberMoney2(totalPagos)}</strong></small>
                                             </div>
                                          </div>

                                          {/* aqui registrar costos y tabla */}
                                          <p className="text-base"><strong>Detalle de la entrega</strong></p>
                                          <div className='my-2 mt-4'>
                                             <div className="mb-3 row">
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                   <TextField id="fechaResguardo" label="Fecha de resguardo" name="fechaResguardo" type="date" holder="Escribe" disabled={registradoPreviamente}
                                                      onChange={(event) => { setFieldValue("fechaResguardo", event.target.value); }} />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                   <TextField id="horaResguardo" label="Hora de resguardo" name="horaResguardo" type="time" holder="Escribe" disabled={registradoPreviamente}
                                                      onChange={(event) => { setFieldValue("horaResguardo", event.target.value); }} />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                   <TextField label="Quien entrega" name="quien_entrega" id="quien_entrega" type="text" holder="Escribe" disabled={registradoPreviamente}
                                                      onChange={(event) => { setFieldValue("quien_entrega", event.target.value); }} />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                   <SelectField id="id_u_entrega" label="Ubicación" name="id_u_entrega" items={dataUbicacion}
                                                      disabled={registradoPreviamente}
                                                      onChange={(event) => {
                                                         setFieldValue("id_u_entrega", event.value)
                                                         setFieldValue("ubicacion_entrega", event.value === "" ? "" : event.label);
                                                         setFieldValue("usuario_recibe", event.value === "" ? "" : event.responsable)
                                                      }} />
                                                </div>
                                             </div>
                                             <div className="mb-3 row">
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                   <TextField label="¿Quién Recibe?" name="usuario_recibe" type="text" holder="Escribe" disabled={registradoPreviamente}
                                                      onChange={(event) => { setFieldValue("usuario_recibe", event.target.value); }} />
                                                </div>
                                             </div>
                                          </div>
                                          <div className='my-2 mt-4'>
                                             <div className="mb-3 row">
                                                <div className="col-sm-12 col-md-6 col-lg-6">
                                                   <TextAreaField label="Comentarios" className="form-control col-12" rows="4" name="comentarios" type="text" holder="" disabled={registradoPreviamente} />
                                                </div>
                                             </div>
                                          </div>
                                          <div className='my-2 mt-4'>
                                             <div className="mb-3 row">
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                   <Field className="form-check-input" type="checkbox" value={values.registro_concluido} checked={values.registro_concluido} disabled={registradoPreviamente} name="registro_concluido" onClick={(event) => {
                                                      setFieldValue("registro_concluido", event.target.checked);
                                                   }} />
                                                   <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Registro concluido</label>
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-9 text-end">
                                                   {
                                                      fechaInicio !== "" &&
                                                      <label>
                                                         Inicio de proceso: {formatearFecha(fechaInicio)}
                                                      </label>
                                                   }
                                                   {
                                                      registradoPreviamente &&
                                                      <label style={{ whiteSpace: 'pre' }}>
                                                         {" | "}Fin de proceso: {formatearFecha(fechaFin)}
                                                      </label>
                                                   }
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </Form>
                                 )}
                              </Formik >
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         )}
         {
            isOpenModal &&
            <ModalCostosResguardoUnidad
               isOpen={isOpenModal}
               setIsOpen={setIsOpenModal}
               item={selectedItem}
               addUpdateCostosAdicionalesTbl={addUpdateCostosAdicionalesTbl}
               toast={toast}
            />
         }
         {
            openModalDelete &&
            <ModalConfirmarEliminar
               isOpen={openModalDelete}
               setIsOpen={setOpenModalDelete}
               item={itemSelected}
               toast={toast}
               deleteCostosAdicionalesTbl={deleteCostosAdicionalesTbl}
            />
         }
         { isOpenDocs &&
            <VisorFile
               isOpen={isOpenDocs}
               setIsOIpen={setIsOpenDocs}
               fileName={fileName}
               doc={SelectedDocs}
            />
         }
      </>
   )
});

export default ResguardoUnidadCards