import toast from "react-hot-toast";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ValuacionRegistroCostoService from "../../Services/Inventario/Valuacion/ValuacionRegistroCostoService";
import { message, FormatNumberMoney2, NumFolio, FormatFecha } from "../../Utils/functions";
import ValuacionesServices from "../../Services/Inventario/ValuacionesService";
import CobranzaService from '../../Services/Cobranza/Cobranza'
import Siniestros from "../../Services/Siniestro/Siniestro";
import { useDispatch, useSelector } from 'react-redux';

const useInspeccionRapida = () => {
    const { id } = useParams();
    const { refFormik } = useRef();
    const navigate = useNavigate();
    const { state } = useLocation();

    const initial = {
        valuacionId: id,
        valuacionInspeccionUnidadId: '',
        documentoDescargado: false,
        vehiculoApto: null,
        observacionesUnidad: '',
        informacionAdicional: '',
        concluido: false,
        fechaInicio: '',
        fechaFin: '',
    };

    const [initialValue, setInitialValue] = useState(initial);
    const optionsAptoInventario = [
        { value: "1", label: 'Sí' },
        { value: "0", label: 'No' },
    ];
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });
    const [loading, setLoading] = useState(false);
    const [activebtncontinuar, setactivebtncontinuar] = useState(false);
    const [stepe, setStepe] = useState([])
    const [stepSelected, setstepSelected] = useState();
    const [modalConfirm, setModalConfirm] = useState(false);
    const [valuacionInspeccionUnidadId, setValuacionInspeccionUnidadId] = useState('');
    const [costos, setCostos] = useState([]);
    const [totalCosto, setTotalCosto] = useState(0);
    const [contratoActual, setContratoActual] = useState();

    const columns = [
        { field: 'idx', headerName: '#', width: 180 },
        { field: 'fechaHora', headerName: 'Fecha y hora', width: 240 },
        { field: 'ejecutivo', headerName: 'Ejecutivo', width: 180 },
        { field: 'concepto', headerName: 'Concepto', width: 180 },
        { field: 'subtotal', headerName: 'Subtotal', width: 180 },
        { field: 'iva', headerName: 'IVA', width: 180 },
        { field: 'total', headerName: 'Total', width: 180 },
        { field: 'acciones', headerName: 'Acciones', width: 200 }
    ];

    useEffect(() => {
        handleGetAllStep();
        dataCob()
    }, [activebtncontinuar]);

    useEffect(() => {
        getDataValuacion();
    }, []);

    const dataCob = async () => {
        await Siniestros.siniestroByVinGet(state.vin)
            .then(res => {
                setContratoActual(res.data)
            })
    }

    const getDataValuacion = async () => {
        setLoading(true);
        await ValuacionesServices.getDataInspeccion(id)
            .then(resp => {
                resp.data.vehiculoApto = resp.data.vehiculoApto ? "1" : "0";
                resp.data.observacionesUnidad = resp.data.observacionesUnidad === null ? '' : resp.data.observacionesUnidad;
                resp.data.informacionAdicional = resp.data.informacionAdicional === null ? '' : resp.data.informacionAdicional;

                if (resp.data.concluido) {
                    setactivebtncontinuar(true);
                }

                setValuacionInspeccionUnidadId(resp.data.valuacionInspeccionUnidadId);
                getCostosInspeccion(resp.data.valuacionInspeccionUnidadId);
                setInitialValue(resp.data);
            })
            .catch(err => {

            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleGetAllStep = async () => {
        setLoading(true)
        await ValuacionRegistroCostoService.getAllStepe(id, state.procesoId)
            .then((res) => {
                let step = res.data.data;
                step.map(x => {
                    if (x.pasoSubStatus === 2) {
                        setstepSelected(x);
                        return;
                    }
                })
                setStepe(step);
                setLoading(false)
            })
            .catch((error) => {
                setLoading(false)
                console.log(error);
            })
    }

    const handleClickTerminar = async () => {
        setModalConfirm(true);
    }

    const handleClickTerminarProceso = async () => {
        setModalConfirm(false)
        setLoading(true)
        let data = {
            id: id,
            paso: stepSelected.paso,
            porcentaje: stepSelected.porcentaje,
            pasoSubStatus: 3,
            procesoId: state.procesoId
        }
        await ValuacionRegistroCostoService.updStep(data)
            .then((res) => {
                if (res.data.data.ban === 1) {
                    toast.success(message("¡Correcto!", res.data.data.mensaje));
                    //navegar al siguiente step
                    if (stepSelected.paso !== 4)
                        navigate(`/inventario/valuacion/${id}/${stepSelected.paso + 1}`, {
                            state: state
                        })
                } else {
                    toast.error(message("¡Operación fallida!", res.data.data.mensaje));
                }
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false)
                console.log(error);
            })
    }

    const handleChangeStatusBtn = (item) => {
        if (item.concluido) {
            setactivebtncontinuar(true)
        }
    }

    const getDateFormat = (fecha, msj) => {
        if (fecha === undefined || fecha === '') {
            return false;
        }
        let d = new Date(fecha + "Z");
        let format = "";
        if (fecha !== undefined) {
            format = ("0" + d.getDate()).slice(-2) + "-" + ("0" + d.getMonth() + 1).slice(-2) + "-" + d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2) + ":" + ("0" + d.getSeconds()).slice(-2);
            return msj + format;
        }
    }

    const [page, setPage] = useState(1);

    const getCostosInspeccion = async (id) => {
        await ValuacionesServices.getCostos({ id: id, page: page, rows: 10 })
            .then(resp => {
                //let costosTmp = [];
                // resp.data.data.forEach((item, idx) => {
                //     costosTmp.push({
                //         idx: (idx + 1),
                //         subTotal: formatearMonto(item?.subTotal), 
                //         total: formatearMonto(item?.total), 
                //         iva: item?.iva + '%',
                //         ...item
                //     })
                // });
                const total = resp.data.data.reduce((previous, current) => {
                    return previous + current.total; // sumar el valor de una propiedad
                }, 0);
                setTotalCosto(total);
                const updatedData = resp.data.data.map((item, index) => ({
                    ...item,
                    subtotal: `$${FormatNumberMoney2(item?.subtotal)}`,
                    total: `$${FormatNumberMoney2(item?.total)}`,
                    iva: item?.iva + '%',
                    idx: index + 1
                }));

                setCostos(updatedData);
            })
            .catch(err => {
                console.error(err);
            })
    }


    const downloadPDF = async () => {
        setLoading(true)
        let nombreArchivo = "InspeccionRapidaCobranza"
        let currentDay = new Date()
        let data = {
            "verb": "string",
            "id": "InspeccionRapidaCobranza",
            "field": "string",
            "value": "string",
            "level": -1,
            "flag": true,
            "anyObject": {
                "contrato_id": contratoActual?.infoAscendes.contrato,
                "vin": state?.vin,
                "datamovil": "",
                "cliente": `${contratoActual?.infoAscendes.cliente}`,
                "marca": contratoActual?.vehiculo?.nombreMarca ?? "",
                "modelo": contratoActual?.vehiculo?.nombreModelo ?? "",
                "anio": contratoActual?.vehiculo?.nombreYear ?? "",
                "version": contratoActual?.vehiculo?.nombreVersion ?? "",
                "color": contratoActual?.vehiculo?.color ?? "",
                "fecha": FormatFecha(currentDay),
                "folio": NumFolio(),
                "ubicacion": ``
            },
            "kvp": {}
        }

        await CobranzaService.GetAnyPdf(data)
            .then(res => {
                var FileSaver = require('file-saver');
                FileSaver.saveAs(res.data, `${nombreArchivo}_${new Date(Date.now()).toLocaleString().split(',')[0]}.PDF`);

                let values = {
                    valuacionId: id,
                    documentoDescargado: true,
                }
        
                submitForm(values);
                
            })
            .catch(e => {
                setLoading(false);
                if (e.response !== undefined)
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            })
        setLoading(false)

    }

    const submitForm = async (values = {}) => {
        setLoading(true);

        if (values.vehiculoApto) {
            values.vehiculoApto = values.vehiculoApto === "1" ? true : false;
        }

        if (values.concluido) {
            values.procesoId = state.procesoId;
            values.paso = 2;
            values.porcentaje = 100;
            values.status = 2;
        }

        await ValuacionesServices.putDataInspeccion(values)
            .then(resp => {

            })
            .catch(err => {

            })
            .finally(() => {
                setLoading(false);
                getDataValuacion();
            });
    }

    const handleEdit = () => { };
    const handleDelete = () => { };
    const handleViewdocs = () => { };
    const [pageCount, setPageCount] = useState(1);
    const handlePageClick = () => { };


    return {
        initialValue,
        setInitialValue,
        refFormik,
        optionsAptoInventario,
        getDateFormat,
        modalMessage,
        setModalMessage,
        loading,
        setLoading,
        getCostosInspeccion,
        downloadPDF,
        submitForm,
        stepe,
        handleClickTerminar,
        activebtncontinuar,
        handleChangeStatusBtn,
        modalConfirm,
        setModalConfirm,
        handleClickTerminarProceso,
        state,
        columns,
        valuacionInspeccionUnidadId,
        costos,
        handleEdit,
        handleDelete,
        handleViewdocs,
        pageCount,
        handlePageClick,
        page,
        totalCosto
    }
}

export default useInspeccionRapida;