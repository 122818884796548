import jwtDecode from "jwt-decode";
import AuthLogin from "./AuthLogin";
import functions from "../Services/DataDocs/dataDocsServices";
import { number } from "yup";

export default function handlePhonNumberFormatter(value) {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNuberLentgh = phoneNumber.length;
    if (phoneNuberLentgh < 3) return phoneNumber;
    if (phoneNuberLentgh < 7) {
        return `(${phoneNumber.slice(0, 2)})-${phoneNumber.slice(2)}`;
    }
    let res = `(${phoneNumber.slice(0, 2)})-${phoneNumber.slice(2, 6)}-${phoneNumber.slice(6, 10)}`
    return res;
}

// export const UrlDatadocs = () => `${process.env.REACT_APP_API_DATADOCS}`;

export const message = (title, message) => <span style={{ fontSize: '13px', margin: '0px' }}><strong>{title}</strong> <br /> <p style={{ fontSize: '12px', margin: '0px' }}>{message}</p></span>

export const EtapaBandera = (fichero, etapa, origen) => {
    return functions.getBanderaEtapa(fichero, etapa, origen).then(res => {
        let dataRes = res.data.data;
        let acabado = true;

        dataRes.forEach(element => {
            if (element.bandera == 0) {
                acabado = false;
            }
        });
        return acabado;
    });
};


export const getDateNow = (dat) => {
    let date = new Date(dat);
    let mes = date.getMonth() + 1;
    let day = date.getDate();
    return `${date.getFullYear()}-${mes >= 10 ? mes : '0' + mes}-${day >= 10 ? day : '0' + day}`
}

export const pageRows = () => {
    let dataRows = [
        { value: 10, label: 10 },
        { value: 20, label: 20 },
        { value: 30, label: 30 },
        { value: 50, label: 50 },
        { value: 100, label: 100 },
    ]
    return dataRows;
}

export const FormatNumber = (number) => {

    if (!number) return number;
    const newValue = number.replaceAll(',', '');
    let newFormat = new Intl.NumberFormat("es-MX", {
        //style:'currency',
        //currency: 'MXN',
    }).format(newValue);

    return newFormat;

}

export const FormatNumberMoney = (number) => {
    if (!number) return number;
    if (typeof number === "number") number = number.toFixed(2);
    return number.toString().replace(/\D/g, "")
        .replace(/([0-9])([0-9]{2})$/, '$1.$2')
        .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ",");

}

export const FormatMoneyToNumber = (money) => {
    return money.replace(/,/g, "")
}

export const FormatNumberMoney2 = (value) => {
    if (value == "NaN") {
        return "0.00";
    }
    return parseFloat(value).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}


export const FormatNumberToDecimal = (number) => {
    if (!number) return number;
    // if(number.length >=3) number = number.split(".")[0]
    let res = number.replace(/\,/g, "");
    return parseFloat(res);
}
export const handleUnchekedColumns = () => {
    let table = document.querySelectorAll('.tbl-custome >tr');

    for (let index = 0; index < table.length; index++) {
        const element = table[index];
        element.firstChild.firstChild.checked = false;
    }
}
export const handleRadioCheckItem = (i) => {
    let table = document.querySelectorAll('.tbl-custome >tr');

    for (let index = 0; index < table.length; index++) {
        const element = table[index];
        if (index !== i)
            element.firstChild.firstChild.checked = false;
    }
}
export const handleIsActiveCheck = (isActive) => {
    let table = document.querySelectorAll('.tbl-custome >tr');
    for (let index = 0; index < table.length; index++) {
        const element = table[index];
        element.firstChild.firstChild.checked = false;
        element.firstChild.firstChild.disabled = !isActive;
    }
}
export const AuthExpireSesion = () => {
    const auth = new AuthLogin();
    const token = auth.token();
    const decodeToken = token && jwtDecode(token);
    if (decodeToken === null) return true
    const expiresAt = new Date(decodeToken.time);
    const isExpired = new Date() > expiresAt;
    return isExpired;
}

export const AddDays_CurrentDate = (day) => {
    let date = new Date();
    let newDate = date.setDate(date.getDate() + parseInt(day));
    return new Date(newDate).toISOString().split("T")[0];
}

export const Normalize = (string) => {
    let str = string?.toLowerCase().replace(/\s+/g, '');
    return str?.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export const NumFolio = () => {
    let date = new Date();
    let mes = date.getMonth() + 1;
    let day = date.getDate();
    return `${date.getFullYear()}${mes >= 10 ? mes : '0' + mes}${day >= 10 ? day : '0' + day}${date.getHours()}${date.getMinutes()}${date.getSeconds()}${date.getMilliseconds()}`
}


export const convertToBase64 = async (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
            resolve(reader.result.split(",")[1])
        }
        reader.onerror = reject
    })
}

export const getDateFormat = (fecha, msj) => {
    let d = new Date(fecha + "Z");
    let format = "";

    let hour = "";

    if (d.getHours() > 12) {
        hour = d.getHours() - 12;
    } else {
        hour = d.getHours();
    }

    if (fecha !== undefined && fecha !== "" && fecha !== null) {
        format = ("0" + d.getDate()).slice(-2) + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + d.getFullYear() + " " + ("0" + hour).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2) + ":" + ("0" + d.getSeconds()).slice(-2) + (d.getHours() >= 12 ? " PM" : " AM");
        return msj + format;
    }
}
export const getDateFormatNoUTC = (fecha) => {
    let d = new Date(fecha);
    let format = "";
    let hour = "";
    if(d.getHours() > 12){
        hour = d.getHours() - 12;
    }else{
        hour = d.getHours();
    }

    if (fecha !== undefined && fecha !== "" && fecha !== null) {
        format = ("0" + d.getDate()).slice(-2) + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + d.getFullYear() + " " + ("0" + hour).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2) + ":" + ("0" + d.getSeconds()).slice(-2) + (d.getHours() >= 12 ? " PM" : " AM");
        return format;
    }

}

export const getFormatDate = (fecha) => {
    let format = "";

    if (fecha !== undefined && fecha !== "" && fecha !== null) {
        format = ("0" + fecha.getDate()).slice(-2) + "-" + ("0" + (fecha.getMonth() + 1)).slice(-2) + "-" + fecha.getFullYear() + " " + ("0" + fecha.getHours()).slice(-2) + ":" + ("0" + fecha.getMinutes()).slice(-2) + ":" + ("0" + fecha.getSeconds()).slice(-2) + (fecha.getHours() >= 12 ? " PM" : " AM");
        return format;
    }
}

export const getOnlyDateFormat = (fechaStr) => {
    const date = new Date(fechaStr);

    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Los meses están indexados desde 0
    const year = date.getUTCFullYear();

    return `${day}-${month}-${year}`;
}

export const getHourFormat = (hour) => {
    let hourExploded = hour.split(":");
    let realHour = Number(hourExploded[0]);
    let meridiam = "AM";
    let finalHour = "";

    if (Number(hourExploded[0]) > 12) {
        realHour = Number(hourExploded[0]) - 12;
        meridiam = "PM";
    }
    realHour = ("0" + realHour).slice(-2);

    if (hourExploded.length > 2) {
        finalHour = `${realHour}:${hourExploded[1]}:${hourExploded[2]} ${meridiam}`;
    } else {
        finalHour = `${realHour}:${hourExploded[1]} ${meridiam}`;
    }
    return finalHour;
}

export const ChangevalueMenu = (isActive) => {
    if (isActive) {
        document.getElementById("main").classList.remove('noVisible')
        document.getElementById("main").classList.add('Visible')
    } else {
        document.getElementById("main").classList.remove('Visible')
        document.getElementById("main").classList.add('noVisible')

    }
}

export const isNumber = (value) => {
    return typeof value === 'number';
}


export const consecutive = (number) => {
    const numberStr = String(number).padStart(3, '0');
    return numberStr
}

export const FormatFecha = (fecha) => {
    const newDate = new Date(fecha)

    const dia = String(newDate.getDate()).padStart(2, '0')
    const mes = String(newDate.getMonth() + 1).padStart(2, '0')
    const anio = newDate.getFullYear()

    const fechaFormateada = `${dia}-${mes}-${anio}`

    return fechaFormateada
}

export const FormatFechaSplit = (fecha) => {
    const fecha_formateada = fecha.split('-')
    const newDate = `${fecha_formateada[2]}-${fecha_formateada[1]}-${fecha_formateada[0]}`

    return newDate
}

export const FormatFechaHora = (fecha) => {
    const newDate = new Date(fecha)

    const dia = String(newDate.getDate()).padStart(2, '0')
    const mes = String(newDate.getMonth() + 1).padStart(2, '0')
    const anio = newDate.getFullYear()
    const horas = String(newDate.getHours()).padStart(2, '0')
    const minutos = String(newDate.getMinutes()).padStart(2, '0')
    const segundos = String(newDate.getSeconds()).padStart(2, '0')

    const fechaFormateada = `${dia}-${mes}-${anio} ${horas}:${minutos}:${segundos}`
    return fechaFormateada
}

export const FormatFolioNumber = (number, sizeNum) => {
    let format = "";
    let cadena = number.toString();
    for (let index = 0; index < (sizeNum - cadena.length); index++) {
        format = format + "0";
    }

    format = format + cadena;
    return format;
}

export const DateFormatDateStr = (date) => {
    const fecha = new Date(date);
    // Opciones de formato
    const opciones = { day: 'numeric', month: 'long', year: 'numeric' };
    // Formatear la fecha
    const fechaFormateada = fecha.toLocaleDateString('es-ES', opciones);
    return fechaFormateada;
}
export const FechaParaSQL = (date) => {
    let dateMDY = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}`;
    return dateMDY;
}

export const FechaYHoraFront = (date) => {
    let dateMDY = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
    return dateMDY;
}

export const formatearMonto = (monto) => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'MXN',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(monto);
};

export const formatearMontoMx = (monto) => {
    return new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'MXN',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(monto);
};

export const handleRangoDeFechas = () => {
    var meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    let rangoFechas = ''
    const currentDate = new Date();
    var ultimoDia = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    if (currentDate.getDate() < 16) {
        rangoFechas = `1 ${meses[currentDate.getMonth()]} - 15 ${meses[currentDate.getMonth()]}`;
    }
    else {
        rangoFechas = `16 ${meses[currentDate.getMonth()]} - ${ultimoDia.getDate()} ${meses[currentDate.getMonth()]}`;
    }
    return rangoFechas
}

export const IsNullOrEmpty = (value) => {
    return (value === null || value === undefined || value === "") ? true : false;
}

export const handleParseDate = (fecha) => {
    const [day, month, year] = fecha.split("-");
    const dateObj = new Date(year, month - 1, day);

    // Devolver la fecha en formato yyyy-MM-dd
    return `${dateObj.getFullYear()}-${(dateObj.getMonth() + 1).toString().padStart(2, '0')}-${dateObj.getDate().toString().padStart(2, '0')}`;
}

export const TextMoneyToNumber = (textNumber) => {
    const number = parseFloat(textNumber.replace(/[^0-9.-]+/g, ""));
    return number
}


export const FormatPhoneNumber = (phoneNumber) => {
    // Convierte el valor a string por si es un número
    const str = phoneNumber.toString();

    if (str.length !== 10) {
        return str
    }
    const formatPhoneNumber = `(${str.slice(0, 2)})-${str.slice(2, 6)}-${str.slice(6)}`;

    return formatPhoneNumber;
}

export const formatearFecha = (fechaISO) => {
    if (!fechaISO) {
        return 'Fecha no válida'; // Mensaje para fechas nulas o indefinidas
    }

    const fecha = new Date(fechaISO);

    if (isNaN(fecha.getTime())) {
        return 'Fecha no válida'; // Mensaje si la fecha no puede ser convertida
    }

    const dia = fecha.getDate().toString().padStart(2, '0');
    const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
    const anio = fecha.getFullYear();
    const horas = (fecha.getHours() % 12 || 12).toString().padStart(2, '0');
    const minutos = fecha.getMinutes().toString().padStart(2, '0');
    const segundos = fecha.getSeconds().toString().padStart(2, '0');
    const ampm = fecha.getHours() >= 12 ? 'PM' : 'AM';

    return `${dia}-${mes}-${anio} ${horas}:${minutos}:${segundos} ${ampm}`;
}

export const getSqlServerDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}
export const Proceso = [
    { value: "Seguro", label: "Seguro" },
    { value: "Gestoría", label: "Gestoría" },
    { value: "Cobranza", label: "Cobranza" },
    { value: "Proceso de Cierre", label: "Proceso de Cierre" },
    { value: "Garantía", label: "Garantía" },
    { value: "AtencionaCliente", label: "Atención a Cliente" },
];


export const isEmpty = (obj) => Object.entries(obj).length === 0;

export const calendarKeys = {
    1: 19,
    2: 20, 
    3: 21,
    4: 22,
};

export const calendarTitle = {
    1: 'Servicios Datamovil - Revisión',
    2: 'Servicios Datamovil - Demo',
    3: 'Servicios Datamovil - Desinstalación',
    4: 'Servicios Datamovil - Reinstalación',
}