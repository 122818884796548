import { Form, Formik, Field } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { TextField } from '../../../../Components/TextField';
import { SelectField } from '../../../../Components/SelectField';
import ValoracionDeUnidad from '../../../../Services/Inventario/Valuacion/ValoracionDeUnidadService'
import Modal from "../../../../Components/Modal/Modal";
import Spinner from "../../../../Components/Loadig";
import { TextAreaField } from "../../../../Components/TextAreaField";

const ValoracionInicial = ({ handleConcluido }) => {
    const { state } = useLocation();
    const { id } = useParams();

    let initial = {
        valuacionValoracionUnidadId: "",
        valuacionid: "",
        valorMercado: null,
        costoReparacion: null,
        adeudos: null,
        utilidad: null,
        valorUnidad: null,
        unidadParaInventario: null,
        usuario: null,
        fechaOperacion: null,
        horaOperacion: null,
        comentariosValorInicial: null,
        autorizarValorFinalUnidad: null,
        autorizarUnidadParaInventario: null,
        autorizarUsuario: null,
        autorizarFechaOperacion: null,
        autorizarHoraOperacion: null,
        autorizarComentarios: null,
        concluido: null
    }

    const [loading, setLoading] = useState(false);
    const [collpsableGrua, setCollpsableGrua] = useState(false);
    const [initialValue, setInitialValue] = useState(initial);
    const [pageRows, setpageRows] = useState(10);
    const [boolSelect, setboolSelect] = useState([{ label: "Sí", value: true }, { label: "No", value: false }]);

    const { refFormik } = useRef();

    useEffect(() => {
        getAll();
    }, []);

    const formatDate = (fecha) => {
        const date = new Date(fecha); // Convierte la fecha al objeto Date
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() es 0 indexado, por eso se suma 1
        const day = String(date.getDate()).padStart(2, '0'); // Asegura que siempre haya 2 dígitos

        return `${year}-${month}-${day}`;
    };

    const getAll = async () => {
        setLoading(true);
        await ValoracionDeUnidad.getValoracionUnidad(id)
            .then((resp) => {
                let valoracionInicial = resp.data;

                const fechaOperacionFormateada = valoracionInicial.fechaOperacion
                    ? formatDate(valoracionInicial.fechaOperacion)
                    : "";

                const autorizarFechaOperacionFormateada = valoracionInicial.autorizarFechaOperacion
                    ? formatDate(valoracionInicial.autorizarFechaOperacion)
                    : "";
                setInitialValue({
                    ...valoracionInicial,
                    fechaOperacion: fechaOperacionFormateada,
                    autorizarFechaOperacion: autorizarFechaOperacionFormateada
                });
                
                handleConcluido({concluido : resp.data.concluido})
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => setLoading(false));
    }

    const validate = Yup.object({

    });

    const submitForm = async (values) => {
        values.procesoId = state.procesoId
        values.paso = 4
        values.porcentaje = 100
        values.status = 2
        setLoading(true);
        await ValoracionDeUnidad.updateValoracionUnidad(values)
            .then((resp) => {
                getAll();
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => setLoading(false));
    };

    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <Formik
                innerRef={refFormik}
                enableReinitialize={true}
                validationSchema={validate}
                initialValues={initialValue}
                onSubmit={(values) => {
                    submitForm(values);
                }}
            >
                {({ isValid, values, setFieldValue, errors }) => (
                    <>
                        <Form>
                            <div className='row mx-md-4 my-4' >
                                <div className="col-12 px-md-3">
                                    <div className="p-4 white-wrapper mb-2">

                                        <div className="row mt-2" data-bs-toggle="collapse" href="#valoreacionUnidad" role="button" aria-expanded="false" aria-controls="valoreacionUnidad" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                            <div className="col-11">
                                                <h6><span></span> <strong>Valoración inicial</strong></h6>
                                            </div>
                                            <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                                <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                            </div>
                                            <div className='col-12'>
                                                <small>{initialValue.siniestroPagoTramitesStatus}</small>
                                            </div>
                                        </div>
                                        <div className="collapse" id="valoreacionUnidad">
                                            {/* <div className="separator"></div> */}
                                            <div className="row mt-2 mb-2">
                                                <div className='col-4'>
                                                    <h6><strong>Detalle de valoracion</strong></h6>
                                                </div>
                                                <div className="col-8">
                                                    <div className="row justify-content-end">
                                                        <div className="col-auto">
                                                            {
                                                                initialValue.concluido ?
                                                                    <fieldset className='btn btn-light secondary d-flex align-items-center mx-2'><i className="icon-dark bx bx-hide mx-1" ></i>Modo lectura</fieldset>
                                                                    : <button
                                                                        className='btn'
                                                                        disabled={initialValue.concluido}
                                                                        type='submit'>
                                                                        Guardar
                                                                    </button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row mt-2 mb-2'>
                                                <div className="col-lg-3 col-md-3 col-sm-12">
                                                    <TextField label="Valor de mercado" name="valorMercado" type="number" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("valorMercado", event.target.value);
                                                    }} />
                                                </div>
                                                <div className='col-lg-3 col-md-3 col-sm-12'>
                                                    <TextField label="Costo de reparacion ($)" name="costoReparacion" type="number" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("costoReparacion", event.target.value);
                                                    }} />
                                                </div>
                                                <div className='col-lg-2 col-md-2 col-sm-12'>
                                                    <TextField label="Adeudo ($)" name="adeudos" type="number" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("adeudos", event.target.value);
                                                    }} />
                                                </div>
                                                <div className='col-lg-2 col-md-2 col-sm-12'>
                                                    <TextField label="Utilidad ($)" name="utilidad" type="number" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("utilidad", event.target.value);
                                                    }} />
                                                </div>
                                                <div className='col-lg-2 col-md-2 col-sm-12'>
                                                    <TextField label="Valor de la unidad ($)" name="valorUnidad" type="number" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("valorUnidad", event.target.value);
                                                    }} />
                                                </div>
                                            </div>

                                            <div className='pt-3'>
                                                <h6><strong>Cambio de ubicación</strong></h6>
                                            </div>
                                            <div className='row mt-2 mb-2'>
                                                <div className='col-lg-3 col-md-6 col-sm-12'>
                                                    <SelectField
                                                        id="unidadParaInventario"
                                                        label="Unidad para inventario"
                                                        name="unidadParaInventario"
                                                        items={boolSelect}
                                                        onChange={(event) => {
                                                            setFieldValue("unidadParaInventario", event.value);
                                                        }}
                                                    />
                                                </div>
                                                <div className='col-lg-3 col-md-6 col-sm-12'>
                                                    <TextField label="Usuario" name="usuario" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("usuario", event.target.value);
                                                    }} />
                                                </div>
                                                <div className='col-lg-3 col-md-6 col-sm-12'>
                                                    <TextField label="Fecha de la operacion" name="fechaOperacion" type="date" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("fechaOperacion", event.target.value);
                                                    }} />
                                                </div>
                                                <div className='col-lg-3 col-md-6 col-sm-12'>
                                                    <TextField label="Hora de la operacion" name="horaOperacion" type="time" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("horaOperacion", event.target.value);
                                                    }} />
                                                </div>
                                            </div>

                                            <div className='row mt-2 mb-2'>
                                                <div className='col-lg-12 col-md-12 col-sm-12'>
            
                                                    <TextAreaField id="Comentarios"
                                                        label="Comentarios"
                                                        disabled={initialValue.concluido}
                                                        className="form-control col-12"
                                                        rows="3"
                                                        name="comentariosValorInicial"
                                                        type="text"
                                                        holder="Escribe"
                                                        onChange={(event) => {
                                                            setFieldValue("comentariosValorInicial", event.target.value);
                                                        }} />

                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </>
                )
                }
            </Formik>


        </>
    )
}

export default ValoracionInicial;