import { Form, Formik, Field } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { TextField } from '../../../../Components/TextField';
import Modal from '../../../../Components/Modal/Modal';
import Spinner from '../../../../Components/Loadig';
import { SelectField } from '../../../../Components/SelectField';
import useAgendarCita from '../../../../Hooks/DatamovilSystems/RevisionPasos/useAgendarCita';
import { calendarKeys, calendarTitle, getDateFormat } from '../../../../Utils/functions';
import { useNavigate, useParams } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast'

const AgendarCitaCard = ({ handleConcluido }) => {
    const { refFormik } = useRef();

    const [collapsible, setCollapsible] = useState(false);

    const validationSchema = Yup.object().shape({
        tipoUbicacion: Yup.string()
            .nullable()
            .when("arriboCita", {
                is: true,
                then: Yup.string()
                    .nullable()
                    .required("El campo es requerido."),
            }),
        ubicacion: Yup.string()
            .nullable()
            .when("arriboCita", {
                is: true,
                then: Yup.string()
                    .nullable()
                    .required("El campo es requerido."),
            }),
        estadoId: Yup.string()
            .nullable()
            .when(["tipoUbicacion", "arriboCita"], {
                is: (tipoUbicacion, arriboCita) => tipoUbicacion === "2" && arriboCita,
                then: Yup.string()
                    .nullable()
                    .required("Estado es requerido."),
            }),
        municipioId: Yup.string()
            .nullable()
            .when(["tipoUbicacion", "arriboCita"], {
                is: (tipoUbicacion, arriboCita) => tipoUbicacion === "2" && arriboCita,
                then: Yup.string()
                    .nullable()
                    .required("Municipio es requerido."),
            }),
        coloniaId: Yup.string()
            .nullable()
            .when(["tipoUbicacion", "arriboCita"], {
                is: (tipoUbicacion, arriboCita) => tipoUbicacion === "2" && arriboCita,
                then: Yup.string()
                    .nullable()
                    .required("Colonia es requerido."),
            }),
        calle: Yup.string()
            .nullable()
            .when(["tipoUbicacion", "arriboCita"], {
                is: (tipoUbicacion, arriboCita) => tipoUbicacion === "2" && arriboCita,
                then: Yup.string()
                    .nullable()
                    .required("Calle es requerido."),
            }),
        numeroExt: Yup.string()
            .nullable()
            .when(["tipoUbicacion", "arriboCita"], {
                is: (tipoUbicacion, arriboCita) => tipoUbicacion === "2" && arriboCita,
                then: Yup.string()
                    .nullable()
                    .required("Número es requerido."),
            }),
        codigoPostal: Yup.string()
            .nullable()
            .when(["tipoUbicacion", "arriboCita"], {
                is: (tipoUbicacion, arriboCita) => tipoUbicacion === "2" && arriboCita,
                then: Yup.string()
                    .nullable()
                    .required("Número es requerido."),
            }),
        fechaCita: Yup.string()
            .nullable()
            .when("arriboCita", {
                is: true,
                then: Yup.string()
                    .required("Fecha de cita es requerida."),
            }),
        horaCita: Yup.string()
            .nullable()
            .when("arriboCita", {
                is: true,
                then: Yup.string()
                    .nullable()
                    .required("Hora de cita es requerida."),
            })

        // ubicacion: Yup.string().required("Ubicación es requerida"),
        // estadoId: Yup.string().required("Estado es requerido"),
        // municipioId: Yup.string().required("Municipio es requerido"),
        // coloniaId: Yup.string().required("Colonia es requerida"),
        // calle: Yup.string().required("Calle es requerida"),
        // numero: Yup.string().required("Número es requerido"),
        // fechaCita: Yup.date().required("Fecha de cita es requerida"),
        // horaCita: Yup.string().required("Hora de cita es requerida")
    });
    const paso = 1;
    const {
        initialValue,
        loading,
        setLoading,
        submitFormAgendarCita,
        getEstados,
        getMunicipios,
        getColonias,
        estados,
        municipios,
        colonias,
        tipoUbicaciones,
        ubicaciones,
        state
    } = useAgendarCita({ handleConcluido, paso, toast });

    const { tipoServicio } = useParams();
    

    const navigate = useNavigate();
    const handleCalendario = () => {
        navigate(`/datamovil/citas/v2/${calendarKeys[tipoServicio]}`, { state: { ...state, title: calendarTitle[tipoServicio], tipoCalendario: calendarKeys[tipoServicio] } });
    }

    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <div className='row mx-md-4 my-4'>
                <div className="col-12 px-md-3">
                    <div className="p-4 white-wrapper mb-2">
                        <div
                            className="row mt-2"
                            data-bs-toggle="collapse"
                            href="#collapseAgendarCita"
                            role="button"
                            aria-expanded="false"
                            aria-controls="collapseAgendarCita"
                            onClick={() => setCollapsible(!collapsible)}
                        >
                            <div className="col-11">
                                <h6><span className={`semaforo semaforo-${initialValue?.arriboCita ? "green" : "default"}`}></span> <strong>Agendar Cita</strong></h6>
                            </div>
                            <div className="col-11">
                                <small>{initialValue.arriboCita ? " Concluido" : " En Proceso"}</small>
                            </div>
                            <div className="col-1 text-end">
                                <i className={!collapsible ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                            </div>
                        </div>
                        <div className="collapse" id="collapseAgendarCita">
                            <div className="divider"></div>
                            <Formik
                                innerRef={refFormik}
                                enableReinitialize={true}
                                initialValues={initialValue}
                                validationSchema={validationSchema}
                                onSubmit={(values) => submitFormAgendarCita(values)}
                            >
                                {({ isValid, values, setFieldValue, errors }) => (
                                    <Form id="agendarCitaForm">
                                        <div className="row mt-2 mb-2">
                                            <div className='col-4'>
                                                <h6><strong>Detalle de ubicación</strong></h6>
                                            </div>
                                            <div className="col-8">
                                                <div className="row justify-content-end">
                                                    <div className="col-auto">
                                                        {
                                                            initialValue.arriboCita ?
                                                                <fieldset className='btn btn-light secondary d-flex align-items-center mx-2'><i className="icon-dark bx bx-hide mx-1" ></i>Modo lectura</fieldset>
                                                                : <button
                                                                    className='btn'
                                                                    disabled={initialValue.arriboCita}
                                                                    type='submit'>
                                                                    Guardar
                                                                </button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-md-3">
                                                <SelectField
                                                    disabled={initialValue?.arriboCita}
                                                    id="tipoUbicacion"
                                                    label="Tipo de Ubicación"
                                                    name="tipoUbicacion"
                                                    items={tipoUbicaciones}
                                                    onChange={(event) => {
                                                        setFieldValue("tipoUbicacion", event.value);
                                                    }}
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <TextField
                                                    disabled={initialValue?.arriboCita || values.tipoUbicacion === 1}
                                                    label="Ubicación"
                                                    name="ubicacion"
                                                    id="ubicacion"
                                                    type="text"
                                                    holder="Escribir"
                                                    onChange={(event) => {
                                                        setFieldValue("ubicacion", event.target.value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        {
                                            values.tipoUbicacion === 2 &&
                                            <>
                                                <div className="row mb-3">
                                                    <div className="col-md-3 col-sm-12">
                                                        <SelectField
                                                            disabled={initialValue?.arriboCita}
                                                            id="estadoId"
                                                            label="Estado"
                                                            name="estadoId"
                                                            items={estados}
                                                            onChange={(event) => {
                                                                setFieldValue("estadoId", event.value);
                                                                getMunicipios(event.value);
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-md-3 col-sm-12">
                                                        <SelectField
                                                            disabled={initialValue?.arriboCita}
                                                            id="municipioId"
                                                            label="Municipio"
                                                            name="municipioId"
                                                            items={municipios}
                                                            onChange={(event) => {
                                                                setFieldValue("municipioId", event.value);
                                                                getColonias(event.value);
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-md-3 col-sm-12">
                                                        <SelectField
                                                            id="coloniaId"
                                                            label="Colonia"
                                                            name="coloniaId"
                                                            disabled={initialValue?.arriboCita}
                                                            items={colonias}
                                                            onChange={(event) => {
                                                                setFieldValue("coloniaId", event.value);
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-md-3 col-sm-12">
                                                        <TextField
                                                            label="Calle"
                                                            name="calle"
                                                            type="text"
                                                            holder="Escribir"
                                                            disabled={initialValue?.arriboCita}
                                                            onChange={(event) => {
                                                                setFieldValue("calle", event.target.value);
                                                            }} />
                                                    </div>
                                                    <div className="col-md-3 col-sm-12">
                                                        <TextField
                                                            label="Número"
                                                            name="numeroExt"
                                                            type="text"
                                                            holder="Escribir"
                                                            disabled={initialValue?.arriboCita}
                                                            onChange={(event) => {
                                                                setFieldValue("numeroExt", event.target.value);
                                                            }} />
                                                    </div>
                                                    <div className="col-md-3 col-sm-12">
                                                        <TextField
                                                            label="Codigo postal"
                                                            name="codigoPostal"
                                                            type="text"
                                                            holder="Escribir"
                                                            disabled={initialValue?.arriboCita}
                                                            onChange={(event) => {
                                                                setFieldValue("codigoPostal", event.target.value);
                                                            }} />
                                                    </div>
                                                </div>
                                            </>
                                        }

                                        <div className="row mb-3">
                                            <h6><strong>Fecha para agendar cita</strong></h6>
                                            {
                                                values.tipoUbicacion === 2 &&
                                                <>
                                                    <div className="col-md-3">
                                                        <TextField disabled={initialValue?.arriboCita} label="Fecha de cita" name="fechaCita" type="date"
                                                            onChange={(event) => {
                                                                setFieldValue("fechaCita", event.target.value);
                                                            }} />
                                                    </div>
                                                    <div className="col-md-3">
                                                        <TextField
                                                            disabled={initialValue?.arriboCita}
                                                            label="Hora de cita"
                                                            name="horaCita"
                                                            type="time"
                                                            
                                                            onChange={(event) => {
                                                                setFieldValue("horaCita", event.target.value);
                                                            }} />
                                                    </div>
                                                </>
                                            }

                                            {
                                                values.tipoUbicacion === 1 &&
                                                <>
                                                    <div className='col-lg-32 col-md-3 col-sm-12'>
                                                        <TextField label="Fecha y hora de cita"
                                                            name="fechayHora"
                                                            type="text"
                                                            disabled={true}
                                                            holder="No hay cita seleccionada" />
                                                    </div>
                                                    <div className='col-lg-32 col-md-3 col-sm-12'>
                                                        <TextField label="Estatus cita"
                                                            name="estatusPaso1"
                                                            type="text"
                                                            disabled={true}
                                                            holder="Estatus cita" />
                                                    </div>
                                                    <div className="col-2" style={{ alignSelf: "flex-end", paddingBottom: "0.5rem" }}>
                                                        <button className='btn'
                                                            disabled={initialValue.arriboCita}
                                                            onClick={() => {
                                                                handleCalendario();
                                                            }}>Ver calendario</button>
                                                    </div>
                                                    <label className='d-flex align-items-center mb-4' style={{ fontSize: '13px', color: 'red' }}><i className='icon-red ri-error-warning-fill' style={{ fontSize: "15px" }}></i> Es importante concluir el registro para inicar el proceso de autorización.</label>
                                                </>
                                            }

                                            <div className="col-md-12 d-flex align-items-center">
                                                <Field type="checkbox" disabled={initialValue?.arriboCita} name="arriboCita" className="form-check-input me-2" onClick={(event) => {
                                                    setFieldValue("arriboCita", event.target.value);
                                                }} />
                                                <label className="form-check-label">
                                                    Registro concluido
                                                </label>
                                            </div>
                                        </div>
                                        <div className="text-end">
                                            <small className="text-muted">
                                                {initialValue?.fechaInicioAgendarCita
                                                    ? `Inicio de proceso ${initialValue.fechaInicioAgendarCita}`
                                                    : ""}
                                                {" | "}
                                                {initialValue?.fechaFinalAgendarCita
                                                    ? `Fin de proceso ${initialValue.fechaFinalAgendarCita}`
                                                    : ""}
                                            </small>

                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AgendarCitaCard;
