import React, { useEffect } from 'react'
import { Navigate, Outlet } from 'react-router-dom';
import Header from '../Components/Header';
import AuthLogin from '../Utils/AuthLogin';
import { useDispatch } from 'react-redux';
import { chekItem } from '../Redux/Slice/quickActionsSlice';
import { AuthExpireSesion } from '../Utils/functions';
import { Module } from '../Auth/Authorization';
import { ValidatePermissionById } from '../Auth/ValidatePermission';
import NotFound from '../Pages/NotFound';
import DatamovilSidebar from '../Components/DatamovilSidebar';
const LayoutMenuDatamovil = () => {
    const dispatch = useDispatch();
    const auth = new AuthLogin();
    const usuario = auth.getUser()

    useEffect(() => {
        dispatch(chekItem({ check: false, item: null }))        
    }, [])
    return (

        <>
            {
                (usuario && !AuthExpireSesion()) ?
                    <>
                        <Header />
                        <DatamovilSidebar />
                        <main id="main" className="main">
                            {
                                ValidatePermissionById(1, Module.DatamovilSystems) ? <Outlet /> : <NotFound />
                            }
                        </main>
                    </>
                    : <Navigate to={'/'} />
            }
        </>
    )
}

export default LayoutMenuDatamovil