import React from 'react'
import ProgressBarHalfCircle from '../../../../Components/ProgressBarHalfCircle/ProgressBarHalfCircle'
import ProgressBar from '../../../../Components/ProgressBar/ProgressBar'
import '../SeguimientoVisita/SeguimientoVisita.css'
import SubMenuSeguimientoVisita from '../SubMenuSeguimientoVisita'
import RowPages from '../../../../Components/RowPages'
import DataTable from '../../../../Components/datatable/DataTable'
import useSeguimientoVisita from '../../../../Hooks/SeguimientoVisita/useSeguimientoVisita'
import Select from 'react-select';
import Modal from '../../../../Components/Modal/Modal'
import Spinner from '../../../../Components/Loadig'
import { handleRangoDeFechas,formatearMonto, formatearMontoMx } from '../../../../Utils/functions'

const SeguimientoVisita = () => {

    const {
        setTextoBusqueda,
        setpageRows,
        columns,
        currentData,
        handleDetails,
        pageCount,
        currentPage,
        dataTipoVisita,
        dataEstatus,
        totalContratos,
        totalContratosUsuario,
        userName,
        dropdownRef,
        toggleDropdown,
        toggleVisible,
        percentage,
        active7,
        toggleActive7,
        active15,
        toggleActive15,
        isOpen,
        isVisible,
        loading,
        firstProgressVigente,
        firstProgressCumplidas,
        secondProgressVigentes,
        secondProgressCumplidas,
        totalMontoCobrado,
        totalMontoVencido,
        handlePageClick,
        setTipoSeleccionado,
        setEstatusSeleccionado,
    } = useSeguimientoVisita()

    return (
        <>
            {loading && <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>}
            <SubMenuSeguimientoVisita />
            <section className='pt-4 px-4'>
                <div className='d-flex justify-content-between'>
                    <div>
                        <div>
                            <span className='fs-5'><strong>{`¡Buen día, ${userName}!`}</strong></span>
                            <span className='seguimiennto-telfonico-calendar'> <i className='bx bx-calendar' ></i>{handleRangoDeFechas()}</span>
                        </div>
                    </div>
                    <div className='seguimiennto-telfonico-busqueda'>
                        {/* <div className="inner-addon right-addon seguimiennto-telfonico-busqueda-bar">
                            <i className="glyphicon fas fa-search icon"></i>
                            <input type="text" className="form-control mb-4" placeholder="Busqueda General de contratos" onChange={(e) => { console.log(e) }} />
                        </div> */}
                        <div className='seguimiento-telefonico-dropdown' ref={dropdownRef} >
                            <button className='seguimiento-telefonico-dropdown-button' onClick={toggleDropdown} >
                                <i className='bx bx-dots-vertical-rounded x2 icon'></i>
                            </button>
                            {isOpen && (
                                <div className="seguimiento-telefonico-dropdown-content">
                                    <a onClick={(e) => toggleVisible(true)} className='seguimiento-telefonico-dropdown-content-link'>Ver todo</a>
                                    <a onClick={(e) => toggleVisible(false)} className='seguimiento-telefonico-dropdown-content-link'>Listado de contratos</a>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>
            {isVisible && (
                <div className='d-flex row'>
                    <div className='col-xl-4 col-sm-12'>
                        <div className='estadistica-proceso-bar'>
                            <span className='col-12 text-center py-2'><strong>Estadísticas de progreso</strong> </span>
                            <span className='col-12 text-center py-2'>Asignados <strong>{totalContratos}</strong></span>
                            <ProgressBarHalfCircle initialPercentage={percentage} />
                        </div>
                    </div>
                    <div className='col-xl-5 col-zm-12'>
                        <div className='estadistica-promesas-de-pago'>
                            <span className='d-flex justify-content-center icon'><strong>Promesa de pago</strong></span>
                            <div className='d-flex justify-content-center py-2'>
                                <div className='seguimiennto-telfonico-promesa-pago-dias-hoy'>Hoy</div>
                                <div className={`seguimiennto-telfonico-promesa-pago-dias ${active7 ? `active` : `inactive`}`}
                                    onClick={(e) => toggleActive7()}
                                >-7 días</div>
                                <div className={`seguimiennto-telfonico-promesa-pago-dias ${active15 ? `active` : `inactive`}`}
                                    onClick={(e) => toggleActive15()}
                                >-15 días</div>
                            </div>
                            <div>
                                <div className='d-flex'>
                                    {/* pROGRESS BAR */}
                                    <div className='col-lg-6 col-md-6 col-sm-6'>
                                        <ProgressBar progress={firstProgressVigente} primary={true} secondProgress={secondProgressVigentes} active={active7 || active15} />
                                        <div className='estadistica-promesas-de-pago-vigentes'>
                                            <span className='col-12'><strong>Promesas vigentes</strong></span>
                                            <span className='col-12'>{formatearMontoMx(firstProgressVigente.totalMontoPromesas)}</span>
                                            <span className='col-12'>{firstProgressVigente.totalContratoPromesas} Contratos</span>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-6'>
                                        <ProgressBar progress={firstProgressCumplidas} secondProgress={secondProgressCumplidas} active={active7 || active15} />
                                        <div className='estadistica-promesas-de-pago-vigentes'>
                                            <span className='col-12'><strong>Promesas cumplidas</strong></span>
                                            <span className='col-12'>{formatearMontoMx(firstProgressCumplidas.totalMontoPromesas)}</span>
                                            <span className='col-12'>{firstProgressCumplidas.totalContratoPromesas} Contratos</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <button onClick={increaseProgress}>Increase Progress</button> */}
                        </div>
                    </div>
                    <div className='col-xl-3 col-sm-12'>
                        <div className='estadistica-monto-final'>
                            <span className='estadistica-monto-final-texto'>Monto vencido</span>
                            <span className='estadistica-monto-final-numero'><strong>{formatearMonto(isNaN(totalMontoVencido) ? 0 : totalMontoVencido)}</strong></span>
                            <span>{totalContratos} contratos</span>
                        </div>
                        <div className='estadistica-monto-final'>
                            <span className='estadistica-monto-cobrado-texto'>Monto cobrado</span>
                            <span className='estadistica-monto-cobrado-numero'> <strong>{formatearMonto(isNaN(totalMontoCobrado) ? 0 : totalMontoCobrado)}</strong></span>
                            <span>{totalContratosUsuario} contratos</span>
                        </div>
                    </div>
                </div>
            )}

            <div className='row col-12 px-4'>
                <div className='col-xl-2 col-sm-4 inner-addon right-addon'>
                    <i className="glyphicon fas fa-search"></i>
                    <input type="text" className="form-control mb-4" placeholder="Buscar" onKeyUp={(e) => { setTextoBusqueda(e.target.value) }} />
                </div>
                <div className='col-xl-3 col-sm-4 my-1 px-1'>
                    <Select options={dataTipoVisita} className='col-12' onChange={(e) => { setTipoSeleccionado(e.label) }} placeholder="Tipo de visita" />
                </div>
                <div className='col-xl-2 col-sm-4 my-1 px-1'>
                    <Select options={dataEstatus} className='col-12' onChange={(e) => { setEstatusSeleccionado(e.label) }} placeholder="Estatus" />
                </div>
            </div>
            <div className='row justify-content-end'>
                <div className='col-sm-2'>
                    <RowPages setpageRows={setpageRows} />
                </div>
            </div>
            <div className='mx-4 table-responsive'>
                <DataTable
                    column={columns}
                    data={currentData}
                    // detailable={ValidatePermission(4, Section.Cartera, SubModule.ProgramacionDeJobs).Historial}
                    detailable={true}
                    handleDetail={handleDetails}
                    paginate={true}
                    pageCount={pageCount}
                    handlePageClick={handlePageClick}
                    currentPage={currentPage - 1}
                />
            </div>
        </>
    )
}

export default SeguimientoVisita