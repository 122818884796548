import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router';
import CobranzaService from '../../Services/Cobranza/Cobranza'
import { useDispatch, useSelector } from 'react-redux';
import { setContrato, setItemGeneral } from '../../Redux/Slice/Cobranza/SeguimientoVisita/SeguimientoVisitaDetalleSlice';
import { formatearMontoMx, FormatFecha } from '../../Utils/functions';

const useSeguimientoVisita = () => {
  const navigate = useNavigate();
  const [textoBusqueda, setTextoBusqueda] = useState("")
  const [pageRows, setpageRows] = useState(10);
  const [data, setData] = useState([{}]);
  const dispatch = useDispatch();
  const [contratosUsuario, setContratosUsuario] = useState([{}]);
  let totalContratosAtendidos = 0;
  let totalContratosCartera = 0;

  const userName = sessionStorage.getItem('usuario')
  const userId = sessionStorage.getItem('userId').trim()
  const { role } = useSelector((state) => state.loginSlice);

  const [percentage, setPercentage] = useState(0)

  const columns = [
    { field: 'contrato_id', headerName: 'Contrato' },
    { field: 'tipo_persona', headerName: 'Tipo de persona' },
    { field: 'nombre', headerName: 'Nombre' },
    { field: 'monto_Adeudo', headerName: 'Monto Vencido' },
    { field: 'fecha', headerName: 'Fecha', width: 100 },
    { field: 'colonia', headerName: 'Dirección' },
    { field: 'tipo_visita', headerName: 'Tipo de visita' },
    { field: 'contrato_status', headerName: 'Estatus' },
  ];

  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState(data);
  let allContratos
  const [active7, setActive7] = useState(false)
  const [active15, setActive15] = useState(false)
  const [firstProgressVigente, setFirstProgressVigente] = useState({
    porcentajeActivos: 0,
    sumaTotalDinero: 0,
    idsDiferentes: 0
  })
  const [firstProgressCumplidas, setFirstProgressCumplidas] = useState({
    porcentajeActivos: 0,
    sumaTotalDinero: 0,
    idsDiferentes: 0
  })
  const [secondProgressVigentes, setSecondProgressVigentes] = useState({
    porcentajeActivos: 0,
    sumaTotalDinero: 0,
    idsDiferentes: 0
  })
  const [secondProgressCumplidas, setSecondProgressCumplidas] = useState({
    porcentajeActivos: 0,
    sumaTotalDinero: 0,
    idsDiferentes: 0
  })

  const [totalContratos, setTotalContratos] = useState('0')
  const [totalContratosUsuario, setTotalContratosUsuario] = useState('0')
  const [totalMontoVencido, setTotalMontoVencido] = useState('0')
  const [totalMontoCobrado, setTotalMontoCobrado] = useState('0')
  const [dataTipoVisita, setDataTipoVisita] = useState([])
  const [tipoSeleccionado, setTipoSeleccionado] = useState("")
  const [estatusSeleccionado, setEstatusSeleccionado] = useState("")

  const [allPromesas, setAllPromesas] = useState()
  const [loading, setLoading] = useState(false);
  const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });

  const [isOpen, setIsOpen] = useState(false)
  const [isVisible, SetIsVisible] = useState(true)
  const dropdownRef = useRef(null)

  const dataEstatus = [
    { value: 0, label: "Estatus" },
    { value: 1, label: "Concluido" },
    { value: 2, label: "Pendiente" },
    { value: 3, label: "Proceso" }
  ]

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const toggleVisible = (visible) => {
    SetIsVisible(visible)
    setIsOpen(false)
  }

  // Volver estas funciones una sola, que active y que guarde los datos secundarios en variable seundaria a enviar.
  const toggleActive7 = () => {

    setActive15(false)
    const vigente = analizarDatos(allPromesas, 7, "vigente")
    const cumplidas = analizarDatos(allPromesas, 7, "cumplida")

    setSecondProgressVigentes(vigente)
    setSecondProgressCumplidas(cumplidas)
    setActive7(!active7)
  }

  const toggleActive15 = () => {
    setActive7(false)
    const vigente = analizarDatos(allPromesas, 15, "vigente")
    const cumplidas = analizarDatos(allPromesas, 15, "cumplida")

    setSecondProgressVigentes(vigente)
    setSecondProgressCumplidas(cumplidas)
    setActive15(!active15)
  }

  function analizarDatos(datos, dias, estado) {
    const hoy = new Date();
    hoy.setHours(0, 0, 0, 0)

    //Sacar el limite del periodo de fechas
    const fechaLimitePeriodo = new Date(hoy);
    const fechaTotal = fechaLimitePeriodo.setDate(hoy.getDate() - 15)
    const fechaTotalFormat = new Date(fechaTotal)

    //filtrado por periodo -15 dias
    let datosFiltrados = datos.filter(item => {
      // let fechaCreacion = new Date(item.fecha_creacion)
      let fechaCreacion = new Date(item?.item?.fechaCreacion)

      if (item?.item?.status === "vigente") {
        // const fechaPromesa = new Date(item.fecha_compromiso);
        const fechaPromesa = new Date(item?.item?.fechaPromesa);
        const today = new Date()
        if (fechaPromesa < today) return false
      }

      return (item?.item?.status === estado) && fechaCreacion >= fechaTotalFormat
    })

    const total = datosFiltrados.length;

    //Sacar el numero de contratos en los ultimos x dias
    const fechaLimite = hoy.setDate(hoy.getDate() - dias)
    const fechaLimiteFormat = new Date(fechaLimite)

    //Filtrado por x dias
    let datosFiltradosFecha = datos.filter(item => {
      let fechaCreacion = new Date(item?.item?.fechaCreacion)

      if (item?.item?.status === "vigente") {
        const fechaPromesa = new Date(item?.item?.fechaPromesa);
        const today = new Date()
        if (fechaPromesa < today) return false
      }

      return fechaCreacion >= fechaLimiteFormat
    })

    // Suma total de dinero
    const activos = datosFiltradosFecha.filter(obj => obj?.item?.status === estado);

    let sumaTotalDinero = activos.length > 0 ? estado === "vigente" ?//Probar el estado
      activos.reduce((total, obj) => total + obj?.item?.monto_promesa, 0) : activos.reduce((total, obj) => total + obj?.item?.pagado, 0) : 0
    // Si es Vigente devolver monto promesa, si es cumplida devolver monto recuperado.

    const sumaTotal = datosFiltrados.length > 0 ? estado === "vigente" ?//Probar el estado
      datosFiltrados.reduce((total, obj) => total + obj?.item?.monto_promesa, 0) : datosFiltrados.reduce((total, obj) => total + obj?.item?.pagado, 0) : 0

    // Número de IDs diferentes
    let idsDiferentes = activos.length

    const contratosDiferentes = datosFiltrados.length > 0 ? new Set(datosFiltrados.map(obj => obj?.item?.contrato_id)).size : 0

    // Número de estado activo y cálculo del porcentaje
    let porcentajeActivos = total > 0 ? (activos.length / total) * 100 : 0;

    //10 para acomodar el tooltip
    const tooltipPercent = Number(porcentajeActivos.toFixed(2)) + 10

    //Si dias es 7 o 15 hacer la resta de sumaTotal de activos, de ids diferentes, y procentajeActivos
    if (dias > 0) {
      if (estado === "vigente") {
        sumaTotalDinero = sumaTotalDinero - firstProgressVigente.sumaTotalDinero
        idsDiferentes = idsDiferentes - firstProgressVigente.idsDiferentes
        porcentajeActivos = porcentajeActivos - firstProgressVigente.porcentajeActivos
      }
      if (estado === "cumplida") {
        sumaTotalDinero = sumaTotalDinero - firstProgressCumplidas.sumaTotalDinero
        idsDiferentes = idsDiferentes - firstProgressCumplidas.idsDiferentes
        porcentajeActivos = porcentajeActivos - firstProgressCumplidas.porcentajeActivos
      }
    }

    return {
      sumaTotalDinero,
      idsDiferentes,
      porcentajeActivos: porcentajeActivos.toFixed(2),
      tooltip: tooltipPercent,
      totalMontoPromesas: sumaTotal,
      totalContratoPromesas: contratosDiferentes
    };
  }

  const closeDropdown = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', closeDropdown)
    return () => document.removeEventListener('mousedown', closeDropdown)
  }, [])

  useEffect(() => {
    let filteredData = []
    let montovencido = 0;
    let montocobrado = 0;
    let contratosActivos = 0;
    if (data !== undefined && data !== null && data.length !== 0) {
      filteredData = data?.filter(item => {

        const nameMatches = textoBusqueda === '' || (item?.contrato_id && item?.contrato_id.toLowerCase().includes(textoBusqueda.toLowerCase()));

        return nameMatches
          && (estatusSeleccionado === "" || estatusSeleccionado === "Estatus" || item?.item?.items[0]?.estatus === estatusSeleccionado)
          && (tipoSeleccionado === "" || tipoSeleccionado === "Tipo de visita" || item?.tipo_visita === tipoSeleccionado)
      })
    }

    let filteredContratosUsuario = []
    if (contratosUsuario !== undefined && contratosUsuario !== null && contratosUsuario.length !== 0) {
      filteredContratosUsuario = contratosUsuario?.filter(item => {

        const nameMatches = textoBusqueda === '' || (item?.contrato_id && item?.contrato_id.toLowerCase().includes(textoBusqueda.toLowerCase()));

        return nameMatches
        // && (estatusSeleccionado === "" || estatusSeleccionado === "Estatus" || item?.item?.items[0]?.estatus === estatusSeleccionado)
        // && (tipoSeleccionado === "" || tipoSeleccionado === "Tipo de visita" || item?.tipo_visita === tipoSeleccionado)
      })
    }

    const indexOfLastItem = currentPage * pageRows
    const indexOfFirstItem = indexOfLastItem - pageRows
    const newData = filteredData?.slice(indexOfFirstItem, indexOfLastItem)
    setCurrentData(newData)

    const newTotalPages = (filteredData === undefined || filteredData === null) ? 1 : Math.ceil(filteredData.length / pageRows)
    setPageCount(newTotalPages)

    // filteredData.map(m => {
    //   // if(m?.item.items.contrato.estatus === "Activo") {
    //   //   contratosActivos++
    //   // }
    //   // montocobrado = montocobrado + m.pago_periodo;
    //   montovencido += m.monto_adeudo
    // })

    filteredData.map(m => {
      // montocobrado = montocobrado + m?.pago_periodo ?? 0;
      montovencido += (m?.monto_adeudo != null ? (typeof m?.monto_adeudo === "string" ? parseFloat(m?.monto_adeudo.replace(/[$,]/g, '')) : m?.monto_adeudo) : 0);
    })

    let contadorPromesasCumplidas = 0;
    filteredContratosUsuario.filter(x => x?.item?.status == "cumplida").map(m => {
      // if(m?.item.items.contrato.estatus === "Activo") {
      //   contratosActivos++
      // }
      montocobrado = montocobrado + (m?.item?.pagado ?? 0);
      contadorPromesasCumplidas++;
      // montovencido = montovencido + m.monto_adeudo
    })
    setTotalMontoCobrado(Number(montocobrado.toFixed(2)))
    setTotalMontoVencido(Number(montovencido.toFixed(2)))

    // let length = filteredData.length
    // let percentage = contratosActivos > 0 ? filteredData.length / contratosActivos : 0

    //   setTotalContratos(length)
    //   setPercentage(percentage)

    setTotalContratos(filteredData.length)
    setTotalContratosUsuario(contadorPromesasCumplidas)
  }, [currentPage, textoBusqueda, pageRows, data, estatusSeleccionado, tipoSeleccionado, contratosUsuario])

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1)
  };

  useEffect(() => {
    // handleGetSeguimientoContrato()
    // handleGetPromesasdePago()

    setLoading(true);
    handleCargaInicial()
  }, [])

  const handleCargaInicial = async () => {
    const [] = await Promise.all([getAllEjecutivosWithFullDetail(), handleGetSeguimientoContrato(), GetUserStats(), GetAllCobranzaTipoVisita()]).finally(() => {
      setLoading(false);
    });
  }

  const GetUserStats = async () => {
    let dataFormateada = []
    let idUsuario = (role === "DMSupervisordeCobranza" || role === "Administrador") ? (role === "Administrador" ? "Admin" : "Supervisor") : userId;

    let data = {
      "verb": "string",
      "id": idUsuario,
      "field": "string",
      "value": "string",
      "level": 0,
      "flag": true,
      "anyObject": {},
      "kvp": {}
    }

    await CobranzaService.GetUserStats(data)
      .then(res => {
        res?.data?.responseData?.map(item => {
          dataFormateada.push({
            item
          });
        });

        const vigente = analizarDatos(dataFormateada, 0, "vigente")
        const cumplidas = analizarDatos(dataFormateada, 0, "cumplida")

        // const suma = dataFormateada.reduce((total, obj) => {
        //   return {
        //     montoVencido: montoVencido + obj.monto_vencido,
        //     montoCobrado: montoCobrado + obj.pagado
        //   };
        // }, { montoVencido: 0, montoCobrado: 0 }); // Inicializamos el acumulador

        setAllPromesas(dataFormateada)
        // setTotalMontoCobrado(suma.montoCobrado)
        // setTotalMontoVencido(suma.montoVencido)
        setFirstProgressVigente(vigente)
        setFirstProgressCumplidas(cumplidas)


      }).catch(e => {
        // setLoading(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })

    let length = dataFormateada.length
    // let percentage = contratosActivos > 0 ? dataFormateada.length / contratosActivos : 0

    setTotalContratosUsuario(length)
    // setPercentage(percentage)
    // setDataTipoVisita(newDataTipo)
    setContratosUsuario(dataFormateada)
    // setLoading(false);
  }

  const getAllEjecutivosWithFullDetail = async () => {
    let userContracts = []

    let data = {
      "verb": "string",
      "id": "string",
      "field": "string",
      "value": "string",
      "level": 1,
      "flag": true,
      "anyObject": {},
      "kvp": {}
    };

    await CobranzaService.getAllEjecutivosWithFullDetail(data)
      .then(res => {
        if (res.data.callSucceded) {
          if (role === "DMCobranza Nivel 2") {
            userContracts = res.data?.responseData?.find(user => user.userid.toLowerCase() === userId.toLowerCase());
            // const userContracts = res.data.responseData.find(user => user.userid.toLowerCase() === 'feb42264-1529-49ad-8473-29e5e61d9402'); //Solo para pruebas  
            // Validar y filtrar si el listado de contratos no es null o vacío
            if (Array.isArray(userContracts?.contratos) && userContracts?.contratos?.length > 0) {
              userContracts.contratos = userContracts.contratos.filter(item => item.cartera_vencida === 1 && item.nivel_cobranza === "2" && item.politica_aval === 0);
            }
            if (userContracts !== undefined && userContracts !== null) {
              if (userContracts?.contratos) {
                let length = userContracts?.contratos?.length
                totalContratosCartera = length
              }
            }
            let percentage = totalContratosCartera > 0 ? ((totalContratosAtendidos / totalContratosCartera) * 100) : 0
            percentage = percentage >= 1 || percentage <= 100 ? (percentage / 100) : 0;
            setPercentage(percentage)
          }
          if (role === "DMSupervisordeCobranza" || role === "Administrador") {
            let length = 0;
            res.data?.responseData.map(user => {
              let usercontracts = user
              if (Array.isArray(usercontracts?.contratos) && usercontracts?.contratos?.length > 0) {
                usercontracts.contratos = usercontracts.contratos.filter(item => item.cartera_vencida === 1 && item.nivel_cobranza === "2" && item.politica_aval === 0);
              }

              if (usercontracts?.contratos !== undefined && usercontracts?.contratos !== null) {
                length = length + usercontracts?.contratos?.length
              }
            })
            totalContratosCartera = length
            let percentage = totalContratosCartera > 0 ? ((totalContratosAtendidos / totalContratosCartera) * 100) : 0
            percentage = percentage >= 1 || percentage <= 100 ? (percentage / 100) : 0;
            setPercentage(percentage)
          }
        }
      }).catch(e => {
        // setLoading(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      });
  }

  const handleGetSeguimientoContrato = async () => {
    // setLoading(true);
    let dataFormateada = []
    let filtrarTipoVisita = new Set()
    // let newDataTipo = [{ value: 0, label: "Tipo de visita" }];
    // let counterTipo = 1;
    let contratosActivos = 0;
    let contratosAtendidos = 0;

    await CobranzaService.GetAllRutasCobranza()
      .then(res => {
        res?.data?.responseData?.map(item => {
          let lista = []
          if (role === "DMCobranza Nivel 2") {
            if (item?.userid.toLowerCase() === userId.toLowerCase()) {
              lista = item?.items
            }
          }
          if (role === "DMSupervisordeCobranza" || role === "Administrador") {
            lista = item?.items
          }

          // if (item?.userid.toLowerCase() === userId.toLowerCase()) {
          for (const i of lista) {
            if (i?.contrato !== null &&
              i?.contrato.cartera_vencida === 1 &&
              i?.contrato.nivel_cobranza === "2" &&
              // i?.estatus === "Pendiente" &&
              i?.cierre_visita === false) {

              // if (i?.contrato.estatus === "Activo") {
              contratosActivos++
              // }

              let colorFondo = ""
              let colorTexto = ""
              switch (i?.contrato?.estatus) {
                case "Cumplida":
                  colorFondo = "#D8F2CD"
                  colorTexto = "#47A066"
                  break;
                case "Rota":
                  colorFondo = "#F5CECE"
                  colorTexto = "#CE2222"
                  break;
                case "Vigente":
                  colorFondo = "#FAE7BC"
                  colorTexto = "#B46B16"
                  break;
                default:
                  break;
              }
              filtrarTipoVisita.add(i?.tipo_visita)
              dataFormateada.push({
                item: item,
                contrato_id: i?.contrato?.contrato_id,
                tipo_persona: Boolean(i?.es_aval) ? "Aval" : "Física",
                // nombre: Boolean(i?.es_aval) ? `${i?.contrato?.nombre_aval} ${i?.contrato?.apellido_paterno_aval} ${i?.contrato?.apellido_materno_aval}` : `${i?.contrato?.nombre} ${i?.contrato?.apellido_paterno} ${i?.contrato?.apellido_materno}`,
                nombre: `${i?.contrato?.nombre} ${i?.contrato?.apellido_paterno} ${i?.contrato?.apellido_materno}`,
                monto_adeudo: i?.contrato?.monto_adeudo,
                monto_Adeudo: formatearMontoMx(i?.contrato?.monto_adeudo),
                fecha: FormatFecha(i?.fecha),
                // colonia: Boolean(i?.es_aval) ? `${i?.contrato?.calle_aval} #${i?.contrato?.no_ext_aval} Col. ${i?.contrato?.colonia_aval}` : `${i?.contrato?.calle} #${i?.contrato?.no_ext} Col. ${i?.contrato?.colonia}`,
                colonia: `${i?.contrato?.calle} #${i?.contrato?.no_ext} Col. ${i?.contrato?.colonia}`,
                tipo_visita: i?.tipo_visita,
                contrato_status: `${i?.estatus}`,
                colorFondo: colorFondo,
                colorTexto: colorTexto,
                pago_periodo: i?.contrato?.pago_periodo,
              })
            }
          }

          for (const i of lista) {
            if (i?.contrato !== null &&
              i?.contrato.cartera_vencida === 1 &&
              i?.contrato.nivel_cobranza === "2" &&
              // i?.estatus === "Pendiente" &&
              i?.cierre_visita === true) {
              contratosAtendidos++;
            }
          }
          // }
        })

        // for (const value of filtrarTipoVisita) {
        //   newDataTipo.push({ value: counterTipo, label: value })
        //   counterTipo++
        // }

      }).catch(e => {
        // setLoading(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })

    let length = dataFormateada.length
    totalContratosAtendidos = contratosAtendidos
    // let percentage = contratosActivos > 0 ? dataFormateada.length / contratosActivos : 0
    let percentage = totalContratosCartera > 0 ? ((totalContratosAtendidos / totalContratosCartera) * 100) : 0
    percentage = percentage >= 1 || percentage <= 100 ? (percentage / 100) : 0;
    setTotalContratos(length)
    setPercentage(percentage)
    // setDataTipoVisita(newDataTipo)

    let arregloOrdenado = dataFormateada.sort(function (a, b) {
      if (a.fecha > b.fecha) {
        return 1;
      }
      if (a.fecha < b.fecha) {
        return -1;
      }
      return 0;
    });
    setData(arregloOrdenado.reverse())
    // setLoading(false);
  }

  const GetAllCobranzaTipoVisita = async () => {
    // setLoading(true);
    await CobranzaService.GetAllCobranzaTipoVisita()
      .then(res => {
        let NewData = [];
        res.data.responseData?.filter(x => x.status).map(element => {
          NewData.push({
            value: element.id,
            label: element.valor
          })
        });
        setDataTipoVisita(NewData);
        // setLoading(false);
      }).catch(() => {
        // setLoading(false);
      })
  }

  const handleDetails = (value) => {

    let detalle = value?.item?.items?.find(item => item.contrato?.contrato_id === value.contrato_id);

    // dispatch(setContrato(value?.item?.items[0]?.contrato));
    dispatch(setContrato(detalle?.contrato));
    // dispatch(setItemGeneral(value?.item?.items[0]));
    dispatch(setItemGeneral(detalle));
    navigate(`/cobranza/seguimiento-visita/detalle`);
  }

  return {
    setTextoBusqueda,
    setpageRows,
    columns,
    currentData,
    handleDetails,
    pageCount,
    currentPage,
    dataTipoVisita,
    dataEstatus,
    totalContratos,
    totalContratosUsuario,
    userName,
    dropdownRef,
    toggleDropdown,
    toggleVisible,
    percentage,
    active7,
    toggleActive7,
    active15,
    toggleActive15,
    isOpen,
    isVisible,
    loading,
    firstProgressVigente,
    firstProgressCumplidas,
    secondProgressVigentes,
    secondProgressCumplidas,
    totalMontoCobrado,
    totalMontoVencido,
    handlePageClick,
    setTipoSeleccionado,
    setEstatusSeleccionado
  }
}
export default useSeguimientoVisita