import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { toast, Toaster } from 'react-hot-toast';
import ModalConfirm from './Componentes/ModalConfirm'
import RevisionStepper from "./Componentes/RevisionStepper";
import useRevisionPasos from "../../../Hooks/DatamovilSystems/RevisionPasos/useRevisionPasos";
import CardGeneral from "./AgendarCita/CardGeneral";
import AgendarCitaCard from "./AgendarCita/AgendarCita";
import ContratoCard from '../../../Components/ContratoCard';
import VehiculoInfoCard from '../../../Components/VehiculoInfoCard'; 
import HistorialServicio from "./Componentes/ListadoHistorial";
import { calendarKeys } from "../../../Utils/functions";

const AgendarCita = React.forwardRef(({ ...props }, ref) => {
    const { id, tipoServicio } = useParams();

    const {
        stepe,
        handleClickTerminar,
        activebtncontinuar,
        loading,
        handleChangeStatusBtn,
        modalConfirm,
        setModalConfirm,
        handleClickTerminarProceso,
        state
    } = useRevisionPasos(toast);


    return (
        <>
            <RevisionStepper
                dataStep={stepe}
                handleClickContinuar={handleClickTerminar}
                activeBtn={activebtncontinuar}
            />



            <div className='d-flex flex-column background-grey' style={{ height: '200vh' }}>
                <div className='row mx-md-4 my-4'>
                    <div className="col-6 px-md-3">
                        <VehiculoInfoCard GeneralId={state?.generalId} verClaves={true} dataCalendar={true} calendarioType={calendarKeys[tipoServicio]} />
                    </div>
                    <div className="col-6 px-md-3">
                        <ContratoCard vin={state?.vin} />
                    </div>
                    
                </div>
                
                <CardGeneral handleConcluido={handleChangeStatusBtn} />
                <AgendarCitaCard  handleConcluido={handleChangeStatusBtn}/>
                {
                    modalConfirm &&
                    <ModalConfirm
                        isOpen={modalConfirm}
                        setIsOpen={setModalConfirm}
                        title={""}
                        handleConfirm={handleClickTerminarProceso}
                    />
                }
                <HistorialServicio />
            </div>
        </>
    )
});

export default AgendarCita;