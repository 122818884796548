import axios from '../../Utils/ApiService';
import { IsNullOrEmpty } from '../../Utils/functions';

const DSystemsServicios = {
    getSeguimientoServiciosPorTipo: ({ buscar, procesoId, tipoServicioId, page, rows, buscarAvanzado, fechaInicio, fechaFin, concluido }) => {
        let query = '';
        if (!IsNullOrEmpty(buscar)) query += '&Buscar=' + buscar + '&';
        if (!IsNullOrEmpty(procesoId)) query += '&ProcesoId=' + procesoId + '&';
        if (!IsNullOrEmpty(buscarAvanzado)) query += '&buscarAvanzado=' + buscarAvanzado + '&';
        if (!IsNullOrEmpty(fechaInicio)) query += '&fechaInicio=' + fechaInicio + '&';
        if (!IsNullOrEmpty(fechaFin)) query += '&fechaFin=' + fechaFin + '&';
        if (!IsNullOrEmpty(concluido)) query += '&concluido=' + concluido + '&';
        query += `TipoServicioId=${tipoServicioId}&Page=${page}&Rows=${rows}`
        return axios.get(`/functions/SeguimientoServicios?${query}`);
    },
    getDataLogService : (id) =>{
        return axios.get(`/functions/GetServicioLog?ServicioId=${id}&Page=${1}&Rows=${10}`);
    },
    getServicio: (id) => {
        return axios.get(`/functions/servicio?ServicioId=${id}`);
    },
    updateServicio: (data) => {
        return axios.put(`/functions/servicio`, data);
    },
    updateCitaServicio: (data) => {
        return axios.put(`/functions/CitaServicio`, data);
    },
    updateServicioInicio: (data) => {
        return axios.put(`/functions/ServicioInicio`, data);
    },
    getServicioRevision: (id) => {
        return axios.get(`/functions/ServicioRevision?ServicioId=${id}`);
    },
    updateServicioRevision: (data) => {
        return axios.put(`/functions/ServicioRevision`, data);
    },
    getServicioCostosAdicionales: (id) => {
        return axios.get(`/functions/ServicioCostosAdicionales?ServicioId=${id}`);
    },
    updateServicioCostosAdicionales: (data) => {
        return axios.put(`/functions/ServicioCostosAdicionales`, data);
    },
    agregarServicioCostosAdicionales: (data) => {
        return axios.post(`/functions/ServicioCostosAdicionales`, data);
    },
    getServicioOrden: (id) => {
        return axios.get(`/functions/ServicioOrden?ServicioId=${id}`);
    },
    updateServicioOrden: (data) => {
        return axios.put(`/functions/ServicioOrden`, data);
    },
    agregarServicioCostosAdicionalesCosto: (data) => {
        return axios.post(`/functions/ServicioCostosAdicionalesCosto`, data);
    },
    getServicioCostosAdicionalesCosto: (id) => {
        return axios.get(`/functions/ServicioCostosAdicionalesCosto?ServicioId=${id}`);
    },
    updateServicioCostosAdicionalesCosto: (data) => {
        return axios.put(`/functions/ServicioCostosAdicionalesCosto`, data);
    },
    deleteServicioCostosAdicionalesCosto: (id) => {
        return axios.delete(`/functions/ServicioCostosAdicionalesCosto?ServicioCostosAdicionalesCostoId=${id}`);
    },
    agregarServicioCostosAdicionalesPago: (data) => {
        return axios.post(`/functions/ServicioCostosAdicionalesPago`, data);
    },
    getServicioCostosAdicionalesPago: (id) => {
        return axios.get(`/functions/ServicioCostosAdicionalesPago?ServicioId=${id}`);
    },
    updateServicioCostosAdicionalesPago: (data) => {
        return axios.put(`/functions/ServicioCostosAdicionalesPago`, data);
    },
    deleteServicioCostosAdicionalesPago: (id) => {
        return axios.delete(`/functions/ServicioCostosAdicionalesPago?servicioCostosAdicionalesPagoId=${id}`);
    },
    getConsultaVINServicio: (id,tipoServicioId) => {
        return axios.get(`/functions/ConsultaVINServicio?VIN=${id}&TipoServicioId=${tipoServicioId}`);
    },
    postServicio: (data) => {
        return axios.post(`/functions/Servicio`, data)
    },
    getServiciosByGeneralId: (id) => {
        return axios.get(`/functions/SeguimientoServiciosByGeneralId?generalId=${id}`);
    },

}

export default DSystemsServicios;