import { Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { SelectField } from "../../../Components/SelectField";
import { TextAreaField } from "../../../Components/TextAreaField";
import * as Yup from 'yup';
import Catalogo from "../../../Services/Catalogos/Catalogo";
import DSystemsServicios from "../../../Services/DatamovilSystems/ServiciosDatamovil";
import { message } from "../../../Utils/functions";

const AutorizacionServicioModal = ({ modalMessage, setModalMessage, getListado }) => {
    const refFormik = useRef();
    const refBtn = useRef();
    const initial = {
        servicioId: '',
        servicioAutorizado: '',
        servicioMotivoRechazo: '',
        comentariosRechazo: '',
    };

    const [initialValue, setInitialValue] = useState(initial);
    const resultados = [
        { value: '1', label: 'Aprobar' },
        { value: '0', label: 'Rechazar' }
    ];
    const [motivos, setMotivos] = useState([]);

    useEffect(() => {
        getMotivosRechazo();
    }, []);

    useEffect(() => {
        getMotivosRechazo();
    }, [modalMessage]);
    const validate = Yup.object({
        servicioAutorizado: Yup.string()
            .required("El campo es requerido."),
        comentariosRechazo: Yup.string()
            .trim("El texto no debe empezar ni terminar con espacios")
            .strict(true)
            .matches(
                "^[a-zA-Z0-9-ZÀ-ÿ\.\,\u00f1\u00d1]+(?: [a-zA-Z0-9-ZÀ-ÿ\.\,\u00f1\u00d1]+)*$",
                "La información ingresada es incorrecta."
            )
            .matches(
                "^[a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ\.\,]+(?: [a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ\.\,]+)*$",
                "solo debe existir un espacio entre cada palabra."
            )
            .required("El campo es requerido."),
        servicioMotivoRechazo: Yup.string()
            .when("servicioAutorizado", {
                is: '0',
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
    });
    const getMotivosRechazo = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.ServicioMotivosRechazo')
            .then(resp => {
                const items = resp.data.map(item => {
                    return {
                        value: item.ServicioMotivosRechazoId,
                        label: item.ServicioMotivosRechazo
                    }
                });
                setMotivos(items);
            })
            .catch(err => {
                console.error(err);
            })
    }

    const handleAutorizar = async (values) => {
        refBtn.current.disabled = true;
        values.servicioId = modalMessage.servicioId;
        values.servicioAutorizado = values.servicioAutorizado === '1' ? true : false;
        await DSystemsServicios.updateServicio(values)
            .then(resp => {
                toast.success(message(`servicio autorizado exitosamente`));
                getListado();
            })
            .catch(err => {
                toast.error(message(`Error al intentar autorizar el servicio`))
            })
            .finally(() => {
                setModalMessage({ isOpen: false })
                refBtn.current.disabled = false;
            });
    };

    return (
        <Formik
            innerRef={refFormik}
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                handleAutorizar(values);
            }}
        >
            {({ isValid, values, setFieldValue, errors }) => (
                <>
                    <Toaster
                        position="top-right"
                        toastOptions={{
                            success: {
                                style: {
                                    background: '#47a066',
                                    color: '#FFFF',
                                    borderLeft: '10px solid #2f7246'
                                },
                            },
                            error: {
                                style: {
                                    background: '#d53f3f',
                                    color: '#FFFF',
                                    borderLeft: '10px solid #ac241a'
                                },
                            },
                        }}
                    />
                    <Form>
                        <section className="p-4">
                            <div className="row">
                                <div className="col-12">
                                    <h4 className="fw-bold">Autorización de solicitudes</h4>
                                </div>
                            </div>
                            <div className="row mt-3 mb-2">
                                <div className="col col-12">
                                    <div className="row mt-2">
                                        <div className="col-lg-6 col-12">
                                            <SelectField
                                                id="servicioAutorizado"
                                                label="Resultado"
                                                name="servicioAutorizado"
                                                items={resultados}
                                                onChange={(event) => {
                                                    setFieldValue("servicioAutorizado", event.value);
                                                }}
                                            />
                                        </div>
                                        {
                                            values.servicioAutorizado === '0' &&
                                            <div className="col-lg-6 col-12">
                                                <SelectField
                                                    id="servicioMotivoRechazo"
                                                    label="Motivo"
                                                    name="servicioMotivoRechazo"
                                                    items={motivos}
                                                    onChange={(event) => {
                                                        setFieldValue("servicioMotivoRechazo", event.value);
                                                    }}
                                                />
                                            </div>
                                        }
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-12">
                                            <TextAreaField label="Comentarios" className="form-control col-12" rows="3" name="comentariosRechazo" type="text" holder="Escribe" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row d-flex justify-content-end mt-4">
                                <div className="col-12 col-md-auto">
                                    <button className="btn btn-outline" onClick={() => setModalMessage({ isOpen: false })}>
                                        Regresar
                                    </button>
                                </div>
                                <div className="col-12 col-md-auto">
                                    <button className="btn" disabled={!isValid} ref={refBtn} type="submite">
                                        Confirmar
                                    </button>
                                </div>
                            </div>
                        </section>
                    </Form>

                </>
            )
            }
        </Formik>
    );
}

export default AutorizacionServicioModal;