import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DSystemsServicios from '../../../Services/DatamovilSystems/ServiciosDatamovil';
import Catalogo from '../../../Services/Catalogos/Catalogo';
import AuthLogin from '../../../Utils/AuthLogin';
import { calendarKeys, formatearFecha, getDateFormat, isEmpty, IsNullOrEmpty, message } from '../../../Utils/functions';

const useRevisionDatamovil = ({ handleConcluido, setRequiereCosto, toast }) => {
    const { id, tipoServicio } = useParams();
    const navigate = useNavigate();
    const { state } = useLocation();

    const auth = new AuthLogin();

    const initial = {
        servicioRevisionDatamovilId: "",
        servicioId: "",
        alteracionDatamovil: null,
        comentariosAlteracion: null,
        datamovilAlterado: null,
        equipoCompleto: null,
        folioId: null,
        formatoHojaId: null,
        funciona: null,
        requiereReparacion: null,
        teclado: null,
        bloqueador: null,
        gps: null,
        aplicaCobro: null,
        observaciones: null,
        ejecutivo: auth.getNombreUsuario(),
        concluido: null,
        usuarioAlta: "",
        usuarioActualiza: null,
        fechaConcluido: null,
        fechaAlta: "",
        fechaActualiza: null,
        numeroServicio: "",
        fechaAltaConcluido: "",
        fechaServicioConcluido: "",
        servicioConcluido: false,
        motivosAlteracion: null,
        observacionesAlteracion: null,
        componenteDispositivoEquipo: null,
        comentariosFuncionamientoEquipo: null,
        componenteDispositivoFuciona: null,
        comentariosFuncionamientoFunciona: null,
        componenteDispositivoReparaciones: null,
        comentariosFuncionamientoReparaciones: null,
        cierreContrato: false,
        desinstalacion: false,
    };

    const [initialValue, setInitialValue] = useState(initial);
    const [loading, setLoading] = useState(false);
    const [formatoHoja, setFormatoHoja] = useState([]);
    const [componentes, setComponentes] = useState([]);
    const [motivos, setMotivos] = useState([]);

    const opcionesBool = [{ value: true, label: 'Si' }, { value: false, label: 'No' }];
    const location = useLocation();
    let url = location.pathname.split("/");

    useEffect(() => {
        getFormatoHoja();
        getComponentes();
        getMotivos();
        getDataRevision();
    }, []);

    const getDataRevision = async () => {
        setLoading(true);
        await DSystemsServicios.getServicioRevision(id)
            .then(res => {
                let resData = res.data
                resData.ejecutivoName = auth.getNombreUsuario()
                resData.servicioConcluido = resData.servicioConcluido ?? false;

                resData.sol_id = resData.sol_id ?? "";
                resData.contrato = resData.contrato ?? "";
                resData.vin = resData.vin ?? "";
                //Validar si aplicaCobro == true y servicioConcluido === setRequiereCosto

                resData.alteracionDatamovil = resData.alteracionDatamovil ?? null;
                resData.datamovilAlterado = resData.datamovilAlterado ?? null;
                resData.equipoCompleto = resData.equipoCompleto ?? null;
                resData.funciona = resData.funciona ?? null;

                resData.observacionesAlteracion = resData.observacionesAlteracion ?? ""
                resData.comentariosFuncionamientoEquipo = resData.comentariosFuncionamientoEquipo ?? ""
                resData.comentariosFuncionamientoFunciona = resData.comentariosFuncionamientoFunciona ?? ""
                resData.comentariosFuncionamientoReparaciones = resData.comentariosFuncionamientoReparaciones ?? ""
                resData.requiereReparacion = resData.requiereReparacion ?? null
                setRequiereCosto(resData.aplicaCobro ?? false);

                // if (resData.aplicaCobro && resData.concluido === false && resData.servicioConcluido) {
                //     setRequiereCosto(true)
                // }
                setInitialValue({ ...initialValue, ...resData });
                handleConcluido({ concluido: resData.concluido ?? false, concluidoGeneral: resData?.concluidoGeneral ?? false })


                // if (resData.alteracionDatamovil && !resData.concluido) {
                //     setRequiereCosto(true)
                // }

            })
            .catch(error => {
                //Error al obtener datos de revision
            })
            .finally(() => {
                setLoading(false);
            })
    };

    const getFormatoHoja = async () => {
        let items = [];
        await Catalogo.getDropdownsByTable('Catalogos.FormatoServicios')
            .then(resp => {
                resp.data.forEach(item => {
                    items.push({
                        value: item.FormatoServiciosId,
                        label: item.FormatoServicio
                    });
                });
                setFormatoHoja(items);
            })
            .catch(err => {
                console.error(err);
            });
    };

    const getComponentes = async () => {
        let items = [];
        await Catalogo.getDropdownsByTable('Catalogos.ComponenteDispositivo')
            .then(resp => {
                resp.data.forEach(item => {
                    items.push({
                        value: item.ComponenteDispositivo,
                        label: item.Descripcion
                    });
                });
                setComponentes(items);
            })
            .catch(err => {
                console.error(err);
            });
    };

    const getMotivos = async () => {
        let items = [];
        await Catalogo.getDropdownsByTable('Catalogos.MotivoAlteracion')
            .then(resp => {
                resp.data.forEach(item => {
                    items.push({
                        value: item.MotivosAlteracion,
                        label: item.Descripcion
                    });
                });
                setMotivos(items);
            })
            .catch(err => {
                console.error(err);
            });
    };

    const submitForm = async (values) => {
        setLoading(true);
        values.procesoId = state.tipoProceso
        values.paso = url[5]
        values.porcentaje = values.aplicaCobro ? 50 : 100
        values.status = 2
        values.cierreContrato = state.proceso.toLowerCase().replaceAll(' ', "") === "cierrecontrato" ? true : false;
        values.desinstalacion = tipoServicio === 3 ? true : false;

        let valueDispositivo = ""
        if (values.motivosAlteracion) {
            const motivoEncontrado = motivos.find(m => m.value === values.motivosAlteracion);
            valueDispositivo = motivoEncontrado ? motivoEncontrado.label : "";
        }
        values.componenteDispositivo = valueDispositivo;
        values.concluido = values.aplicaCobro ? false : true

        //console.log(values)

        //return;
        DSystemsServicios.updateServicioRevision(values)
            .then(resp => {
                if (values.aplicaCobro && values.servicioConcluido) {
                    setRequiereCosto(true)
                }
                console.log(resp)
                if (resp.data.ban === 1) {
                    toast.success(message(resp.data.message));
                } else {
                    toast.error(message(resp.data.message));
                }

            })
            .catch(error => {
                //Error al obtener datos de revision
            })
            .finally(() => {
                getDataRevision();
                setLoading(false);
            })
    };

    const submitCostoAdicional = async (values) => {
        setLoading(true);

        //values.componenteDispositivo = 
        return;
        DSystemsServicios.agregarServicioCostosAdicionales(values)
            .then(res => {

            })
            .catch(error => {
                //Error al obtener datos de revision
            })
            .finally(() => {
                setLoading(false);
            })
    };


    return {
        initialValue,
        loading,
        setLoading,
        submitForm,
        opcionesBool,
        componentes,
        motivos,
        formatoHoja
    };
};

export default useRevisionDatamovil;
