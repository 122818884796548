import React, { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import toast, { Toaster } from 'react-hot-toast'
import UnidadesRecuperadasDetalleHook from "../../../Hooks/UnidadesRecuperadas/UnidadesRecuperadasDetalleHook.js"
import VehiculoInfoCard from "../../../Components/VehiculoInfoCard.jsx"
import CarteraInfoCard from "../../../Components/CarteraInfoCard.jsx"
import ContratoCard from "../../../Components/ContratoCard.jsx"
import Modal from "../../../Components/Modal/Modal.jsx"
import Spinner from "../../../Components/Loadig.jsx"
import EliminarPromesaPagoModal from "../../../Components/Modal/EliminarPromesaPagoModal.jsx"
import DatosPromesaPagoModal from "../../../Components/Modal/DatosPromesaPagoModal.jsx"
import RegistrarReferenciaModal from "../../../Components/Modal/RegistrarReferencia.jsx"
import AgregarDatosContactoModal from "../../../Components/Modal/AgregarDatosContactoModal.jsx"
import IncumplimientoPromesaPagoModal from "./Cards/IncumplimientoPromesadePagoModal.jsx"
import AmpliacionPromesasdePagoModal from "./Cards/AmpliacionPromesasdePagoModal.jsx"
import DireccionesContactoModal from "../../../Components/Modal/DireccionesContactoModal.jsx"
import RegistroLlamadaModal from "../../../Components/Modal/RegistroLlamadaModal.jsx"
import VisorFile from "../../../Components/VisorFile.jsx"
import DataTable from "../../../Components/datatable/DataTable.jsx"
import Accordion from "../../../Components/TitleAcordeon.jsx"
import TablaAnidada from "../../../Components/TablaAnidada.jsx"
import CitaEntregaUnidad from "./Cards/CitaEntregaUnidad.jsx"
import EntregaUnidad from "./Cards/EntregaUnidad.jsx"
import { ValidatePermission, ValidatePermissionById } from "../../../Auth/ValidatePermission.js"
import { Section, SubModule } from "../../../Auth/Authorization.js"

const UnidadesRecuperadasDetalle = () => {
    const { state } = useLocation();
    const {
        currentDataSeguimiento,
        userDetail,
        promesasRotas,
        loading,
        setLoading,
        handleReturnPage,
        disableInteractions,

        //Catalogos
        motivoEliminacion,
        relacionSolicitante,
        dataEstado,
        tipoContacto,
        subtipoContacto,
        infoContactosFiltered,

        ///Promesas de Pago
        columnsDetallePromesas,
        columnsGarantiaPromesas,
        columnsHistorialPromesas,
        currentDataPromesas,
        dataGarantia,
        dataGarantiaHistorial,
        handlePromesasPago,
        handlePromesasPagoDelete,
        handleGetPromesasdePago,
        handleModalIncumplimiento,
        handleModalAmpliacion,
        isOpenModalPromesaPagoData,
        setisOpenModalPromesaPagoData,
        promesaPagoData,
        isOpenModalPromesaPagoDelete,
        setisOpenModalPromesaPagoDelete,
        isOpenModalPromesaPagoIncumplimiento,
        setisOpenModalPromesaPagoIncumplimiento,
        isOpenModalPromesaPagoAmpliacion,
        setisOpenModalPromesaPagoAmpliacion,
        motivosVisita,
        infoAvaliable,

        //Directorio de contactos
        columnsInformacionContacto,
        columnsSubInformacionContacto,
        currentDataSeguimientoLog,
        directorioValue,
        infoContactoData,
        handleSetStateTab,
        handleModalRef,
        handleGetInformacionContactos,
        handleModalAdd,
        handleDireccionesModal,
        handleRegistroLlamada,
        handleContactosSeguimientoLog,
        isOpenModalAgregarContacto,
        setisOpenModalAgregarContacto,
        isOpenModalReferencias,
        setisOpenModalReferencias,
        isOpenModalDireccionContactos,
        setIsOpenModalDireccionContactos,
        isOpenModalRegistroLlamada,
        setIsOpenModalRegistroLlamada,
        setDisableInteractions,
        setDirectorioValue,
        setCurrentDataSeguimiento,

        //Subir Documentos
        handleUploadFile,
        handleViewdocs,
        isOpenDocs,
        setIsOpenDocs,
        fileName,
        SelectedDocs,
        bloqueocita_entrega,

        //Logs
        columnsSeguimientoLog,
    } = UnidadesRecuperadasDetalleHook(state);

    const [bloqueoid, setBloqueoid] = useState(false);
    const [collpsableHistorial, setCollpsableHistorial] = useState(false);
    const [collpsableDirectorio, setCollpsableDirectorio] = useState(false);
    const [collpsableSeguimiento, setCollpsableSeguimiento] = useState(false);
    const [collpsableCita, setCollpsableCita] = useState(false);
    const [collpsableEntrega, setCollpsableEntrega] = useState(false);
    let promesasDisabled = (disableInteractions) && infoAvaliable.cont_aval !== "0"
    const bloqueoampliacion = state.ruta_item?.promesa.cont_ampliacion == 2;
    const bloqueobotoneshistorial = state.resultado == 'Promesa de auto definitiva';
    const handleBloqueoidChange = (nuevoValor) => {
        setBloqueoid(nuevoValor);
    };
    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            {
                loading &&
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            }
            {
                isOpenModalPromesaPagoData &&
                <DatosPromesaPagoModal
                    data={promesaPagoData}
                    isOpen={isOpenModalPromesaPagoData}
                    setIsOpen={setisOpenModalPromesaPagoData}

                />
            }
            {
                isOpenModalPromesaPagoDelete &&
                <EliminarPromesaPagoModal
                    item={promesaPagoData}
                    isOpen={isOpenModalPromesaPagoDelete}
                    setIsOpen={setisOpenModalPromesaPagoDelete}
                    state={state.ruta_item?.contrato}
                    toast={toast}
                    motivoEliminacion={motivoEliminacion}
                    GetData={handleGetPromesasdePago}
                />
            }
            {
                isOpenModalPromesaPagoAmpliacion &&
                <AmpliacionPromesasdePagoModal
                    item={promesaPagoData}
                    isOpen={isOpenModalPromesaPagoAmpliacion}
                    setIsOpen={setisOpenModalPromesaPagoAmpliacion}
                    state={state.ruta_item?.contrato}
                    promesa={state.ruta_item?.promesa}
                    toast={toast}
                    GetData={handleGetPromesasdePago}
                    infoContactoData={infoContactoData}
                />
            }
            {
                isOpenModalPromesaPagoIncumplimiento &&
                <IncumplimientoPromesaPagoModal
                    item={promesaPagoData}
                    isOpen={isOpenModalPromesaPagoIncumplimiento}
                    setIsOpen={setisOpenModalPromesaPagoIncumplimiento}
                    state={state.ruta_item?.contrato}
                    promesa={state.ruta_item?.promesa}
                    toast={toast}
                    GetData={handleGetPromesasdePago}
                />
            }
            {
                isOpenModalReferencias &&
                <RegistrarReferenciaModal
                    directorioValue={state.ruta_item?.contrato.tipo_persona}
                    setIsOpen={setisOpenModalReferencias}
                    isOpen={isOpenModalReferencias}
                    relacionSolicitante={relacionSolicitante}
                    dataEstado={dataEstado}
                    tipoContacto={tipoContacto}
                    subtipoContacto={subtipoContacto}
                    state={state.ruta_item?.contrato}
                    toast={toast}
                    GetDataContactos={handleGetInformacionContactos}
                    GetDataSeguimiento={handleContactosSeguimientoLog}
                />
            }
            {
                isOpenModalAgregarContacto &&
                <AgregarDatosContactoModal
                    setIsOpen={setisOpenModalAgregarContacto}
                    isOpen={isOpenModalAgregarContacto}
                    relacionSolicitante={relacionSolicitante}
                    dataEstado={dataEstado}
                    tipoContacto={tipoContacto}
                    subtipoContacto={subtipoContacto}
                    infoContactoData={infoContactoData}
                    state={state.ruta_item?.contrato}
                    toast={toast}
                    GetDataContactos={handleGetInformacionContactos}
                    GetDataSeguimiento={handleContactosSeguimientoLog}
                />
            }
            {
                isOpenModalDireccionContactos &&
                <DireccionesContactoModal
                    data={infoContactoData}
                    isOpen={isOpenModalDireccionContactos}
                    setIsOpen={setIsOpenModalDireccionContactos}

                />
            }
            {
                isOpenModalRegistroLlamada &&
                <RegistroLlamadaModal
                    setIsOpen={setIsOpenModalRegistroLlamada}
                    isOpen={isOpenModalRegistroLlamada}
                    motivosVisita={motivosVisita}
                    infoContactoData={infoContactoData}
                    user={userDetail}
                    state={state.ruta_item?.contrato}
                    toast={toast}
                    GetDataContactos={handleGetInformacionContactos}
                    GetDataPromesa={handleGetPromesasdePago}
                    GetDataSeguimiento={handleContactosSeguimientoLog}
                    directorioValue={directorioValue}
                    generadoEn={"Seguimiento Telefónico"}
                    currentDataSeguimiento={currentDataSeguimiento}
                    montoAdeudo={currentDataSeguimiento.monto_adeudo}
                    setDisableInteractions={setDisableInteractions}
                    setDirectorioValue={setDirectorioValue}
                    setCurrentDataSeguimiento={setCurrentDataSeguimiento}
                    infoAval={infoAvaliable?.cont_aval}
                />
            }
            {
                isOpenDocs &&
                <VisorFile
                    isOpen={isOpenDocs}
                    setIsOIpen={setIsOpenDocs}
                    fileName={fileName}
                    doc={SelectedDocs}
                />
            }
            <div style={{ background: "#F5F5F5", paddingBottom: "1rem" }}>
                <header className='heder-subtitle d-flex justify-content-start align-items-center border-bottom px-2' style={{ background: "#FFF" }}>
                    <div className='cursor-pointer' onClick={() => { handleReturnPage() }}>
                        <i className="bx bxs-chevron-left mx-2"></i>
                        Regresar a listado de unidades recuperadas
                    </div>
                </header>
                <div className="col col-11 d-flex">
                    <div className="col col-6 px-4 py-2">
                        <VehiculoInfoCard
                            VIN={state?.vin}
                            verClaves={true}
                            solicitarClave={true}
                        />
                    </div>
                    <div className="col col-6 px-4 py-2">
                        <ContratoCard
                            vin={state?.vin} />
                    </div>
                </div>
                <div className="col col-11 px-4 py-2">
                    <CarteraInfoCard
                        datos={currentDataSeguimiento}
                        user={userDetail}
                        promesasRotas={promesasRotas} />
                </div>
                <ValidatePermission isActive={ValidatePermissionById(4, Section.UnidadesRecuperadas_PromesadePago, SubModule.UnidadesRecuperadas)?.Ver}>
                    <div className="col-11 px-md-3">
                        <div className="p-4 white-wrapper mb-2">
                            <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseHistorial" role="button" aria-expanded="false" aria-controls="collapseHistorial" onClick={() => setCollpsableHistorial(!collpsableHistorial)}>
                                <div className="col-11">
                                    <h6><strong>Historial de promesas de pago</strong></h6>
                                </div>
                                <div className="col-1 col-md-1 collapsable-icon" style={{ textAlign: 'right' }}>
                                    <i className={!collpsableHistorial ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                </div>
                            </div>

                            <div className="collapse" id="collapseHistorial" >
                                <div className="separator"></div>
                                <div className="row mt-2 px-4">
                                    <p className="text-base mt-3"><strong>Registro de garantía actual</strong></p>
                                    <div style={{ overflow: 'auto' }}>
                                        <DataTable
                                            column={columnsGarantiaPromesas}
                                            data={dataGarantia}
                                            actionColumns
                                            shieldNegativeColumn
                                            handleShieldNegative={bloqueobotoneshistorial ? () => { } : handleModalIncumplimiento}
                                            calendarPlusColumn
                                            handleCalendarPlus={bloqueobotoneshistorial || bloqueoampliacion ? () => { } : handleModalAmpliacion}
                                        />
                                    </div>
                                    <p className="text-base"><strong>Historial de garantías</strong></p>
                                    <div style={{ overflow: 'auto' }}>
                                        <DataTable
                                            column={columnsHistorialPromesas}
                                            data={dataGarantiaHistorial}
                                        />
                                    </div>
                                    <p className="text-base"><strong>Detalle de promesas</strong></p>
                                    <div style={{ overflow: 'auto' }}>
                                        <DataTable
                                            column={columnsDetallePromesas}
                                            data={currentDataPromesas}
                                            detailable={true}
                                            handleDetail={handlePromesasPago}
                                            actionColumns
                                            deleteableColumn
                                            handleDelete={currentDataPromesas.some(promesa => promesa.promesa_status_nombre === 'Rota') ? () => { } : handlePromesasPagoDelete}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ValidatePermission>
                {!bloqueobotoneshistorial && (
                    <>
                        <ValidatePermission isActive={ValidatePermissionById(4, Section.UnidadesRecuperadas_DirectorioDeContactos, SubModule.UnidadesRecuperadas)?.Ver}>
                            <div className="col-11 px-md-3">
                                <div className="p-4 white-wrapper mb-2">

                                    <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseDirectorio" role="button" aria-expanded="false" aria-controls="collapseHistorial" onClick={() => setCollpsableDirectorio(!collpsableDirectorio)}>
                                        <div className="col-11">
                                            <h6><strong>Directorio de contactos</strong></h6>
                                        </div>
                                        <div className="col-1 col-md-1 collapsable-icon" style={{ textAlign: 'right' }}>
                                            <i className={!collpsableDirectorio ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                        </div>
                                    </div>
                                    <div className="collapse" id="collapseDirectorio" >
                                        <div className="separator"></div>
                                        <ul className="nav nav-tabs text-sm" id="myTab" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <button className={`seguimiento-telefonico-detalle-nav-link ${directorioValue == "Cliente" && "tab-active"}`} value={'Cliente'} type="button" role="tab" onClick={(e) => handleSetStateTab(e.target.value)} disabled={promesasDisabled}>Cliente</button>
                                            </li>
                                            {infoAvaliable.cont_aval !== "0" ?
                                                <li className="nav-item" role="presentation">
                                                    <button className={`seguimiento-telefonico-detalle-nav-link ${directorioValue == "Aval" && "tab-active"}`} value={'Aval'} type="button" role="tab" onClick={(e) => handleSetStateTab(e.target.value)} >Aval</button>
                                                </li>
                                                : null
                                            }
                                            {infoAvaliable.cont_coacreditado !== "0" ?
                                                <li className="nav-item" role="presentation">
                                                    <button className={`seguimiento-telefonico-detalle-nav-link  ${directorioValue == "Coacreditado" && "tab-active"}`} value={'Coacreditado'} type="button" role="tab" onClick={(e) => handleSetStateTab(e.target.value)} disabled={promesasDisabled} >Coacreditado</button>
                                                </li>
                                                : null
                                            }
                                            {infoAvaliable.cont_apoderado !== "0" ?
                                                <li className="nav-item" role="presentation">
                                                    <button className={`seguimiento-telefonico-detalle-nav-link  ${directorioValue == "Apoderado" && "tab-active"}`} value={'Apoderado'} type="button" role="tab" onClick={(e) => handleSetStateTab(e.target.value)} disabled={promesasDisabled} >Representante Legal</button>
                                                </li>
                                                : null
                                            }
                                            <li className="nav-item" role="presentation">
                                                <button className={`seguimiento-telefonico-detalle-nav-link  ${directorioValue == "Referencia" && "tab-active"}`} value={'Referencia'} type="button" role="tab" onClick={(e) => handleSetStateTab(e.target.value)} disabled={promesasDisabled} >Referencias</button>
                                            </li>
                                            <li className="d-flex align-items-center">
                                                {promesasRotas >= 3 && <div className="error"> <i className='bx bxs-error'> </i> Registro de llamada, inhabilitado</div>}
                                            </li>

                                        </ul>
                                        <div>
                                            <div className="d-flex align-items-center my-2 mt-3">
                                                <span className="mx-2"><strong>Datos de contacto</strong></span>
                                                {directorioValue === 'Referencia' && (
                                                    <button
                                                        className='btn btn-blue d-flex justify-content-center align-items-center'
                                                        style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                                        onClick={handleModalRef}
                                                    >
                                                        Agregar Referencias
                                                    </button>
                                                )}
                                            </div>
                                            <div style={{ overflow: 'auto' }}>
                                                <TablaAnidada
                                                    state={state?.ruta_item?.contrato}
                                                    data={infoContactosFiltered}
                                                    column={columnsInformacionContacto}
                                                    addColumns={3}
                                                    buttonText={"Agregar"}
                                                    handleClick={handleModalAdd}
                                                    moreDirections={true}
                                                    interacciones={true}
                                                    handleMoreDirections={handleDireccionesModal}
                                                    handleRegistro={handleRegistroLlamada}
                                                    childColumn={columnsSubInformacionContacto}
                                                    // childData={currentDataPromesas}
                                                    childCommentColumn={true}
                                                    childActionColumns={true}
                                                    childRegistroColumn={true}
                                                    registroColumnText="Registrar llamada"
                                                    setLoading={setLoading}
                                                >
                                                    <DataTable />
                                                </TablaAnidada>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ValidatePermission>
                        <ValidatePermission isActive={ValidatePermissionById(4, Section.UnidadesRecuperadas_SeguimientoContactos, SubModule.UnidadesRecuperadas)?.Ver}>
                            <div className="col-11 px-md-3">
                                <div className="p-4 white-wrapper mb-2">
                                    <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseSeguimiento" role="button" aria-expanded="false" aria-controls="collapseSeguimiento" onClick={() => setCollpsableSeguimiento(!collpsableSeguimiento)}>
                                        <div className="col-11">
                                            <h6><strong>Seguimiento telefónico</strong></h6>
                                        </div>
                                        <div className="col-1 col-md-1 collapsable-icon" style={{ textAlign: 'right' }}>
                                            <i className={!collpsableSeguimiento ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                        </div>
                                    </div>
                                    <div className="collapse" id="collapseSeguimiento" >
                                        <div className="separator"></div>
                                        <div style={{ overflow: 'auto' }}>
                                            <DataTable
                                                column={columnsSeguimientoLog}
                                                data={currentDataSeguimientoLog}
                                                handleDocuments={handleViewdocs}
                                                handleAction={(value, idx) => { handleUploadFile(value, idx) }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ValidatePermission>
                        {bloqueocita_entrega && (
                            <>
                                <ValidatePermission isActive={ValidatePermissionById(4, Section.UnidadesRecuperadas_CitaEntregaUnidad, SubModule.UnidadesRecuperadas)?.Ver}>
                                    <div className="col-11 px-md-3">
                                        <div className="p-4 white-wrapper mb-2">
                                            <div
                                                className="row mt-2 px-4"
                                                data-bs-toggle="collapse"
                                                href="#collapseCita"
                                                role="button"
                                                aria-expanded="false"
                                                aria-controls="collapseCita"
                                                onClick={() => setCollpsableCita(!collpsableCita)}
                                            >
                                                <div className="col-11">
                                                    <h6>
                                                        <strong>Cita entrega de unidad</strong>
                                                    </h6>
                                                </div>
                                                <div
                                                    className="col-1 col-md-1 collapsable-icon"
                                                    style={{ textAlign: "right" }}
                                                >
                                                    <i
                                                        className={
                                                            !collpsableCita
                                                                ? "icon-blue bx bx-chevron-down x2"
                                                                : "icon-blue bx bx-chevron-up x2"
                                                        }
                                                    ></i>
                                                </div>
                                            </div>
                                            <div className="collapse" id="collapseCita">
                                                <div className="separator"></div>
                                                <div className="p-4">
                                                    <CitaEntregaUnidad
                                                        dataEstado={dataEstado}
                                                        toast={toast}
                                                        state={state}
                                                        vehiculo={state?.ruta_item?.contrato.vehiculo}
                                                        onBloqueoidChange={handleBloqueoidChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ValidatePermission>
                                <ValidatePermission isActive={ValidatePermissionById(4, Section.UnidadesRecuperadas_EntregaUnidad, SubModule.UnidadesRecuperadas)?.Ver}>

                                    {bloqueoid && (
                                        <div className="col-11 px-md-3">
                                            <div className="p-4 white-wrapper mb-2">
                                                <div
                                                    className="row mt-2 px-4"
                                                    data-bs-toggle="collapse"
                                                    href="#collapseEntrega"
                                                    role="button"
                                                    aria-expanded="false"
                                                    aria-controls="collapseEntrega"
                                                    onClick={() => setCollpsableEntrega(!collpsableEntrega)}
                                                >
                                                    <div className="col-11">
                                                        <h6>
                                                            <strong>Entrega de la unidad</strong>
                                                        </h6>
                                                    </div>
                                                    <div
                                                        className="col-1 col-md-1 collapsable-icon"
                                                        style={{ textAlign: "right" }}
                                                    >
                                                        <i
                                                            className={
                                                                !collpsableEntrega
                                                                    ? "icon-blue bx bx-chevron-down x2"
                                                                    : "icon-blue bx bx-chevron-up x2"
                                                            }
                                                        ></i>
                                                    </div>
                                                </div>
                                                <div className="collapse" id="collapseEntrega">
                                                    <div className="separator"></div>
                                                    <div className="p-4">
                                                        <EntregaUnidad state={state} toast={toast} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </ValidatePermission>
                            </>
                        )}
                    </>)}
            </div>
        </>
    )
}

export default UnidadesRecuperadasDetalle