import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setDatosRuta } from '../../Redux/Slice/Cobranza/SeguimientoVisita/DetalleRutaSlice';
import CobranzaService from '../../Services/Cobranza/Cobranza';
import { formatearMontoMx, FormatFecha, FormatFechaSplit, FormatFolioNumber } from '../../Utils/functions';
import { useLocalStorage } from '../Cobranza/useLocalStorage';

const useRutasCobranza = () => {
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const [data, setData] = useState([])
   const { setItem } = useLocalStorage('datos_nueva_ruta')
   const { role } = useSelector((state) => state.loginSlice);
   const [loadingcobradores, setLoadingCobradores] = useState(false)
   const userId = sessionStorage.getItem('userId').trim()

   const columns = [
      { field: 'ruta', headerName: 'Ruta', width: 300 },
      { field: 'cobrador', headerName: 'Cobrador', width: 350 },
      { field: 'contratos', headerName: 'Cantidad Contratos', width: 200 },
      { field: 'fechavisita', headerName: 'Fecha de la visita', width: 250 }
   ];

   const columnsDetail = [
      { field: 'numero', headerName: '#' },
      { field: 'contrato_id', headerName: 'Contrato' },
      { field: 'tipo_persona', headerName: 'Tipo de Persona' },
      { field: 'nombre', headerName: 'Nombre' },
      { field: 'tipo_visita', headerName: 'Tipo de Visita' },
      { field: 'dias_vencido', headerName: 'Días vencidos' },
      { field: 'monto_adeudo', headerName: 'Monto vencido' },
      { field: 'fecha_visita', headerName: 'Fecha de visita' },
      { field: 'estatus', headerName: 'Estatus' },
   ];

   const [page, setPage] = useState(1);
   const [str, setStr] = useState("");
   const [loading, setLoading] = useState(true);
   const [pageCount, setPageCount] = useState();
   const [pageRows, setpageRows] = useState(10);
   const [openModalRuta, setOpenModalRuta] = useState(false);
   const [currentData, setCurrentData] = useState(data)
   const [filterFechaInicio, setFilterFechaInicio] = useState("")
   const [filterFechaFin, setFilterFechaFin] = useState("")
   const [listaCobradores, setListaCobradores] = useState([])
   const [datazonas, setZonas] = useState([])

   useEffect(() => {
      let filteredData = data.filter(item => {
         let fechaFiltrada = true
         const nameMatches = str === '' || (item.ruta && item.ruta.toLowerCase().includes(str.toLowerCase()));
         if (filterFechaInicio !== "" && filterFechaFin !== "") {
            const FechaVencimiento = new Date(filterFechaFin)
            const FechaInicio = new Date(filterFechaInicio)
            const fechaItem = new Date(item.fecha_inicio)
            fechaFiltrada = fechaItem >= FechaInicio && fechaItem <= FechaVencimiento
         }
         if (filterFechaInicio !== "" && filterFechaFin !== "" && str !== "") {
            return (nameMatches || fechaFiltrada)
         }
         else if (filterFechaInicio !== "" && filterFechaFin !== "") {
            return (fechaFiltrada)
         }
         else {
            return (nameMatches)
         }
      })

      const indexOfLastItem = page * pageRows
      const indexOfFirstItem = indexOfLastItem - pageRows
      const newData = filteredData.slice(indexOfFirstItem, indexOfLastItem)
      setCurrentData(newData)

      const newTotalPages = Math.ceil(filteredData.length / pageRows)
      setPageCount(newTotalPages)
   }, [page, str, pageRows, data, filterFechaInicio, filterFechaFin])

   const handlePageClick = (event) => {
      setPage(event.selected + 1);
      // handleGetAllCobradores(event.selected + 1);
   };

   const handleCrearRuta = () => {
      setOpenModalRuta(true);
   }

   useEffect(() => {
      sessionStorage.setItem('contratos_asignados', JSON.stringify([]));
      setItem("")
      getAllCobradores();
      handleGetAllUsersNotInCobranza()
   }, []);

   useEffect(() => {
      handleGetRutas();
   }, [listaCobradores])

   const handleGetRutas = async () => {
      let NewData = [];
      let rutaHeadId = ''
      let eventoHeadId = ''
      let eventoItemId = ''
      setLoading(true);
      await CobranzaService.GetAllRutasCobranza()
         .then(res => {
            res.data.responseData?.map((item) => {
               let DetailData = [];
               let counter = 0;
               let nombrecobrador = ""
               let counterDetalle = 0;
               let cierre = true;

               let lista = []
               if (role === "DMCobranza Nivel 2") {
                  if (item?.userid.toLowerCase() === userId.toLowerCase()) {
                     lista = item?.items
                  }
               }
               if (role === "DMSupervisordeCobranza" || role === "Administrador") {
                  lista = item?.items
               }

               listaCobradores?.map(c => {
                  if (c.userId.toLowerCase() === item.userid.toLowerCase()) {
                     nombrecobrador = c?.nombrecobrador
                  }
               })

               lista?.sort((one, two) => (one.fecha < two.fecha ? -1 : 1))?.map((element) => {
                  if (element?.contrato !== null && element?.contrato?.cartera_vencida === 1 && element?.contrato?.nivel_cobranza === "2") {
                     if (!element.cierre_visita) {
                        cierre = false;
                     }
                     counter++;
                     counterDetalle++;
                     const montoAdeudo = formatearMontoMx(element?.contrato?.monto_adeudo);

                     const fechaUltimoPago = FormatFecha(element?.contrato?.fecha_ultimo_pago)
                     const fechaUltimaClave = FormatFecha(element?.contrato?.vigencia_clave)
                     const fechaUltimoCalculo = FormatFecha(element?.contrato?.fecha_ultimo_calculo)
                     const montoInsoluto = formatearMontoMx(element?.contrato?.saldo_credito)
                     rutaHeadId = element?.ruta_head_id
                     eventoHeadId = element?.evento_head_id
                     eventoItemId = element?.evento_item_id


                     let cont = {
                        ...element?.contrato,
                        fecha_ultimo_pago: fechaUltimoPago,
                        fecha_ultima_clave: fechaUltimaClave,
                        fecha_ultimo_calculo: fechaUltimoCalculo,
                        saldo_credito: montoInsoluto,
                        monto_adeudo: montoAdeudo
                     }

                     DetailData.push({
                        //numero: FormatFolioNumber(item?.folio,9),
                        // numero: item?.folio_big,
                        id: element?.id,
                        item: cont,
                        numero: counterDetalle,
                        tipo_visita: element?.tipo_visita,
                        fecha_visita: FormatFecha(element?.fecha),
                        contrato_id: element.contrato === null ? "" : element?.contrato?.contrato_id,
                        tipo_persona: element.contrato === null ? "" : Boolean(element?.es_aval) ? "Aval" : "Física",
                        nombre: element.contrato === null ? "" : `${element?.contrato?.nombre} ${element?.contrato?.apellido_paterno} ${element?.contrato?.apellido_materno}`,
                        // nombre: element.contrato === null ? "" : (Boolean(element?.es_aval) ? `${element?.contrato?.nombre_aval} ${element?.contrato?.apellido_paterno_aval} ${element?.contrato?.apellido_materno_aval}` : `${element?.contrato?.nombre} ${element?.contrato?.apellido_paterno} ${element?.contrato?.apellido_materno}`),
                        direccion: element.contrato === null ? "" : `Calle ${element?.contrato?.calle} ${element?.contrato?.no_ext}, Col.${element?.contrato?.colonia}, C/.P. ${element?.contrato?.cp}`,
                        // direccion: element.contrato === null ? "" : (Boolean(element?.es_aval) ? `Calle ${element?.contrato?.calle_aval} ${element?.contrato?.no_ext_aval}, Col.${element?.contrato?.colonia_aval}, C/.P. ${element?.contrato?.cp_aval}` : `Calle ${element?.contrato?.calle} ${element?.contrato?.no_ext}, Col.${element?.contrato?.colonia}, C/.P. ${element?.contrato?.cp}`),
                        monto_adeudo: montoAdeudo,
                        estatus: element?.cierre_visita ? "Concluido" : element?.estatus === null ? "" : element?.estatus,
                        municipio: element.contrato === null ? "" : element?.contrato?.municipio,
                        // municipio: element.contrato === null ? "" :(Boolean(element?.es_aval) ? element?.contrato?.municipio_aval : element?.contrato?.municipio),
                        dias_vencido: element.contrato === null ? "" : element?.contrato?.dias_vencido,
                        zona: element.contrato === null ? "" : element?.contrato?.zona,
                        zonaid: element.contrato === null ? "" : element?.contrato?.zonaid,
                        cierre_visita: element?.cierre_visita || element?.estatus === "Proceso" || element?.estatus === "Concluido" ? true : false,
                        evento_head_id: element?.evento_head_id,
                        evento_item_id: element?.evento_item_id,
                        fecha_fin: element?.fecha_fin,
                        fecha_inicio: element?.fecha_inicio,
                        ruta_head_id: element?.ruta_head_id,
                        fecha: element?.fecha,
                        userid: element?.userid,
                        resultado: element?.resultado
                     })
                  }
               })
               if (!cierre) {
                  NewData.push({
                     //ruta: item?.folio === null ? "" : FormatFolioNumber(item?.folio,9),
                     id: item?.id,
                     ruta: item?.folio_big,
                     contratos: counter,
                     fechainicio: item?.fecha_inicio === null ? "" : FormatFecha(item?.fecha_inicio),
                     fecha_inicio: item?.fecha_inicio === null ? "" : item?.fecha_inicio,
                     fechafin: item?.fecha_fin === null ? "" : FormatFecha(item?.fecha_fin),
                     fecha_fin: item?.fecha_fin === null ? "" : item?.fecha_fin,
                     fechavisita: item?.fecha_inicio === null ? "" : FormatFecha(item?.fecha_inicio),
                     userId: item?.userid === null ? "" : item?.userid,
                     detail: DetailData,
                     cobrador: nombrecobrador,
                     ruta_head_id: rutaHeadId,
                     evento_head_id: eventoHeadId,
                     evento_item_id: eventoItemId
                  })
                  counterDetalle = 0;
               }
            })
         })
         .catch(e => {
            setLoading(false);
            console.log(`ERROR -- ${e}`)
         })

      let arregloOrdenado = NewData.sort(function (a, b) {
         if (a.fecha_inicio > b.fecha_inicio) {
            return 1;
         }
         if (a.fecha_inicio < b.fecha_inicio) {
            return -1;
         }
         return 0;
      });
      setData(arregloOrdenado.reverse())
      setLoading(false);
      // setData(NewData);
   }

   const handleGetAllUsersNotInCobranza = async () => {
      setLoading(true)
      let dataZonas = [];
      await CobranzaService.getAllEjecutivosWithFullDetail()
         .then(res => {
            if (res?.data?.callSucceded) {
               res?.data?.responseData?.map(item => {
                  if (item?.nivel_cobrador === "2") {
                     let NewDataZonas = []
                     let nombreZona = new Set();
                     let idZona = new Set();
                     let counter = 0
                     item?.contratos?.map(element => {
                        if (element?.zona !== null) {
                           nombreZona.add(element?.zona)
                           idZona.add(element?.zonaid)
                        }
                     })
                     let listaZonas = Array.from(idZona)
                     for (const value of nombreZona) {
                        NewDataZonas.push({
                           value: listaZonas[counter],
                           label: value
                        })
                        counter++
                     }
                     dataZonas.push({ userid: item?.userid, zonas: NewDataZonas });
                  }
               })
            }
         })
         .catch(e => {
            setLoading(false)
            console.log(`ERROR -- ${e}`)
         })
      setZonas(dataZonas)
      setLoading(false)
   }

   const getAllCobradores = async () => {
      setLoadingCobradores(true);
      let NewData = []
      await CobranzaService.getEjecutivosFullDetail()
         .then(res => {
            if (res.data.callSucceded) {
               res.data.responseData?.map(item => {
                  NewData.push({
                     userId: item?.userid,
                     nombrecobrador: item?.user_detail?.names
                  })
               })
            }
            setLoadingCobradores(false);
         })
         .catch(e => {
            setLoadingCobradores(false);
            console.log(`ERROR -- ${e}`)
         })
      setListaCobradores(NewData)
   }

   const handleDetails = (item) => {
      let cobrador = ""
      listaCobradores.map(c => {
         if (item?.userId?.toLowerCase() === c?.userId?.toLowerCase()) {
            cobrador = c.nombrecobrador
         }
      })


      let arrayZonas = []
      datazonas.map(registro => {
         if (item?.userId.toLowerCase() === registro?.userid.toLowerCase()) {
            arrayZonas = registro.zonas;
         }
      })

      dispatch(setDatosRuta({
         id: item?.id,
         item: item,
         ruta: item.ruta,
         cobrador_id: item?.userId,
         nombrecobrador: cobrador,
         fechainicio: FormatFechaSplit(item?.fechainicio),
         fechafin: FormatFechaSplit(item?.fechafin),
         contratos: item?.contratos,
         items: item?.detail,
         zonas: arrayZonas,
         editar: (role === "DMSupervisordeCobranza" || role === "Administrador") ? true : false,
         registro_nuevo: false
      })
      );

      navigate("/cobranza/seguimiento-visita/rutas-cobranza/ruta-detalle", {
         state: {
            page: 'Detalle',
            title: "Detalle de ruta",
            puedeEditar: false,
            tipoUsuario: 0
         },
      });
   }

   return {
      columns,
      columnsDetail,
      currentData,
      pageCount,
      setStr,
      loading,
      handlePageClick,
      handleDetails,
      handleCrearRuta,
      page,
      setpageRows,
      openModalRuta,
      setOpenModalRuta,
      setFilterFechaInicio,
      setFilterFechaFin,
      loadingcobradores
   }
}

export default useRutasCobranza