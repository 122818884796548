import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-hot-toast';
import axios from 'axios';
import { FormatFecha, message, FormatPhoneNumber, formatearMontoMx } from "../../Utils/functions";
import AuthLogin from "../../Utils/AuthLogin";
import CobranzaService from "../../Services/Cobranza/Cobranza";
import UnidadesRecuperadasService from "../../Services/Cartera/UnidadesRecuperadas";
import Usuario from "../../Services/Users/Usuarios";
import Llamada from "../../Services/Cartera/Llamadas";
import dataDocsServices from "../../Services/DataDocs/dataDocsServices";
import { useDispatch, useSelector } from "react-redux";
import { selectContratoCard, clearDataContrato } from '../../Redux/Slice/Contrato/ContratoCardSlice';

const UnidadesRecuperadasDetalleHook = (state) => {
    const auth = new AuthLogin();
    let url = auth.UrlDataDocs();
    const contratoInfo = useSelector((state) => selectContratoCard(state));
    // const politicaAval = Boolean(state?.ruta_item?.contrato?.politica_aval) || false
    const bloqueocita_entrega = state.ruta_item.promesa.monto_recuperado >= state.ruta_item.promesa.monto_vencido;
    const columnsDetallePromesas = [
        { field: 'folio_promesa', headerName: 'Folio Promesa' },
        { field: 'cobrador_nombre', headerName: 'Cobrador' },
        { field: 'periodo', headerName: 'Periodo' },
        { field: 'motivo_promesa_nombre', headerName: 'Motivo Promesa' },
        { field: 'fecha_creacion', headerName: 'Fecha Registro Promesa' },
        { field: 'montoVencidoFormat', headerName: 'Monto Vencido' },
        { field: 'montoPromesaFormat', headerName: 'Monto Promesa' },
        { field: 'montoRecuperadoFormat', headerName: 'Monto Recuperado' },
        { field: 'fecha_compromiso', headerName: 'Fecha Compromiso' },
        { field: 'promesa_status', headerName: 'Estatus' },
        { field: 'vigencia', headerName: 'Vigencia' },
    ];

    const columnsGarantiaPromesas = [
        { field: 'folio_big', headerName: 'Folio Promesa' },
        { field: 'newcobrador', headerName: 'Cobrador' },
        { field: 'montoVencidoFormat', headerName: 'Monto Vencido' },
        { field: 'montoPrometidoFormat', headerName: 'Monto Promesa' },
        { field: 'montoRecuperado', headerName: 'Monto Recuperado' },
        { field: 'fechaCompromisoFormat', headerName: 'Fecha Compromiso' },
        { field: 'vigencia', headerName: 'Días Vigencia' },
    ];

    const columnsHistorialPromesas = [
        { field: 'folio_big', headerName: 'Folio Promesa' },
        { field: 'cobradores', headerName: 'Cobrador' },
        { field: 'montoVencidoFormat', headerName: 'Monto Vencido' },
        { field: 'montoPrometidoFormat', headerName: 'Monto Promesa' },
        { field: 'montoRecuperadoFormat', headerName: 'Monto Recuperado' },
        { field: 'fechaCompromisoFormat', headerName: 'Fecha Compromiso' },
        { field: 'fechaFinFormat', headerName: 'Fecha de Cumplimiento' },
    ];

    const columnsInformacionContacto = [
        { field: 'relacion_solicitante', headerName: 'Relación con solicitante' },
        { field: 'nombre_ref', headerName: 'Nombre' },
        { field: 'apellido_paterno_ref', headerName: 'Apellido' },
        { field: 'domicilio_ref', headerName: 'Domicilio Principal' },
        // { field: 'interacciones', headerName: 'Interacciones' },
        // { field: 'operacion_resultado_id_nombre', headerName: 'Última Interacción' }
    ];

    const columnsSubInformacionContacto = [
        { field: 'tipo_contacto', headerName: 'Tipo de Contacto' },
        { field: 'subtipo_contacto', headerName: 'Subtipo' },
        { field: 'dato', headerName: 'Valor' },
        { field: 'fecha', headerName: 'Ultima interaccion' },
        { field: 'interacciones', headerName: 'Interacciones' },
        { field: 'validacion', headerName: 'Exitoso' },
        { field: 'estatus', headerName: 'Estatus' },
        { field: 'comentariosUI', headerName: 'Comentarios' },
    ]

    const columnsSeguimientoLog = [
        { field: 'fecha', headerName: 'Fecha' },
        { field: 'cobrador', headerName: 'Cobrador' },
        { field: 'contacto', headerName: 'Contacto' },
        { field: 'nombre', headerName: 'Nombre' },
        { field: 'valor', headerName: 'Valor' },
        { field: 'operacion_tipo_nombre', headerName: 'Operacion' },
        { field: 'operacion_resultado_id_nombre', headerName: 'Resultado' },
        { field: 'aplica_visita_nombre', headerName: 'Aplica visita' },
        { field: 'motivo_visita_nombre', headerName: 'Motivo visita' },
        { field: 'comentariosUI', headerName: 'Comentarios' },
        { field: 'acciones', headerName: 'Ver evidencia' },

    ];

    const [currentDataSeguimiento, setCurrentDataSeguimiento] = useState([]);
    const [userDetail, setUserDetail] = useState([]);
    const [loading, setLoading] = useState(true);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });
    const navigate = useNavigate();

    const [disableInteractions, setDisableInteractions] = useState(false)

    // Promesas de pago
    const [currentDataPromesas, setCurrentDataPromesas] = useState([])
    const [dataGarantia, setDataGarantia] = useState([])
    const [dataGarantiaHistorial, setDataGarantiaHistorial] = useState([])
    const [promesasRotas, setPromesasRotas] = useState(0);
    const [promesaPagoData, setPromesaPagoData] = useState([])
    const [isOpenModalPromesaPagoData, setisOpenModalPromesaPagoData] = useState(false)
    const [isOpenModalPromesaPagoDelete, setisOpenModalPromesaPagoDelete] = useState(false)
    const [isOpenModalPromesaPagoIncumplimiento, setisOpenModalPromesaPagoIncumplimiento] = useState(false)
    const [isOpenModalPromesaPagoAmpliacion, setisOpenModalPromesaPagoAmpliacion] = useState(false)
    const [motivoEliminacion, setMotivoEliminacion] = useState([])

    //Directorio de contactos
    const [directorioValue, setDirectorioValue] = useState("Cliente")
    const [isOpenModalReferencias, setisOpenModalReferencias] = useState(false)
    const [isOpenModalAgregarContacto, setisOpenModalAgregarContacto] = useState(false)
    const [isOpenModalDireccionContactos, setIsOpenModalDireccionContactos] = useState(false)
    const [isOpenModalRegistroLlamada, setIsOpenModalRegistroLlamada] = useState(false)
    const [currentDataSeguimientoLog, setCurrentDataSeguimientoLog] = useState([])
    const [dataEstado, setDataEstado] = useState([]);
    const [tipoContacto, setTipoContacto] = useState([]);
    const [subtipoContacto, setSubtipoContacto] = useState([]);
    const [infoContactoData, setInfoContactoData] = useState()
    const [infoContactos, setInfoContactos] = useState([])
    const [relacionSolicitante, setRelacionSolicitante] = useState([])
    const [infoContactosFiltered, setInfoContactosFiltered] = useState([])
    const [motivosVisita, setMotivosVisita] = useState([])
    const [tipoResultado, setTipoResultado] = useState([])
    const [infoAvaliable, setInfoAvaliable] = useState({
        cont_apoderado: 0,
        cont_referencia: 0,
        cont_coacreditado: 0,
        cont_aval: 0
    })

    //Subir Documentos
    const [isOpenDocs, setIsOpenDocs] = useState(false)
    const [fileName, setFileName] = useState("")
    const [SelectedDocs, setSelectedDocs] = useState([]);

    useEffect(() => {
        getAllData()
    }, [])

    const getAllData = async () => {
        setLoading(true)
        try {
            await handleGetSeguimientoContrato();
            // Promesas de Pago
            await handleFormatState()
            await handleGetPromesasdePago()
            await handleMotivoEliminacion()
            await promesastop5async()
            //Directorio de contactos
            await handleGetAllRelacionSolicitante()
            await handleGetEstadoMx()
            await handleGetTipoContacto()
            await handleGetSubtiposContacto()
            await handleGetInformacionContactos()

            //Seguimiento de contactos 
            await handleContactosSeguimientoLog()

            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    const promesastop5async = async () => {
        const req = {
            contrato_id: state.ruta_item?.contrato.contrato_id
        };
        let promesasRotas = 0
        const today = new Date()
        try {
            const response = await CobranzaService.GetTop5xContrato(req);
            const promesasUpdate = response.data;
            let colorFondo
            let colorTexto
            if (promesasUpdate) {
                promesasUpdate?.forEach((promesa) => {


                    switch (promesa.promesa_status_nombre) {
                        case "Cumplida":
                            colorFondo = "#D8F2CD"
                            colorTexto = "#47A066"
                            break;
                        case "Rota":
                            colorFondo = "#F5CECE"
                            colorTexto = "#CE2222"
                            break;
                        case "Vigente":
                            const fechaPromesa = new Date(promesa.fecha_compromiso);

                            if (fechaPromesa < today) {
                                promesa.promesa_status_nombre = "Rota";
                                colorFondo = "#F5CECE";
                                colorTexto = "#CE2222";
                            } else {
                                promesa.promesa_status_nombre = "Vigente";
                                colorFondo = "#FAE7BC";
                                colorTexto = "#B46B16";
                            }
                            break;
                        default:
                            break;
                    }

                    if (promesa.promesa_status_nombre === 'Rota') {
                        promesasRotas++
                    } else if (
                        promesa.promesa_status_nombre === "Vigente" &&
                        promesa.fecha_promesa < today
                    ) {
                        promesasRotas++

                    }
                })
            }

            const formattedData = promesasUpdate.map((promesa) => ({
                ...promesa,
                fecha_compromiso: promesa.fecha_compromiso
                    ? FormatFecha(promesa.fecha_compromiso)
                    : "-",
                fecha_creacion: promesa.fecha_creacion
                    ? FormatFecha(promesa.fecha_creacion)
                    : "-",
                montoVencidoFormat: `$${promesa.monto_vencido}`,
                montoPromesaFormat: `$${promesa.monto_promesa}`,
                montoRecuperadoFormat: `$${promesa.monto_recuperado}`,
                vigencia: promesa.vigencia
                    ? FormatFecha(promesa.vigencia)
                    : "-",
                promesa_status: promesa.promesa_status_nombre,
                colorFondo: colorFondo,
                colorTexto: colorTexto
            }));

            setCurrentDataPromesas(formattedData);
        } catch (error) {
            console.error("Error al obtener el top 5:", error);
        }
    };
    const handleFormatState = async () => {
        let newPromesa = state.ruta_item?.promesa
        
        let montoVencidoFormat = `$${newPromesa.monto_vencido}`
        let montoPrometidoFormat
        let montoRecuperadoFormat
        let vigenciaDiasFormat
        let fechaCompromisoFormat
        if (newPromesa?.tipo_promesa === "Promesa de entrega definitiva") {
            montoPrometidoFormat = `-`
            fechaCompromisoFormat = '-'
            montoRecuperadoFormat = '-'
            vigenciaDiasFormat = "-"
        }
        else {
            montoVencidoFormat = `$${newPromesa.monto_vencido}`
            montoPrometidoFormat = state.montoVencido
            montoRecuperadoFormat = state.montoRecuperado
            fechaCompromisoFormat = newPromesa.fecha_vencimiento
                ? FormatFecha(newPromesa.fecha_vencimiento)
                : "-";
            // const today = new Date()
            const ayer = new Date()
            ayer.setDate(ayer.getDate() - 1)
            let fechaVencimiento = "-"
            let vigenciaDias = 0

            fechaVencimiento = FormatFecha(newPromesa.fecha_vencimiento)
            const fechaVencimientoDay = new Date(newPromesa.fecha_vencimiento)
            const diferenciaDias = fechaVencimientoDay - ayer
            vigenciaDias = diferenciaDias / (1000 * 60 * 60 * 24)
            vigenciaDiasFormat = vigenciaDias >= 0 ? Math.ceil(vigenciaDias) : 0
        }

        setDataGarantia([{
            ...newPromesa,
            montoVencidoFormat: montoVencidoFormat,
            montoPrometidoFormat: montoPrometidoFormat,
            fechaCompromisoFormat: fechaCompromisoFormat,
            vigencia: vigenciaDiasFormat,
            newcobrador: state.ruta_item.contrato.names.names,
            // montoRecuperado: state.montoRecuperado
            montoRecuperado: montoRecuperadoFormat


        }])
        const data = {
            evento_item_id: state.ruta_item?.evento_item_id,
            id: state.ruta_item?.id
        };
       const res = await UnidadesRecuperadasService.getHistorialGarantias(data);
            const historialgarantias=res.data;          
            const formattedhistorial = historialgarantias.map((garantia) => ({
                ...garantia,
                fechaCompromisoFormat: garantia.fecha_compromiso
                    ? FormatFecha(garantia.fecha_compromiso)
                    : "-",
                fechaFinFormat: garantia.fecha_fin
                    ? FormatFecha(garantia.fecha_fin)
                    : "-",
                montoVencidoFormat: `$${garantia.monto_vencido || 0}`,
                montoPrometidoFormat: `$${garantia.monto_prometido}`,
                montoRecuperadoFormat: `$${garantia.monto_recuperado}`,
                vigencia: garantia.vigencia
                    ? FormatFecha(garantia.vigencia)
                    : "-",
                promesa_status: garantia.promesa_status_nombre || "-"
            }));
            setDataGarantiaHistorial(formattedhistorial);
    }


    const handleGetSeguimientoContrato = async () => {

        let contrato = state.ruta_item.contrato

        const fechaClave = (state.ruta_item.contrato.vigencia_clave === "1990-01-01T00:00:00" || state.ruta_item.contrato.vigencia_clave === "1900-01-01T00:00:00") ? "-" : FormatFecha(state.ruta_item.contrato.vigencia_clave)
        const montoAdeudo = formatearMontoMx(state.ruta_item.contrato.monto_adeudo);
        contrato.monto_adeudo = montoAdeudo
        contrato.vigencia_clave = fechaClave

        setCurrentDataSeguimiento(contrato)
        await Usuario.getUsuarioById(state.ruta_item.userid)
            .then(res => {
                const user = res.data.data
                user.names = res.data.data.nombreUsuario
                setUserDetail(user)
            }).catch(e => {
                setLoading(false);
                if (e.response !== undefined)
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            })
    }

    const handleReturnPage = () => {
        navigate(`/cobranza/unidades-recuperadas`);
    }

    const handleGetPromesasdePago = async () => {
        const dataQuery = {
            contrato_id: state.ruta_item?.contrato.contrato_id,
            periodo: state.ruta_item?.contrato.periodo
        }
        await CobranzaService.getAllPromesa_de_PagoxPeriodo(dataQuery)
            .then(res => {
                let promesasRotas = 0
                const today = new Date()
                const ayer = new Date()
                ayer.setDate(ayer.getDate() - 1)
                const update = res.data.map(item => {

                    let colorFondo
                    let colorTexto

                    switch (item.promesa_status_nombre) {
                        case "Cumplida":
                            colorFondo = "#D8F2CD"
                            colorTexto = "#47A066"
                            break;
                        case "Rota":
                            colorFondo = "#F5CECE"
                            colorTexto = "#CE2222"
                            break;
                        case "Vigente":
                            const fechaPromesa = new Date(item.fecha_compromiso);

                            if (fechaPromesa < today) {
                                item.promesa_status_nombre = "Rota";
                                colorFondo = "#F5CECE";
                                colorTexto = "#CE2222";
                            } else {
                                item.promesa_status_nombre = "Vigente";
                                colorFondo = "#FAE7BC";
                                colorTexto = "#B46B16";
                            }
                            break;
                        default:
                            break;
                    }

                    const fechaPromesa = FormatFecha(item.fecha_promesa)
                    const montoVencidoFormat = formatearMontoMx(item.monto_vencido)
                    const montoPromesaFormat = formatearMontoMx(item.monto_promesa)
                    const montoRecuperadoFormat = formatearMontoMx(item.monto_recuperado)
                    const fechaCreacionFormat = FormatFecha(item.fecha_creacion)
                    const fechaCompromisoFormat = FormatFecha(item.fecha_vencimiento)
                    const vigenciaFormat = FormatFecha(item.vigencia)

                    //Dias Vencidos
                    const nuevaFecha = new Date(item.fecha_promesa)
                    const diference = today - nuevaFecha
                    const daysDifference = Math.floor(diference / (1000 * 60 * 60 * 24));
                    let dias_vencidos
                    if (daysDifference > 0) {
                        dias_vencidos = daysDifference
                    } else {
                        dias_vencidos = "-"
                    }

                    //dias de Vigencia
                    let vigenciaDias = 0
                    if (item.vigencia && item.promesa_status_nombre === "Vigente") {
                        const diferenciaDias = new Date(item.vigencia) - (ayer)
                        vigenciaDias = diferenciaDias / (1000 * 60 * 60 * 24)
                    }


                    let ultimaActualizacion
                    if (item.fecha_ultima_actualizacion) {
                        ultimaActualizacion = FormatFecha(item.fecha_ultima_actualizacion)
                    } else {
                        ultimaActualizacion = "-"
                    }


                    return {
                        ...item,
                        promesa_status: item.promesa_status_nombre,
                        colorFondo: colorFondo,
                        colorTexto: colorTexto,
                        fecha_promesa: fechaPromesa,
                        montoVencidoFormat: montoVencidoFormat,
                        montoPromesaFormat: montoPromesaFormat,
                        montoRecuperadoFormat: montoRecuperadoFormat,
                        ultimaActualizacion: ultimaActualizacion,
                        dias_vencidos: dias_vencidos,
                        fecha_creacion: fechaCreacionFormat,
                        vigencia: vigenciaFormat,
                        fecha_compromiso: fechaCompromisoFormat,
                        dias_de_promesa: vigenciaDias >= 0 ? Math.ceil(vigenciaDias) : 0,
                    }
                })

                const promesasUpdate = update.filter(promesa => promesa.contrato_id === state.ruta_item?.contrato.contrato_id) || [{}]
                if (promesasUpdate) {
                    promesasUpdate?.forEach((promesa) => {
                        if (promesa.promesa_status_nombre === 'Rota') {
                            promesasRotas++
                        } else if (
                            promesa.promesa_status_nombre === "Vigente" &&
                            promesa.fecha_promesa < today
                        ) {
                            promesasRotas++

                        }
                    })
                }

                if (promesasRotas >= 3) {
                    actualizaVisitabyContrato(state.ruta_item?.contrato.contrato_id)
                }

                setPromesasRotas(promesasRotas)


            }).catch(e => {
                setLoading(false);
                if (e.response !== undefined)
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            })
    }

    const handlePromesasPago = (item) => {
        setPromesaPagoData(item)
        setisOpenModalPromesaPagoData(true)
    }

    const handleMotivoEliminacion = async () => {
        await CobranzaService.getAllMotivoEliminacion()
            .then(res => {
                let motivo = []
                res.data?.responseData?.map(item => {
                    if (item.activo) {
                        motivo.push({
                            value: item.id,
                            label: item.nombre
                        });
                    }
                });
                setMotivoEliminacion(motivo);
            })
    }

    const handlePromesasPagoDelete = (item) => {
        setPromesaPagoData(item)
        setisOpenModalPromesaPagoDelete(true)
    }

    const handleModalIncumplimiento = (value) => {
        // alert("Función ejecutada desde el componente hijo")
        setisOpenModalPromesaPagoIncumplimiento(true)
    }

    const handleModalAmpliacion = (item) => {
        // alert("Función ejecutada desde el componente hijo")
        setInfoContactoData(item)
        setisOpenModalPromesaPagoAmpliacion(true)
    }
    ///Directorio de contactos
    const handleSetStateTab = (value) => {
        setDirectorioValue(value)
    }

    const handleModalRef = (value) => {
        setisOpenModalReferencias(true)
    }

    const GetInformacionContactos = async () => {
        let infoAval
        const data = {
            sol_id: state.ruta_item?.contrato.sol_id,
            contrato: state.contrato,
            vin: state.vin
        }

        let result = await CobranzaService.getInformacionContactos(data)
            .then(res => {

                setInfoAvaliable({
                    cont_apoderado: res.data.responseData?.cont_apoderado,
                    cont_referencia: res.data.responseData?.cont_referencia,
                    cont_coacreditado: res.data.responseData?.cont_coacreditado,
                    cont_aval: res.data.responseData?.cont_aval
                })

                infoAval = res.data.responseData?.cont_aval

                let datosFormateados = res.data.responseData.datos_referencia.map((item) => {
                    if (item) {
                        let domicilio = `${item.calle_ref} ${item.no_ext_ref} ${item.colonia_ref}`
                        return {
                            ...item,
                            domicilio_ref: domicilio,
                            filter: false
                        }
                    }
                })
                return datosFormateados
            }).catch(e => {
                if (e.response !== undefined)
                    toast.error(message("Error.!", e.response.data.errors?.error));
                else
                    toast.error(message("Error.!", e.message));
            })

        //Sacar politica Aval
        const Itemdata = {
            FIELD: "contrato_id",
            VALUE: state.contrato_id,
        };
        try {
            const resp = await CobranzaService.GetAllCobranzaEventoItemByField(Itemdata);
            let directorioDisable = resp?.data?.responseData[0]?.politica_aval || false

            if (directorioDisable && infoAval) setDirectorioValue("Aval")
            setDisableInteractions(directorioDisable)
        } catch (err) {
            console.error(err);
        }

        return result
    }

    const handleGetInformacionContactos = async () => {
        const data = await GetInformacionContactos()
        const promesasRotasField =
        {
            FIELD: "ID",
            VALUE: "LLAMADAS_CONSECUTIVAS_NIVEL_1"
        }

        const responseLlamadasConsecutivas = await CobranzaService.getAllParametrosByField(promesasRotasField)
        const maxIterations = Number(responseLlamadasConsecutivas.data.responseData[0].valorpublico)

        const updateResults = await Promise.all(
            data.map(async (item) => {
                const updateItem = await AddInteracciones(item)
                if ((updateItem >= maxIterations) && (item.tipo === "Cliente")) {
                    if (disableInteractions) {
                        setDirectorioValue("Aval")
                    }
                }
                item.interacciones = updateItem

                return item
            })
        )
        setInfoContactos(updateResults)
    }


    const AddInteracciones = async (item) => {
        let data = {
            field: "id_referencia_ascendes",
            value: item.idreferencia
        }
        try {
            const response = await CobranzaService.getRowsIteractions(data)
            return response.data.responseData; // Devuelve los datos de la respuesta
        } catch (e) {
            if (e.response !== undefined)
                toast.error(message("Error.!", e.response.data.errors?.error));
            else
                toast.error(message("Error.!", e.message));
        }
    };

    const actualizaVisitabyContrato = async (contratoId) => {
        let data = {
            contrato: contratoId
        }
        try {
            const response = await CobranzaService.actualizaVisitabyContrato(data)
            return response.data; // Devuelve los datos de la respuesta
        } catch (e) {
            setLoading(false);
            if (e.response !== undefined)
                toast.error(message("Error.!", e.response.data.errors?.error));
            else
                toast.error(message("Error.!", e.message));
        }
    };

    const handleGetAllRelacionSolicitante = async (id) => {
        await CobranzaService.getAllRelacionSolicitante()
            .then(res => {
                let relaciones = []
                res.data?.responseData?.map(item => {
                    if (item.activo) {
                        relaciones.push({
                            value: item.id,
                            label: item.nombre
                        })
                    }
                });
                setRelacionSolicitante(relaciones)
            })
    }


    const handleGetEstadoMx = async () => {
        await Llamada.GetEstadoMx().then(res => {
            let Estados = []
            res.data.data?.map(item => {
                Estados.push({
                    value: item.edoMexicoId,
                    label: item.estado
                });
            });
            setDataEstado(Estados);
        })
    }

    const handleGetTipoContacto = async () => {
        await CobranzaService.getAllTipoContacto()
            .then(res => {
                let tipos = []
                res.data.responseData?.map(item => {
                    if (item.activo) {
                        tipos.push({
                            value: item.id,
                            label: item.nombre
                        });
                    }
                });
                setTipoContacto(tipos);
            })
    }

    const handleGetSubtiposContacto = async () => {
        await CobranzaService.getAllSubTipoContacto()
            .then(res => {
                let subtipo = []
                res.data?.map(item => {
                    if (item.activo) {
                        subtipo.push({
                            value: item.id,
                            label: item.nombre
                        });
                    }
                });
                setSubtipoContacto(subtipo);
            })
    }

    const handleModalAdd = (value) => {
        // alert("Función ejecutada desde el componente hijo")
        setInfoContactoData(value)
        setisOpenModalAgregarContacto(true)
    }

    const handleDireccionesModal = (item) => {
        setInfoContactoData(item)
        setIsOpenModalDireccionContactos(true)
    }

    const handleRegistroLlamada = (item) => {
        setInfoContactoData(item)
        setIsOpenModalRegistroLlamada(true)
    }

    //Subir y Ver Documentos


    const handleGetTokenDatadocs = async () => {
        await dataDocsServices.UpdateTokenDataDc()
            .then(res => {
                auth.handleAuthSesionDataDocs(res.data.data);
            }).catch(e => { })
    }

    const handleViewdocs = async (item) => {
        setLoading(true);
        setFileName(item.nombreDocumento);
        await handleGetTokenDatadocs();
        await axios.post(`${url}Documento/GetFileById/${item.idFolder}/${item.documentoId}/false`, {}, {
            headers: {
                'Authorization': 'Bearer ' + auth.tokenDataDocs(),
            },
            responseType: "blob"
        })
            .then(res => {

                var file = new File([res.data], res.data.name, { type: res.data.type });
                setSelectedDocs([file])
                setLoading(false);
                setIsOpenDocs(true)
            }).catch(e => {

                setLoading(false);
                if (e.response !== undefined)
                    toast.error(message("Error.!", e.response.data.errors?.error));
                else
                    toast.error(message("Error.!", e.message));
            })
    }

    const handleUploadFile = async (value, subido) => {
        if (subido) {
            await handleViewdocs(value)
        } else {
            navigate(`/cobranza/seguimiento-telefonico/datadocs/fCobranza/eTelefono/${value.contrato}/${value.id}`, {
                state: { ...value, registroId: value.id, vin: contratoInfo.vin, nombreMarca: contratoInfo.nombreMarca, nombreModelo: contratoInfo.nombreModelo, nombreVersion: contratoInfo.nombreVersion, nombreYear: contratoInfo.nombreYear }
            });
        }
    }

    useEffect(() => {
        const result = infoContactos?.filter(item =>
            item?.tipo?.toLowerCase().includes(directorioValue.toLowerCase())
        );
        setInfoContactosFiltered(result)
    }, [directorioValue, infoContactos])

    const handleContactosSeguimientoLog = async () => {


        let AllMotivos = await CobranzaService.getAllMotivoVisita()
            .then(res => {
                let newValueMotivos = [{
                    value: "",
                    label: null
                }]
                res.data.responseData?.map(item => {
                    if (item.activo) {
                        newValueMotivos.push({
                            value: item.id,
                            label: item.nombre
                        });
                    }
                });
                return newValueMotivos
            })



        let AllTipoResultados = await CobranzaService.getAllTipoResultado()
            .then(res => {
                let newValueTipo = [{
                    value: "",
                    label: null
                }]
                res.data.responseData?.map(item => {
                    if (item.activo) {
                        newValueTipo.push({
                            value: item.id,
                            label: item.nombre
                        });
                    }
                });
                return newValueTipo
            })

        let AllTipoOperaciones = await CobranzaService.getAllTipoOperacion()
            .then(res => {
                let operacion = []
                res.data?.map(item => {
                    if (item.activo) {
                        operacion.push({
                            value: item.id,
                            label: item.nombre
                        });
                    }
                });
                return operacion
            })

        const data = {
            "value": state.contrato
        }
        await CobranzaService.getAllSeguimientoContactosLogs(data)
            .then(res => {
                const update = res.data.responseData.map(item => {
                    const fechaFormat = FormatFecha(item.fecha)
                    const aplicaVisitaFormat = item.aplica_visita ? "Si" : "No"

                    const motivoVisitaFormat = AllMotivos?.find(motivo => motivo?.value?.toLowerCase() === item?.motivo_visita_id?.toLowerCase())
                    const resultadoFormat = AllTipoResultados?.find(resultado => resultado?.value?.toLowerCase() === item?.operacion_resultado_id?.toLowerCase() || null)
                    const operacionFormat = AllTipoOperaciones?.find(operacion => operacion?.value?.toLowerCase() === item?.operacion_id?.toLowerCase() || null)
                    let formatValue = item.valor
                    if (item.contacto === "Celular" || item.contacto === "Télefono fijo") {
                        formatValue = FormatPhoneNumber(item.valor)
                    }

                    return {
                        ...item,
                        fecha: fechaFormat,
                        aplica_visita_nombre: aplicaVisitaFormat,
                        motivo_visita_nombre: motivoVisitaFormat.label,
                        operacion_resultado_id_nombre: resultadoFormat.label,
                        operacion_tipo_nombre: operacionFormat?.label,
                        message: item.comentario_visita,
                        comentariosUI: item.comentario_resultado,
                        valor: formatValue
                    }
                })
                setMotivosVisita(AllMotivos)
                setTipoResultado(AllTipoResultados)
                setCurrentDataSeguimientoLog(update)
            })


    }
    return {
        currentDataSeguimiento,
        userDetail,
        promesasRotas,
        loading,
        setLoading,
        handleReturnPage,
        disableInteractions,

        //Catalogos
        motivoEliminacion,
        relacionSolicitante,
        dataEstado,
        tipoContacto,
        subtipoContacto,
        infoContactosFiltered,

        ///Promesas de Pago
        columnsDetallePromesas,
        columnsGarantiaPromesas,
        columnsHistorialPromesas,
        currentDataPromesas,
        dataGarantia,
        dataGarantiaHistorial,
        handlePromesasPago,
        handlePromesasPagoDelete,
        handleGetPromesasdePago,
        handleModalIncumplimiento,
        handleModalAmpliacion,
        isOpenModalPromesaPagoData,
        setisOpenModalPromesaPagoData,
        promesaPagoData,
        isOpenModalPromesaPagoDelete,
        setisOpenModalPromesaPagoDelete,
        isOpenModalPromesaPagoIncumplimiento,
        setisOpenModalPromesaPagoIncumplimiento,
        isOpenModalPromesaPagoAmpliacion,
        setisOpenModalPromesaPagoAmpliacion,
        motivosVisita,
        tipoResultado,
        infoAvaliable,

        //Directorio de contactos
        columnsInformacionContacto,
        columnsSubInformacionContacto,
        currentDataSeguimientoLog,
        directorioValue,
        infoContactoData,
        handleSetStateTab,
        handleModalRef,
        handleGetInformacionContactos,
        handleModalAdd,
        handleDireccionesModal,
        handleRegistroLlamada,
        handleContactosSeguimientoLog,
        setCurrentDataPromesas,
        isOpenModalAgregarContacto,
        setisOpenModalAgregarContacto,
        isOpenModalReferencias,
        setisOpenModalReferencias,
        isOpenModalDireccionContactos,
        setIsOpenModalDireccionContactos,
        isOpenModalRegistroLlamada,
        setIsOpenModalRegistroLlamada,
        setDisableInteractions,
        setDirectorioValue,
        setCurrentDataSeguimiento,
        //Subido
        handleUploadFile,
        handleViewdocs,
        isOpenDocs,
        setIsOpenDocs,
        fileName,
        SelectedDocs,
        bloqueocita_entrega,
        //Logs
        columnsSeguimientoLog
    }
}

export default UnidadesRecuperadasDetalleHook;