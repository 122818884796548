import { useEffect, useRef, useState } from "react";
import Catalogo from "../../../Services/Catalogos/Catalogo";
import DSAsignaciones from "../../../Services/DatamovilSystems/Asignaciones";
import { getDateFormat, getDateFormatNoUTC, IsNullOrEmpty, Proceso } from "../../../Utils/functions";
import { is } from "date-fns/locale";

const DSSinAsignarHook = () => {
    const refFormik = useRef();
    const selectRef = useRef();
    let initial = {
        buscar: ''
    }
    const [initialValue, setInitialValue] = useState(initial);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [buscar, setBuscar] = useState('');
    const [proceso, setProceso] = useState('');
    const [tipoSolicitud, setTipoSolicitud] = useState('');
    const [tipoUbicacion, setTipoUbicacion] = useState('');
    const [page, setPage] = useState(1);
    const [currenPage, setCurrenPage] = useState(1);
    const [rows, setRows] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '' });
    const [procesos, setProcesos] = useState([]);
    const [tipoSolicitudes, setTipoSolicitudes] = useState([]);

    const columns = [
        { field: 'vin', headerName: 'VIN', widht: '150px' },
        { field: 'contrato', headerName: 'Contrato', widht: '150px' },
        { field: 'proceso', headerName: 'Proceso', widht: '150px' },
        // { field: 'subProceso', headerName: 'Subproceso', widht: '150px' },
        { field: 'tipoServicio', headerName: 'Tipo de solicitud', widht: '150px' },
        { field: 'tipoUbicacion', headerName: 'Tipo de Ubicación', widht: '150px' },
        { field: 'fechaHoraCita', headerName: 'Fecha y hora de cita', widht: '150px' },
        { field: 'ubicacion', headerName: 'Ubicación', widht: '150px' },
    ];

    const tipoUbicaciones = [
        { value: '', label: 'Todos' },
        { value: '1', label: 'Interna' },
        { value: '2', label: 'Externa' }
    ];

    useEffect(() => {
        getProcesos();
        getTipoSolicitudes();        
    }, []);

    useEffect(() => {
        if (buscar !== "" || proceso !== "" || tipoSolicitud !== "" || tipoUbicacion !== "") {
            setCurrenPage(1);
            getListado(1);
          }
          else {
            getListado(currenPage);  
            setCurrenPage(1);
          }
            
    }, [buscar, proceso, tipoSolicitud, tipoUbicacion, page, rows]);

    const getProcesos = async () => {
        // await Catalogo.getDropdownsByTable('Catalogos.Procesos')
        // .then(resp => {
        let newData = [{ value: "", label: "Todos" }];

        // let items = resp.data.map(item => {
        //     newData.push( {
        //         value: item.ProcesoId,
        //         label: item.Proceso
        //     })
        // });
        setProcesos([{ value: "", label: "Todos" }, ...Proceso]);
        // })
        // .catch(err => {
        //     console.error(err);
        // });
    }

    const getTipoSolicitudes = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.Servicios')
            .then(resp => {
                let newData = [{ value: "", label: "Todos" }];
                let items = resp.data.map(item => {
                    newData.push({ value: item.TipoServicioId, label: item.TipoServicio });
                });
                setTipoSolicitudes(newData);
            })
            .catch(err => {
                console.error(err);
            });
    }
    const getListado = async (page = 1) => {        
        setLoading(true);
        const params = {
            buscar,
            proceso,
            tipoSolicitud,
            tipoUbicacion,
            page:page ?? currenPage,
            rows
        };

        await DSAsignaciones.getSeguimientoSinAsignar(params)
            .then(resp => {
                let items = [];
                resp?.data?.data?.forEach(item => {
                    item.fechaHoraCita = IsNullOrEmpty(item?.fechaCita) ? "-" : getDateFormatNoUTC(`${item?.fechaCita?.split("T")[0]}T${item?.horaCita}`, "");
                    item.subProceso = item.proceso === "Seguro" ? "Siniestro" : "-";
                    items.push(item);
                });
                setTotalRows(resp?.data?.totalRows);
                setTotalPages(resp?.data?.totalPages);
                setData(items);
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getFormatDate = (fecha) => {
        return fecha.split("T")[0].split("-").reverse().join("-");
    }

    const handlePageClick = (page) => {
        setCurrenPage(page.selected + 1);        
        getListado(page.selected + 1);
    }

    return {
        refFormik,
        selectRef,
        initialValue,
        data,
        loading,
        setBuscar,
        buscar,
        columns,
        setModalMessage,
        modalMessage,
        setProceso,
        setTipoSolicitud,
        setTipoUbicacion,
        procesos,
        tipoSolicitudes,
        tipoUbicaciones,
        getListado,
        totalRows,
        totalPages,
        page,
        handlePageClick,
        setRows,
        currenPage
    }
}

export default DSSinAsignarHook;