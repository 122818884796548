import React, { useEffect, useRef, useState } from 'react'
import ValuacionesServices from '../../Services/Inventario/ValuacionesService';
import { getOnlyDateFormat } from '../../Utils/functions';
import { useNavigate } from 'react-router-dom';

const useSeguimientoValuaciones = () => {
    const navigate = useNavigate();
    const selectRef = useRef();

    let initial = {
        buscarAvanzada: "",
        fechaInicio: "",
        fechaFin: "",
        concluido: false,
        page: 1,
        rows: 10
    };

    const columns = [
        { field: 'vin', headerName: 'VIN', width: 150 },
        { field: 'contrato', headerName: 'Contrato', width: 150 },
        { field: 'proceso', headerName: 'Proceso', width: 150 },
        { field: 'subproceso', headerName: 'Subproceso', width: 150 },
        { field: 'marca', headerName: 'Marca', width: 150 },
        { field: 'modelo', headerName: 'Modelo', width: 150 },
        { field: 'year', headerName: 'Año', width: 150 },
        { field: 'fechaInicio', headerName: 'Fecha de inicio', width: 250 },
        { field: 'fechaMovimiento', headerName: 'Fecha de último movimiento'},
        { field: 'estatus', headerName: 'Estatus', width: 350 },
        { field: 'subestatus', headerName: 'Subestatus', width: 150 },
    ];

    const [initialValue, setInitialValue] = useState(initial);
    const [buscar, setBuscar] = useState('');
    const [estatus, setEstatus] = useState(null);
    const [loading, setLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '' });
    const [data, setData] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [listEstatus, setListEstatus] = useState([]);

    useEffect(() => {
        getListadoEstatus();
        // getListado();
    }, []);

    const getListadoEstatus = async () => {
        await ValuacionesServices.getEstatus(1)
            .then(resp => {
                const listado = [{ value: "", label: "Todos" }];
                resp.data.map(item => {
                    listado.push({
                        value: item.valuacionStatusId,
                        label: item.estatus
                    });
                });
                setListEstatus(listado);
            })
            .catch(err => {
                console.error(err);
            });
    }

    useEffect(() => {
        getListado();
    }, [initialValue,buscar,estatus,currentPage]);

    const getListado = async () => {
        setLoading(true);
        const params = {
            buscar: buscar,
            estatus: estatus,
            page: currentPage,
            rows: perPage,
        }
        await ValuacionesServices.getAllValuaciones(params)
            .then(resp => {
                let result = resp.data.data.map(item => {
                    return {
                        vin: item.vin,
                        contrato: item.contrato,
                        proceso: item.proceso,
                        subproceso: item.subproceso,
                        marca: item.nombreMarca,
                        modelo: item.nombreModelo,
                        year: item.nombreYear,
                        fechaInicio: item.fechaInicio,
                        fechaMovimiento: item.fechaUltimoMovimiento,
                        tiempo: item.tiempoProceso,
                        estatus: item.estatus,
                        subestatus: item.subEstatus,
                        paso: item.paso,
                        valuacionId: item.valuacionId,
                        generalId: item.generalId,
                        version: item.nombreVersion,
                        year: item.nombreYear,
                        procesoId: item.procesoId
                    }
                });

                setData(result);
                setTotalPages(resp.data.totalPages);
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const submitSearch = async (values, advanced = false, fn) => {
        setLoading(true);
        
        if(advanced){
            fn('estatus', '');
            setEstatus('');
            fn('buscar', '');
            setBuscar('');
        }

        values.buscar = values.buscarAvanzada;
        await ValuacionesServices.getAllValuaciones(values, advanced)
            .then(resp => {
                let result = resp.data.data.map(item => {
                    return {
                        vin: item.vin,
                        contrato: item.contrato,
                        proceso: item.proceso,
                        subproceso: item.subproceso,
                        marca: item.nombreMarca,
                        modelo: item.nombreModelo,
                        year: item.nombreYear,
                        fechaInicio: item.fechaInicio,
                        fechaMovimiento: item.fechaUltimoMovimiento,
                        tiempo: item.tiempoProceso,
                        estatus: item.estatus,
                        subestatus: item.subEstatus,
                        paso: item.paso,
                        valuacionId: item.valuacionId,
                    }
                });
                setData(result);
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const cleanSearch = (fn) => {
        const changeState = new Promise((resolve, reject) => {
            fn('estatus', '');
            setEstatus('');

            fn('buscar', '');
            setBuscar('');

            resolve();
        });

        changeState.then(() => {
            getListado();
        });
    }

    const clearAdvanced = (fn) => {
        fn('buscarAvanzada', '');
        fn('fechaInicio', '');
        fn('fechaFin', '');
        fn('concluido', '');
    }

    const handleEdit = (item) => {
        navigate(`/inventario/valuacion/${item.valuacionId}/${item.paso}`, {
            state: item,
        })
    };
    const handlePageClick = (page) => {
        setCurrentPage(page.selected+1);
    };

    return {
        initialValue,
        columns,
        buscar,
        setBuscar,
        selectRef,
        estatus,
        setEstatus,
        getListado,
        loading,
        setLoading,
        modalMessage,
        setModalMessage,
        data,
        totalPages,
        currentPage,
        setCurrentPage,
        handleEdit,
        handlePageClick,
        submitSearch,
        listEstatus,
        cleanSearch,
        clearAdvanced
    };
}

export default useSeguimientoValuaciones;