import { useEffect, useState } from 'react'
import Parametros from '../../Services/Parametros/Parametros';
import { getDateFormatNoUTC } from '../../Utils/functions';


const ParametrosCarteraHistorialHooks = () => {
  const [loading, setLoading] = useState(true);

  const [strFiltro, setStrFiltro] = useState(""); // Filtro de búsqueda
  const [dateTime, setDateTime] = useState("");  // Fecha seleccionada

  const [dataParametrosCarteraLogs, setDataParametrosCarteraLogs] = useState([]);

  const [page, setPage] = useState(1);
  const [pageRows, setpageRows] = useState(10)
  const [pageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  const columns = [
    { field: 'parametro', headerName: 'Parámetro' },
    { field: 'valor_anterior', headerName: 'Valor anterior' },
    { field: 'valor_nuevo', headerName: 'Valor nuevo' },
    { field: 'usuario_nombre', headerName: 'Usuario' },
    { field: 'last_update', headerName: 'Fecha y hora' },
  ];

  // Use Inicial 
  useEffect(() => {
    handleGetAllParametrosCarteraLog(1);
    setCurrentPage(1)
    setPage(1)
  }, [strFiltro, dateTime, pageRows])

  /* Todos los parametros */
  // Función para cargar los datos de los parámetros
  const handleGetAllParametrosCarteraLog = async (pag) => {
    setLoading(true)

    const data = {
      "page": pag,
      "rows": pageRows,
      "buscar": strFiltro,
      "fecha": dateTime
    }

    try {
      const res = await Parametros.GetAllCobranzaParametrosLogs(data);

      if (res.data && res.data.data) {
        const datosFormateados = res.data.data.map((item) => {
          
          const fecha = getDateFormatNoUTC(item.dateupdate, '');

          return {
            ...item,
            parametro: item.parametro,
            usuario_nombre: item.usuarioupdate,
            last_update: fecha,
          };
        });

        setDataParametrosCarteraLogs(datosFormateados);
      }
      
      setPageCount(res.data.totalPages);
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }


  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
    handleGetAllParametrosCarteraLog(event.selected + 1);
};


  return {
    columns,
    loading,
    handlePageClick,
    currentPage,
    setpageRows,
    dateTime,
    setStrFiltro,
    strFiltro,
    setDateTime,
    dataParametrosCarteraLogs,
    setDateTime,
    pageCount
  };

}
export default ParametrosCarteraHistorialHooks