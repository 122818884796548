import { Fragment, useEffect, useState } from "react";
import './DataTableCollapsable.css';
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";

const DataTableCollapsable = ({ id, iswidth, columns, data = [], paginate = true, pageCount, handlePageClick, currentPage }) => {
    const navigate = useNavigate();
    const [open, setOpen] = useState({});

    const toggleCollapse = (id) => {
        setOpen((prevOpen) => ({
            ...prevOpen,
            [id]: !prevOpen[id],
        }));
    };

    
    const [rows, setRows] = useState([]);
    const [TotalPage, setTotalPage] = useState(null);

    useEffect(() => {
        setTotalPage(pageCount);
    }, [data])


    return (
        <Fragment>
            <div className="row">
                <div className="col-12">
                    <div className="row mt-2">
                        <div className="col text-sm" style={{ maxWidth: '30px', height: '40px', lineHeight: '40px' }}></div>
                        <div className="col text-sm fw-bold" style={{ maxWidth: '100px', height: '40px', lineHeight: '40px' }}>Folio</div>
                        <div className="col text-sm fw-bold" style={{ maxWidth: '300px', height: 'auto', lineHeight: '40px' }}>Técnico</div>
                        <div className="col text-sm fw-bold" style={{ maxWidth: '200px', height: '40px', lineHeight: '40px' }}>Cantidad de servicios</div>
                        <div className="col text-sm fw-bold" style={{ maxWidth: '200px', height: '40px', lineHeight: '40px' }}>Fecha de servicio</div>
                        <div className="col text-sm" style={{ maxWidth: '20px', height: '40px', lineHeight: '40px' }}></div>
                    </div>
                    {
                        data.length === 0 &&
                        <div className="row">
                            <div className="col-12 text-center">
                                <span>No hay asignaciones generadas aún</span>
                            </div>
                        </div>
                    }
                    {
                        data.length > 0 &&
                        data.map((item, index) => {
                            return <Fragment key={`row_${index}`}>
                                <div className={`row stripped table-collapsable ${index % 2 === 0 ? 'even' : 'odd'}`} style={{ cursor: 'pointer', height: 'auto', lineHeight: '40px' }}>
                                    <div className="col" style={{ maxWidth: '30px' }} data-bs-toggle="collapse" href={`#collapseItem${index}`} role="button" aria-expanded="false" aria-controls={`collapseItem${index}`} onClick={() => toggleCollapse(index)}>
                                        {
                                            open[index] ? <i className="ri-subtract-line"></i> : <i className="ri-add-line"></i>
                                        }
                                    </div>
                                    <div className="col text-sm" style={{ maxWidth: '100px' }} data-bs-toggle="collapse" href={`#collapseItem${index}`} role="button" aria-expanded="false" aria-controls={`collapseItem${index}`} onClick={() => toggleCollapse(index)}>{item.folioId}</div>
                                    <div className="col text-sm" style={{ maxWidth: '300px' }} data-bs-toggle="collapse" href={`#collapseItem${index}`} role="button" aria-expanded="false" aria-controls={`collapseItem${index}`} onClick={() => toggleCollapse(index)}>{item.tecnico}</div>
                                    <div className="col text-sm" style={{ maxWidth: '200px' }} data-bs-toggle="collapse" href={`#collapseItem${index}`} role="button" aria-expanded="false" aria-controls={`collapseItem${index}`} onClick={() => toggleCollapse(index)}>{item.cantidadServicios}</div>
                                    <div className="col text-sm" style={{ maxWidth: '200px' }} data-bs-toggle="collapse" href={`#collapseItem${index}`} role="button" aria-expanded="false" aria-controls={`collapseItem${index}`} onClick={() => toggleCollapse(index)}>{item.fechaHoraCita ? item.fechaHoraCita : '-'}</div>
                                    <div className="col" style={{ maxWidth: '20px' }}>
                                        <button className="btn-link" onClick={() => {
                                            navigate(`/datamovil/asignaciones/${item.folioId}`)
                                        }}>
                                            <i className="ri-arrow-right-s-line"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="row collapse table-collapsable" id={`collapseItem${index}`}>
                                    <div className="row">
                                        <div className="col text-sm fw-bold" style={{ maxWidth: '30px', marginLeft: '30px', height: 'auto', lineHeight: '30px' }}>#</div>
                                        <div className="col text-sm fw-bold" style={{ maxWidth: '120px', height: '30px', lineHeight: '30px' }}>Contrato</div>
                                        <div className="col text-sm fw-bold" style={{ maxWidth: '140px', height: '30px', lineHeight: '30px' }}>Tipo de servicio</div>
                                        <div className="col text-sm fw-bold" style={{ width: 'auto', maxWidth: '170px', height: '30px', lineHeight: '30px' }}>Proceso</div>
                                        <div className="col text-sm fw-bold" style={{ maxWidth: '190px', height: 'auto', lineHeight: '30px' }}>Fecha y hora de cita</div>
                                        <div className="col text-sm fw-bold" style={{ maxWidth: '160px', height: '30px', lineHeight: '30px' }}>Tipo de Ubicación</div>
                                        <div className="col text-sm fw-bold" style={{ maxWidth: '400px', height: '30px', lineHeight: '30px' }}>Ubicación</div>
                                    </div>
                                    {
                                        item.detalle.length === 0 &&
                                        <div className="row">
                                            <div className="col-12 text-center">
                                                <span>No hay servicios asigandos aún a este folio</span>
                                            </div>
                                        </div>
                                    }
                                    {
                                        item.detalle.length > 0 &&
                                        item.detalle.map((subItem, subIndex) => {
                                            return <Fragment key={`subrow_${subIndex}`}>
                                                <div className="row">
                                                    <div className="col text-xs" style={{ maxWidth: '30px', marginLeft: '30px', height: '30px', lineHeight: '30px' }}>{subIndex + 1}</div>
                                                    <div className="col text-xs" style={{ maxWidth: '120px', height: '30px', lineHeight: '30px' }}>{subItem.contrato}</div>
                                                    <div className="col text-xs" style={{ maxWidth: '140px', height: '30px', lineHeight: '30px' }}>{subItem.tipoServicio}</div>
                                                    <div className="col text-xs" style={{ width: 'auto', maxWidth: '170px', height: 'auto', lineHeight: '30px' }}>{subItem.proceso}</div>
                                                    <div className="col text-xs" style={{ maxWidth: '190px', height: '30px', lineHeight: '30px' }}>{subItem?.fechaHoraCita}</div>
                                                    <div className="col text-xs" style={{ maxWidth: '160px', height: '30px', lineHeight: '30px' }}>{subItem.tipoUbicacion}</div>
                                                    <div className="col text-xs" style={{ maxWidth: 'auto', height: 'auto', lineHeight: '30px' }}>{subItem.ubicacion}</div>
                                                </div>
                                            </Fragment>
                                        })
                                    }
                                </div>
                            </Fragment>
                        })
                    }
                </div>
            </div>
            <div className='d-flex align-items-center float-end'>
                {
                    paginate && TotalPage > 0 &&
                    <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={TotalPage}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                        forcePage={currentPage}
                    />
                }
            </div>
        </Fragment>
    );
}

export default DataTableCollapsable;