import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DSystemsServicios from '../../../Services/DatamovilSystems/ServiciosDatamovil';
import Llamada from '../../../Services/Cartera/Llamadas';
import Catalogo from "../../../Services/Catalogos/Catalogo";
import { useDispatch, useSelector } from 'react-redux';
import { clearScheduleByTipoCalendario, selectScheduleByTipoCalendario } from '../../../Redux/Slice/Calendario/calendarDynamicalSlice';
import { calendarKeys, formatearFecha, getDateFormat, isEmpty, IsNullOrEmpty, message } from '../../../Utils/functions';
import { da } from 'date-fns/locale';

const useAgendarCita = ({ handleConcluido, paso, toast }) => {
    const { id, tipoServicio } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { state } = useLocation();

    const location = useLocation();
    let url = location.pathname.split("/");


    const CitaServicioDatamovil = useSelector((state) => selectScheduleByTipoCalendario(state, calendarKeys[tipoServicio]));

    const initial = {
        servicioId: "",
        generalId: "",
        tipoServicioId: 0,
        procesoId: 0,
        tipoSolicitudId: "",
        comentarios: "",
        servicioAutorizado: true,
        servicioMotivoRechazo: null,
        comentariosRechazo: null,
        asignado: false,
        fechaCita: "",
        horaCita: "",
        fechayHora: "",
        tipoUbicacion: 1,
        ubicacion: null,
        bloqueoId: null,
        citaAutorizada: null,
        arriboCita: null,
        usuarioAutorizo: null,
        vehiculoEnUbicacion: true,
        usuarioAlta: "",
        usuarioActualiza: null,
        fechaAlta: "",
        fechaUpdate: null,
        concluido: null,
        fechaConcluido: null,
        estadoId: null,
        municipioId: null,
        coloniaId: null,
        calle: null,
        numeroExt: null,
        fechaAutorizacion: null,
        tipoOrden: "",
        tipoServicio: "",
        proceso: "",
        usuarioSolicita: "",
        usuarioAprobo: null,
        usuarioActualizaStr: null,
        concluirRegistroInicioServicio: false,
        fechaInicioUbicacion: "", // Fecha txt
        horaInicioUbicacion: "", // hora visible

        vin: state?.vin ?? "",
        marca: state?.marca ?? "",
        modelo: state?.modelo ?? "",
        anio: state?.anio ?? "",
        tipoServicio: state?.tipoServicio ?? "",
        tipoProceso: state?.tipoProceso ?? "",
        paso: url[5],
        tipoCalendario:calendarKeys[tipoServicio]
    };

    const [initialValue, setInitialValue] = useState(initial);
    const [loading, setLoading] = useState(false);
    const [estados, setEstados] = useState([]);
    const [municipios, setMunicipios] = useState([]);
    const [colonias, setColonias] = useState([]);
    const opcionesBool = [{ value: true, label: 'Si' }, { value: false, label: 'No' }];
    const [ubicaciones, setUbicaciones] = useState([]);

    const tipoUbicaciones = [
        { value: 1, label: 'Interna' },
        { value: 2, label: 'Externa' },
    ];

    useEffect(() => {
        getEstados();
        getDataCita();
        getUbicaciones();
    }, []);

    const mapResponseData = (data, valueKey, labelKey) =>
        data.map(item => ({ value: item[valueKey], label: item[labelKey] }));

    const getUbicaciones = async () => {
        await Catalogo.getDropdownsByTable('Sistema.Ubicacion')
            .then(resp => {
                const items = resp.data.map(item => {
                    return {
                        value: item.UbicacionId,
                        label: item.Nombre,
                        responsable: item.Responsable
                    }
                })
                setUbicaciones(items);
            })
    }

    const getDataCita = async () => {
        try {
            setLoading(true);
            const res = await DSystemsServicios.getServicio(id);
            const data = res.data;
            // if (data.fechaCita) data.fechaCita = data.fechaCita.split("T")[0];
            if (data.estadoId) await getMunicipios(data.estadoId);
            if (data.municipioId) await getColonias(data.municipioId);
            data.vehiculoEnUbicacion = data.vehiculoEnUbicacion ?? null;
            data.contrato = state.contrato;
            // data.codEstatusPaso1 = state?.codEstatusPaso1 ?? 1;
            data.paso = url[5];
            data.tipoUbicacion = data?.tipoUbicacion ? data?.tipoUbicacion : CitaServicioDatamovil?.day ? 1 : "";
            data.calendarioId = data?.calendarioId ?? CitaServicioDatamovil?.calendarioId ?? "";
            data.sucursalId = data.sucursalId ?? CitaServicioDatamovil?.sucursalId ?? "";

            data.tipoCalendario = calendarKeys[tipoServicio];
            // data.fechayHora= !IsNullOrEmpty(data?.fechaCita) ? formatearFecha(`${data?.fechaCita.split("T")[0]}T${data?.horaCita}`) : !isEmpty(CitaServicioDatamovil) ? getFormatFromCalendar(CitaServicioDatamovil.day, CitaServicioDatamovil.hour):"";
            data.fechayHora = !IsNullOrEmpty(CitaServicioDatamovil?.day) ? formatearFecha(`${CitaServicioDatamovil.day}T${CitaServicioDatamovil.hour}`) : !IsNullOrEmpty(data?.fechaCitaPaso1) ? data?.fechaHoraCitaPaso1 : "";
            let fechaCitaFormat = CitaServicioDatamovil?.day?.split('-').reverse().join('-')
            
            if (data?.tipoUbicacion === 1) {
                data.ubicacion = data.nombreUbicacionPaso1 ?? CitaServicioDatamovil.sucursalName;
                data.fechaCita = CitaServicioDatamovil?.day ?? data.fechaCitaPaso1 ?? "";
                data.fechaCitaStr = fechaCitaFormat;
                data.horaCita = CitaServicioDatamovil.hour ?? data.horaCitaPaso1 ?? "";
                handleConcluido({ concluido: (data?.codEstatusPaso1 === 2 && data?.asignado) ? data?.arriboCita : false, codStatus: data?.codEstatusPaso1, concluidoGeneral: data?.concluidoGeneral });
            } else {
                //data.fechaCita = data?.fechaCita.split(" ")[0];
                data.fechaCita = data.fechaCita ? new Date(data.fechaCita).toISOString().split('T')[0] : ""
                //data.fechaCita = "2025-12-12"
                data.horaCita = CitaServicioDatamovil.hour ?? data.horaCita ?? "";
                handleConcluido({ concluido: (data?.codEstatusPaso1 === 2 && data?.asignado) ? data?.arriboCita : false, codStatus: data?.codEstatusPaso1, concluidoGeneral: data?.concluidoGeneral });
            }            
            setInitialValue({ ...initialValue, ...data });

        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const submitFormAgendarCita = async (values) => {
        try {         
            setLoading(true);
            await DSystemsServicios.updateServicio(values).then(resp => {
                if (resp.data.ban === 1) {
                    toast.success(message(resp.data.message));
                    dispatch(clearScheduleByTipoCalendario({ tipoCalendario: calendarKeys[tipoServicio] }));
                } else {
                    toast.error(message(resp.data.message));
                }
            }).catch(err => {
                setLoading(false);
                console.error(err);
            }).finally(() => {
                setLoading(false);
                getDataCita();
            });
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const submitFormUbicacionVehiculo = async (values) => {
        try {
            setLoading(true);
            await DSystemsServicios.updateServicioInicio(values);
            getDataCita()
            //Aqui se agrega el insertar costo
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const getEstados = async () => {
        try {
            const res = await Llamada.GetEstadoMx();
            setEstados(mapResponseData(res.data.data, 'edoMexicoId', 'estado'));
        } catch (error) {
            console.error(error);
        }
    };

    const getMunicipios = async (estadoId) => {
        if (!estadoId) return;
        try {
            const res = await Llamada.GetMunicipio(estadoId);
            setMunicipios(mapResponseData(res.data.data, 'municipioId', 'municipio'));
        } catch (error) {
            console.error(error);
        }
    };

    const getColonias = async (municipioId) => {
        if (!municipioId) return;
        try {
            const res = await Llamada.GetColonia(municipioId);
            setColonias(mapResponseData(res.data.data, 'coloniaId', 'colonia'));
        } catch (error) {
            console.error(error);
        }
    };

    return {
        initialValue,
        loading,
        setLoading,
        submitFormAgendarCita,
        submitFormUbicacionVehiculo,
        getEstados,
        getMunicipios,
        getColonias,
        estados,
        municipios,
        colonias,
        opcionesBool,
        tipoUbicaciones,
        ubicaciones,
        state
    };
};

export default useAgendarCita;
