import { useDispatch, useSelector } from "react-redux";
import { Section } from "../../../Auth/Authorization";
import { ValidatePermission, ValidatePermissionById } from "../../../Auth/ValidatePermission";
// import Tab from "../../../Components/Tabs/Tab";
// import Tabs from "../../../Components/Tabs/Tabs";
// import SeguimientoAsignaciones from "./SeguimientoAsignaciones";
// import SeguimientoSinAsignar from "./SeguimientoSinAsignar";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

import {tabActiveComisionesInventario} from '../../../Redux/Slice/navigateSliceComisionesInventario'

const AsignacionesDatamovilSystems = () => {
    const { value } = useSelector((state) => state.tabNavigateComisionesInventario);
    const dispatch = useDispatch();

    const navigate = useNavigate();

    useEffect(() => {
        handleVigate(value);
    }, [value])

    const handleSetStateTab = (value) => {
      dispatch(tabActiveComisionesInventario(value)); 
    }
    const handleVigate = (value) => {    
        if (ValidatePermissionById(3, Section.AsignacionesSinAsignarSection) && value === 1) {          
            navigate(`/datamovil/asignaciones/sinasignar`);
        }
        else if (ValidatePermissionById(3, Section.AsignacionesServiciosSection) && value === 2) {        
            navigate(`/datamovil/asignaciones/asignacionservicios`);            
        }     
      }
    return (
        <>
            <ul className="nav nav-tabs" id="myTab" role="tablist">
                <ValidatePermission isActive={ValidatePermissionById(3, Section.AsignacionesSinAsignarSection)}>
                    <li className="nav-item" role="presentation">
                        <button className={`nav-link ${value == 1 && "tab-active"}`} value={1} type="button" role="tab" onClick={(e) => { handleSetStateTab(e.target.value) }}>Servicios sin asignar</button>
                    </li>
                </ValidatePermission>
                <ValidatePermission isActive={ValidatePermissionById(3, Section.AsignacionesServiciosSection)}>
                    <li className="nav-item" role="presentation">
                        <button className={`nav-link ${value === 2 && "tab-active"}`} value={2} type="button" role="tab" onClick={(e) => { handleSetStateTab(e.target.value) }}>Asignación de servicios</button>
                    </li>
                </ValidatePermission>           
            </ul>
        </>
    );
}

export default AsignacionesDatamovilSystems;
