import { Form, Formik, Field } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { TextField } from '../../../../Components/TextField';
import { TextAreaField } from '../../../../Components/TextAreaField';
import Modal from '../../../../Components/Modal/Modal';
import Spinner from '../../../../Components/Loadig';
import { SelectField } from '../../../../Components/SelectField';
import useOrdenServicio from '../../../../Hooks/DatamovilSystems/RevisionPasos/useOrdenServicio';
import toast, { Toaster } from 'react-hot-toast'

const CardOrdenServicio = ({ handleConcluido }) => {
    const { refFormik } = useRef();
    const [collapsible, setCollapsible] = useState(false);

    const validationSchema = Yup.object().shape({
        departamentoId: Yup.string()
            .nullable()
            .when('concluido', {
                is: true,
                then: Yup.string()
                    .nullable()
                    .required('El departamento es requerido')
            }),
        motivoSercicioId: Yup.string()
            .nullable()
            .when('concluido', {
                is: true,
                then: Yup.string()
                    .nullable()
                    .required('El motivo de servicio es requerido')
            }),
        fechaSolicitud: Yup.string()
            .nullable()
            .when('concluido', {
                is: true,
                then: Yup.string()
                    .nullable()
                    .required('La fecha de solicitud es requerida')
            }),
        horaSolicitud: Yup.string()
            .nullable()
            .when('concluido', {
                is: true,
                then: Yup.string()
                    .nullable()
                    .required('La hora de solicitud es requerida')
            }),
        comentarios: Yup.string()
            .nullable()
            .when('concluido', {
                is: true,
                then: Yup.string()
                    .nullable()
                    .required('Escribe un comentario')
            }),
        personaSolicitaServicio: Yup.string()
            .nullable()
            .when('concluido', {
                is: true,
                then: Yup.string()
                    .nullable()
                    .required('El campo es requerido')
            }),
    });

    const {
        initialValue,
        loading,
        setLoading,
        submitForm,
        departamentos,
        motivoServicio,
        state
    } = useOrdenServicio({ handleConcluido, toast })

    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <div className='row mx-md-4 my-4'>
                <div className="col-12 px-md-3">
                    <div className="p-4 white-wrapper mb-2">
                        <div
                            className="row mt-2"
                            data-bs-toggle="collapse"
                            href="#collapseOrdenServicio"
                            role="button"
                            aria-expanded="false"
                            aria-controls="collapseOrdenServicio"
                            onClick={() => setCollapsible(!collapsible)}
                        >
                            <div className="col-11">
                                <h6>
                                    <span className={`semaforo semaforo-${initialValue?.concluido ? "green" : "default"}`}></span>
                                    &nbsp;
                                    <strong>Orden de servicio</strong>
                                </h6>
                            </div>
                            <div className="col-1 text-end">
                                <i className={!collapsible ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                            </div>
                            <div className='col-12'>
                                <small>{initialValue.concluido ? "Concluido" : "En Proceso"}</small>
                            </div>
                        </div>
                        <div className="collapse" id="collapseOrdenServicio">
                            <div className='divider'></div>
                            <Formik
                                innerRef={refFormik}
                                enableReinitialize={true}
                                initialValues={initialValue}
                                validationSchema={validationSchema}
                                onSubmit={(values) => submitForm(values)}
                            >
                                {({ isValid, values, setFieldValue, errors }) => (
                                    <Form id="ordenServicioForm">

                                        <div className="row mt-2 mb-2">
                                            <div className="col-12">
                                                <div className="row justify-content-end">
                                                    <div className="col-auto">
                                                        {
                                                            initialValue.concluido ?
                                                                <fieldset className='btn btn-light secondary d-flex align-items-center mx-2'><i className="icon-dark bx bx-hide mx-1" ></i>Modo lectura</fieldset>
                                                                : <button
                                                                    className='btn'
                                                                    disabled={initialValue.concluido}
                                                                    type='submit'>
                                                                    Guardar
                                                                </button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-md-4">
                                                <SelectField
                                                    label="Departamento"
                                                    name="departamentoId"
                                                    id="departamentoId"
                                                    disabled={initialValue.concluido}
                                                    items={departamentos}
                                                    onChange={(event) => {
                                                        setFieldValue("departamentoId", event.value);
                                                        setFieldValue("departamento", event.label);
                                                    }}
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <SelectField
                                                    label="Motivo de servicio"
                                                    name="motivoSercicioId"
                                                    disabled={initialValue.concluido}
                                                    items={motivoServicio}
                                                    onChange={(event) => setFieldValue("motivoSercicioId", event.value)}
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <TextField
                                                    label="Persona que solicita el servicio"
                                                    holder={"Escribe"}
                                                    name="personaSolicitaServicio"
                                                    disabled={initialValue.concluido}
                                                    type="text"
                                                />
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <div className="col-md-4">
                                                <TextField
                                                    label="Fecha de solicitud"
                                                    name="fechaSolicitud"
                                                    disabled={true}
                                                    type="text"
                                                    onChange={(event) => setFieldValue("fechaSolicitud", event.target.value)}
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <TextField
                                                    label="Hora de solicitud"
                                                    name="horaSolicitud"
                                                    disabled={true}
                                                    type="text"
                                                    onChange={(event) => setFieldValue("horaSolicitud", event.target.value)}
                                                />
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <div className="col-md-8">
                                                <TextAreaField id="comentarios"
                                                    label="Comentarios"
                                                    disabled={initialValue.concluido}
                                                    className="form-control col-8"
                                                    rows="3"
                                                    name="comentarios"
                                                    type="text"
                                                    holder="Escribe"
                                                    onChange={(event) => setFieldValue("comentarios", event.target.value)}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-12 d-flex align-items-center">
                                            <Field type="checkbox" name="concluido" className="form-check-input me-2"
                                                disabled={initialValue.concluido}
                                                onClick={(event) => {
                                                    setFieldValue("concluido", event.target.value);
                                                }} />
                                            <label className="form-check-label">Concluir solicitud de servicio</label>
                                        </div>
                                        {/* {initialValue?.fechaConcluido} */}
                                        <div className="text-end">
                                            <small className="text-muted">
                                                <small className="text-muted">
                                                    {initialValue?.fechaAlta
                                                        ? `Inicio de proceso ${initialValue.fechaAlta}`
                                                        : ""}
                                                    {" | "}
                                                    {initialValue?.fechaConcluido
                                                        ? `Fin de proceso ${initialValue.fechaConcluido}`
                                                        : ""}
                                                </small>
                                            </small>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CardOrdenServicio;
