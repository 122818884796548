import { useFloating, shift, offset, arrow } from '@floating-ui/react';
import React, { useRef, useState } from "react";
import './TooltipUI.css';

const TooltipComentarios = ({ value, position = 'top right' }) => {
   const arrowRef = useRef();
   const { refs, floatingStyles, update } = useFloating({
      placement: position,
      middleware: [
         offset(30),
         shift(),
         arrow({ element: arrowRef }),
      ],
   });
   const [isVisible, setIsVisible] = useState(false);

   const toggleTooltip = (visible) => {
      setIsVisible(visible);
      if (visible) {
         update();
      }
   };

   return (
      <>
         <span
            ref={refs.setReference}
            onMouseEnter={() => toggleTooltip(true)}
            onMouseLeave={() => toggleTooltip(false)}
         >
            <i className='icon-blue ri-error-warning-fill' style={{ fontSize: "30px" }}></i>
         </span>

         {isVisible && (
            <div ref={refs.setFloating} style={floatingStyles} className="tooltipComentarios">
               {value}
            </div>
         )}
      </>
   );
}

export default TooltipComentarios;