import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate';
import '../datatable/DataTable.css'
import { boolean, object } from 'yup';

const DataTableDinamica = ({ iswidth, column, configuration, data, editable, downtable, pageCount, deleteable, detailable, recover, addtable, handleEdit,
    handleAdd, handleDownload, handleDelete, handleDetail, handleRecover, handlePageClick, paginate = true, checkbox = false, handleCheck,
    currentPage, actions, handleAction, type = 0, handleSort, handleDocuments, isStatus, handleChange, testDriverMantener, testDriverLiberar, testDriverHistorial, handleTDrive,
    handleEndTDrive, handleHistoryTDrive, tagCovert = false, autorizar, rechazar, handleAutorizaCita, autorizaCita, sinRenovar }) => {
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [TotalPage, setTotalPage] = useState(null);
    const [short, setShort] = useState(false);
    const [editRow, setEditRow] = useState(false);

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    useEffect(() => {
        setColumns(column);
        setTotalPage(pageCount);

        if (data === null)
            setRows([]);
        else
            setRows(data);
    }, [data])

    let FieldsArray = ['status', 'Status', 'estatus', 'interfaz', 'costo', 'tiempo', 'requerido', 'valor', 'valorAnterior', 'valorNuevo'];
    const handlegetPropertyValue = (object, field) => {
        let value = object[field];
        if (FieldsArray.includes(field) && typeof (value) === 'boolean') {
            value = value ? 'Activo' : 'Inactivo'
        }
        if (FieldsArray.includes(field) && typeof (value) !== 'boolean') {
            value = value?.length > 30 ? `${value.substr(0, 40)}...` : value;
        }
        if (field === "")
            value = <u onClick={() => { handleDocuments(object) }}>Ver</u>;//<button onClick={() => { handleDocuments(object) }} className='btn-tbl mx-1 btnAdd'><i className="ri-folder-3-fill"></i></button>
        if (field === "nombreDocumento")
            value = <u onClick={() => { handleDocuments(object) }}>{value}</u>;
        return value

    }

    const handleEditObj = (obj) => {
        obj.edit = true;
        forceUpdate();
        setRows(data);
    }

    const handleSaveObj = (obj) => {
        obj.edit = false;
        handleEdit(obj);
        forceUpdate();
        setRows(data);
    }

    const handleCancel = (obj) => {
        obj.edit = false;
        forceUpdate();
        setRows(data);
    }

    const gravedad = (value) => {
        if (value == 1) {
            return <span className='badge bg-danger'>!!!</span>
        }
        if (value == 2) {
            return <span className='badge bg-warning'>!!</span>
        }
        if (value == 3) {
            return <span className='badge bg-secondary'>!</span>
        }
    }

    const handleGetCheckValue = (Object, property) => {
        let config = JSON.parse(configuration);
        if (config[property].tipo === 'boolean' || config[property].tipo === 'bool' || config[property].tipo === 'bit') {
            if (typeof Object.Status !== 'boolean' && Object.Status === 'Activo') {
                return true;
            } else if (typeof Object.Status !== 'boolean' && Object.Status !== 'Activo') {
                return false;
            } else {
                return Object.Status;
            }
        }
    }

    const handleSetValue = (obj, field, value) => {
        obj[field] = value;
        forceUpdate();
        setRows(data);
    }

    const handleCheckedChange = (Object, property, value) => {
        let tmpData = data.filter(item => item === Object)[0];


        let config = JSON.parse(configuration);
        if (config[property].tipo === 'boolean' || config[property].tipo === 'bool' || config[property].tipo === 'bit') {
            if (typeof Object.Status !== 'boolean' && Object.Status === 'Activo') {
                // console.log('false')
                tmpData.Status = 'Inactivo';
                // return false;
            } else if (typeof Object.Status !== 'boolean' && Object.Status !== 'Activo') {
                // console.log('true')
                tmpData.Status = 'Activo';
                // return true;
            } else {
                // console.log(!Object.Status)
                tmpData.Status = Object.Status ? 'Inactivo' : 'Activo';
                // return !Object.Status;
            }

            // console.log(tmpData.Status)
            setRows({
                ...data,
                tmpData
            });
            forceUpdate();
        }
    }

    const handleGetPropertyType = (object, property) => {
        let config = JSON.parse(configuration);
        let type = "";

        switch (config[property].tipo) {
            case 'nvarchar':
            case 'varchar':
            case 'text':
            case 'longtext':
            case 'shorttext':
                type = "text";
                break;
            case 'integer':
            case 'number':
            case 'bigint':
            case 'int':
                type = "number";
                break;
            case 'date':
            case 'datetime':
                type = "date";
                break;
            case 'time':
                type = "time";
                break;
            case 'boolean':
            case 'bit':
                type = "checkbox";
                break;
        }

        return type;

    }

    return (
        <>
            <table className="table table-striped table-hover" style={{ width: iswidth ? '100vw' : ``, overflowX: 'auto' }}>
                <thead>
                    <tr className='custom-table'>
                        {
                            checkbox &&
                            <th scope="col" style={{ width: `35px` }} ></th>
                        }
                        {
                            columns?.map((item, index) => {
                                return <th scope="col" style={{ width: `${item.width}px` }} key={index}>{item.headerName} {item.short ? <i className="bx bx-sort" onClick={() => { setShort(!short); handleSort(short ? "asc" : "desc") }}></i> : null}</th>
                            })
                        }
                    </tr>
                </thead>
                <tbody className='tbl-custome'>
                    {
                        rows.length > 0 ?
                            rows?.map((rowObj, index) => {
                                return <tr key={index}>
                                    {
                                        checkbox ? <td><input id={rowObj.logUsuarioId} className='form-check-input chk' type="checkbox" onChange={(e) => { handleCheck(e.target.checked, rowObj, index) }} /></td> : null
                                    }

                                    {
                                        !rowObj.edit && columns?.map((column, index) => {
                                            return <td key={index}>
                                                {column?.dropdownData && column.componente === "dropdown" ?
                                                    <select
                                                        className="form-control mb-4"
                                                        // style={{background: "inherit;"}}
                                                        disabled={true}
                                                        value={rowObj.TipoPersonaId || ""}
                                                    >
                                                        <option value="">Seleccione una opción</option>
                                                        {column.dropdownData.map((option) => (
                                                            <option key={option.value} value={option.value}>
                                                                {option.text}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    :
                                                    <span className='' style={{ borderRadius: '5px', fontSize: "16px", padding: column.field === 'proceso' || column.field === 'nombreProceso' || column.field === 'parametro' || column.field === 'dia' ? '3px 10px 3px 10px' : 'none', color: column.field === 'proceso' || column.field === 'nombreProceso' || column.field === 'parametro' ? `${rowObj.colorTexto}` : 'none', backgroundColor: column.field === 'proceso' || column.field === 'nombreProceso' || column.field === 'parametro' || column.field === 'dia' ? `${rowObj.colorFondo}` : 'none', color: column.field === 'proceso' || column.field === 'nombreProceso' || column.field === 'parametro' ? `${rowObj.colorTexto}` : 'none' }}>{column.field == 'tipoCorreo' && gravedad(rowObj.prioridadId)} {handlegetPropertyValue(rowObj, column.field)}</span>
                                                }
                                            </td>
                                        })
                                    }
                                    {
                                        rowObj.edit && columns?.map((column, index) => {
                                            return <td key={index}>
                                                {/*Aqui agregar el dropdows.*/}
                                                {column?.dropdownData && column?.componente === "dropdown"?
                                                    <select
                                                        className="form-control mb-4"
                                                        value={rowObj[column.field] || ""}
                                                        onChange={(event) => {
                                                            handleSetValue(rowObj, column.field, event.target.value);
                                                        }}
                                                    >
                                                        <option value="">Seleccione una opción</option>
                                                        {column.dropdownData.map((option) => (
                                                            <option key={option.value} value={option.value}>
                                                                {option.text}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    :

                                                    <input
                                                        type={handleGetPropertyType(rowObj, column.field)}
                                                        checked={handleGetCheckValue(rowObj, column.field)}
                                                        value={handlegetPropertyValue(rowObj, column.field)}
                                                        onChange={(event) => {
                                                            handleSetValue(rowObj, column.field, event.target.value)
                                                        }}
                                                        onClick={(event) => {
                                                            handleCheckedChange(rowObj, column.field, event.target.checked)
                                                        }
                                                        }
                                                    />
                                                }
                                            </td>
                                        })
                                    }
                                    {
                                        tagCovert &&
                                        <td style={{ width: '60px' }}>
                                            {
                                                rowObj?.convertido_inventario &&
                                                <span className='icon d-flex align-items-center'><i className="icon status-icon mx-2" style={{ backgroundColor: '#004C97' }}></i>Convertido</span>
                                            }

                                        </td>
                                    }
                                    {
                                        actions &&
                                        <td style={{ width: '40px' }} className='pe-0'>
                                            {/* style={{position: 'absolute',height:'37px'}} */}
                                            <div className='d-flex'>

                                                <div className="dropdown">
                                                    <button className="btn-tbl mx-1 btnActions dropdown-toggle" type="button" data-toggle="dropdown" data-boundary="window" aria-haspopup="true" aria-expanded="false">
                                                        <i className="bx bx-dots-horizontal-rounded"></i>
                                                    </button>
                                                    <ul className='dropdown-content-tbl pt-2 dropdown-menu'>
                                                        <li><a className="px-1" style={{ fontSize: '12px', color: 'gray' }}>acciones</a></li>
                                                        {
                                                            type === 1 &&
                                                            <li onClick={() => { handleAction(rowObj) }}><a className="dropdown-item"> <span className='bx bxs-folder'></span> Asignar Folder</a></li>
                                                        }
                                                        {
                                                            type === 2 && rowObj.usuarioUtilitarioId === null &&
                                                            <li onClick={() => { handleAction(rowObj, 1) }}><a className="dropdown-item"> <span className='bx bxs-car'></span> Vehículo utilitario</a></li>
                                                        }
                                                        {
                                                            type === 2 && rowObj.usuarioUtilitarioId &&
                                                            <li onClick={() => { handleAction(rowObj, 4) }}><a className="dropdown-item"> <span className='ri-user-3-fill'></span> Ver Detalle</a></li>
                                                        }
                                                        {
                                                            type === 2 && rowObj.usuarioUtilitarioId &&
                                                            <li onClick={() => { handleAction(rowObj, 2) }}><a className="dropdown-item"> <span className='bi bi-arrow-counterclockwise'></span> Historial utilitario</a></li>
                                                        }
                                                        {
                                                            type === 2 && rowObj.usuarioUtilitarioId &&
                                                            <li onClick={() => { handleAction(rowObj, 3) }}><a className="dropdown-item"> <span className='bx bxs-trash-alt'></span> Desasignar</a></li>
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </td>
                                    }

                                    <td className='ps-0'>
                                        {/* style={{position: 'absolute',height:'37px'}} */}
                                        <div className='d-flex'>
                                            {
                                                isStatus ? <div className="form-check form-switch mx-1">
                                                    <input className="form-check-input" type="checkbox" checked={rowObj.status} onChange={(e) => { handleChange(e.target.checked, rowObj) }} />
                                                </div> : null
                                            }
                                            {
                                                downtable ? <button className='btn-tbl mx-1 none-color mx-1'><i className="ri-download-line icon" onClick={() => { handleDownload(rowObj) }}></i></button> : null
                                            }
                                            {
                                                addtable ? <button className='btn-tbl mx-1 btnAdd'><i className="bx bx-list-plus" onClick={() => { handleAdd(rowObj) }}></i></button> : null
                                            }
                                            {
                                                editable && !rowObj.edit ? <button className='btn-tbl mx-1 btnEdit' onClick={() => { handleEditObj(rowObj) }}><i className="bx bx-pencil"></i></button> : null
                                            }
                                            {
                                                editable && rowObj.edit ? <><button className='btn-tbl mx-2 btnEdit' onClick={() => { handleCancel(rowObj) }}><i className="bx bx-x-circle"></i></button> <button className='btn-tbl mx-1 btnEdit' onClick={() => { handleSaveObj(rowObj) }}><i className="bx bx-save"></i></button></> : null
                                            }
                                            {
                                                deleteable ? <fieldset className='btn-tbl mx-1 btnDelete' style={{ paddingLeft: '6px', paddingRight: '6px' }} onClick={() => { handleDelete(rowObj) }}><i className="bx bxs-trash-alt"></i></fieldset> : null
                                            }
                                            {
                                                detailable ? <button className='btn-tbl mx-1 btnDetail' onClick={() => { handleDetail(rowObj) }}><i className="bx bxs-chevron-right"></i></button> : null
                                            }
                                            {
                                                recover ? <button className='btn-tbl mx-1 btnDetail' onClick={() => { handleRecover(rowObj) }}><i className="bi bi-reply-fill"></i></button> : null
                                            }
                                            {
                                                testDriverMantener ? <button className={`btn-tbl-circle mx-1 ${rowObj.mantener ? "active-color" : "none-color"} `} data-toggle="tooltip" data-placement="top" title={`${rowObj.mantener ? "Dejar de mantener" : "Mantener prueba"}`} onClick={() => { handleTDrive(rowObj) }}><i className={`bi bi-lightbulb ico ${rowObj.mantener ? "icon-white" : "icon"}`}></i></button> : null
                                            }
                                            {
                                                testDriverLiberar ? <button className={`btn-tbl-circle mx-1 none-color`} data-toggle="tooltip" data-placement="top" title="Concluir prueba de manejo" onClick={() => { handleEndTDrive(rowObj) }}><i className={`ri-check-fill ico icon`}></i></button> : null
                                            }
                                            {
                                                testDriverHistorial ? <button className={`btn-tbl-circle mx-1 none-color`} data-toggle="tooltip" data-placement="top" title="Ver historial de estatus" onClick={() => { handleHistoryTDrive(rowObj) }}><i className={`ri-file-list-line ico icon`}></i></button> : null
                                            }
                                            {
                                                autorizar ? <button className={`btn-tbl-circle-st mx-1`} data-toggle="tooltip" data-placement="top" title="Autorizar" onClick={() => { handleAction(rowObj, 1) }}><i className={`ri-check-fill ico`}></i></button> : null
                                            }
                                            {
                                                rechazar ? <button className={`btn-tbl-circle-st mx-1`} data-toggle="tooltip" data-placement="top" title="Rechazar" onClick={() => { handleAction(rowObj, 0) }}><i className={`ri-close-line ico`}></i></button> : null
                                            }
                                            {
                                                autorizaCita ? <button className={`btn-transparent mx-1`} data-toggle="tooltip" data-placement="top" title="Autorizar Cita" onClick={() => { handleAutorizaCita(rowObj) }}><i className={`ri-arrow-right-s-line ico`}></i></button> : null
                                            }
                                            {
                                                rowObj?.sinRenovar &&
                                                    sinRenovar ? <button className={`btn-tbl mx-1 mx-1`} data-toggle="tooltip" data-placement="top" title="Sin Renovar" onClick={() => { handleAction(rowObj) }}><i className={`bx bxs-shield-x ico `}></i></button> : null
                                            }
                                        </div>
                                    </td>
                                </tr>
                            }) : <tr><td colSpan={(columns.length + 1)} className="text-center">No se encontrarón registros</td></tr>

                    }
                </tbody>
            </table>
            {
                paginate && TotalPage > 0 &&
                <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={TotalPage}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                    forcePage={currentPage}
                />
            }

        </>
    )
}

export default DataTableDinamica