import { Form, Formik, Field } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { TextField } from '../../../../Components/TextField';
import Modal from '../../../../Components/Modal/Modal';
import Spinner from '../../../../Components/Loadig';
import { SelectField } from '../../../../Components/SelectField';
import useRevisionDatamovil from '../../../../Hooks/DatamovilSystems/RevisionPasos/useRevisionDatamovil';
import { TextAreaField } from '../../../../Components/TextAreaField';
import toast, { Toaster } from 'react-hot-toast';

const CardRevisionDatamovil = ({ handleConcluido, setRequiereCosto }) => {
    const { refFormik } = useRef();
    const [collapsible, setCollapsible] = useState(false);
    const { state } = useLocation();

    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        servicioConcluido: Yup.boolean(),
        ...[
            "numeroServicio",
            "formatoHojaId",
            "observaciones",
            "ejecutivoName",
            "gps",
            "teclado",
            "comentariosAlteracion",
            "bloqueador"
        ].reduce((acc, field) => {
            acc[field] = Yup.string().nullable().when("servicioConcluido", {
                is: true,
                then: Yup.string().nullable().required("Campo requerido"),
            });
            return acc;
        }, {}),
        
        aplicaCobro: Yup.boolean()
            .nullable()
            .when("servicioConcluido", {
                is: true,
                then: Yup.boolean()
                    .nullable()
                    .required("Selecciona una opción"),
            }),
        alteracionDatamovil: Yup.boolean()
            .nullable()
            .when("servicioConcluido", {
                is: true,
                then: Yup.boolean()
                    .nullable()
                    .required("Selecciona una opción"),
            }),
        datamovilAlterado: Yup.boolean()
            .nullable()
            .when("servicioConcluido", {
                is: true,
                then: Yup.boolean()
                    .nullable()
                    .required("Selecciona una opción"),
            }),

        equipoCompleto: Yup.boolean()
            .nullable()
            .when("servicioConcluido", {
                is: true,
                then: Yup.boolean()
                    .nullable()
                    .required("Selecciona una opción"),
            }),
        funciona: Yup.boolean()
            .nullable()
            .when("servicioConcluido", {
                is: true,
                then: Yup.boolean()
                    .nullable()
                    .required("Selecciona una opción"),
            }),
        requiereReparacion: Yup.boolean()
            .nullable()
            .when("servicioConcluido", {
                is: true,
                then: Yup.boolean()
                    .nullable()
                    .required("Selecciona una opción"),
            }),
        motivosAlteracion: Yup.string()
            .nullable()
            .when(["servicioConcluido", "datamovilAlterado"], {
                is: (servicioConcluido, datamovilAlterado) => servicioConcluido && datamovilAlterado,
                then: Yup.string()
                    .nullable()
                    .required("Selecciona una opción"),
            }),

        observacionesAlteracion: Yup.string()
            .nullable()
            .when(["servicioConcluido", "datamovilAlterado"], {
                is: (servicioConcluido, datamovilAlterado) => servicioConcluido && datamovilAlterado,
                then: Yup.string()
                    .nullable()
                    .required("El comentario es necesario"),
            }),

        componenteDispositivoEquipo: Yup.string()
            .nullable()
            .when(["servicioConcluido", "equipoCompleto"], {
                is: (servicioConcluido, equipoCompleto) => {
                    return (servicioConcluido && equipoCompleto === false);
                },
                then: Yup.string()
                    .nullable()
                    .required("Selecciona una opción"),
            }),

        comentariosFuncionamientoEquipo: Yup.string()
            .nullable()
            .when(["servicioConcluido", "equipoCompleto"], {
                is: (servicioConcluido, equipoCompleto) => servicioConcluido && equipoCompleto === false,
                then: Yup.string()
                    .nullable().required("El comentario es requerido"),
            }),

        componenteDispositivoFuciona: Yup.string()
            .nullable()
            .when(["servicioConcluido", "funciona"], {
                is: (servicioConcluido, funciona) => servicioConcluido && funciona === false,
                then: Yup.string()
                    .nullable()
                    .required("Selecciona una opción"),
            }),

        comentariosFuncionamientoFunciona: Yup.string()
            .nullable()
            .when(["servicioConcluido", "funciona"], {
                is: (servicioConcluido, funciona) => servicioConcluido && funciona === false,
                then: Yup.string()
                    .nullable()
                    .required("El comentario es requerido"),
            }),

        componenteDispositivoReparaciones: Yup.string()
            .nullable()
            .when(["servicioConcluido", "requiereReparacion"], {
                is: (servicioConcluido, requiereReparacion) => servicioConcluido && requiereReparacion,
                then: Yup.string()
                    .nullable()
                    .required("Selecciona una opción"),
            }),

        comentariosFuncionamientoReparaciones: Yup.string()
            .nullable()
            .when(["servicioConcluido", "requiereReparacion"], {
                is: (servicioConcluido, requiereReparacion) => servicioConcluido && requiereReparacion,
                then: Yup.string()
                    .nullable()
                    .required("El comentario es requerido"),
            }),
    });


    const handleUploadFile = async () => {
        navigate(`/datamovil/datadocs/fRevInmov/eRevInmov/${state.contrato}/${state.servicioId}`, {
            state: {
                ...state,
                registroId: state.servicioId,
                vin: state.vin,
                nombreMarca: state.marca,
                nombreModelo: state.modelo,
                nombreVersion: state.version,
                nombreYear: state.anio
            }
        });
    }

    const {
        initialValue,
        loading,
        setLoading,
        submitForm,
        opcionesBool,
        formatoHoja,
        componentes,
        motivos
    } = useRevisionDatamovil({ handleConcluido, setRequiereCosto, toast })

    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                <div className='row mx-md-4 my-4'>
                    <div className="col-12 px-md-3">
                        <div className="p-4 white-wrapper mb-2">
                            <div
                                className="row mt-2"
                                data-bs-toggle="collapse"
                                href="#collapseCardRevision"
                                role="button"
                                aria-expanded="false"
                                aria-controls="collapseCardRevision"
                                onClick={() => setCollapsible(!collapsible)}
                            >
                                <div className="col-11">
                                    <h6><span className={`semaforo semaforo-${initialValue?.servicioConcluido ? "green" : "default"}`}></span> <strong>Revisión Datamovil</strong></h6>
                                </div>
                                <div className="col-11">
                                    <small>{initialValue.servicioConcluido ? " Concluido" : " En Proceso"}</small>
                                </div>
                                <div className="col-1 text-end">
                                    <i className={!collapsible ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                </div>
                            </div>
                            <div className="collapse" id="collapseCardRevision">
                                <div className="divider"></div>
                                <Formik
                                    innerRef={refFormik}
                                    enableReinitialize={true}
                                    initialValues={initialValue}
                                    validationSchema={validationSchema}
                                    onSubmit={(values) => submitForm(values)}
                                >
                                    {({ setFieldValue, values }) => {
                                        //console.log("servicioConcluido:", values.servicioConcluido);
                                        //console.log("equipoCompleto:", values.equipoCompleto);
                                        return (
                                            <Form id="revisionDatamovilForm">

                                                <div className="row mt-2 mb-2">
                                                    <div className='col-4'>
                                                        <h6><strong>Detalle de ubicacion</strong></h6>
                                                    </div>
                                                    <div className="col-8">
                                                        <div className="row justify-content-end">
                                                            <div className="col-auto">
                                                                <button
                                                                    id="btnSubirDocumento"
                                                                    className="btn btn-outline col-12 mw-100 d-flex "
                                                                    type="button"
                                                                    onClick={handleUploadFile}
                                                                    style={{
                                                                        padding: "8px",
                                                                        lineHeight: "20px",
                                                                        border: "none",
                                                                        backgroundColor: "#f0f0f0",
                                                                    }}>
                                                                    <i className="ri-file-3-fill fs-4" style={{ color: "#004C97", marginRight: "8px", }} ></i>
                                                                    Subir documento
                                                                </button>
                                                            </div>
                                                            <div className="col-auto">
                                                                {
                                                                    initialValue.servicioConcluido ?
                                                                        <fieldset className='btn btn-light secondary d-flex align-items-center mx-2'><i className="icon-dark bx bx-hide mx-1" ></i>Modo lectura</fieldset>
                                                                        : <button
                                                                            className='btn'
                                                                            disabled={initialValue.servicioConcluido}
                                                                            type='submit'>
                                                                            Guardar
                                                                        </button>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row mb-3">
                                                    <div className="col-md-3">
                                                        <SelectField
                                                            label="¿Alteración de instalación de Datamovil?"
                                                            name="alteracionDatamovil"
                                                            disabled={initialValue.servicioConcluido}
                                                            items={opcionesBool}
                                                            onChange={(event) => setFieldValue("alteracionDatamovil", event.value)}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="row mb-3">
                                                    <div className="col-md-6">
                                                        <TextAreaField
                                                            label="Comentarios de instalación"
                                                            disabled={initialValue?.servicioConcluido}
                                                            className="form-control col-12"
                                                            rows="3"
                                                            name="comentariosAlteracion"
                                                            type="text"
                                                            holder="Escribe"
                                                            onChange={(event) => {
                                                                setFieldValue("comentariosAlteracion", event.target.value);
                                                            }} />
                                                    </div>
                                                </div>

                                                <div className="row mb-3">
                                                    <div className="col-md-3">
                                                        <SelectField
                                                            label="¿Datamovil alterado?"
                                                            name="datamovilAlterado"
                                                            disabled={initialValue.servicioConcluido}
                                                            items={opcionesBool}
                                                            onChange={(event) => setFieldValue("datamovilAlterado", event.value)}
                                                        />
                                                    </div>

                                                    {
                                                        (values.datamovilAlterado === true && values.datamovilAlterado !== null) && (
                                                            <>
                                                                <div className="col-md-3">
                                                                    <SelectField
                                                                        label="Motivos de alteracion "
                                                                        name="motivosAlteracion"
                                                                        disabled={initialValue.servicioConcluido}
                                                                        items={motivos}
                                                                        onChange={(event) => setFieldValue("motivosAlteracion", event.value)}
                                                                    />
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                </div>
                                                {
                                                    (values.datamovilAlterado === true && values.datamovilAlterado !== null) && (
                                                        <div className="row mb-3">
                                                            <div className="col-md-6">
                                                                <TextAreaField
                                                                    label="Observaciones de alteracion "
                                                                    disabled={initialValue?.servicioConcluido}
                                                                    className="form-control col-12"
                                                                    rows="3"
                                                                    name="observacionesAlteracion"
                                                                    type="text"
                                                                    holder="Escribe"
                                                                    onChange={(event) => {
                                                                        setFieldValue("observacionesAlteracion", event.target.value);
                                                                    }} />
                                                            </div>
                                                        </div>
                                                    )
                                                }

                                                <div className="row mb-3">
                                                    <div className="col-md-3">
                                                        <TextField
                                                            label="Número de servicio"
                                                            disabled={initialValue?.servicioConcluido}
                                                            name="numeroServicio"
                                                            id="numeroServicio"
                                                            type="text"
                                                            holder=''
                                                            onChange={(event) => setFieldValue("numeroServicio", event.target.value)}
                                                        />
                                                    </div>
                                                    <div className="col-md-3">
                                                        <SelectField
                                                            label="Formato de hoja"
                                                            name="formatoHojaId"
                                                            disabled={initialValue.servicioConcluido}
                                                            items={formatoHoja}
                                                            onChange={(event) => setFieldValue("formatoHojaId", event.value)}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="row mb-3">
                                                    <div className="col-md-3">
                                                        <SelectField
                                                            label="¿Equipo completo?"
                                                            name="equipoCompleto"
                                                            disabled={initialValue.servicioConcluido}
                                                            items={opcionesBool}
                                                            onChange={(event) => setFieldValue("equipoCompleto", event.value)}
                                                        />
                                                    </div>
                                                    {
                                                        (values.equipoCompleto === false && values.equipoCompleto !== null) && (
                                                            <>
                                                                <div className="col-md-3">
                                                                    <SelectField
                                                                        label="Componente del dispositivo "
                                                                        name="componenteDispositivoEquipo"
                                                                        disabled={initialValue.servicioConcluido}
                                                                        items={componentes}
                                                                        onChange={(event) => setFieldValue("componenteDispositivoEquipo", event.value)}
                                                                    />
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <TextField
                                                                        label="Comentarios del funcionamiento "
                                                                        name="comentariosFuncionamientoEquipo"
                                                                        type="text"
                                                                        disabled={initialValue.servicioConcluido}
                                                                        holder="Escribe"
                                                                        onChange={(event) => setFieldValue("comentariosFuncionamientoEquipo", event.target.value)}
                                                                    />
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                </div>

                                                <div className='row mb-3'>
                                                    <div className="col-md-3">
                                                        <SelectField
                                                            label="¿Funciona?"
                                                            name="funciona"
                                                            disabled={initialValue.servicioConcluido}
                                                            items={opcionesBool}
                                                            onChange={(event) => setFieldValue("funciona", event.value)}
                                                        />
                                                    </div>
                                                    {
                                                        (values.funciona === false && values.funciona !== null) && (
                                                            <>
                                                                <div className="col-md-3">
                                                                    <SelectField
                                                                        label="Componente del dispositivo "
                                                                        name="componenteDispositivoFuciona"
                                                                        disabled={initialValue.servicioConcluido}
                                                                        items={componentes}
                                                                        onChange={(event) => setFieldValue("componenteDispositivoFuciona", event.value)}
                                                                    />
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <TextField
                                                                        label="Comentarios del funcionamiento "
                                                                        name="comentariosFuncionamientoFunciona"
                                                                        type="text"
                                                                        disabled={initialValue.servicioConcluido}
                                                                        holder="Escribe"
                                                                        onChange={(event) => setFieldValue("comentariosFuncionamientoFunciona", event.target.value)}
                                                                    />
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                </div>

                                                <div className="row mb-3">
                                                    <div className="col-md-3">
                                                        <SelectField
                                                            label="¿El equipo requiere reparaciones?"
                                                            name="requiereReparacion"
                                                            disabled={initialValue.servicioConcluido}
                                                            items={opcionesBool}
                                                            onChange={(event) => setFieldValue("requiereReparacion", event.value)}
                                                        />
                                                    </div>
                                                    {
                                                        (values.requiereReparacion && values.requiereReparacion !== null) && (
                                                            <>
                                                                <div className="col-md-3">
                                                                    <SelectField
                                                                        label="Componente del dispositivo "
                                                                        name="componenteDispositivoReparaciones"
                                                                        disabled={initialValue.servicioConcluido}
                                                                        items={componentes}
                                                                        onChange={(event) => setFieldValue("componenteDispositivoReparaciones", event.value)}
                                                                    />
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <TextField
                                                                        label="Comentarios del funcionamiento "
                                                                        name="comentariosFuncionamientoReparaciones"
                                                                        type="text"
                                                                        disabled={initialValue.servicioConcluido}
                                                                        holder="Escribe"
                                                                        onChange={(event) => setFieldValue("comentariosFuncionamientoReparaciones", event.target.value)}
                                                                    />
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                </div>

                                                <div className="row mb-3">
                                                    <h6><strong>Componentes</strong></h6>
                                                    <div className="col-md-3">
                                                        <TextField
                                                            label="Teclado"
                                                            name="teclado"
                                                            type="text"
                                                            disabled={initialValue.servicioConcluido}
                                                            holder=""
                                                            onChange={(event) => setFieldValue("teclado", event.target.value)}
                                                        />
                                                    </div>
                                                    <div className="col-md-3">
                                                        <TextField
                                                            label="Bloqueador"
                                                            disabled={initialValue.servicioConcluido}
                                                            name="bloqueador"
                                                            type="text"
                                                            holder=''
                                                            onChange={(event) => setFieldValue("bloqueador", event.target.value)}
                                                        />
                                                    </div>
                                                    <div className="col-md-3">
                                                        <TextField
                                                            label="GPS"
                                                            name="gps"
                                                            type="text"
                                                            holder=''
                                                            disabled={initialValue.servicioConcluido}
                                                            onChange={(event) => setFieldValue("gps", event.target.value)}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="row mb-3">
                                                    <div className="col-md-3">
                                                        <SelectField
                                                            label="¿Aplica un cobro?"
                                                            name="aplicaCobro"
                                                            disabled={initialValue.servicioConcluido}
                                                            items={opcionesBool}
                                                            onChange={(event) => {
                                                                setFieldValue("aplicaCobro", event.value)
                                                                setRequiereCosto(event.value)
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="row mb-3">
                                                    <div className="col-md-6">
                                                        <TextAreaField
                                                            label="Observaciones"
                                                            disabled={initialValue?.servicioConcluido}
                                                            className="form-control col-12"
                                                            rows="3"
                                                            name="observaciones"
                                                            type="text"
                                                            holder="Escribe"
                                                            onChange={(event) => {
                                                                setFieldValue("observaciones", event.target.value);
                                                            }} />
                                                    </div>
                                                </div>

                                                <div className="row mb-3">
                                                    <div className="col-md-4">
                                                        <TextField
                                                            label="Ejecutivo"
                                                            name="ejecutivoName"
                                                            type="text"
                                                            holder=''
                                                            onChange={(event) => setFieldValue("ejecutivoName", event.target.value)}
                                                            disabled
                                                        />
                                                    </div>
                                                </div>

                                                <div className="row mb-3">
                                                    <div className="col-md-3 d-flex align-items-center">
                                                        <Field type="checkbox"
                                                            disabled={initialValue.servicioConcluido}
                                                            name="servicioConcluido"
                                                            className="form-check-input me-2"
                                                            onClick={(event) => {
                                                                setFieldValue("servicioConcluido", event.target.value);
                                                            }}
                                                        />
                                                        <label className="form-check-label">Servicio concluido</label>
                                                    </div>
                                                </div>

                                                <div className="text-end">
                                                    <small className="text-muted">
                                                        {initialValue?.fechaAltaConcluido
                                                            ? `Inicio de proceso ${initialValue.fechaAltaConcluido}`
                                                            : ""}
                                                        {" | "}
                                                        {initialValue?.fechaServicioConcluido
                                                            ? `Fin de proceso ${initialValue.fechaServicioConcluido}`
                                                            : ""}
                                                    </small>

                                                </div>
                                            </Form>
                                        )
                                    }}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CardRevisionDatamovil;

