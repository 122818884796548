import React, { useRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import Spinner from "../../../../Components/Loadig"
import Modal from "../../../../Components/Modal/Modal"
import { TextField } from '../../../../Components/TextField';
import { Formik, Form, Field } from 'formik'
import { TextAreaField } from '../../../../Components/TextAreaField';
import { SelectField } from '../../../../Components/SelectField';
import { toast, Toaster } from 'react-hot-toast';
import CobranzaService from '../../../../Services/Cobranza/Cobranza';
import { message, NumFolio, formatearMontoMx } from '../../../../Utils/functions';
import { setItemGeneral, setContrato } from '../../../../Redux/Slice/Cobranza/SeguimientoVisita/SeguimientoVisitaDetalleSlice';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from "react-router-dom";
import { BrowserView } from 'react-device-detect';

const RegistroPromesaCards = React.forwardRef(({ ...props }, ref) => {
   const { contratoActual, itemGeneral } = useSelector((state) => state.SeguimientoVisitaDetalleSlice);
   const [collpsableGrua, setCollpsableGrua] = useState(false);
   const refFormik = useRef();
   const dispatch = useDispatch();
   const [fechaInicio, setFechaInicio] = useState(new Date((new Date()).setDate((new Date()).getDate() + 1)));
   const [show, setShow] = useState(false);
   const [formularioActual, establecerFormularioActual] = useState();
   const [registradoPreviamente, setRegistradoPreviamente] = useState(false);
   const [dataMotivoPromesa, setDataMotivoPromesa] = useState([])
   const navigate = useNavigate();
   const userId = sessionStorage.getItem('userId').trim()
   const { role } = useSelector((state) => state.loginSlice);

   useEffect(() => {
      setLoading(true)
      if(show){
         HandleMotivoPromesa()
      }
      else {
         setLoading(false)
      }
   }, [show])

   // const handleGetAllData = async () => {
   //    HandleMotivoPromesa()
   // setLoading(true)
   // const [] = await Promise.all([HandleMotivoPromesa()]).finally(() => {
   // setLoading(false)
   // });
   // }

   useEffect(() => {
      if (itemGeneral?.promesa !== null && itemGeneral?.promesa !== undefined) {
         handleCargarDatosPromesa();
         setShow(true);

         if (itemGeneral?.promesa?.registro_concluido === 1) {
            if (itemGeneral?.promesa?.grua === 1) {
               props.handleNextSteps(1);
            }
            else if (itemGeneral?.promesa?.grua === 0) {
               props.handleNextSteps(2);
            }
            else {
               props.handleNextSteps(0);
               //cuando es Promesa de pago y ya es registro concluido: habilitar boton de cerrar visita
               props.handleSetCerrarVisita(true);
            }
         }
      }
      else {
         if (itemGeneral?.visitaDoms !== null && itemGeneral?.visitaDoms !== undefined && itemGeneral?.visitaDoms?.[0] !== null) {
            if (itemGeneral?.visitaDoms[0]?.resultado === "Promesa de auto garantía") {
               establecerFormularioActual(0);  //Garantía de pago
               setShow(true);
            }
            else if (itemGeneral?.visitaDoms[0]?.resultado === "Promesa de pago") {
               establecerFormularioActual(1);  //Promesa de pago
               setShow(true);
            }
            else if (itemGeneral?.visitaDoms[0]?.resultado === "Promesa de auto definitiva") {
               establecerFormularioActual(2);  //Promesa de entrega definitiva
               setShow(true);
            }
            else {
               //si no es alguna de las 3 opciones donde se ve un formulario no se muestra
               setShow(false);
            }
         }
         else {
            setShow(false);
         }
      }
   }, [itemGeneral, props.show])

   const getDiasPromesa = (fecha) => {
      let dias = 0
      if (itemGeneral?.promesa !== null && itemGeneral?.promesa?.fecha_inicio !== null) {
         let fechaInicio = new Date(itemGeneral?.promesa?.fecha_inicio)
         dias = Math.ceil((new Date(fecha) - fechaInicio) / (1000 * 60 * 60 * 24));
      }
      else {
         dias = Math.ceil((new Date(fecha) - new Date()) / (1000 * 60 * 60 * 24));
      }

      return isNaN(dias) ? 0 : dias
   }

   const handleActualizarItem = async (subirdocumento) => {
      setLoading(true);

      await CobranzaService.GetAllRutasCobranza()
         .then(res => {
            res?.data?.responseData?.map(item => {
               if (item?.userid.toLowerCase() === userId.toLowerCase() || role === "DMSupervisordeCobranza" || role === "Administrador") {
                  for (const i of item?.items) {
                     if (i?.contrato !== null && i?.contrato.contrato_id === contratoActual?.contrato_id && i?.id === itemGeneral?.id) {
                        dispatch(setItemGeneral(i));
                        dispatch(setContrato(i?.contrato));
                        if(subirdocumento){
                           handleUploadFile(i?.promesa?.id)
                        }
                     }
                  }
               }
            })
         })
         .catch(e => {
            setLoading(false);
         })
         setLoading(false);
   }

   const handleCalcularPagoPorPeriodo = (monto, fecha) => {
      let frecuencia = 7;
      if (contratoActual?.frecuencia_pago?.toLowerCase() === "quincenal") {
         frecuencia = 15;
      } else if (contratoActual?.frecuencia_pago?.toLowerCase() === "mensual") {
         frecuencia = 30;
      }

      let dias_promesa = getDiasPromesa(fecha);
      let result = dias_promesa / frecuencia;

      let total = 0
      total = Number(monto * result).toFixed(2);

      return formatearMontoMx(total);
   }

   const calcularTotal = (fecha) => {
      let frecuencia = 7;
      if (contratoActual?.frecuencia_pago?.toLowerCase() === "quincenal") {
         frecuencia = 15;
      } else if (contratoActual?.frecuencia_pago?.toLowerCase() === "mensual") {
         frecuencia = 30;
      }
      let dias_promesa = getDiasPromesa(fecha);
      let result = dias_promesa / frecuencia;

      let pago_periodo = (contratoActual?.pago_periodo !== undefined && contratoActual?.pago_periodo !== null) ? contratoActual?.pago_periodo : 0
      let monto_adeudo = (contratoActual?.monto_adeudo !== undefined && contratoActual?.monto_adeudo !== null) ? contratoActual?.monto_adeudo : 0

      if (result <= 0) {
         result = 1
      }

      let res = Number((result * pago_periodo + monto_adeudo).toFixed(2))
      //es number no acepta la cadena formateada tipo money
      // setTotalNumerico(res)
      return  res;
      // return res;
   }


   const calcularPagoPeriodo = (fecha) => {
      let frecuencia = 7;
      if (contratoActual?.frecuencia_pago?.toLowerCase() === "quincenal") {
         frecuencia = 15;
      } else if (contratoActual?.frecuencia_pago?.toLowerCase() === "mensual") {
         frecuencia = 30;
      }
      let dias_promesa = getDiasPromesa(fecha);
      let result = dias_promesa / frecuencia;

      let pago_periodo = (contratoActual?.pago_periodo !== undefined && contratoActual?.pago_periodo !== null) ? contratoActual?.pago_periodo : 0
      let monto_adeudo = (contratoActual?.monto_adeudo !== undefined && contratoActual?.monto_adeudo !== null) ? contratoActual?.monto_adeudo : 0

      if (result <= 0) {
         result = 1
      }

      return isNaN(result) || isNaN (pago_periodo) ? formatearMontoMx(0) : formatearMontoMx((result * pago_periodo).toFixed(2))
   }

   const getFormatFecha = (fecha) => {
      const year = fecha.getFullYear();
      const month = String(fecha.getMonth() + 1).padStart(2, '0');
      const day = String(fecha.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
   }

   let initialGarantiaPago = {
      tipo_promesa: "Garantía de pago",
      folio: 1,
      folio_big: NumFolio(),
      proceso: "Cobranza",
      cobrador_apoyo: "",
      cobrador_apoyo_id: "",
      cobradores: "",
      grua: 2,
      fecha_vencimiento: getFormatFecha(fechaInicio),
      dias_de_promesa: getDiasPromesa(fechaInicio),
      monto_vencido: formatearMontoMx(contratoActual?.monto_adeudo),
      pago_periodo: handleCalcularPagoPorPeriodo(contratoActual?.pago_periodo, fechaInicio),
      // pago_periodo: contratoActual?.pago_periodo,
      total: formatearMontoMx(calcularTotal(fechaInicio)),
      total_float: calcularTotal(fechaInicio),
      registro_concluido: false,
      fecha_inicio: "",
      fecha_fin: "",
      fecha_registro: ""
   };
   let initialPromesaPago = {
      tipo_promesa: "Promesa de pago",
      motivoPromesa: "",
      fecha_compromiso: "",
      horarioCompromiso: "",
      folio_big: NumFolio(),
      monto_prometido: formatearMontoMx(0),
      monto_vencido: formatearMontoMx(contratoActual?.monto_adeudo),
      comentarios: "",
      registro_concluido: false,
      fecha_inicio: "",
      fecha_fin: "",
      fecha_registro: ""
   };
   let initialPromesaEntregaDefinitiva = {
      tipo_promesa: "Promesa de entrega definitiva",
      folio: 1,
      folio_big: NumFolio(),
      proceso: "Cobranza",
      cobrador_apoyo: "",
      cobrador_apoyo_id: "",
      cobradores: "",
      grua: "",
      registro_concluido: false,
      fecha_inicio: "",
      fecha_fin: "",
      fecha_compromiso: "",
      fecha_registro: ""
   };
   const [datosGarantiaPago, setDatosGarantiaPago] = useState(initialGarantiaPago);
   const [datosPromesaPago, setDatosPromesaPago] = useState(initialPromesaPago);
   const [datosPromesaEntregaDefinitiva, setDatosPromesaEntregaDefinitiva] = useState(initialPromesaEntregaDefinitiva);
   const [loading, setLoading] = useState(false);

   const dataCobradorApoyo = [
      { value: 1, label: "Si" },
      { value: 2, label: "No" }
   ];

   const dataCobrador = props.allContracts.filter(
      (item) =>
         parseInt(item?.nivel_cobrador, 10) >= 2 &&
         item?.activo === 1 &&
         item?.user_detail?.status === true
   )
      .map((item) => ({
         value: item?.user_detail?.userId,
         label: item?.user_detail?.names,
      }));

   const cobrador_id = props.allContracts.filter((item) => item?.user_detail?.names === itemGeneral?.promesa?.cobradores)[0]?.user_detail?.userId

   const dataGrua = [
      { value: 1, label: "Si" },
      { value: 2, label: "No" }
   ];

   const HandleMotivoPromesa = async () => {
      await CobranzaService.getAllMotivosPromesa()
         .then(res => {
            let motivo = []
            res.data?.responseData?.map(item => {
               if (item.activo) {
                  motivo.push({
                     value: item.id,
                     label: item.nombre
                  });
               }
            });
            setDataMotivoPromesa(motivo);
            setLoading(false)
         })
   }

   const handleCargarDatosPromesa = () => {
      if (itemGeneral?.promesa?.tipo_promesa === "Garantía de pago") {
         establecerFormularioActual(0);
         let date = new Date(itemGeneral?.promesa?.fecha_vencimiento);
         let year = date.getFullYear();
         let month = (date.getMonth() + 1).toString().length === 1 ? `0${date.getMonth() + 1}` : (date.getMonth() + 1)
         let day = date.getDate().toString().length === 1 ? `0${date.getDate()}` : date.getDate()
         setRegistradoPreviamente(Boolean(itemGeneral?.promesa?.registro_concluido));
         setDatosGarantiaPago({
            tipo_promesa: "Garantía de pago",
            folio: `${itemGeneral?.promesa?.folio}`,
            folio_big: `${itemGeneral?.promesa?.folio_big}`,
            proceso: itemGeneral?.promesa?.proceso,
            cobrador_apoyo: itemGeneral?.promesa?.cobrador_apoyo === 1 ? 1 : 2,
            cobrador_apoyo_id: (props.allContracts.filter((item) => item?.user_detail?.names === itemGeneral?.promesa?.cobradores)[0]?.user_detail?.userId),
            cobradores: itemGeneral?.promesa?.cobradores === '' ? " " : itemGeneral?.promesa?.cobradores,
            grua: itemGeneral?.promesa?.grua === 1 ? 1 : 2,
            fecha_vencimiento: `${year}-${month}-${day}`,
            dias_de_promesa: itemGeneral?.promesa?.dias_de_promesa,
            monto_vencido: formatearMontoMx(itemGeneral?.promesa?.monto_vencido),
            pago_periodo: formatearMontoMx(itemGeneral?.promesa?.pago_periodo),
            // pago_periodo: handleCalcularPagoPorPeriodo(itemGeneral?.promesa?.pago_periodo, `${year}-${month}-${day}`),
            // pago_periodo: handleCalcularPagoPorPeriodo(contratoActual.pago_periodo, `${year}-${month}-${day}`),
            total: isNaN(itemGeneral?.promesa?.total) || itemGeneral?.promesa?.total === null ? formatearMontoMx(calcularTotal(`${year}-${month}-${day}`)) : formatearMontoMx(itemGeneral?.promesa?.total),
            total_float: itemGeneral?.promesa?.total,
            registro_concluido: itemGeneral?.promesa?.registro_concluido,
            fecha_inicio: itemGeneral?.promesa?.fecha_inicio,
            fecha_fin: itemGeneral?.promesa?.fecha_fin,
            fecha_registro: itemGeneral?.promesa?.fecha_fin ?? ""
         })
      }
      if (itemGeneral?.promesa?.tipo_promesa === "Promesa de pago") {
         establecerFormularioActual(1)
         let date = new Date(itemGeneral?.promesa?.fecha_compromiso);
         let year = date.getFullYear();
         let month = (date.getMonth() + 1).toString().length === 1 ? `0${date.getMonth() + 1}` : (date.getMonth() + 1)
         let day = date.getDate().toString().length === 1 ? `0${date.getDate()}` : date.getDate()
         let hours = date.getHours().toString().length === 1 ? `0${date.getHours()}` : date.getHours()
         let minutes = date.getMinutes().toString().length === 1 ? `0${date.getMinutes()}` : date.getMinutes()
         setRegistradoPreviamente(Boolean(itemGeneral?.promesa?.registro_concluido));
         setDatosPromesaPago({
            tipo_promesa: "Promesa de pago",
            motivo_promesa: itemGeneral?.promesa?.motivo_promesa,
            fecha_compromiso: `${year}-${month}-${day}`,
            horarioCompromiso: `${hours}:${minutes}`,
            folio_big: `${itemGeneral?.promesa?.folio_big}` ?? NumFolio(),
            monto_prometido: formatearMontoMx(itemGeneral?.promesa?.monto_prometido),
            monto_vencido: formatearMontoMx(contratoActual?.monto_adeudo),
            comentarios: itemGeneral?.promesa?.comentarios,
            registro_concluido: itemGeneral?.promesa?.registro_concluido,
            fecha_inicio: itemGeneral?.promesa?.fecha_inicio,
            fecha_fin: itemGeneral?.promesa?.fecha_fin,
            fecha_registro: itemGeneral?.promesa?.fecha_fin ?? ""
         })
      }

      if (itemGeneral?.promesa?.tipo_promesa === "Promesa de entrega definitiva") {
         establecerFormularioActual(2)
         setRegistradoPreviamente(Boolean(itemGeneral?.promesa?.registro_concluido));
         let date = new Date(itemGeneral?.promesa?.fecha_compromiso);
         let year = date.getFullYear();
         let month = (date.getMonth() + 1).toString().length === 1 ? `0${date.getMonth() + 1}` : (date.getMonth() + 1)
         let day = date.getDate().toString().length === 1 ? `0${date.getDate()}` : date.getDate()
         setDatosPromesaEntregaDefinitiva({
            tipo_promesa: "Promesa de entrega definitiva",
            folio: `${itemGeneral?.promesa?.folio}`,
            folio_big: `${itemGeneral?.promesa?.folio_big}`,
            fecha_compromiso: itemGeneral?.promesa?.fecha_compromiso !== null ? `${year}-${month}-${day}` : "",
            proceso: itemGeneral?.promesa?.proceso,
            cobrador_apoyo: itemGeneral?.promesa?.cobrador_apoyo === 1 ? 1 : 2,
            cobrador_apoyo_id: props.allContracts.filter((item) => item?.user_detail?.names === itemGeneral?.promesa?.cobradores)[0]?.user_detail?.userId,
            cobradores: itemGeneral?.promesa?.cobradores === '' ? " " : itemGeneral?.promesa?.cobradores,
            grua: itemGeneral?.promesa?.grua === 1 ? 1 : 2,
            registro_concluido: itemGeneral?.promesa?.registro_concluido,
            fecha_inicio: itemGeneral?.promesa?.fecha_inicio,
            fecha_fin: itemGeneral?.promesa?.fecha_fin,
            fecha_registro: itemGeneral?.promesa?.fecha_fin ?? ""
         })
      }
   }

   useEffect(() => {
      datosGarantiaPago.cobrador_apoyo_id = cobrador_id
      datosPromesaEntregaDefinitiva.cobrador_apoyo_id = cobrador_id
   }, [cobrador_id])

   const handleGuardar = async (values, subirdocumento) => {
      setLoading(true);
      let data = {};
      let idRegistroPrincipal = uuidv4();
      if (formularioActual === 0) {
         {/* tipo promesa: garantia de pago */ }
         data = {
            "verb": "string",
            "id": "string",
            "field": "string",
            "value": "string",
            "flag": false,
            "anyObject": {
               "id": itemGeneral?.promesa === null ? idRegistroPrincipal : itemGeneral?.promesa.id,
               "ruta_head_id": itemGeneral?.ruta_head_id,
               "ruta_item_id": itemGeneral?.id,
               "evento_head_id": itemGeneral?.evento_head_id,
               "evento_item_id": itemGeneral?.evento_item_id,
               "tipo_promesa": values.tipo_promesa,
               "folio": null,
               "folio_big": values.folio_big,
               "proceso": "Cobranza",
               "cobrador_apoyo": values.cobrador_apoyo === 1 ? 1 : 0,
               "cobradores": values.cobrador_apoyo === 1 ? values.cobradores : "",
               "grua": values.grua === 1 ? 1 : 0,
               "motivo_promesa": "",
               // "total": parseFloat(values?.total?.replace(/[\$,]/g, '')),
               "total": isNaN(values.total_float) ? 0 : parseFloat(values.total_float),
               "fecha_compromiso": null,
               "fecha_vencimiento": values.fecha_vencimiento,
               "dias_de_promesa": Number(values.dias_de_promesa.toFixed(0)),
               "monto_prometido": 0,
               "monto_vencido": contratoActual?.monto_adeudo,
               "comentarios": "",
               "pago_periodo": values.pago_periodo.replace(/[^0-9.]/g, ""),
               "monto_por_cubrir": 0,
               "registro_concluido": values.registro_concluido ? 1 : 0,
               "promesa_status_id": "66498F01-9033-4BE1-9353-EE0FD4217120",
               "fecha_inicio": values.fecha_inicio !== "" ? values.fecha_inicio : getSqlServerDateTime(),
               "fecha_fin": values.registro_concluido === true ? getSqlServerDateTime() : new Date(1990, 0, 1).toISOString().replace('T', ' ').slice(0, 23),
               "fecha_registro": values.fecha_registro !== "" ? values.fecha_registro : getSqlServerDateTime()
            }
         }
      }
      if (formularioActual === 1) {
         {/* tipo promesa: promesa de pago */ }
         data = {
            "verb": "string",
            "id": "string",
            "field": "string",
            "value": "string",
            "flag": false,
            "anyObject": {
               "id": itemGeneral?.promesa === null ? idRegistroPrincipal : itemGeneral?.promesa.id,
               "ruta_head_id": itemGeneral?.ruta_head_id,
               "ruta_item_id": itemGeneral?.id,
               "evento_head_id": itemGeneral?.evento_head_id,
               "evento_item_id": itemGeneral?.evento_item_id,
               "tipo_promesa": values.tipo_promesa,
               "folio": null,
               "folio_big": values.folio_big,
               "proceso": "",
               "cobrador_apoyo": 1,
               "cobradores": "",
               "grua": 2,
               "motivo_promesa": values.motivo_promesa,
               "total": 0,
               "fecha_compromiso": `${values.fecha_compromiso}T${values.horarioCompromiso}`,
               "fecha_vencimiento": null,
               "dias_de_promesa": 5,
               "monto_prometido": parseFloat(values.monto_prometido.replace(/[^0-9.]/g, "")),
               "monto_vencido": contratoActual?.monto_adeudo,
               "comentarios": values.comentarios,
               "pago_periodo": "",
               "monto_por_cubrir": 0,
               "registro_concluido": values.registro_concluido ? 1 : 0,
               "promesa_status_id": "66498F01-9033-4BE1-9353-EE0FD4217120",
               "fecha_inicio": values.fecha_inicio !== "" ? values.fecha_inicio : getSqlServerDateTime(),
               "fecha_fin": values.registro_concluido === true ? getSqlServerDateTime() : new Date(1990, 0, 1).toISOString().replace('T', ' ').slice(0, 23),
               "fecha_registro": values.fecha_registro !== "" ? values.fecha_registro : getSqlServerDateTime()
            }
         }
      }
      if (formularioActual === 2) {
         {/* tipo promesa: promesa de entrega definitiva */ }
         data = {
            "verb": "string",
            "id": "string",
            "field": "string",
            "value": "string",
            "flag": false,
            "anyObject": {
               "id": itemGeneral?.promesa === null ? idRegistroPrincipal : itemGeneral?.promesa.id,
               "ruta_head_id": itemGeneral?.ruta_head_id,
               "ruta_item_id": itemGeneral?.id,
               "evento_head_id": itemGeneral?.evento_head_id,
               "evento_item_id": itemGeneral?.evento_item_id,
               "tipo_promesa": values.tipo_promesa,
               "folio": null,
               "folio_big": values.folio_big,
               "proceso": values.proceso,
               "cobrador_apoyo": values.cobrador_apoyo === 1 ? 1 : 0,
               "cobradores": values.cobrador_apoyo === 1 ? values.cobradores : "",
               "grua": values.grua === 1 ? 1 : 0,
               "motivo_promesa": "",
               "total": 0,
               "fecha_compromiso": null,
               "fecha_vencimiento": null,
               "dias_de_promesa": 5,
               "monto_prometido": 0,
               "monto_vencido": contratoActual?.monto_adeudo,
               "comentarios": "",
               "pago_periodo": "",
               "monto_por_cubrir": 0,
               "registro_concluido": values.registro_concluido ? 1 : 0,
               "promesa_status_id": "66498F01-9033-4BE1-9353-EE0FD4217120",
               "fecha_inicio": values.fecha_inicio !== "" ? values.fecha_inicio : getSqlServerDateTime(),
               "fecha_fin": values.registro_concluido === true ? getSqlServerDateTime() : new Date(1990, 0, 1).toISOString().replace('T', ' ').slice(0, 23),
               "fecha_registro": values.fecha_registro !== "" ? values.fecha_registro : getSqlServerDateTime()
            }
         }
      }

      console.log(data)

      await CobranzaService.createUpsertCobranzaPromesaPago(data)
         .then(res => {
            console.log(res)
            setLoading(false)
            toast.success(message("¡Operación exitosa!", res.data.mensaje));
            handleActualizarItem(subirdocumento)
            UpsertCobranzaRutaItem()
            setRegistradoPreviamente(values.registro_concluido)
            // if (values.tipo_promesa === "Promesa de pago") {
            //    handleAgendarPromesa(contratoActual?.monto_adeudo, values.fecha_compromiso)
            // }
         }).catch(e => {
            console.log(e)
            setLoading(false)
            props.handleNextSteps(false);
            if (e.response !== undefined)
               toast.error(message("¡Error!", e.response.data.message));
            else
               toast.error(message("¡Error!", e.message));
         });
   }

   const handleAgendarPromesa = async (monto, fecha_compromiso) => {
      setLoading(true);
      let data = {
         "sol_id": contratoActual?.sol_id,
         "contrato": contratoActual?.contrato_id,
         "folio_promesa_dataware": "string",
         "vin": contratoActual?.vin,
         "persona": contratoActual?.tipo_persona,
         "motivo": itemGeneral?.promesa?.motivo_promesa,
         "comentario": itemGeneral?.promesa?.comentarios,
         "monto_promesa": monto,
         "garantia": true,
         "fecha_compromiso": fecha_compromiso
      }

      console.log(data)

      await CobranzaService.AgendaPromesaPago(data)
         .then(res => {
            console.log(res)
         }).catch(e => {
            console.log(e)
            setLoading(false)
         });
      setLoading(true);
   }

   //VALIDACIONES DE FORMULARIOS
   {/* tipo promesa: garantia de pago */ }
   const validateFormGarantiaPago = Yup.object({
      // tipo_promesa: Yup.string().required("El campo es requerido."),
      // folio: Yup.string().required("El campo es requerido."),
      // proceso: Yup.string().required("El campo es requerido."),
      cobrador_apoyo: Yup.string().required("El campo es requerido."),
      cobradores: Yup.string().when('cobrador_apoyo', {
         is: "1",
         then: Yup.string().required('El campo es requerido.'),
      }),
      grua: Yup.string().required("El campo es requerido."),
      fecha_vencimiento: Yup.string().required("El campo es requerido."),
      dias_de_promesa: Yup.string().required("El campo es requerido."),
      monto_vencido: Yup.string().required("El campo es requerido."),
      pago_periodo: Yup.string().required("El campo es requerido."),
      total: Yup.string().required("El campo es requerido."),
   });
   {/* tipo promesa: promesa de pago */ }
   const validateFormPromesaPago = Yup.object({
      // tipo_promesa: Yup.string().required("El campo es requerido."),
      motivo_promesa: Yup.string().required("El campo es requerido."),
      fecha_compromiso: Yup.string().required("El campo es requerido."),
      horarioCompromiso: Yup.string().required("El campo es requerido."),
      monto_prometido: Yup.string().required("El campo es requerido."),
      monto_vencido: Yup.string().required("El campo es requerido."),
      comentarios: Yup.string().required("El campo es requerido."),
   });
   {/* tipo promesa: promesa de entrega definitiva */ }
   const validateFormPromesaEntregaDefinitica = Yup.object({
      // tipo_promesa: Yup.string().required("El campo es requerido."),
      // folio: Yup.string().required("El campo es requerido."),
      // proceso: Yup.string().required("El campo es requerido."),
      cobrador_apoyo: Yup.string().required("El campo es requerido."),
      // fecha_compromiso: Yup.string().required("El campo es requerido."),
      cobradores: Yup.string().when('cobrador_apoyo', {
         is: "1",
         then: Yup.string().required('El campo es requerido.'),
      }),
      grua: Yup.string().required("El campo es requerido."),
   });

   function formatearFecha(fechaISO) {
      if (!fechaISO) {
         return 'Fecha no válida'; // Mensaje para fechas nulas o indefinidas
      }

      const fecha = new Date(fechaISO);

      if (isNaN(fecha.getTime())) {
         return 'Fecha no válida'; // Mensaje si la fecha no puede ser convertida
      }

      const dia = fecha.getDate().toString().padStart(2, '0');
      const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
      const anio = fecha.getFullYear();
      const horas = (fecha.getHours() % 12 || 12).toString().padStart(2, '0');
      const minutos = fecha.getMinutes().toString().padStart(2, '0');
      const segundos = fecha.getSeconds().toString().padStart(2, '0');
      const ampm = fecha.getHours() >= 12 ? 'PM' : 'AM';

      return `${dia}-${mes}-${anio} ${horas}:${minutos}:${segundos} ${ampm}`;
   }

   function getSqlServerDateTime() {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const day = String(now.getDate()).padStart(2, '0');
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      const seconds = String(now.getSeconds()).padStart(2, '0');
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
   }

   const calcularMontoPrometido = (valor) => {
      const numericValue = valor.replace(/[^0-9.]/g, "");
      const validValue = numericValue.split(".").slice(0, 2).join(".");
      // return formatearMontoMx(validValue);
      return validValue;
   };

   const formatToMoney = (valor) => {
      const numero = parseFloat(valor);
      if (isNaN(numero)) return "";
      return numero.toLocaleString("es-MX", {
         style: "currency",
         currency: "MXN",
         minimumFractionDigits: 2,
         maximumFractionDigits: 2,
      });
   };

   const handleChangeMontoPrometido = (value) => {
      return (calcularMontoPrometido(value));
   };

   const handleChangeTotal = (value) => {
      return (calcularTotal(value));
   };

   const handleBlur = (value) => {
      const numericValue = parseFloat(value.replace(/[^0-9.]/g, "")) || 0;
      return formatearMontoMx(numericValue)
   };

   const handleFocus = (value) => {
      const numericValue = parseFloat(value.replace(/[^0-9.]/g, "")) || 0;
      return numericValue === 0 ? "" : numericValue;
   };

   const handleUploadFile = async (id) => {
      navigate(`/cobranza/seguimiento-visita/datadocs/fDomicilio/eSegVisit/${contratoActual?.contrato_id}/${id}`, {
         state: { ...contratoActual, registroId: id, vin: contratoActual.vin, nombreMarca: contratoActual?.vehiculo?.nombreMarca, nombreModelo: contratoActual?.vehiculo?.nombreModelo, nombreVersion: contratoActual?.vehiculo?.nombreVersion, nombreYear: contratoActual?.vehiculo?.nombreYear }
      });
   }

   const UpsertCobranzaRutaItem = async () => {
      setLoading(true);
      let data = {
         "verb": "string",
         "id": "string",
         "field": "string",
         "value": "string",
         "level": 0,
         "flag": false,
         "anyObject": {
            "id": itemGeneral?.id,
            "ruta_head_id": itemGeneral?.ruta_head_id,
            "evento_item_id": itemGeneral?.evento_item_id,
            "evento_head_id": itemGeneral?.evento_head_id,
            "fecha": itemGeneral?.fecha,
            "tipo_visita": itemGeneral?.tipo_visita,
            "estatus": itemGeneral?.estatus,
            "resultado": itemGeneral?.resultado,
            "userid": contratoActual?.userid,
            // "fecha_inicio": itemGeneral?.fecha_inicio != null ? itemGeneral?.fecha_inicio : getSqlServerDateTime(),
            // "fecha_fin": itemGeneral?.fecha_fin != null ? itemGeneral?.fecha_fin : getSqlServerDateTime(),
            "cierre_visita": itemGeneral?.cierre_visita,
            "dias_vencido": contratoActual?.dias_vencido,
            "monto_vencido": contratoActual?.monto_vencido,
            "es_aval": itemGeneral?.es_aval
         }
      }

      await CobranzaService.UpsertCobranzaRutaItem(data)
         .then(res => {
            setLoading(false)
         }).catch(e => {
            setLoading(false)
            props.handleNextSteps(false);
            if (e.response !== undefined)
               toast.error(message("¡Error!", e.response.data.message));
            else
               toast.error(message("¡Error!", e.message));
         })
   }

   return (
      <>
         <Toaster
            position="top-right"
            toastOptions={{
               success: {
                  style: {
                     background: '#47a066',
                     color: '#FFFF',
                     borderLeft: '10px solid #2f7246'
                  },
               },
               error: {
                  style: {
                     background: '#d53f3f',
                     color: '#FFFF',
                     borderLeft: '10px solid #ac241a'
                  },
               },
            }}
         />
         {
            loading &&
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
         }
         {show && (
            <div className="row wrapper-vehiculo">
               <div className="col col-12">
               <div className="row mx-md-4 my-4">
                  <div className="col-12 px-md-3">
                     <div className="p-4 white-wrapper mb-2">
                        <div
                           className="row mt-2 px-4"
                           data-bs-toggle="collapse"
                           href="#collapsePromesaPago"
                           role="button"
                           aria-expanded="false"
                           aria-controls="collapseRegistroTramite"
                           onClick={() => setCollpsableGrua(!collpsableGrua)}
                        >
                           <div className="col-11">
                              <h6>
                                 <span className={`semaforo semaforo-`} ></span>{" "}
                                 <strong>Promesa de Pago</strong>
                              </h6>
                           </div>
                           <div className="col-1 col-md-1 collapsable-icon" style={{ textAlign: "right" }} >
                              <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                           </div>
                        </div>
                     </div>
                        <div className="collapse" id="collapsePromesaPago">
                           <div className="separator"></div>
                           <div className="row mt-2 px-4">
                              {/* registro de promesa */}
                              {/* tipo promesa: garantia de pago */}
                              {formularioActual === 0 && (
                                 <Formik innerRef={refFormik}
                                    enableReinitialize={true}
                                    initialValues={datosGarantiaPago}
                                    validationSchema={validateFormGarantiaPago}
                                    onSubmit={(values) => {
                                       handleGuardar(values, false);
                                    }}
                                 >
                                    {({ isValid, values, setFieldValue }) => (
                                       <Form>
                                          <div className="row mt-2 justify-content-end">
                                             <div className="row col-lg-3 col-sm-12 justify-content-end">
                                                <div className="col-sm-6 col-lg-6">
                                                   <BrowserView>
                                                   {/* <div className="col-auto"> */}
                                                      <fieldset
                                                      onClick={()=>{
                                                         if(registradoPreviamente){
                                                            handleActualizarItem(true)
                                                         }
                                                         else{
                                                            handleGuardar(values, true)
                                                         }
                                                      }}
                                                      className='btn btn-gray'>
                                                         <i className="icon-light fa fa-file "></i> { registradoPreviamente ? "Ver documentos" : "Subir documento" } 
                                                      </fieldset>
                                                   {/* </div> */}
                                                   </BrowserView>
                                                </div>
                                                {
                                                   !registradoPreviamente 
                                                   ?  <div className="col-sm-6 col-lg-6">
                                                         <button
                                                            className="btn col-12"
                                                            type="submit"
                                                            // disabled={!(isValid) || registradoPreviamente}
                                                            style={{
                                                               padding: "10px",
                                                               lineHeight: "15px",
                                                            }}>
                                                            Guardar
                                                         </button>
                                                      </div>
                                                   :  <div className="col-sm-6 col-lg-6">
                                                      {
                                                         registradoPreviamente && (
                                                            <fieldset className='btn btn-light secondary d-flex align-items-center mx-2'>
                                                            <i className="icon-dark bx bx-hide mx-1" ></i>Modo lectura</fieldset>
                                                         )
                                                      }
                                                      </div>
                                                }
                                             </div>
                                          </div>
                                          <div className="row justify-content-between">
                                             <div className="col-sm-6 col-xl-3">
                                                <p className="text-base"><strong>Detalle de promesa de pago</strong></p>
                                             </div>
                                          </div>
                                          <div className='my-2 mt-4'>
                                             <div className="mb-3 row">
                                                <div className="col-sm-6 col-xl-3">
                                                   <TextField label="Tipo de promesa" disabled name="tipo_promesa" type="text" holder="" />
                                                </div>
                                                <div className="col-sm-6 col-xl-3">
                                                   <TextField label="Folio" name="folio_big" type="text" holder={values.folio_big === "Por definir" ? "" : values.folio_big} disabled={true} />
                                                </div>
                                                <div className="col-sm-6 col-xl-3">
                                                   <TextField label="Proceso" disabled name="proceso" type="text" value="Cobranza" />
                                                </div>
                                                <div className="col-sm-6 col-xl-3">
                                                   <SelectField id="cobrador_apoyo" disabled={registradoPreviamente} label="Cobrador de apoyo" name="cobrador_apoyo" items={dataCobradorApoyo}
                                                      onChange={(event) => {
                                                         if (event.value === "") {
                                                            setFieldValue("cobrador_apoyo", "");
                                                            setFieldValue("cobradores", "");
                                                         }
                                                         else {
                                                            setFieldValue("cobrador_apoyo", event.value);
                                                         }
                                                      }} />
                                                </div>
                                             </div>
                                             <div className='my-2 mt-4'>
                                                <div className="mb-3 row">
                                                   {
                                                      registradoPreviamente
                                                         ? <>
                                                            {
                                                               itemGeneral?.promesa?.cobrador_apoyo === 1 && (
                                                                  <div className="col-sm-6 col-xl-3">
                                                                     <TextField label="Cobradores" disabled name="cobradores" holder="" type="text" />
                                                                  </div>
                                                               )
                                                            }
                                                         </>
                                                         : values.cobrador_apoyo !== 1 ? <></> :
                                                            <div className="col-sm-6 col-xl-3">
                                                               {/* <div className="col-sm-6 col-xl-3"> */}
                                                               <SelectField id="cobrador_apoyo_id" label="Cobradores" name="cobrador_apoyo_id" items={dataCobrador}
                                                                  onChange={(event) => {
                                                                     setFieldValue("cobrador_apoyo_id", event.value)
                                                                     setFieldValue("cobradores", event.value === "" ? "" : event.label);
                                                                  }} />
                                                               {/* </div> */}
                                                            </div>
                                                   }
                                                   <div className="col-sm-6 col-xl-3">
                                                      <SelectField id="grua" disabled={registradoPreviamente} label="Grua" name="grua" items={dataGrua}
                                                         onChange={(event) => {
                                                            if (event.value === "") {
                                                               setFieldValue("grua", "");
                                                            }
                                                            else {
                                                               setFieldValue("grua", event.value);
                                                            }
                                                         }} />
                                                   </div>
                                                </div>
                                             </div>
                                             <p className="text-base"><strong>Fecha de promesa</strong></p>
                                             <div className='my-2 mt-4'>
                                                <div className="mb-3 row">
                                                   <div className="col-sm-6 col-xl-3">
                                                      <TextField id="fecha_vencimiento" disabled={registradoPreviamente} label="Fecha de vencimiento" name="fecha_vencimiento" type="date" min={new Date(fechaInicio)} holder={values.fecha_vencimiento === "" ? "Escribe" : values.fecha_vencimiento} onChange={(event) => {
                                                         setFieldValue("fecha_vencimiento", event.target.value); setFieldValue("total", calcularTotal(event.target.value)); setFieldValue("total_float", calcularTotal(event.target.value));
                                                         setFieldValue("dias_de_promesa", getDiasPromesa(event.target.value));
                                                         setFieldValue("pago_periodo", calcularPagoPeriodo(event.target.value));
                                                      }} />
                                                   </div>
                                                   <div className="col-sm-6 col-xl-3">
                                                      <TextField label="Días de promesa" name="dias_de_promesa" type="text" holder="" disabled readOnly={true} />
                                                   </div>
                                                </div>
                                             </div>
                                             <p className="text-base"><strong>Monto por cubrir</strong></p>
                                             <div className='my-2 mt-4'>
                                                <div className="mb-3 row">
                                                   <div className="col-sm-6 col-xl-3 d-flex align-items-center">
                                                      {/* <TextField label="Monto vencido" name="monto_vencido" type="text" holder="" readOnly={true} isMoney={true} /> */}
                                                      <TextField label="Monto vencido" name="monto_vencido" type="text" holder="" readOnly={true} />
                                                      <div className="mt-3 ms-3">
                                                         <i className="icon-light fa fa-plus ms-2" style={{ color: '#1351A5' }}></i>
                                                      </div>
                                                   </div>
                                                   <div className="col-sm-6 col-xl-3 d-flex align-items-center">
                                                      <TextField label="Pago de periodo" name="pago_periodo" type="text" holder="" disabled readOnly={true} />
                                                      <div className="mt-3 ms-3">
                                                         <i className="icon-light fa fa-equals ms-2" style={{ color: '#1351A5' }}></i>
                                                      </div>
                                                   </div>
                                                   <div className="col-sm-6 col-xl-3">
                                                      <TextField icon={"bi bi-currency-dollar"} disabled={registradoPreviamente} label="Total" isMoney={true} name="total" holder=""
                                                         // onChange={(event) => { setFieldValue("total", handleChangeTotal(values.fecha_vencimiento)); }}
                                                         onChange={(event) => { setFieldValue("total", isNaN(event.target.value) || event.target.value < 0 ? 0 : event.target.value); 
                                                            setFieldValue("total_float", isNaN(event.target.value) || event.target.value < 0 ? 0 : event.target.value);
                                                         }}
                                                         onBlur={(event) => { setFieldValue("total", handleBlur(event.target.value)); }}
                                                         onFocus={(event) => { setFieldValue("total", handleFocus(event.target.value)); }} />
                                                   </div>
                                                </div>
                                                <div className="mb-3 row">
                                                   <div className="col-sm-6 col-xl-3">
                                                      <Field className="form-check-input" type="checkbox" checked={values.registro_concluido} disabled={registradoPreviamente} name="registro_concluido" onChange={(event) => {
                                                         setFieldValue("registro_concluido", event.target.checked);
                                                      }} />
                                                      <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Registro concluido</label>
                                                   </div>
                                                   <div className="col-sm-12 col-md-6 col-lg-9 text-end">
                                                      {
                                                         values.fecha_inicio !== "" &&
                                                         <label>
                                                            Inicio de proceso: {formatearFecha(values.fecha_inicio)}
                                                         </label>
                                                      }
                                                      {
                                                         registradoPreviamente &&
                                                         <label style={{ whiteSpace: 'pre' }}>
                                                            {" | "}Fin de proceso: {formatearFecha(values.fecha_fin)}
                                                         </label>
                                                      }
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </Form>
                                    )}
                                 </Formik >
                              )}
                              {/* tipo promesa: promesa de pago */}
                              {formularioActual === 1 && (
                                 <Formik innerRef={refFormik}
                                    enableReinitialize={true}
                                    initialValues={datosPromesaPago}
                                    validationSchema={validateFormPromesaPago}
                                    onSubmit={(values) => {
                                       handleGuardar(values, false);
                                    }}
                                 >
                                    {({ isValid, values, setFieldValue }) => (
                                       <Form>
                                          <div className="row mt-2 justify-content-end">
                                             <div className="row col-lg-3 col-sm-12 justify-content-end">
                                                <div className="col-sm-6 col-lg-6">
                                                   <BrowserView>
                                                      {/* <div className="col-auto"> */}
                                                      <fieldset
                                                      onClick={()=>{
                                                         if(registradoPreviamente){
                                                            handleActualizarItem(true)
                                                         }
                                                         else{
                                                            handleGuardar(values, true)
                                                         }
                                                      }}
                                                      className='btn btn-gray'>
                                                         <i className="icon-light fa fa-file "></i> { registradoPreviamente ? "Ver documentos" : "Subir documento" } 
                                                      </fieldset>
                                                   {/* </div> */}
                                                   </BrowserView>
                                                </div>
                                                {
                                                   !registradoPreviamente 
                                                   ?  <div className="col-sm-6 col-lg-6">
                                                         <button
                                                            className="btn col-12"
                                                            type="submit"
                                                            // disabled={!(isValid) || registradoPreviamente}
                                                            style={{
                                                               padding: "10px",
                                                               lineHeight: "15px",
                                                            }}>
                                                            Guardar
                                                         </button>
                                                      </div>
                                                   : <div className="col-sm-6 col-lg-6">
                                                      {
                                                         registradoPreviamente && (
                                                            <fieldset className='btn btn-light secondary d-flex align-items-center mx-2'>
                                                            <i className="icon-dark bx bx-hide mx-1" ></i>Modo lectura</fieldset>
                                                         )
                                                      }
                                                      </div>
                                                }
                                             </div>
                                          </div>
                                          <div className="row justify-content-between">
                                             <div className="col-sm-6 col-xl-3">
                                                <p className="text-base"><strong>Detalle de promesa de pago</strong>
                                                </p>
                                             </div>
                                          </div>
                                          <div className="my-2 mt-4 mb-3 row">
                                             <div className="col-sm-6 col-xl-3">
                                                <TextField disabled label="Tipo de promesa" name="tipo_promesa" type="text" holder="" />
                                             </div>
                                             <div className="col-sm-6 col-xl-3">
                                                {
                                                   !registradoPreviamente
                                                      ?
                                                      <SelectField id="motivo_promesa" label="Motivo promesa" name="motivo_promesa" items={dataMotivoPromesa}
                                                         onChange={(event) => {
                                                            if (event.value == "") {
                                                               setFieldValue("motivo_promesa", "");
                                                            }
                                                            else {
                                                               setFieldValue("motivo_promesa", event.label);
                                                            }
                                                         }} /> : <TextField label="Motivo promesa" name="motivo_promesa" type="text" holder={values.motivo_promesa} disabled={true} />
                                                }
                                             </div>
                                             <div className="col-sm-6 col-xl-3">
                                                <TextField id="fecha_compromiso" label="Fecha compromiso" name="fecha_compromiso" type="date" disabled={registradoPreviamente} holder={values.fecha_compromiso === "" ? "Escribe" : values.fecha_compromiso} onChange={(event) => { setFieldValue("fecha_compromiso", event.target.value); }} />
                                             </div>
                                             <div className="col-sm-6 col-xl-3">
                                                <TextField id="horarioCompromiso" label="Horario compromiso" name="horarioCompromiso" type="time" disabled={registradoPreviamente} holder={values.horarioCompromiso === "" ? "Escribe" : values.horarioCompromiso} onChange={(event) => { setFieldValue("horarioCompromiso", event.target.value); }} />
                                             </div>
                                          </div>
                                          <div className="mb-3 row">
                                             <div className="col-sm-6 col-xl-3">
                                                <TextField label="Monto prometido" name="monto_prometido" type="text" holder="" disabled={registradoPreviamente}
                                                   onChange={(event) => { setFieldValue("monto_prometido", handleChangeMontoPrometido(event.target.value)); }}
                                                   onBlur={(event) => { setFieldValue("monto_prometido", handleBlur(event.target.value)); }}
                                                   onFocus={(event) => { setFieldValue("monto_prometido", handleFocus(event.target.value)); }}
                                                />
                                             </div>
                                             <div className="col-sm-6 col-xl-3">
                                                <TextField label="Monto vencido" name="monto_vencido" type="text" holder="" readOnly={true} disabled={registradoPreviamente} />
                                             </div>
                                          </div>
                                          <div className="mb-3 row" style={{ marginTop: 30 }}>
                                             <div className="col-12 col-sm-12 col-md-12 col-lg-10">
                                                <TextAreaField label="Comentarios promesa de pago" className="col-8 mt-2" rows="4" name="comentarios" type="text" holder="" disabled={registradoPreviamente}
                                                   style={{
                                                      border: "none",
                                                      backgroundColor: "#f0f0f0",
                                                      resize: "none",
                                                   }} />
                                             </div>
                                          </div>
                                          <div className="mb-3 row">
                                             <div className="col-sm-6 col-xl-3">
                                                <Field className="form-check-input" type="checkbox" checked={values.registro_concluido} disabled={registradoPreviamente} name="registro_concluido" onChange={(event) => {
                                                   setFieldValue("registro_concluido", event.target.checked);
                                                }} />
                                                <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Registro concluido</label>
                                             </div>
                                             <div className="col-sm-12 col-md-6 col-lg-9 text-end">
                                                {
                                                   values.fecha_inicio !== "" &&
                                                   <label>
                                                      Inicio de proceso: {formatearFecha(values.fecha_inicio)}
                                                   </label>
                                                }
                                                {
                                                   registradoPreviamente &&
                                                   <label style={{ whiteSpace: 'pre' }}>
                                                      {" | "}Fin de proceso: {formatearFecha(values.fecha_fin)}
                                                   </label>
                                                }
                                             </div>
                                          </div>
                                       </Form>
                                    )}
                                 </Formik >
                              )}
                              {/* tipo promesa: promesa de entrega definitiva */}
                              {formularioActual === 2 && (
                                 <Formik innerRef={refFormik}
                                    enableReinitialize={true}
                                    initialValues={datosPromesaEntregaDefinitiva}
                                    validationSchema={validateFormPromesaEntregaDefinitica}
                                    onSubmit={(values) => {
                                       handleGuardar(values, false);
                                    }}
                                 >
                                    {({ isValid, values, setFieldValue }) => (
                                       <Form>
                                          <div className="row mt-2 justify-content-end">
                                             <div className="row col-lg-3 col-sm-12 justify-content-end">
                                                <div className="col-sm-6 col-lg-6">
                                                <BrowserView>
                                                   {/* <div className="col-auto"> */}
                                                   <fieldset
                                                   onClick={()=>{
                                                      if(registradoPreviamente){
                                                         handleActualizarItem(true)
                                                      }
                                                      else{
                                                         handleGuardar(values, true)
                                                      }
                                                   }}
                                                   className='btn btn-gray'>
                                                      <i className="icon-light fa fa-file "></i> { registradoPreviamente ? "Ver documentos" : "Subir documento" } 
                                                   </fieldset>
                                                   {/* </div> */}
                                                   </BrowserView>
                                                </div>
                                                {
                                                   !registradoPreviamente 
                                                   ?  <div className="col-sm-6 col-lg-6">
                                                         <button
                                                            className="btn col-12"
                                                            type="submit"
                                                            // disabled={!(isValid) || registradoPreviamente}
                                                            style={{
                                                               padding: "10px",
                                                               lineHeight: "15px",
                                                            }}>
                                                            Guardar
                                                         </button>
                                                      </div>
                                                   : <div className="col-sm-6 col-lg-6">
                                                      {
                                                         registradoPreviamente && (
                                                            <fieldset className='btn btn-light secondary d-flex align-items-center mx-2'>
                                                            <i className="icon-dark bx bx-hide mx-1" ></i>Modo lectura</fieldset>
                                                         )
                                                      }
                                                      </div>
                                                }
                                             </div>
                                          </div>
                                          <div className="row justify-content-between">
                                             <div className="col-sm-6 col-xl-3">
                                                <p className="text-base"><strong>Detalle de promesa de pago</strong>
                                                </p>
                                             </div>
                                          </div>
                                          <div className='my-2 mt-4'>
                                             <div className="mb-3 row">
                                                <div className="col-sm-6 col-xl-3">
                                                   <TextField disabled label="Tipo de promesa" name="tipo_promesa" type="text" holder="" />
                                                </div>
                                                <div className="col-sm-6 col-xl-3">
                                                   <TextField label="Folio" name="folio_big" type="text" holder={values.folio_big === "Por definir" ? "" : values.folio_big} disabled={true} />
                                                </div>
                                                <div className="col-sm-6 col-xl-3">
                                                   <TextField label="Proceso" name="proceso" disabled type="text" value="Cobranza" />
                                                </div>
                                                <div className="col-sm-6 col-xl-3">
                                                   <SelectField id="cobrador_apoyo" label="Cobrador de apoyo" name="cobrador_apoyo" items={dataCobradorApoyo} disabled={registradoPreviamente}
                                                      onChange={(event) => {
                                                         if (event.value === "") {
                                                            setFieldValue("cobrador_apoyo", "");
                                                            setFieldValue("cobradores", "");
                                                         }
                                                         else {
                                                            setFieldValue("cobrador_apoyo", event.value);
                                                         }
                                                      }} />
                                                </div>
                                             </div>
                                             <div className="mb-3 row">
                                                {
                                                   registradoPreviamente
                                                      ? <>
                                                         {
                                                            itemGeneral?.promesa?.cobrador_apoyo === 1 && (
                                                               <div className="col-sm-6 col-xl-3">
                                                                  <TextField label="Cobradores" disabled name="cobradores" type="text" />
                                                               </div>
                                                            )
                                                         }
                                                      </>
                                                      : values.cobrador_apoyo !== 1 ? <></> :
                                                         <div className="col-sm-6 col-xl-3">
                                                            <SelectField id="cobrador_apoyo_id" label="Cobradores" name="cobrador_apoyo_id" items={dataCobrador}
                                                               onChange={(event) => {
                                                                  setFieldValue("cobrador_apoyo_id", event.value)
                                                                  setFieldValue("cobradores", event.value === "" ? "" : event.label);
                                                               }} />
                                                         </div>
                                                }
                                                <div className="col-sm-6 col-xl-3">
                                                   <SelectField id="grua" label="Grua" name="grua" items={dataGrua} disabled={registradoPreviamente}
                                                      onChange={(event) => {
                                                         setFieldValue("grua", event.value === "" ? "" : event.value);
                                                      }} />
                                                </div>
                                                {/* <div className="col-sm-6 col-xl-3">
                                                   <TextField id="fecha_compromiso" label="Fecha compromiso" name="fecha_compromiso" type="date" disabled={registradoPreviamente} holder={values.fecha_compromiso === "" ? "Escribe" : values.fecha_compromiso} onChange={(event) => { setFieldValue("fecha_compromiso", event.target.value); }} />
                                                </div> */}
                                             </div>
                                          </div>
                                          <div className="mb-3 row">
                                             <div className="col-sm-6 col-xl-3">
                                                <Field className="form-check-input" type="checkbox" checked={values.registro_concluido} disabled={registradoPreviamente} name="registro_concluido" onChange={(event) => {
                                                   setFieldValue("registro_concluido", event.target.checked);
                                                }} />
                                                <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Registro concluido</label>
                                             </div>
                                             <div className="col-sm-12 col-md-6 col-lg-9 text-end">
                                                {
                                                   values.fecha_inicio !== "" &&
                                                   <label>
                                                      Inicio de proceso: {formatearFecha(values.fecha_inicio)}
                                                   </label>
                                                }
                                                {
                                                   registradoPreviamente &&
                                                   <label style={{ whiteSpace: 'pre' }}>
                                                      {" | "}Fin de proceso: {formatearFecha(values.fecha_fin)}
                                                   </label>
                                                }
                                             </div>
                                          </div>
                                       </Form>
                                    )}
                                 </Formik >
                              )}
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         )}
      </>
   )
})

export default RegistroPromesaCards