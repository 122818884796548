import React, { useEffect, useRef, useState } from 'react'
import { FormatNumberMoney2, getDateFormat, message, formatearMonto } from '../../../../Utils/functions'
import DataTable from '../../../../Components/datatable/DataTable'
import { Field, Form, Formik } from 'formik'
import { toast, Toaster } from 'react-hot-toast';
import axios from 'axios';
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import AuthLogin from '../../../../Utils/AuthLogin'
import dataDocsServices from '../../../../Services/DataDocs/dataDocsServices'
import ModalCostos from './ModalCostos'
import InicioDeValuacionService from '../../../../Services/Inventario/Valuacion/InicioDeValuacionService'
import Modal from '../../../../Components/Modal/Modal';
import Spinner from '../../../../Components/Loadig';
import RowPages from '../../../../Components/RowPages';
import VisorFile from '../../../../Components/VisorFile';

const auth = new AuthLogin();
const CostosAdicionales = ({ handleConcluido, valuesInit }) => {
    console.log(valuesInit, ";;;;;;;;;")
    let url = auth.UrlDataDocs();
    const [collpsableGrua, setCollpsableGrua] = useState(false);
    const { refFormik } = useRef();
    const { id } = useParams();
    const navigate = useNavigate();
    const { state } = useLocation();

    let initial = {
        valuacionId: "",
        valuacionRegistroGastosId: "",
        concluido: handleConcluido,
        fechaInicio: "",
        fechaFin: "",
        costosAdicionalesTbl: []
    };

    const [initialValue, setInitialValue] = useState(initial);
    const [costoInitial, setCostoInitial] = useState({ costosAdicionalesTbl: [] });
    const [totalPagos, setTotalPagos] = useState(0);
    const [data, setData] = useState([]);
    const [montoFinanciar, setMontoFinanciar] = useState(0);
    const [loading, setLoading] = useState(false);
    const [selectedItem, setSelectedItem] = useState();
    const [isOpenModal, setIsOpenModal] = useState(false);
    // ver doc
    const [fileName, setFileName] = useState("");
    const [isOpenDocs, setIsOpenDocs] = useState(false)
    const [SelectedDocs, setSelectedDocs] = useState([]);

    const [pageCountH, setPageCountH] = useState()
    const [currenPageH, setCurrenPageH] = useState(1);
    const [pageRows, setpageRows] = useState(10);

    const columnsPagos = [
        { field: 'id', headerName: '#' },
        { field: 'fechaHora', headerName: 'Fecha y hora' },
        { field: 'ejecutivo', headerName: 'Ejecutivo' },
        { field: 'concepto', headerName: 'Concepto' },
        { field: 'subTotal', headerName: 'Subtotal' },
        { field: 'iva', headerName: 'IVA' },
        { field: 'total', headerName: 'Total' },
        { field: 'acciones', headerName: 'Acciones', width: 200 }
    ];


    useEffect(() => {
        getAll();
    }, [pageRows, valuesInit]);


    const getAll = async () => {

        if (!valuesInit.valuacionInicioId) {
            return;
        }

        setLoading(true);
        await InicioDeValuacionService.GetUbicacionVehiculoCostos(currenPageH, pageRows, valuesInit.valuacionInicioId)
            .then((resp) => {

                let respdata = resp.data;
                setTotalPagos(handleTotal(respdata));

                let array = respdata.map((x, idx) => {
                    return {
                        ...x,
                        id: idx + 1,
                        subTotal: `$${FormatNumberMoney2(x?.subTotal)}`,
                        total: `$${FormatNumberMoney2(x?.total)}`,
                        iva: x?.iva + '%'
                    }
                });

                setCostoInitial({ ...respdata, costosAdicionalesTbl: array });
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => setLoading(false));
    }

    const handleTotal = (array) => {
        const total = array.reduce((previous, current) => {
            return previous + current.total; // sumar el valor de una propiedad
        }, 0);
        return total;
    }

    const handleEdit = (item) => {
        setSelectedItem({ ...item });
        setIsOpenModal(true)
    }

    const handleDelete = (item) => {
        setLoading(true);
        InicioDeValuacionService.DeleteUbicacionVehiculoCostos(item.valuacionInicio_CostoAdicionalId)
            .then(resp => {
                if (resp.data.ban === 1) {
                    toast.success(message("¡Correcto!", resp.data.mensaje));
                    getAll()
                } else {
                    toast.error(message("¡Operación fallida!", resp.data.mensaje));
                }
                setLoading(false);
            }).catch(error => {
                toast.error(message("¡Operación fallida!", error.message));
                console.log(error);
                setLoading(false);
            })
    }

    const handleGetTokenDatadocs = async () => {
        await dataDocsServices.UpdateTokenDataDc()
            .then(res => {
                auth.handleAuthSesionDataDocs(res.data.data);
            }).catch(e => { })
    }

    const handleViewdocs = async (item) => {
        setLoading(true);
        setFileName(item.nombreDocumento);
        await handleGetTokenDatadocs();
        await axios.post(`${url}Documento/GetFileById/${item.idFolder}/${item.documentoId}/false`, {}, {
            headers: {
                'Authorization': 'Bearer ' + auth.tokenDataDocs(),
            },
            responseType: "blob"
        })
            .then(res => {
                var file = new File([res.data], res.data.name, { type: res.data.type });
                setSelectedDocs([file])
                setLoading(false);
                setIsOpenDocs(true)
            }).catch(e => {
                setLoading(false);
                if (e.response !== undefined)
                    toast.error(message("Error.!", e.response.data.errors?.error));
                else
                    toast.error(message("Error.!", e.message));
            })
    }

    const handleUploadFile = async (value, subido) => {
        if (subido) {
            await handleViewdocs(value)
        } else {
            navigate(`/datamovil/datadocs/fSiniestro/eContPago/${state.contrato}/${value?.valuacionInicioId}`, {
                state: { ...value, registroId: value.valuacionInicio_CostoAdicionalId, vin: state.vin, nombreMarca: state.marca, nombreModelo: state.modelo, nombreVersion: state.version, nombreYear: state.anio }
            });
        }
    }
    const handlePageClickH = (event) => {
        setCurrenPageH(event.selected + 1);
        //getAll(event.selected + 1);
    };
    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            {
                isOpenDocs &&
                <VisorFile
                    isOpen={isOpenDocs}
                    setIsOIpen={setIsOpenDocs}
                    fileName={fileName}
                    doc={SelectedDocs}
                />
            }

            <div className="row mt-2 mb-2 pt-4">
                <div className="col-auto">
                    <small><strong>costos adicionales</strong></small>
                </div>
                <div className="col-auto">
                    <fieldset
                        className='btn'
                        disabled={initialValue.concluido ?? false}
                        onClick={() => { setIsOpenModal(true); setSelectedItem({ valuacionRegistroGastosId: initialValue.valuacionRegistroGastosId, valuacionInicioId: valuesInit.valuacionInicioId }) }}>
                        Registrar costo
                    </fieldset>
                </div>
            </div>
            <div className="row mt-2 mb-2">
                <div className="col-12">
                    <DataTable
                        id="tablaPagos"
                        name="pagos"
                        column={columnsPagos}
                        data={costoInitial?.costosAdicionalesTbl}
                        editable={!initialValue.concluido}
                        handleEdit={handleEdit}
                        deleteable={!initialValue.concluido}
                        handleDelete={handleDelete}
                        handleDocuments={handleViewdocs}
                        handleAction={(value, idx) => { handleUploadFile(value, idx) }}
                        paginate={true}
                        pageCount={pageCountH}
                        handlePageClick={handlePageClickH}
                        currentPage={currenPageH - 1}
                    />
                </div>
            </div>
            <div className="row mt-2 px-4 mb-2 justify-content-end">
                <div className="col-auto">
                    <small><strong>Total: ${FormatNumberMoney2(totalPagos)}</strong></small>
                </div>
            </div>

            {
                isOpenModal &&
                <ModalCostos
                    isOpen={isOpenModal}
                    setIsOpen={setIsOpenModal}
                    item={selectedItem}
                    valuesInit={initialValue}
                    handleGetAll={getAll}
                    toast={toast}
                />
            }
        </>
    )
}

export default CostosAdicionales