import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router';
import SeguimientoVisitaService from '../../Services/Cartera/SeguimientoVisitaService';
import SeguroPolizaService from '../../Services/Seguros/SeguroPolizaService';
import CobranzaService from '../../Services/Cobranza/Cobranza';
import { message } from '../../Utils/functions';
import { FormatFecha, formatearMontoMx } from '../../Utils/functions'
import InstalacionDatamovilService from '../../Services/Inventario/InventarioDatamovilService';
import { useDispatch, useSelector } from 'react-redux';

import { setContrato, setItemGeneral } from '../../Redux/Slice/Cobranza/SeguimientoVisita/SeguimientoVisitaDetalleSlice';

const useHistorialVisitas = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const columns = [
        { field: 'folio', headerName: 'Ruta', width: 150 },
        { field: 'contrato_id', headerName: 'Contrato', width: 150 },
        { field: 'cobrador', headerName: 'Cobrador', width: 200 },
        { field: 'tipo_persona', headerName: 'Tipo de persona', width: 150 },
        { field: 'nombre', headerName: 'Nombre', width: 150 },
        { field: 'dias_vencido', headerName: 'Días vencidos', width: 150 },
        { field: 'tipo_visita', headerName: 'Tipo de visita', width: 150 },
        { field: 'monto_vencido', headerName: 'Monto vencido', width: 150 },
        { field: 'estatus', headerName: 'Resultado', width: 150 },
        { field: 'fecha_formateada', headerName: 'Fecha visita', width: 150 }
    ];

    const [textoBusqueda, setTextoBusqueda] = useState("")
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(1);
    const [loading, setLoading] = useState(false);
    const [opcionesTipoVisita, setopcionesTipoVisita] = useState([]);
    const [TipoVisita, setTipoVisita] = useState("")
    //Filtro
    const [data, setData] = useState([{}]);
    const [pageRows, setpageRows] = useState(10);
    const [currentData, setCurrentData] = useState(data);
    const [fechaInicio, setFechaInicio] = useState("");
    const [fechaFin, setFechaFin] = useState("");
    const userId = sessionStorage.getItem('userId').trim();
    const [rangoFechas, setRangoFechas] = useState();
    const [percentage, setPercentage] = useState(0)
    const { role } = useSelector((state) => state.loginSlice);
    const [listaCobradores, setListaCobradores] = useState([]);
    const [cobradorSeleccionado, setCobradorSeleccionado] = useState("")
    const [totalContratosUsuario, setTotalContratosUsuario] = useState('0')
    const [contratosUsuario, setContratosUsuario] = useState([{}]);

    const [active7, setActive7] = useState(false)
    const [active15, setActive15] = useState(false)
    let allContratos
    const [firstProgressVigente, setFirstProgressVigente] = useState({
        porcentajeActivos: 0,
        sumaTotalDinero: 0,
        idsDiferentes: 0
    })
    const [firstProgressCumplidas, setFirstProgressCumplidas] = useState({
        porcentajeActivos: 0,
        sumaTotalDinero: 0,
        idsDiferentes: 0
    })
    const [secondProgressVigentes, setSecondProgressVigentes] = useState({
        porcentajeActivos: 0,
        sumaTotalDinero: 0,
        idsDiferentes: 0
    })
    const [secondProgressCumplidas, setSecondProgressCumplidas] = useState({
        porcentajeActivos: 0,
        sumaTotalDinero: 0,
        idsDiferentes: 0
    })
    const [totalContratos, setTotalContratos] = useState('0')
    const [totalMontoVencido, setTotalMontoVencido] = useState('0')
    const [totalMontoCobrado, setTotalMontoCobrado] = useState('0')
    // const [totalContratosCartera, setTotalContratosCartera] = useState('0')
    // const [totalContratosAtendidos, setTotalContratosAtendidos] = useState('0')

    let totalContratosAtendidos = 0;
    let totalContratosCartera = 0;


    const [allPromesas, setAllPromesas] = useState()
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });

    const [isOpen, setIsOpen] = useState(false)
    const [isVisible, SetIsVisible] = useState(true)
    const dropdownRef = useRef(null)

    const toggleDropdown = () => {
        setIsOpen(!isOpen)
    }

    const toggleVisible = (visible) => {
        SetIsVisible(visible)
        setIsOpen(false)
    }

    // Volver estas funciones una sola, que active y que guarde los datos secundarios en variable seundaria a enviar.
    const toggleActive7 = () => {
        setActive15(false)
        const vigente = analizarDatos(allPromesas, 7, "vigente")
        const cumplidas = analizarDatos(allPromesas, 7, "cumplida")

        setSecondProgressVigentes(vigente)
        setSecondProgressCumplidas(cumplidas)
        setActive7(!active7)
    }

    const toggleActive15 = () => {
        setActive7(false)
        const vigente = analizarDatos(allPromesas, 15, "vigente")
        const cumplidas = analizarDatos(allPromesas, 15, "cumplida")
        setSecondProgressVigentes(vigente)
        setSecondProgressCumplidas(cumplidas)
        setActive15(!active15)
    }

    function analizarDatos(datos, dias, estado) {
        const hoy = new Date();
        hoy.setHours(0, 0, 0, 0)

        //Sacar el limite del periodo de fechas
        const fechaLimitePeriodo = new Date(hoy);
        const fechaTotal = fechaLimitePeriodo.setDate(hoy.getDate() - 15)
        const fechaTotalFormat = new Date(fechaTotal)

        //filtrado por periodo -15 dias
        let datosFiltrados = datos.filter(item => {
            // let fechaCreacion = new Date(item.fecha_creacion)
            let fechaCreacion = new Date(item?.item?.fechaCreacion)

            if (item?.item?.status === "vigente") {
                // const fechaPromesa = new Date(item.fecha_compromiso);
                const fechaPromesa = new Date(item?.item?.fechaPromesa);
                const today = new Date()
                if (fechaPromesa < today) return false
            }

            return (item?.item?.status === estado) && fechaCreacion >= fechaTotalFormat
        })

        const total = datosFiltrados.length;

        //Sacar el numero de contratos en los ultimos x dias
        const fechaLimite = hoy.setDate(hoy.getDate() - dias)
        const fechaLimiteFormat = new Date(fechaLimite)

        //Filtrado por x dias
        let datosFiltradosFecha = datos.filter(item => {
            let fechaCreacion = new Date(item?.item?.fechaCreacion)

            if (item?.item?.status === "vigente") {
                const fechaPromesa = new Date(item?.item?.fechaPromesa);
                const today = new Date()
                if (fechaPromesa < today) return false
            }

            return fechaCreacion >= fechaLimiteFormat
        })

        // Suma total de dinero
        const activos = datosFiltradosFecha.filter(obj => obj?.item?.status === estado);

        let sumaTotalDinero = activos.length > 0 ? estado === "vigente" ?//Probar el estado
            activos.reduce((total, obj) => total + obj?.item?.monto_promesa, 0) : activos.reduce((total, obj) => total + obj?.item?.pagado, 0) : 0
        // Si es Vigente devolver monto promesa, si es cumplida devolver monto recuperado.

        const sumaTotal = datosFiltrados.length > 0 ? estado === "vigente" ?//Probar el estado
            datosFiltrados.reduce((total, obj) => total + obj?.item?.monto_promesa, 0) : datosFiltrados.reduce((total, obj) => total + obj?.item?.pagado, 0) : 0

        // Número de IDs diferentes
        let idsDiferentes = activos.length

        const contratosDiferentes = datosFiltrados.length > 0 ? new Set(datosFiltrados.map(obj => obj?.item?.contrato_id)).size : 0

        // Número de estado activo y cálculo del porcentaje
        let porcentajeActivos = total > 0 ? (activos.length / total) * 100 : 0;

        //10 para acomodar el tooltip
        const tooltipPercent = Number(porcentajeActivos.toFixed(2)) + 10

        //Si dias es 7 o 15 hacer la resta de sumaTotal de activos, de ids diferentes, y procentajeActivos
        if (dias > 0) {
            if (estado === "vigente") {
                sumaTotalDinero = sumaTotalDinero - firstProgressVigente.sumaTotalDinero
                idsDiferentes = idsDiferentes - firstProgressVigente.idsDiferentes
                porcentajeActivos = porcentajeActivos - firstProgressVigente.porcentajeActivos
            }
            if (estado === "cumplida") {
                sumaTotalDinero = sumaTotalDinero - firstProgressCumplidas.sumaTotalDinero
                idsDiferentes = idsDiferentes - firstProgressCumplidas.idsDiferentes
                porcentajeActivos = porcentajeActivos - firstProgressCumplidas.porcentajeActivos
            }
        }

        return {
            sumaTotalDinero,
            idsDiferentes,
            porcentajeActivos: porcentajeActivos.toFixed(2),
            tooltip: tooltipPercent,
            totalMontoPromesas: sumaTotal,
            totalContratoPromesas: contratosDiferentes
        };
    }

    useEffect(() => {
        // habdleGetAll();
        // handleGetPromesasdePago();

        setLoading(true);
        handleCargaInicial()
    }, [])


    const handleCargaInicial = async () => {
        const [] = await Promise.all([getAllEjecutivosWithFullDetail(), habdleGetAll(), GetUserStats(), GetAllCobranzaTipoVisita()]).finally(() => {
            setLoading(false);
        });
    }

    useEffect(() => {
        let montovencido = 0;
        let montocobrado = 0;
        // Filtra los datos según los filtros seleccionados
        const filteredData = data.filter(x => {
            if (role === "DMCobranza Nivel 2") {
                return (textoBusqueda === '' || (x.folio && x.folio.toLowerCase().includes(textoBusqueda.toLowerCase())) ||
                    (x.contrato_id && x.contrato_id.toLowerCase().includes(textoBusqueda.toLowerCase())) ||
                    (x.tipo_persona && x.tipo_persona.toLowerCase().includes(textoBusqueda.toLowerCase()))) &&
                    (TipoVisita === '' || TipoVisita === 'Tipo de visita' || (x.tipo_visita && x.tipo_visita.toLowerCase().includes(TipoVisita.toLowerCase())));
            }

            if (role === "DMSupervisordeCobranza" || role === "Administrador") {
                const nameMatches = textoBusqueda === '' ||
                    (x.folio && x.folio.toLowerCase().includes(textoBusqueda.toLowerCase())) ||
                    (x.contrato_id && x.contrato_id.toLowerCase().includes(textoBusqueda.toLowerCase())) ||
                    (x.tipo_persona && x.tipo_persona.toLowerCase().includes(textoBusqueda.toLowerCase()));

                return nameMatches &&
                    (TipoVisita === '' || TipoVisita === 'Tipo de visita' || (x.tipo_visita && x.tipo_visita.toLowerCase().includes(TipoVisita.toLowerCase()))) &&
                    (cobradorSeleccionado === "" || cobradorSeleccionado === "Cobrador" || x.cobrador === cobradorSeleccionado);
            }
        });

        const filteredDataSegundoFiltro = filteredData.filter(x => {
            const fechaVisita = new Date(x.fecha);
            const cumpleFechaInicio = fechaInicio ? fechaVisita >= new Date(fechaInicio) : true;
            const cumpleFechaFin = fechaFin ? fechaVisita <= new Date(fechaFin) : true;
            return cumpleFechaInicio && cumpleFechaFin;
        }, []);


        let filteredContratosUsuario = []
        if (contratosUsuario !== undefined && contratosUsuario !== null && contratosUsuario.length !== 0) {
            filteredContratosUsuario = contratosUsuario?.filter(item => {

                const nameMatches = textoBusqueda === '' || (item?.contrato_id && item?.contrato_id.toLowerCase().includes(textoBusqueda.toLowerCase()));

                return nameMatches
                // && (estatusSeleccionado === "" || estatusSeleccionado === "Estatus" || item?.item?.items[0]?.estatus === estatusSeleccionado)
                // && (tipoSeleccionado === "" || tipoSeleccionado === "Tipo de visita" || item?.tipo_visita === tipoSeleccionado)
            })
        }

        const indexOfLastItem = currentPage * pageRows
        const indexOfFirstItem = indexOfLastItem - pageRows
        const newData = filteredDataSegundoFiltro.slice(indexOfFirstItem, indexOfLastItem)
        setCurrentData(newData)
        const newTotalPages = Math.ceil(filteredDataSegundoFiltro.length / pageRows)
        setPageCount(newTotalPages)

        // filteredData.map(m => {
        //     // montocobrado += m.contrato?.pago_periodo ?? 0;
        //     montovencido += m?.contrato?.monto_adeudo != null ? (typeof m?.contrato?.monto_adeudo === "string" ? parseFloat(m?.contrato?.monto_adeudo.replace(/[$,]/g, '')) : m?.contrato?.monto_adeudo) : 0;
        // })

        filteredData.map(m => {
            // montocobrado = montocobrado + m?.pago_periodo ?? 0;
            montovencido += (m?.monto_adeudo != null ? (typeof m?.monto_adeudo === "string" ? parseFloat(m?.monto_adeudo.replace(/[$,]/g, '')) : m?.monto_adeudo) : 0);
        })

        let contadorPromesasCumplidas = 0;
        filteredContratosUsuario.filter(x => x?.item?.status == "cumplida").map(m => {
            // if(m?.item.items.contrato.estatus === "Activo") {
            //   contratosActivos++
            // }
            montocobrado = montocobrado + (m?.item?.pagado ?? 0);
            contadorPromesasCumplidas++;
            // montovencido = montovencido + m.monto_adeudo
        })

        setTotalMontoCobrado(Number(montocobrado.toFixed(2)))
        setTotalMontoVencido(Number(montovencido.toFixed(2)))

        setTotalContratos(filteredData.length)
        // setTotalContratosUsuario(filteredContratosUsuario.length)
        setTotalContratosUsuario(contadorPromesasCumplidas)
    }, [data, pageRows, textoBusqueda, TipoVisita, currentPage, cobradorSeleccionado, contratosUsuario])

    const closeDropdown = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setIsOpen(false)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', closeDropdown)
        return () => document.removeEventListener('mousedown', closeDropdown)
    }, [])


    const getAllEjecutivosWithFullDetail = async () => {
        let userContracts = []

        let data = {
            "verb": "string",
            "id": "string",
            "field": "string",
            "value": "string",
            "level": 1,
            "flag": true,
            "anyObject": {},
            "kvp": {}
          };
          
        await SeguimientoVisitaService.getAllEjecutivosWithFullDetail(data)
            .then(res => {
                if (res.data.callSucceded) {
                    if (role === "DMCobranza Nivel 2") {
                        userContracts = res.data?.responseData?.find(user => user.userid.toLowerCase() === userId.toLowerCase());
                        // const userContracts = res.data.responseData.find(user => user.userid.toLowerCase() === 'feb42264-1529-49ad-8473-29e5e61d9402'); //Solo para pruebas  
                        // Validar y filtrar si el listado de contratos no es null o vacío
                        if (Array.isArray(userContracts?.contratos) && userContracts?.contratos?.length > 0) {
                            userContracts.contratos = userContracts.contratos.filter(item => item.cartera_vencida === 1 && item.nivel_cobranza === "2" && item.politica_aval === 0);
                        }
                        if (userContracts !== undefined && userContracts !== null) {
                            if (userContracts?.contratos) {
                                let length = userContracts?.contratos?.length
                                totalContratosCartera = length
                            }
                        }
                        let percentage = totalContratosCartera > 0 ? ((totalContratosAtendidos / totalContratosCartera) * 100) : 0
                        percentage = percentage >= 1 || percentage <= 100 ? (percentage / 100) : 0;
                        setPercentage(percentage)
                    }
                    if (role === "DMSupervisordeCobranza" || role === "Administrador") {
                        let length = 0;
                        res.data?.responseData.map(user => {
                            let usercontracts = user
                            if (Array.isArray(usercontracts?.contratos) && usercontracts?.contratos?.length > 0) {
                                usercontracts.contratos = usercontracts.contratos.filter(item => item.cartera_vencida === 1 && item.nivel_cobranza === "2" && item.politica_aval === 0);
                            }

                            if (usercontracts?.contratos !== undefined && usercontracts?.contratos !== null) {
                                length = length + usercontracts?.contratos?.length
                            }
                        })
                        totalContratosCartera = length
                        let percentage = totalContratosCartera > 0 ? ((totalContratosAtendidos / totalContratosCartera) * 100) : 0
                        percentage = percentage >= 1 || percentage <= 100 ? (percentage / 100) : 0;
                        setPercentage(percentage)
                    }
                }
            }).catch(e => {
                // setLoading(false);
                if (e.response !== undefined)
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            });
    }

    const habdleGetAll = async () => {
        // setLoading(true);
        let dataFormateada = []
        let filtrarTipoVisita = new Set()
        let newDataTipo = [{ value: 0, label: "Tipo de visita" }];
        let newDataCobradores = [{ value: 0, label: "Cobrador" }];
        let filtrarCobradores = new Set()
        let counterCobradores = 1;
        let counterTipo = 1;
        let contratosActivos = 0;
        let contratosAtendidos = 0;

        await CobranzaService.GetAllRutasCobranza()
            .then(res => {
                res?.data?.responseData?.map(item => {

                    let lista = []
                    if (role === "DMCobranza Nivel 2") {
                        if (item?.userid.toLowerCase() === userId.toLowerCase()) {
                            lista = item?.items
                        }
                    }
                    if (role === "DMSupervisordeCobranza" || role === "Administrador") {
                        lista = item?.items
                    }

                    // if (item?.userid.toLowerCase() === userId.toLowerCase()) {
                    for (const i of lista) {
                        if (i?.contrato !== null &&
                            i?.contrato.cartera_vencida === 1 &&
                            i?.cierre_visita &&
                            i?.contrato.nivel_cobranza === "2") {
                            // if (i?.contrato.estatus === "Activo") {
                            contratosActivos++
                            // }

                            filtrarTipoVisita.add(i?.tipo_visita)
                            filtrarCobradores.add(i?.contrato?.names?.names)
                            dataFormateada.push({
                                //folio: item?.folio?.toString().padStart(9, '0'),
                                folio: item?.folio_big,
                                item: item,
                                contrato_id: i?.contrato?.contrato_id,
                                tipo_persona: Boolean(i?.es_aval) ? "Aval" : "Física",
                                // nombre: Boolean(i?.es_aval) ? `${i?.contrato?.nombre_aval} ${i?.contrato?.apellido_paterno_aval} ${i?.contrato?.apellido_materno_aval}` : `${i?.contrato?.nombre} ${i?.contrato?.apellido_paterno} ${i?.contrato?.apellido_materno}`,
                                nombre: `${i?.contrato?.nombre} ${i?.contrato?.apellido_paterno} ${i?.contrato?.apellido_materno}`,
                                monto_adeudo: i?.contrato?.monto_adeudo,
                                monto_Adeudo: formatearMontoMx(i?.contrato?.monto_adeudo),
                                monto_vencido: formatearMontoMx(i?.monto_vencido),
                                fecha: i?.fecha,
                                tipo_visita: i?.tipo_visita,
                                contrato_status: `${i?.estatus}`,
                                fecha_formateada: FormatFecha(i?.fecha),
                                contrato: i?.contrato,
                                estatus: i?.visitaDoms[0]?.encontraste_cliente === 0 ? i?.visitaDoms[0]?.motivo : i?.estatus,
                                dias_vencido: i?.dias_vencido,
                                cobrador: i?.contrato?.names?.names
                            })
                        }
                    }

                    for (const i of lista) {
                        if (i?.contrato !== null &&
                            i?.contrato.cartera_vencida === 1 &&
                            i?.contrato.nivel_cobranza === "2" &&
                            // i?.estatus === "Pendiente" &&
                            i?.cierre_visita === true) {
                            contratosAtendidos++;
                        }
                    }
                    // }
                })
            }).catch(e => {
                // setLoading(false);
                if (e.response !== undefined)
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            })

        for (const value of filtrarTipoVisita) {
            newDataTipo.push({ value: counterTipo, label: value })
            counterTipo++
        }
        for (const value of filtrarCobradores) {
            newDataCobradores.push({ value: counterCobradores, label: value })
            counterCobradores++
        }

        let length = dataFormateada.length
        totalContratosAtendidos = contratosAtendidos
        // let percentage = contratosActivos > 0 ? dataFormateada.length / contratosActivos : 0
        let percentage = totalContratosCartera > 0 ? ((totalContratosAtendidos / totalContratosCartera) * 100) : 0
        percentage = percentage >= 1 || percentage <= 100 ? (percentage / 100) : 0;
        // const opcionesTipoVisita = [...new Map(dataFormateada.map(item => [item.label,{ value: item.id, label: item.tipo_visita }])).values()];
        // setopcionesTipoVisita(newDataTipo);

        setTotalContratos(length)
        setPercentage(percentage)
        setListaCobradores(newDataCobradores)

        let arregloOrdenado = dataFormateada.sort(function (a, b) {
            if (a.fecha > b.fecha) {
                return 1;
            }
            if (a.fecha < b.fecha) {
                return -1;
            }
            return 0;
        });
        setData(arregloOrdenado.reverse())
    }

    const GetAllCobranzaTipoVisita = async () => {
        // setLoading(true);
        await CobranzaService.GetAllCobranzaTipoVisita()
            .then(res => {
                let NewData = [];
                res.data.responseData?.filter(x => x.status).map(element => {
                    NewData.push({
                        value: element.id,
                        label: element.valor
                    })
                });
                setopcionesTipoVisita(NewData);
                // setLoading(false);
            }).catch(() => {
                // setLoading(false);
            })
    }

    // const habdleGetAll = async () => {
    //     setLoading(true);
    //     await SeguimientoVisitaService.getAllCobranzaRutaHead()
    //         .then(res => {
    //             const userContracts = res.data.responseData.find(user => user.userid.toLowerCase() === userId.toLowerCase());

    //             // Validar y filtrar si el listado de contratos no es null o vacío
    //             // if (Array.isArray(userContracts?.contratos) && userContracts?.contratos?.length > 0) {
    //             //     userContracts.contratos = userContracts.contratos.filter(item => item.cartera_vencida === 1 && item.nivel_cobranza === "2");
    //             // }

    //             // feb42264-1529-49ad-8473-29e5e61d9402
    //             let datosFormateados = userContracts?.items?.map((item) => {
    //                 console.log(item)
    //                 // const fechaPago = FormatFecha(item.fecha_ultimo_pago)
    //                 let filtroContrato = null
    //                 if (item?.contrato !== null && item?.contrato?.cartera_vencida === 1 && item?.contrato?.nivel_cobranza === "2") {
    //                     filtroContrato = item.contrato
    //                 }
    //                 const fechaFormateada = FormatFecha(item.fecha)
    //                 return {
    //                     ...item,
    //                     contrato: filtroContrato,
    //                     folio: userContracts.folio.toString().padStart(9, '0'),
    //                     contrato_id: item.contrato.contrato_id,
    //                     numero_ejecutivo: item.contrato.numero_ejecutivo,
    //                     nombre: item.contrato.nombre + ' ' + item.contrato.apellido_paterno + ' ' + item.contrato.apellido_materno,
    //                     dias_vencido: item.contrato.dias_vencido,
    //                     monto_adeudo: item.contrato.monto_adeudo,
    //                     fecha_formateada: fechaFormateada,
    //                     tipo_persona: filtroContrato === null ? "" : filtroContrato?.tipo_persona
    //                 }
    //             })

    //             let contratosFiltrador = userContracts?.items.filter(item => item.contrato.estatus === 'Activo')
    //             let length = userContracts?.items.filter(item => item.contrato).length;
    //             let percentage = contratosFiltrador?.length > 0 ? length / contratosFiltrador?.length : 0

    //             setTotalContratos(length)
    //             setPercentage(percentage)

    //             // setRangoFechas(userContracts.rangoFechas);

    //             const opcionesTipoVisita = [...new Map(datosFormateados.map(item => [item.label,{ value: item.id, label: item.tipo_visita }])).values()];
    //             setopcionesTipoVisita(opcionesTipoVisita);

    //             setData(datosFormateados);
    //             console.log(datosFormateados)
    //         }).catch(e => {
    //             setLoading(false);
    //             if (e.response !== undefined)
    //                 setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
    //             else
    //                 setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
    //         });
    //     setLoading(false);
    // }

    const handleDownloadFile = async () => {
        setLoading(true);
        await InstalacionDatamovilService.downloadVehiculosInventario(TipoVisita)
            .then(res => {

                var FileSaver = require('file-saver');
                FileSaver.saveAs(res.data, `VehiculoDeInventario-${new Date(Date.now()).toLocaleString().split(',')[0]}.xlsx`);

                habdleGetAll(1);

                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }

    const handlePageClick = (event) => {
        setCurrentPage(event.selected + 1);
        // habdleGetAll(event.selected + 1);
    };

    // const handleDetails = (value) => {
    //     //Movimiento a Detalles del Job
    //     navigate(`/cobranza/seguimiento-visita/historial-visitas-detalle`, {
    //         state: value
    //     })
    // }

    const handleDetails = async (value) => {
        // setLoading(true);

        let detalle = value?.item?.items?.find(item => item.contrato?.contrato_id === value.contrato_id);
        dispatch(setContrato(detalle.contrato));
        dispatch(setItemGeneral(detalle));
        navigate(`/cobranza/seguimiento-visita/historial-visitas-detalle`);

        // await CobranzaService.GetAllRutasCobranza()
        //     .then(res=>{
        //         res.data.responseData?.map(user=>{
        //         if(user.userid.toLowerCase() === userId.toLowerCase()){
        //             user.items?.map(item=>{
        //             if(item.contrato.contrato_id.toLowerCase() === contrato.contrato_id.toLowerCase()){
        //                 dispatch(setContrato(item.contrato));
        //                 dispatch(setItemGeneral(item));
        //                 navigate(`/cobranza/seguimiento-visita/historial-visitas-detalle`);
        //             }
        //             })
        //         }
        //         })
        //       setLoading(false);
        //     })
        //     .catch(e => {
        //         setLoading(false);
        //     })
    }

    const GetUserStats = async () => {
        let dataFormateada = []
        let idUsuario = (role === "DMSupervisordeCobranza" || role === "Administrador") ? (role === "Administrador" ? "Admin" : "Supervisor") : userId;

        let data = {
            "verb": "string",
            "id": idUsuario,
            "field": "string",
            "value": "string",
            "level": 0,
            "flag": true,
            "anyObject": {},
            "kvp": {}
        }

        await CobranzaService.GetUserStats(data)
            .then(res => {
                res?.data?.responseData?.map(item => {
                    dataFormateada.push({
                        item
                    });
                });

                const vigente = analizarDatos(dataFormateada, 0, "vigente")
                const cumplidas = analizarDatos(dataFormateada, 0, "cumplida")

                // const suma = dataFormateada.reduce((total, obj) => {
                //   return {
                //     montoVencido: montoVencido + obj.monto_vencido,
                //     montoCobrado: montoCobrado + obj.pagado
                //   };
                // }, { montoVencido: 0, montoCobrado: 0 }); // Inicializamos el acumulador

                setAllPromesas(dataFormateada)
                // setTotalMontoCobrado(suma.montoCobrado)
                // setTotalMontoVencido(suma.montoVencido)
                setFirstProgressVigente(vigente)
                setFirstProgressCumplidas(cumplidas)


            }).catch(e => {
                // setLoading(false);
                if (e.response !== undefined)
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            })

        let length = dataFormateada.length
        // let percentage = contratosActivos > 0 ? dataFormateada.length / contratosActivos : 0

        setTotalContratosUsuario(length)
        // setPercentage(percentage)
        // setDataTipoVisita(newDataTipo)
        setContratosUsuario(dataFormateada)
        // setLoading(false);
    }

    return {
        setTextoBusqueda,
        columns,
        currentData,
        handleDetails,
        handlePageClick,
        currentPage,
        pageCount,
        loading,
        opcionesTipoVisita,
        setpageRows,
        setTipoVisita,
        handleDownloadFile,
        setFechaInicio,
        setFechaFin,
        rangoFechas,
        dropdownRef,
        toggleDropdown,
        toggleVisible,
        firstProgressVigente,
        firstProgressCumplidas,
        secondProgressVigentes,
        secondProgressCumplidas,
        totalMontoCobrado,
        totalMontoVencido,
        active7,
        active15,
        totalContratos,
        totalContratosUsuario,
        percentage,
        isVisible,
        toggleActive7,
        toggleActive15,
        isOpen,
        role,
        listaCobradores,
        setCobradorSeleccionado
    }
}

export default useHistorialVisitas