import axios from '../../Utils/ApiService';

const UnidadesRecuperadasService = {
    /* Catalogo Promesas */
    getAllVisitaDom: async () => {
        return await axios.post(`/Cartera/GetAllVisitaDom`);
    },
    getAllMotivoIncumplimiento: async () => {
        return await axios.get(`/Cartera/GetAllMotivoIncumplimiento`);
    },
    getAllMotivoAmpliacion: async () => {
        return await axios.get(`/Cartera/GetAllMotivoAmpliacion`);
    },
    getAllTipoPersona: async () => {
        return await axios.get(`/Cartera/GetAllTipoPersona`);
    },
    getAllTipoPersonaByField: async (data) => {
        return await axios.post(`/Cartera/GetAllTipoPersonaByField`, data);
    },
    getAllCobranzaPromesaPagoByFields: async (data) => {
        return await axios.post(`/Cartera/GetAllCobranzaPromesaPagoByFields`, data);
    },
    getAllCitasEntregaUnidad: async (data) => {
        return await axios.post(`/Cartera/GetAllCitasEntregaUnidad`, data);
    },
    getAllEntregaUnidad: async (data) => {
        return await axios.post(`/Cartera/GetAllEntregaUnidad`, data);
    },
    upsertCobranzaPromesaPago: async (data) => {
        return await axios.post(`/Cartera/UpsertCobranzaPromesaPago`, data);
    },
    upsertCitasEntregaUnidad: async (data) => {
        return await axios.post(`/Cartera/UpsertCitasEntregaUnidad`, data);
    },
    upsertEntregaUnidad: async (data) => {
        return await axios.post(`/Cartera/UpsertEntregaUnidad`, data);
    },
    createNotificacionesCobranza: async (data) => {
        return await axios.post(`/Cartera/CreateNotificacionesCobranza`, data);
    },

    //Cita de entrega
    getAllCitaEntregaUnidadxVehiculo: async () => {
        return await axios.get(`/Cartera/GetAllCitaEntregaUnidadxVehiculo`);
    },
    getAllMotivoRechazoEntregaUnidad: async () => {
        return await axios.get(`/Cartera/GetAllMotivoRechazoEntregaUnidad`);
    },
    upsertCitasEntregaUnidadxVehiculo: async (data) => {
        return await axios.post(`/Cartera/UpsertCitasEntregaUnidadxVehiculo`, data);
    },
    getPagosByContrato: async (data) => {
        return await axios.post(`/Cartera/GetAllPagosCarteraByField`, data);
    }, 
    getHistorialGarantias: async (data) => {
        return await axios.post(`/Cartera/GetHistorialGarantias`, data);
    },
    getValidaUnidadRecuperada: async (data) => {
        return await axios.post(`/Cartera/GetValidaUnidadRecuperada`, data);
    },
}

export default UnidadesRecuperadasService