import { Form, Formik, Field } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FormatNumberMoney2, getDateFormat, message } from '../../../../Utils/functions'
import { toast, Toaster } from 'react-hot-toast';
import Modal from '../../../../Components/Modal/Modal';
import Spinner from '../../../../Components/Loadig';
import DataTable from '../../../../Components/datatable/DataTable'
import ModalCostos from './ModalCostos'
import DSystemsServicios from '../../../../Services/DatamovilSystems/ServiciosDatamovil';
import ModalPagos from './ModalPagos';
import { useSelector } from 'react-redux';
import AuthLogin from '../../../../Utils/AuthLogin';
import Garantias from '../../../../Services/Cartera/Garantias';
import documentsHelper from '../../../../Utils/documents';
import dataDocsServices from '../../../../Services/DataDocs/dataDocsServices';
import axios from 'axios';
import VisorFile from '../../../../Components/VisorFile';

const auth = new AuthLogin();
const RegistrosPagosTable = ({ submitButton, totalPago,concluido }) => {
    const { id } = useParams();
    const { state } = useLocation();
    const navigate = useNavigate();
    let url = auth.UrlDataDocs();
    const { clienteSiniestro,
        contratoAcendes } = useSelector((state) => state.reloadSlice);
    let userName = auth.getNombreUsuario();

    const [isOpenModal, setIsOpenModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState();
    const [loading, setLoading] = useState(false);
    const [totalPagos, setTotalPagos] = useState(0);
    const [initialValue, setInitialValue] = useState();
    const [data, setData] = useState([]);

    // ver doc
    const [fileName, setFileName] = useState("");
    const [isOpenDocs, setIsOpenDocs] = useState(false)
    const [SelectedDocs, setSelectedDocs] = useState([]);

    const columnsPagos = [
        { field: 'id', headerName: '#' },
        { field: 'fechaAlta', headerName: 'Fecha y hora' },
        { field: 'usuarioAltaStr', headerName: 'Ejecutivo' },
        { field: 'notas', headerName: 'Notas' },
        { field: 'metodoPago', headerName: 'Metodo de pago' },
        { field: 'montoStr', headerName: 'Monto' },
        { field: 'descarga', headerName: 'Recibo' },
        { field: 'acciones', headerName: 'Acciones', width: 200 }
    ];

    useEffect(() => {
        getAll()
    }, [])

    const getAll = async () => {
        setLoading(true);
        DSystemsServicios.getServicioCostosAdicionalesPago(id)
            .then(res => {
                const updatedData = res.data.map((item, index) => ({
                    ...item,
                    montoStr: `$${FormatNumberMoney2(item.monto)}`,
                    id: index + 1
                }));
                const totalPagos = updatedData.reduce((sum, item) => sum + (item.monto || 0), 0);

                setData(updatedData);
                setTotalPagos(totalPagos);
                totalPago(totalPagos);

            })
            .catch(error => {
                console.error("Error al obtener los datos:", error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleDownload = async (data) => {
        const requestBody = {
            nombrePlantilla: "Recibo",
            parametros: {
                "<<VARIABLE_FECHA>>": data?.fechaAlta?.split(" ")[0] ?? "",
                "<<VARIABLE_NOMBRE>>": clienteSiniestro,
                "<<VARIABLE_NOMBRE_USUARIO>>": userName,
                "<<VARIABLE_CONTRATO>": contratoAcendes,
                "<<VARIABLE_CANTIDAD>>": `$${FormatNumberMoney2(data.monto)}`,
                "<<VARIABLE_CONCEPTO>>": data.nota,
                "<<VARIABLE_TELEFONO>>": "",
                "<<VARIABLE_DIRECCION_UBICACION>>": "",
            }
        };

        await Garantias.obtenerDocumentoRecepcion(requestBody).then(resp => {
            //Pendiente de revisar por que no jala con el file-server                
            documentsHelper.downloadBase64File(resp.data.archivo, `${resp.data.procesoRelacionado}-${resp.data.fechaAlta.split('T')[0]}.pdf`);
        }).catch(err => {
            console.error(err);
        });
    };
    const handleEdit = (item) => {
        setSelectedItem({ ...item });
        setIsOpenModal(true)
    }

    const handleDelete = (item) => {
        setLoading(true);
        DSystemsServicios.deleteServicioCostosAdicionalesPago(item.servicioCostosAdicionalesPagoId)
            .then(res => {
                getAll()
            })
            .catch(error => {

            })
            .finally(() => {
                setLoading(false)
            })
    }
    const handleGetTokenDatadocs = async () => {
        await dataDocsServices.UpdateTokenDataDc()
            .then(res => {
                auth.handleAuthSesionDataDocs(res.data.data);
            }).catch(e => { })
    }
    const handleViewdocs = async (item) => {
        setLoading(true);
        setFileName(item.nombreDocumento);
        await handleGetTokenDatadocs();
        await axios.post(`${url}Documento/GetFileById/${item.idFolder}/${item.documentoId}/false`, {}, {
            headers: {
                'Authorization': 'Bearer ' + auth.tokenDataDocs(),
            },
            responseType: "blob"
        })
            .then(res => {
                var file = new File([res.data], res.data.name, { type: res.data.type });
                setSelectedDocs([file])
                setLoading(false);
                setIsOpenDocs(true)
            }).catch(e => {
                setLoading(false);
                if (e.response !== undefined)
                    toast.error(message("Error.!", e.response.data.errors?.error));
                else
                    toast.error(message("Error.!", e.message));
            })
    }
    const handleUploadFile = async (value, subido) => {
        if (subido) {
            await handleViewdocs(value)
        } else {
            navigate(`/datamovil/datadocs/fSiniestro/eContPago/${value?.folioId}/${value?.servicioId}`, {
                state: { ...value, registroId: value.servicioCostosAdicionalesPagoId, vin: state.vin, nombreMarca: state.marca, nombreModelo: state.modelo, nombreVersion: state.version, nombreYear: state.anio }
            });
        }
    }
    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <div className="row mt-2 mb-2 pt-4">
                <div className="col-auto">
                    <small><strong>Registro de pagos</strong></small>
                </div>
                <div className="col-auto">
                    <fieldset
                        className='btn'
                        disabled={concluido}
                        onClick={() => { setIsOpenModal(true); setSelectedItem({}) }}>
                        Registrar pago
                    </fieldset>
                </div>
            </div>
            <div className="row mt-2 mb-2">
                <div className="col-12">
                    <DataTable
                        column={columnsPagos}
                        data={data}
                        // editable={true}
                        // handleEdit={handleEdit}
                        // deleteable={true}
                        // handleDelete={handleDelete}                        
                        handleDownload={handleDownload}
                        handleAction={(value, idx) => { handleUploadFile(value, idx) }}
                    />
                </div>
            </div>
            <div className="row mt-2 px-4 mb-2 justify-content-end">
                <div className="col-auto">
                    <small><strong>Total: ${FormatNumberMoney2(totalPagos)}</strong></small>
                </div>
            </div>
            {
                isOpenModal &&
                <ModalPagos
                    isOpen={isOpenModal}
                    setIsOpen={setIsOpenModal}
                    item={selectedItem}
                    valuesInit={initialValue}
                    handleGetAll={getAll}
                    toast={toast}
                />
            }
            {
                isOpenDocs &&
                <VisorFile
                    isOpen={isOpenDocs}
                    setIsOIpen={setIsOpenDocs}
                    fileName={fileName}
                    doc={SelectedDocs}
                />
            }
        </>
    );
};

export default RegistrosPagosTable;
