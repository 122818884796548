import { Formik, Form, Field } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup';
import Modal from './Modal';
import { TextField } from '../TextField';
import ValuacionesServices from '../../Services/Inventario/ValuacionesService';
import { message } from '../../Utils/functions';
import toast, { Toaster } from 'react-hot-toast';
import Spinner from '../Loadig';

const InventarioCostosInspeccionModal = ({ modalMessage, setModalMessage, getCostosInspeccion, valuacionInspeccionUnidadId }) => {
    const refbtn = useRef();
    let initial = {
        concepto: "",
        subtotal: 0,
        iva: "",
        total: "",
    };
    const [initialValue, setInitialValue] = useState(initial);

    const validate = Yup.object({
        concepto: Yup.string()
            .required("El campo es requerido.")
            .trim("El texto no debe empezar ni terminar con espacios")
            .strict(true)
            .matches(
                "^[a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+(?: [a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+)*$",
                "La información ingresada es incorrecta."
            )
            .matches(
                "^[a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+(?: [a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+)*$",
                "solo debe existir un espacio entre cada palabra."
            )
            .min(3, 'minimo 3 caracteres'),
        subtotal: Yup.number()
            .required("El campo es requerido."),
        iva: Yup.number().test({
            name: 'is-Iva',
            skipAbsent: true,
            test(value, ctx) {
                if (value < 0)
                    return ctx.createError({ message: 'ingrese un valor positivo' })
                if (value >= 100)
                    return ctx.createError({ message: 'ingrese un valor entre 0 y 99.99' })
                return true
            }
        }),
        total: Yup.number()
            .required("El campo es requerido.")
    });

    const handleAddEdit = async (values) => {
        values.valuacionInspeccionUnidadId = valuacionInspeccionUnidadId;
        refbtn.current.disabled = true;
        await ValuacionesServices.postCostos(values)
            .then(resp => {
                toast.success(message("¡Operación exitosa!", "Costo registrado correctamente"));
                getCostosInspeccion(valuacionInspeccionUnidadId);
            })
            .catch(err => {
                toast.error(message("¡Operación fallida!", "Error al agregar el costo"));
            })
            .finally(() => {
                setModalMessage({ modalMessage: false });
                refbtn.current.disabled = false;
            });
    }

    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <Formik
                enableReinitialize={true}
                initialValues={initialValue}
                validationSchema={validate}
                onSubmit={(values) => {
                    handleAddEdit(values);
                }}
            >
                {({ isValid, setFieldValue, values }) => (
                    <Modal isOpen={modalMessage} color='#fff'>
                        <section className='d-flex justify-content-between mx-4'>
                            <section className='py-2 d-flex flex-column'>
                                <div className='d-flex justify-content-between'>
                                    <span className=''><strong>Registrar costo</strong></span>
                                </div>
                                <span className='mt-2 fs-6'>A continuación completa todos los campos para el registro del costos.</span>
                            </section>
                        </section>
                        <section className='my-4 mx-4' style={{ width: '30rem' }}>
                            <Form>
                                <div className="mb-3 row">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <TextField label="Concepto" name="concepto" type="text" holder="Escribe" />
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <TextField 
                                        isMoney={true} 
                                        icon={"bi bi-currency-dollar"} 
                                        label="Subtotal" name="subTotal"  holder="Escribe" onChange={(event) => {
                                            setFieldValue("subTotal", event.target.value)
                                            let total = (parseFloat(event.target.value) * parseInt(values.iva) / 100) + parseFloat(event.target.value);
                                            setFieldValue("total", total);
                                        }} />
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <TextField label="IVA (%)" name="iva" type="number" holder="Escribe" onChange={(event) => {
                                            setFieldValue("iva", event.target.value);
                                            let total = (parseFloat(values.subTotal) * parseInt(event.target.value) / 100) + parseFloat(values.subTotal);
                                            setFieldValue("total", total);
                                        }} />
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <TextField label="Total"
                                            isMoney={true}
                                            icon={"bi bi-currency-dollar"}
                                            disabled name="total" type="number" holder="Escribe" />
                                    </div>
                                </div>
                                <section className='my-3'>
                                    <button id="AT_btnCancelar" className='btn btn-outline' type='reset' onClick={() => { setModalMessage(false) }}>Cancelar</button>
                                    <button id="AT_btnGuardar" className='btn mx-2' ref={refbtn} type="submit" disabled={!(isValid)}>Guardar</button>
                                </section>
                            </Form>
                        </section>
                    </Modal>
                )}
            </Formik>
        </>
    )
}

export default InventarioCostosInspeccionModal