import { useParams } from "react-router-dom";
import { Formik } from "formik";
import React, { useEffect, useRef, useState } from 'react'
import Modal from "../../../Components/Modal/Modal";
import Spinner from "../../../Components/Loadig";
import ListadoServiciosHook from "../../../Hooks/DatamovilSystems/ServiciosListados/ListadoServiciosHook";
import { TextField } from "../../../Components/TextField";
import DataTable from "../../../Components/datatable/DataTable";
import { SelectField } from "../../../Components/SelectField";
import ModalCrearManual from "./ModalCrearManual";
import ModalBusquedaAvanzada from "./ModalBusquedaAvanzada";
import { DataProcesos, Proceso } from "../../../Utils/functions";
import RowPages from "../../../Components/RowPages";
import { ValidatePermission, ValidatePermissionById } from "../../../Auth/ValidatePermission";
import { Section, SubModule } from "../../../Auth/Authorization";

const SeguimientoServiciosXTipo = () => {
    const { id } = useParams();
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isOpenModalSearch, setIsOpenModalSearch] = useState(false);
    let tipoServicios = {
        1: 'Revisión Datamovil',
        2: 'Programación en demo',
        3: 'Desinstalación Datamovil',
        4: 'Reinstalación Datamovil'
    }

    const {
        refFormik,
        initialValue,
        data,
        loading,
        setBuscar,
        buscar,
        columns,
        setModalMessage,
        modalMessage,
        procesos,
        setProceso,
        handleEdit,
        busquedaAvanzada,
        setBusquedaAvanzada,
        selectRefStatus,
        totalPages,
        currentPage,
        handlePageClick,
        setpageRows
    } = ListadoServiciosHook();

    return (
        <>
            <Formik
                innerRef={refFormik}
                enableReinitialize={true}
                initialValues={initialValue}
            // validationSchema={validate}
            >
                {({ isValid, values, setFieldValue, errors }) => (
                    <>
                        <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                        <section className="mt-2 mx-2">
                            <div className='pb-4'>
                                <section className='mx-4 my-4 d-flex flex-column'>
                                    <div className="col-12 mt-2">
                                        <div className="row justify-content-between">
                                            <div className="col-auto">
                                                <strong className='mt-2'>{tipoServicios[id]}</strong>
                                            </div>
                                            <ValidatePermission isActive={ValidatePermissionById(4, Section.RevisionSection, SubModule.Revision).Ver}>
                                                <ValidatePermission isActive={ValidatePermissionById(4, Section.RevisionSection, SubModule.Revision).Agregar}>
                                                    <div className="col-auto">
                                                        <button
                                                            className="btn"
                                                            onClick={() => { setIsOpenModal(true) }}
                                                        >
                                                            Crear Manual
                                                        </button>
                                                    </div>
                                                </ValidatePermission>
                                            </ValidatePermission>
                                        </div>
                                    </div>

                                    <ValidatePermission isActive={ValidatePermissionById(4, Section.RevisionSection, SubModule.Revision).Ver}>

                                        <div className='col-12'>
                                            <div className="row">
                                                <div className="col-12 col-md-2 mt-2 mr-1 inner-addon right-addon">
                                                    <i className="glyphicon fas fa-search"></i>
                                                    <TextField id="buscar" label="" name="buscar" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("buscar", event.target.value);
                                                        setBuscar(event.target.value)
                                                    }} />
                                                </div>
                                                <div className="col-12 col-md-2 mt-2 mr-1 inner-addon right-addon">

                                                    <SelectField id="sucursalId" name="sucursalId" items={Proceso} onChange={(event) => {
                                                        setFieldValue("sucursalId", event.value);
                                                        setProceso(event.value)
                                                    }} />
                                                </div>

                                                <div className="col-12 col-md-auto my-1 px-1">
                                                    <button
                                                        className="btn btn-blue me-2"
                                                        onClick={() => { setIsOpenModalSearch(true) }}
                                                    >
                                                        Busqueda avanzada
                                                    </button>
                                                    <button
                                                        className="btn"
                                                        onClick={() => {
                                                            setFieldValue("buscar", "");
                                                            setProceso("")
                                                            setBuscar("")
                                                            setFieldValue("sucursalId", "");
                                                            setBusquedaAvanzada(
                                                                {
                                                                    buscarAvanzado: "",
                                                                    fechaInicio: "",
                                                                    fechaFin: "",
                                                                    concluido: ""
                                                                }
                                                            );
                                                        }}
                                                    >
                                                        Limpiar filtro
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className='col-12'>
                                                <RowPages
                                                    setpageRows={setpageRows}
                                                />
                                                <div className='mx-4 table-responsive tbl-scroll'>
                                                    <DataTable
                                                        column={columns}
                                                        data={data}
                                                        editable={ValidatePermissionById(4, Section.RevisionSection, SubModule.Revision).Actualizar}
                                                        handleEdit={handleEdit}
                                                        pageCount={totalPages}
                                                        currentPage={currentPage - 1}
                                                        handlePageClick={handlePageClick}
                                                    />
                                                </div>

                                            </div>
                                        </div>

                                    </ValidatePermission>

                                </section>
                            </div>
                        </section>
                    </>
                )
                }
            </Formik>
            {
                isOpenModal &&
                <ModalCrearManual
                    isOpen={isOpenModal}
                    setIsOpen={setIsOpenModal}
                />
            }

            {
                isOpenModalSearch &&
                <ModalBusquedaAvanzada
                    isOpen={isOpenModalSearch}
                    setIsOpen={setIsOpenModalSearch}
                    busquedaAvanzada={busquedaAvanzada}
                    handleBusqueda={setBusquedaAvanzada}
                />
            }
        </>
    );
}

export default SeguimientoServiciosXTipo;