import React, { useEffect, useState } from 'react'
import Parametros from '../../Services/Parametros/Parametros';
import { FormatFecha } from '../../Utils/functions'


const ParametrosCarteraHooks = () => {
  const [strFiltro, setStrFiltro] = useState("")
  const [dataParametroSelect, setDataParametroSelect] = useState([]);
  const [parametroSelect, setParametroSelect] = useState("");
  const [dataCategorias, setDataCategorias] = useState([]);
  // const [dataCategoriasWithParameters, setDataCategoriasWithParameters] = useState([]);
  const [dataAllParametrosCartera, setDataAllParametrosCartera] = useState([]);
  const [dataAllParametrosCarteraFiltered, setDataAllParametrosCarteraFiltered] = useState([]);
  const [loading, setLoading] = useState(false)


  const columns = [
    // { field: 'id', headerName: '' },
    { field: 'indexId', headerName: '#' },
    { field: 'descripcion', headerName: 'Parámetro' },
    { field: 'valorpublico', headerName: 'Valor' },
    // { field: 'extravalue', headerName: 'Valor extra' },
    { field: 'last_update', headerName: 'Última Modificación' }
  ];

  // Use Inicial 
  useEffect(() => {
    handleGetAllParametrosCartera();
    handleGetCategories();
    // handleGetParametrosByCategoria()
  }, [])

  useEffect(() => {
    // Hacer Proceso para  strFiltro
    const filteredData = dataAllParametrosCartera.filter(item => {
      const nameMatches = strFiltro === '' || (item.descripcion && item.descripcion.toLowerCase().includes(strFiltro.toLowerCase()));

      return (
        nameMatches &&
        (parametroSelect === '' || item.id === parametroSelect)
      )
    })

    setDataAllParametrosCarteraFiltered(filteredData)
  }, [dataAllParametrosCartera, strFiltro, dataAllParametrosCartera, parametroSelect])

  useEffect(() => {
    // Hacer Proceso para  strFiltro
    // console.log("Aqui");
  }, [parametroSelect])

  useEffect(() => {

    // Hacer Proceso para  strFiltro
  }, [dataCategorias])

  // Consulta de apis
  /* Todos los parametros */
  const handleGetAllParametrosCartera = async () => {
    setLoading(true)
    await Parametros.GetAllParametrosCartera()
      .then(res => {
        let NewData = [
          { value: '', label: 'Parámetro' }
        ]

        res.data.responseData?.map(item => {
          NewData.push({
            value: item.id,
            label: item.descripcion
          })
          // }
        })

        let datosFormateados = res.data.responseData?.map((item) => {
          const fechaPago = FormatFecha(item.dateupdate)
          return {
            ...item,
            last_update: fechaPago
          }
        })

        setDataParametroSelect(NewData)
        setDataAllParametrosCartera(datosFormateados)
        setLoading(false)

      }).catch(e => {
        setLoading(false)
      })
  }

  /* Todos los parametros */
  const handleGetCategories = async () => {
    await Parametros.GetAllParametrosCartera()
      .then(res => {
        let NewData = [];

        res.data.responseData?.map(item => {
          NewData.push(
            item.categoriapublica
          )
        })

        let result = NewData.filter((item, index) => {
          return NewData.indexOf(item) === index;
        })

        setDataCategorias(result)
      }).catch(e => {
      })
  }

  /* Todos los parametros */
  // const handleGetParametrosByCategoria = async (value) => {

  //   let data = {
  //     FIELD: "Categoria",
  //     VALUE: "seguimiento_contacto_telefonico"
  //   }

  //   await Parametros.GetAllParametrosByField(data)
  //     .then(res => {
  //       let NewData = [];

  //       res.data.responseData?.map(item => {
  //         NewData.push(
  //           value,
  //           item
  //         )
  //       })

  //       // return data
  //       setDataCategoriasWithParameters(NewData);
  //       // return res.data.responseData;
  //     });
  // }

  return {
    columns,
    loading,
    setStrFiltro,
    dataParametroSelect,
    setParametroSelect,
    dataCategorias,
    dataAllParametrosCartera,
    dataAllParametrosCarteraFiltered,
    handleGetAllParametrosCartera
  };
}
export default ParametrosCarteraHooks