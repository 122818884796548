import { useDispatch, useSelector } from "react-redux";
import { Section } from "../../../Auth/Authorization";
import { ValidatePermission, ValidatePermissionById } from "../../../Auth/ValidatePermission";
// import Tab from "../../../Components/Tabs/Tab";
// import Tabs from "../../../Components/Tabs/Tabs";
// import SeguimientoAutorizaciones from "./SeguimientoAutorizaciones";
// import SeguimientoCitasServicios from "./SeguimientoCitasServicios";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

import {tabActiveComisionesInventario} from '../../../Redux/Slice/navigateSliceComisionesInventario'

const SolicitudesDatamovilSystems = () => {
    const { value } = useSelector((state) => state.tabNavigateComisionesInventario);
    const dispatch = useDispatch();
   
    const navigate = useNavigate();
   
    useEffect(() => {
        handleVigate(value);
    }, [value])
   
    const handleSetStateTab = (value) => {
      dispatch(tabActiveComisionesInventario(value));
    }
    const handleVigate = (value) => {    
      if (ValidatePermissionById(3, Section.ServiciosDatamovilSection) && value === 1) {          
          navigate(`/datamovil/solicitudes/citasdatamovil`);
      }
      else if (ValidatePermissionById(3, Section.CitasServiciosDatamovilSection) && value === 2) {        
          navigate(`/datamovil/solicitudes/citasservicio`);              
      }     
    }
    return (
        <ul className="nav nav-tabs" id="myTab" role="tablist">
            <ValidatePermission isActive={ValidatePermissionById(3, Section.ServiciosDatamovilSection)}>
                <li className="nav-item" role="presentation">
                    <button className={`nav-link ${value == 1 && "tab-active"}`} value={1} type="button" role="tab" onClick={(e) => { handleSetStateTab(e.target.value) }}>Servicios Datamovil</button>
                </li>
            </ValidatePermission>
            <ValidatePermission isActive={ValidatePermissionById(3, Section.CitasServiciosDatamovilSection)}>
                <li className="nav-item" role="presentation">
                    <button className={`nav-link ${value === 2 && "tab-active"}`} value={2} type="button" role="tab" onClick={(e) => { handleSetStateTab(e.target.value) }}>Citas de Servicio Datamovil</button>
                </li>
            </ValidatePermission>           
        </ul>
    );
}

export default SolicitudesDatamovilSystems;