import { Field, Formik } from "formik";
import AsignacionServiciosSinAsignarHook from "../../../Hooks/DatamovilSystems/Asignaciones/AsignacionServiciosSinAsignarHook";
import Modal from "../../../Components/Modal/Modal";
import Spinner from "../../../Components/Loadig";
import { TextField } from "../../../Components/TextField";
import Select from "react-select";
import DataTable from "../../../Components/datatable/DataTable";
import { useParams } from "react-router-dom";
import { forwardRef, useImperativeHandle } from "react";
import { Toaster } from "react-hot-toast";
import RowPages from "../../../Components/RowPages";

const AsignacionServiciosSinAsignar = forwardRef(({ servicioAsignacion, onUpdated }, ref) => {
    const { folioId } = useParams();

    const {
        tipoUbicaciones,
        data,
        handleCheck,
        refFormik,
        selectRef,
        initialValue,
        modalMessage,
        setModalMessage,
        loading,
        setLoading,
        buscar,
        setBuscar,
        tipoServicios,
        columns,
        totalServicios,
        handleAsignar,
        getServiciosSinAsignar,
        setTipoUbicacion,
        setTipoServicio,
        totalPages,
        currenPage,
        handlePageClick,
        setRows,
        refConfirm
    } = AsignacionServiciosSinAsignarHook({ servicioAsignacion, onUpdated });


    useImperativeHandle(ref, () => ({
        getServiciosSinAsignar,
    }));

    return (
        <Formik
            innerRef={refFormik}
            enableReinitialize={true}
            initialValues={initialValue}
        // validationSchema={validate}
        >
            {({ isValid, values, setFieldValue, errors }) => (
                <>
                    <Toaster
                        position="top-right"
                        toastOptions={{
                            success: {
                                style: {
                                    background: '#47a066',
                                    color: '#FFFF',
                                    borderLeft: '10px solid #2f7246'
                                },
                            },
                            error: {
                                style: {
                                    background: '#d53f3f',
                                    color: '#FFFF',
                                    borderLeft: '10px solid #ac241a'
                                },
                            },
                        }}
                    />
                    <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                    <Modal isOpen={modalMessage.isOpen} color='#fff' width={600}>
                        {
                            modalMessage.type === 1 &&
                            <>
                                <section className="p-4">
                                    <div className="row">
                                        <div className="col-12">
                                            <h4 className="fw-bold">Confirma los cambios</h4>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <span className="text-sm">¿Estás seguro de los cambios realizados en la asignación: {folioId}?</span>
                                        </div>
                                    </div>
                                    <div className="row d-flex justify-content-end mt-4">
                                        <div className="col-12 col-md-auto">
                                            <button className="btn btn-outline" onClick={() => setModalMessage({ isOpen: false })}>
                                                Regresar
                                            </button>
                                        </div>
                                        <div className="col-12 col-md-auto">
                                            <button className="btn" ref={refConfirm} onClick={() => handleAsignar(values)}>
                                                Confirmar
                                            </button>
                                        </div>
                                    </div>
                                </section>
                            </>
                        }
                    </Modal>
                    <div className="row mt-4">
                        <div className="col-12 col-md-12">
                            <div className="row mb-2 justify-content-between">
                                <div className="col-12 col-md-auto">
                                    <strong className='mt-2'>Servicios no asignados</strong>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="row justify-content-end align-items-center">
                                        <div className="col-auto">
                                            <Field className="form-check-input"
                                                id="concluido"
                                                disabled={initialValue.concluido}
                                                type="checkbox" name="concluido"
                                                onClick={(event) => {
                                                    setFieldValue("concluido", event.target.value);
                                                }} />
                                            <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Configuración finalizada</label>
                                        </div>
                                        <div className="col-auto">
                                            <button className="btn" onClick={() => setModalMessage({ isOpen: true, type: 1 })}>
                                                Confirmar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-1">
                        <div className="col-12 col-md-10">
                            <div className="row">
                                <div className="col-12 col-md-3 mr-1 inner-addon right-addon">
                                    <i className="glyphicon fas fa-search"></i>
                                    <TextField id="buscar" label="" name="buscar" type="text" holder="Escribe" onChange={(event) => {
                                        setFieldValue("buscar", event.target.value);
                                        setBuscar(event.target.value)
                                    }} />
                                </div>
                                <div className="col-12 col-md-4 col-lg-3">
                                    <Select ref={selectRef} options={tipoServicios} className='col-12' onChange={(e) => {
                                        setFieldValue("estatus", e.value);
                                        setTipoServicio(e.value);
                                        // getListado()
                                    }} placeholder="Tipo de servicio" />
                                </div>
                                <div className="col-12 col-md-4 col-lg-3">
                                    <Select ref={selectRef} options={tipoUbicaciones} className='col-12' onChange={(e) => {
                                        setFieldValue("estatus", e.value);
                                        setTipoUbicacion(e.value);
                                        // getListado()
                                    }} placeholder="Tipo de ubicación" />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-2">
                            <div className="row">
                                <div className="col-12 d-flex justify-content-end">
                                    <span>Servicios seleccionados: {totalServicios}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="d-flex justify-content-end">
                        <RowPages
                            setpageRows={setRows}
                        />
                    </div> */}
                    <div className="row mt-2">
                        <div className='col-12'>
                            {
                                loading &&
                                <DataTable
                                    column={columns}
                                    data={[]}
                                    headerColorClass={'header-secondary'}
                                />
                            }
                            {
                                !loading &&
                                <DataTable
                                    column={columns}
                                    data={data}
                                    checkbox={true}
                                    handleCheck={handleCheck}
                                    paginate={false}
                                    headerColorClass={'header-secondary'}
                                    actions={false}
                                    noAddons={true}     
                                    pageCount={totalPages}
                                    handlePageClick={handlePageClick}
                                    currentPage={currenPage - 1}                               
                                />
                            }
                        </div>
                    </div>
                </>
            )
            }
        </Formik>
    );
});

export default AsignacionServiciosSinAsignar;