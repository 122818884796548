import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router';
import SeguroSinCotizarService from '../../../Services/Seguros/seguroSinCotizarService';
import SeguroPolizaService from '../../../Services/Seguros/SeguroPolizaService';
import { handleIsActiveCheck, message } from '../../../Utils/functions';
import CancelacionSeguroService from '../../../Services/Seguros/CancelacionSeguroService';
import AutorizarDictamenService from '../../../Services/Seguros/AutorizarDictamenService';
import { Proceso } from '../../../Utils/constan';

const useAutorizacionSolicitud = (toast) => {    
    const columns = [
        { field: 'folio', headerName: 'Folio cita', width: 150 },
        // { field: 'tipoCita', headerName: 'Tipo de cita', width: 150 },
        { field: 'contrato', headerName: 'Contrato', width: 150 },
        { field: 'proceso', headerName: 'Proceso', width: 150 },         
        { field: 'tipoUbicacion', headerName: 'Tipo Ubicación', width: 150 },                
        { field: 'ejecutivo', headerName: 'Cobrador', width: 150 },
        { field: 'fechaHoraCita', headerName: 'Fecha y hora de cita', width: 250 },
        { field: 'ubicacionOrigen', headerName: 'Ubicación origen', width: 200 },
        // { field: 'comentariosUI', headerName: 'Comentarios Ubicación', width: 250 },
        { field: 'ubicacionDestino', headerName: 'Ubicación Destino', width: 200 },
    ];

    const [data, setData] = useState([]);
    const [currenPage, setCurrenPage] = useState(1);
    const [pageCount, setPageCount] = useState();
    const [str, setStr] = useState("");
    const [loading, setLoading] = useState(false); 
    const [pageRows, setpageRows] = useState(10);
    const [openModal, setOpenModal] = useState(false)
    const [itemSelected, setItemSelected] = useState();

    const [tipoUbicacionId, setTipoUbicacionId] = useState("");
    useEffect(() => {
        habdleGetAll(currenPage);
        setCurrenPage(1);
    }, [str,pageRows,tipoUbicacionId])
    useEffect(() => {
        handleGetTipoCompra();
        handleGetAllUsoUnidad();        
    }, [])


    const habdleGetAll = async (page) => {
        setLoading(true);
        await AutorizarDictamenService.GetAllAutorizarCitas(page, pageRows, str,Proceso.Valuacion,"","",23,tipoUbicacionId)
            .then(res => {

                // let newData = res.data.data.map(item => {
                //     return { ...item,
                //     comentariosUI: item.comentariosRU
                //     }
                // })
                setData( res.data.data);
                setPageCount(res.data.totalPages);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }   
    const handleGetAllUsoUnidad = async () => {
        await SeguroSinCotizarService.getlAllUsoUnidad()
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.usoUnidadId,
                        label: item.usoUnidad
                    })
                });
                // setDataUsoUnidad(NewData);
            }).catch(e => {

            })
    }
    const handleGetTipoCompra = async () => {
        await SeguroPolizaService.getAllTipocompra()
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.tipoCompraId,
                        label: item.tipoCompra
                    })
                });
                // settipoCompra(NewData);
            }).catch(e => {
            })
    }   

    const handlePageClick = (event) => {
        setCurrenPage(event.selected + 1);
        habdleGetAll(event.selected + 1);
    };

    return {
        columns,
        data,
        handlePageClick,
        currenPage,
        pageCount,
        setStr,
        loading,
        setpageRows,
        setOpenModal,
        openModal,
        habdleGetAll,
        setItemSelected,
        itemSelected,
        setTipoUbicacionId
    }
}

export default useAutorizacionSolicitud