import React, { useState, useRef, useEffect } from 'react';
import { Formik, Form } from 'formik';
import Modal from '../../../../Components/Modal/Modal';
import Spinner from '../../../../Components/Loadig';
import DataTable from '../../../../Components/datatable/DataTable';
import DSystemsServicios from '../../../../Services/DatamovilSystems/ServiciosDatamovil';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const HistorialServicio = () => {

    let initial = {
        "totalRows": 1,
        "servicioLogId": "",
        "servicioId": "",
        "fechaHora": "",
        "usuario": "",
        "vehiculoEnUbicacion": false,
        "accederUbicacion": true,
        "accederVehiculo": true,
        "comentarios": ""
    }

    const refFormik = useRef();
    const [collapsible, setCollapsible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dataValue, setDataValue] = useState(initial);
    const { id } = useParams();

    const columns = [
        { field: 'usuario', headerName: 'Usuario Solicita', width: '200' },
        { field: 'vehiculoEnUbicacion', headerName: 'Vehículo en Ubicación', width: '200' },
        { field: 'accederUbicacion', headerName: 'Acceder Ubicación', width: '200' },
        { field: 'accederVehiculo', headerName: 'Acceder Vehículo', width: '200' },
        { field: 'comentarios', headerName: 'Comentarios', width: '300' },
        { field: 'fechaHora', headerName: 'Fecha y Hora', width: '200' },

    ];

    useEffect(() => {
        getDataLogService()
    }, [])

    const getDataLogService = async () => {
        await DSystemsServicios.getDataLogService(id) //state.procesoId
            .then((res) => {
                let resData = res.data.map(item => ({
                    ...item,
                    vehiculoEnUbicacion: item.vehiculoEnUbicacion ? "Si" : "No",
                    accederUbicacion: item.accederUbicacion ? "Si" : "No",
                    accederVehiculo: item.accederVehiculo ? "Si" : "No"
                }));
                setDataValue(resData)
            })
            .catch((error) => {
                console.log(error);
            }).finally(() => {

            })
    }
    return (
        (Object.keys(dataValue).length > 0) &&
        <>
            <Modal isOpen={loading} color='transparent'>
                <Spinner message={"Cargando..."} />
            </Modal>
            <div className='row mx-md-4 my-4'>
                <div className="col-12 px-md-3">
                    <div className="p-4 white-wrapper mb-2">
                        <div
                            className="row mt-2"
                            data-bs-toggle="collapse"
                            href="#collapseInfoService"
                            role="button"
                            aria-expanded="false"
                            aria-controls="collapseInfoService"
                            onClick={() => setCollapsible(!collapsible)}
                        >
                            <div className="col-11">
                                <h6><strong>Historial de servicio</strong></h6>
                            </div>
                            <div className="col-1 text-end">
                                <i className={!collapsible ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                            </div>
                        </div>
                        <div className="collapse" id="collapseInfoService">
                            <div className="divider"></div>
                            <Formik innerRef={refFormik} enableReinitialize>
                                {() => (
                                    <Form>
                                        <div className='row mt-3'>
                                            <div className="col-md-12">
                                                <DataTable
                                                    column={columns}
                                                    data={dataValue}
                                                />
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default HistorialServicio;
