import React from 'react'
import ValuacionDetalle from '../ValuacionDetalle'
import ContratoHeaderCard from '../../../Seguro/Siniestros/ContratoHeaderCard'
import RegistroDeCostosCard from './RegistroDeCostosCard'
import Modal from '../../../../Components/Modal/Modal'
import Spinner from '../../../../Components/Loadig'
import { toast, Toaster } from 'react-hot-toast';
import VehiculoInfoCard from '../../../../Components/VehiculoInfoCard'
import useRegistroDeGastos from '../../../../Hooks/Inventario/Valuacion/useRegistroDeGastos'
import ModalConfirm from '../ModalConfirm'
import ContratoCard from '../../../../Components/ContratoCard'

const RegistroDeCostos = () => {

    const {
        stepe,
        handleClickTerminar,
        activebtncontinuar,
        loading,
        handleChangeStatusBtn,
        modalConfirm,
        setModalConfirm,
        handleClickTerminarProceso,
        state
    } = useRegistroDeGastos(toast);

    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <ValuacionDetalle
                dataStep={stepe}
                handleClickContinuar={handleClickTerminar}
                activeBtn={activebtncontinuar}
            />
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <div className='d-flex flex-column background-grey' style={{ height: '100vh' }}>
                <div className='row mx-md-4 my-4'>
                    <div className="col-6 px-md-3">
                        <VehiculoInfoCard GeneralId={state?.generalId} verClaves={true} solicitarClave={false} />
                    </div>
                    <div className="col-6 px-md-3">
                        <ContratoCard vin={state?.vin} />
                    </div>
                </div>
                <RegistroDeCostosCard
                
                    handleConcluido={handleChangeStatusBtn}
                />
                {
                    modalConfirm &&
                    <ModalConfirm
                        isOpen={modalConfirm}
                        setIsOpen={setModalConfirm}
                        title={"Registro de gastos"}
                        handleConfirm={handleClickTerminarProceso}
                    />
                }
            </div>
        </>
    )
}

export default RegistroDeCostos